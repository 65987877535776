import axios from 'axios'

class RatingsService {
    constructor() {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async getBranchGivenRatings(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/ratings/given`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getBranchReceivedRatings(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/ratings`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getBranchOpenRatings(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/bookings?mode=rateable`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async branchAddRating(branchId, bookingId, punctuality, kindness, quality) {
        try {
            const response = await axios.post(
                `${this.apiBase}/branch/${branchId}/ratings`,
                {
                    booking_id: bookingId,
                    punctuality,
                    kindness,
                    quality,
                },
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getFreelancerOpenRatings() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/bookings?mode=rateable`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getFreelancerGivenRatings() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/ratings/given`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getFreelancerReceivedRatings() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/ratings`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async freelancerAddRating(bookingId, equipment, neatness, support) {
        try {
            const response = await axios.post(
                `${this.apiBase}/me/ratings`,
                {
                    booking_id: bookingId,
                    equipment: equipment,
                    neatness: neatness,
                    support: support,
                },
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }
}

export default RatingsService
