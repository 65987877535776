import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { DEVICE, PRIMARY_COLOR } from '../assets/constants'
import { COLORS, SPACING } from '../../assets/constants'

export const FlexDiv = styled.div`
    display: flex;
    justify-content: ${(props) => (props.hcentered ? 'center' : 'initial')};
    align-items: ${(props) => (props.vcentered ? 'center' : 'initial')};

    > * {
        flex: 1 1 0;
        width: 0;
    }
`

export const StyledWrapper = styled.div`
    position: relative;
    ${(props) => !props.noBackground && `background: #ffffff;`}
    ${(props) =>
        !props.noBackground && `box-shadow: 1px 1px 10px 2px rgba(0,0,0,0.10);`}
    ${(props) => !props.noBackground && `padding: 30px;`}
    ${(props) => props.noBackground && `margin-bottom: 40px !important;`}
    border-radius: 5px;
    font-family: Montserrat, sans-serif;

    * {
        font-family: Montserrat, sans-serif;
    }
`

export const ClickableLink = styled.p`
    text-align: center;
    font-size: 0.8em;
    margin-top: 35px;
    margin-bottom: 15px;
    color: #db64ff;
    cursor: pointer;

    &:hover {
        filter: brightness(120%);
    }
`

export const SubTitle = styled.h6`
    text-transform: uppercase;
    color: #c3b6c7;
    font-size: 0.7em;
    letter-spacing: 0.06em;
`

export const SubHeading = styled.h3`
    font-family: Montserrat, Sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.02em;
`

export const SecondarySubHeading = styled.h4`
    font-family: Montserrat, Sans-serif;
    font-weight: 700;
    text-transform: none !important;
`

export const StyledButton = styled.button`
    font-family: Montserrat, sans-serif;
    border: none;
    background-color: ${(props) =>
        props.primary ? '#db64ff' : props.tertiary ? 'transparent' : '#C3B6C7'};
    color: ${(props) =>
        props.color ? props.color : props.tertiary ? '#bbbbbb' : '#ffffff'};
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    padding: ${(props) => (props.tertiary ? '0' : '11px')};
    transition: filter 0.2s;
    cursor: pointer;

    &:hover {
        filter: brightness(110%);
    }

    &:focus {
        outline: 0;
    }

    &[disabled] {
        background-color: #c3b6c7;
        opacity: 0.6;
        pointer-events: none;
    }
`

export const StyledPrimaryButton = styled.button`
    font-family: Montserrat, sans-serif;
    border: none;
    background-color: #db64ff;
    color: #ffffff;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    padding: 11px;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover {
        filter: brightness(110%);
    }

    &:focus {
        outline: 0;
    }

    &[disabled] {
        background-color: #c3b6c7;
        opacity: 0.6;
        pointer-events: none;
    }
`

export const StyledTertiaryButton = styled.button`
    font-family: Montserrat, sans-serif;
    border: none;
    background-color: transparent;
    color: #bbbbbb;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover {
        filter: brightness(110%);
    }

    &:focus {
        outline: 0;
    }

    &[disabled] {
        background-color: #c3b6c7;
        opacity: 0.6;
        pointer-events: none;
    }
`

export const SaveButton = styled(StyledButton)`
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 25px;
    float: right;

    @media ${DEVICE.phone} {
        width: 100%;
        padding: 9px;
        font-size: 13px;
    }
`

export const StyledSubmit = styled.input`
    &[type='submit'] {
        font-family: Montserrat, sans-serif;
        border: none;
        background-color: ${(props) =>
            props.primary
                ? '#db64ff'
                : props.tertiary
                ? 'transparent'
                : '#C3B6C7'};
        color: ${(props) => (props.tertiary ? '#bbbbbb' : '#ffffff')};
        border-radius: 50px;
        font-weight: 600;
        font-size: 14px;
        padding: ${(props) => (props.tertiary ? '0' : '11px')};
        transition: filter 0.2s;

        &:hover {
            filter: brightness(110%);
        }

        &:focus {
            outline: 0;
        }

        &[disabled] {
            filter: grayscale(1);
            opacity: 0.6;
            pointer-events: none;
        }
    }
`

export const Backdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0000004d;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const InputSection = styled.div`
    font-family: Montserrat, sans-serif;

    > div,
    input {
        font-family: Montserrat, sans-serif;
        width: 100%;
        font-size: 1em;
    }

    a {
        color: ${PRIMARY_COLOR};
    }

    select {
        padding: 10px 55px 10px 12px;
    }

    input {
        border-radius: 3px;
        background-color: transparent;
        color: #1c1c21;
        border: 1px solid;
        border-color: #ebebeb;
        padding: 10px 12px;

        &[disabled] {
            background: #f7f7f7;
            color: #727272;
        }

        &[type='checkbox'] {
            display: none;

            & + label {
                cursor: pointer;
            }

            & + label:before {
                width: 15px;
                height: 15px;
                background-color: #e9e9e9;
                display: block;
                content: '';
                float: left;
                margin-right: 10px;
                margin-top: 6px;
                cursor: pointer;
            }

            &:checked + label:before {
                background-color: ${PRIMARY_COLOR};
            }
        }
    }
`

export const StyledDatePicker = styled(DatePicker)`
    font-family: Montserrat, sans-serif;
    font-size: 1em;
    padding: 8px 10px;
    ${(props) => props.lowercase && 'text-transform: lowercase;'}
`

export const StyledSelect = styled.select`
    font-family: Montserrat, sans-serif;
    font-size: 1em;
    padding: 8px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid;
    border-color: #ebebeb;
    border-radius: 3px;

    background-image: linear-gradient(
            45deg,
            transparent 50%,
            ${PRIMARY_COLOR} 50%
        ),
        linear-gradient(135deg, ${PRIMARY_COLOR} 50%, transparent 50%),
        linear-gradient(to right, #ebebeb, #ebebeb);
    background-position: calc(100% - 20px) calc(1em + 4px),
        calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.65em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
`

export const StyledLabel = styled.label`
    text-transform: uppercase;
    font-weight: 700;
    color: #c3b6c7;
    font-size: 0.6em;
    letter-spacing: 0.06em;
    display: block;
    margin-bottom: 5px;
    white-space: nowrap;

    @media ${DEVICE.phone} {
        font-size: 0.7em;
    }

    @media ${DEVICE.short_phone} {
        font-size: 0.6em;
    }
`

export const AvailabilityBadge = styled.span`
    padding: 5px 0;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 600;
    width: 100%;
`

export const FormWrapper = styled.div`
    @media ${DEVICE.phone} {
        div {
            flex-direction: column;
        }
    }
`

export const TextButton = styled.button`
    font-size: 0.9em;
    font-family: Montserrat, sans-serif;
    border: none;
    padding: 0;
    color: ${PRIMARY_COLOR};
    font-weight: bold;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        filter: brightness(110%);
    }

    &:active,
    &:focus {
        outline: 0;
    }

    @media ${DEVICE.phone} {
        font-size: 1em;
    }
`

export const ListElementWrapper = styled(StyledWrapper)`
    display: flex;
    ${(props) => !props.noBackground && `padding: 20px 30px;`}
    margin-bottom: ${(props) => (props.noMargin === true ? '2px' : '7px')};
    height: ${(props) =>
        props.itemsAutoHeight
            ? 'auto'
            : props.isSubItem
            ? '55px'
            : props.height
            ? `${props.height}px`
            : '75px'};
    transition: transform 0.3s;

    &:hover {
        ${(props) =>
            !props.noBackground &&
            `transform: scale(1.007);`}/* cursor: pointer; */
        /* background-color: ${COLORS.background}; */
        z-index:50 !important;
    }

    & > * {
        flex: 1 1 0;
        width: 0;
        min-width: 0;

        &:last-child {
            flex: 0.2 1 0;
            justify-content: flex-end;
            padding: 0;
            position: ${(props) =>
                props.itemsAutoHeight ? 'absolute' : 'initial'};
            right: 30px;
        }
    }

    @media ${DEVICE.phone} {
        font-size: 0.8em;
        padding: 15px 15px;
        height: ${(props) => (props.itemsAutoHeight ? 'auto' : '65px')};
    }
`

export const ListElement = styled.div`
    font-size: 1em;
    font-weight: normal;
    display: flex;
    align-items: ${(props) =>
        props.itemsAutoHeight ? 'flex-start' : 'center'};
    padding-right: ${(props) => (props.itemsAutoHeight ? '0' : '0.9em')};
    visibility: ${(props) => (props.hidden === true ? 'hidden' : 'visible')};

    i {
        font-style: initial;
        font-weight: 600;
        font-size: 0.8em;
        color: #c3b6c7;
    }

    b {
        word-break: break-word;
        hyphens: auto;
    }

    @media ${DEVICE.phone} {
        i {
            overflow: hidden;
            hyphens: auto;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
`

export const InfoMessage = styled.span`
    display: block;
    font-size: 1.1em;
    font-weight: normal;
    line-height: 2;
`

export const FormValue = styled.p`
    font-weight: bold;

    @media ${DEVICE.phone} {
        font-size: 0.8rem !important;
        word-wrap: break-word;
    }
`

export const PopupInfo = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: ${SPACING.medium}px;

    @media ${DEVICE.phone} {
        align-items: flex-start;
        flex-direction: column;
    }
`

export const RatingSection = styled.div`
    margin-top: 35px;
    text-align: center;

    @media ${DEVICE.phone} {
        margin-top: 15px;
    }
`

export const CourseWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`

export const RatingRemark = styled.p`
    text-align: center;
    font-size: 0.8em;
    margin-top: 35px;
    margin-bottom: 0;

    @media ${DEVICE.phone} {
        margin-top: 15px;
    }
`
