import { css } from "styled-components"

export { }

export const COLORS = {
    light: '#ffffff',
    dark: '#000000',
    lightgrey: '#f5f3f6',
    grey: '#e7e7e7',
    primary: '#db64ff',
    primaryFaded: '#ccb9d2',
    secondary: '#67d74e',
    secondaryFaded: '#67d74e',
    textPrimary: 'black',
    textSecondary: 'grey',
    background: '#f9f9f9',
    backgroundPrimary: '#c3b6c7',
    faded: '#e9e9e9',
    onRequest: '#9BC74C',
    medium: '#C7FF61',
    high: '#DEFFA2'
}

export const SPACING = {
    xsmall: 8,
    small: 12,
    medium: 16,
    large: 22,
    xlarge: 36,
}

export const MENU_WIDTH = 350;
export const BREAKPOINT_TABLET = 1167;
export const BREAKPOINT_PHONE = 767;

export const SHADOW = {
    soft: 'box-shadow: 3px 3px 15px 1px rgba(209,209,209,1);',
    sharp: 'box-shadow: 1px 1px 6px 1px rgb(56, 43, 43, 0.2);',
    inner: 'box-shadow: inset 0 0 15px rgba(145,145,145,1);',
}

export const RADIUS = {
    tiny: 4,
    small: 12,
    large: 18,
}

export const DEVICE = {
    phone: `(max-width: 767px)`,
    short_phone: `(max-height: 790px) and (max-width: 767px)`,
    tablet: `(max-width: 1167px)`,
};

export const NO_SCROLL_BARS = css`
     -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
`;