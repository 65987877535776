import React, { Component } from 'react'
import { FlexDiv, StyledButton, StyledWrapper } from '../styled-components'
import styled from 'styled-components'
import { PRIMARY_COLOR, SECONDARY_COLOR, DEVICE } from '../../assets/constants'
import { MdInfoOutline } from 'react-icons/md'
import JobbelIcon from '../../assets/images/jobbelganger-icon.png'
import Loader from '../loader'
import CompanyDataForm from '../account-setup/company-data-form'
import BranchDataForm from '../account-setup/branch-data-form'
import PopupMessage from '../popup-message'
import { withTranslation } from 'react-i18next'
import AccountService from '../../../services/account-service'
import BranchService from '../../../services/branch-service'

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const InfoPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: ${SECONDARY_COLOR};
    color: #fff;
    flex: 0.4 1 0 !important;
    margin: -30px;
    padding: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media ${DEVICE.phone} {
        display: none;
    }

    @media ${DEVICE.tablet} {
        display: none;
    }
`

const SetupDiv = styled(FlexDiv)`
    min-height: 925px;
    height: 65vh;

    @media ${DEVICE.tablet} {
        height: 100vh;
    }

    @media ${DEVICE.phone} {
        height: auto;
        min-height: auto;
    }
`

const Step = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    position: relative;
    margin: ${(props) => (props.active ? '0 -10px 0 -10px' : 'initial')};
    flex: 1 1 0;
    width: 0;
    cursor: pointer;
`

const StepCircle = styled.div`
    color: #ffffff;
    font-weight: bold;
    font-size: 0.7em;
    width: ${(props) => (props.active ? '30px' : '10px')};
    height: ${(props) => (props.active ? '30px' : '10px')};
    color: ${(props) => (props.active ? '#ffffff' : 'transparent')};
    background-color: ${(props) => (props.active ? '#9cc74c' : '#838383')};
    border-radius: ${(props) => (props.active ? '15px' : '5px')};
    display: flex;
    justify-content: center;
    align-items: center;
`

const StepDescription = styled.div`
    color: #9cc74c;
    font-weight: bold;
    text-align: center;
    margin-top: 25px;
    line-height: 1.2;
    transform: ${(props) => (props.active ? 'translateY(-10px)' : 'initial')};
    font-size: 0.8em;
    letter-spacing: 0;
    height: 4em;

    @media ${DEVICE.phone} {
        opacity: 0;
        margin-top: -24px;
    }
`

const LineWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    padding: 13px 25%;
`

const StepLine = styled.div`
    width: 100%;
    height: 4px;
    background: ${(props) => (props.active ? '#9cc74c' : '#838383')};
`

const SetupFormsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 60px;

    @media ${DEVICE.tablet} {
        margin-right: 0;
    }

    @media ${DEVICE.phone} {
        margin-right: 0;
    }
`

const StyledDescriptionHeader = styled.h2`
    @media ${DEVICE.tablet} {
        font-size: 1.2em;
    }

    @media ${DEVICE.phone} {
        font-size: 1.2em;
    }
`

const StyledDescription = styled.p`
    @media ${DEVICE.tablet} {
        font-size: 0.9em;
    }

    @media ${DEVICE.phone} {
        font-size: 0.9em;
    }
`

const MessageWrapper = styled.div`
    margin-bottom: 15px;

    p {
        margin-bottom: 5px !important;
        font-size: 0.7em;
    }

    a {
        color: ${PRIMARY_COLOR};
        cursor: pointer;
    }
`

class SetupWidget extends Component {
    constructor(props) {
        super(props)
        const { t, i18n } = props

        this.steps = [t('setup.chef.company'), t('setup.chef.branch')]
        this.stepDescriptions = [
            {
                title: t('setup.chef.company'),
                label: t('setup.chef.companyDescription'),
            },
            {
                title: t('setup.chef.branch'),
                label: t('setup.chef.branchDescription'),
                labelAlt: t('setup.chef.branchDescriptionAlt'),
            },
        ]

        this.state = {
            profile: undefined,
            currentStep: 0,
            isLoading: false,
            showMessage: false,
            message: '',
            company: {
                alreadySet: false,
                companyName: '',
                invoiceEmail: '',
                street: '',
                housenumber: '',
                addressAdditional: '',
                zipcode: '',
                city: '',
                state: '',
                country: 'DE',
                phone: '',
                website: '',
                isBranch: 'no',
                branchName: '',
            },
            branch: {
                name: '',
                email: '',
                street: '',
                housenumber: '',
                addressAdditional: '',
                zipcode: '',
                city: '',
                state: '',
                country: 'DE',
                phone: '',
                website: '',
            },
            countries: [],
        }

        this.accountService = new AccountService()
        this.branchService = new BranchService()
    }

    componentDidMount() {
        this.accountService.getCountries().then((res) => {
            if (res && res.data) {
                this.setState({ ...this.state, countries: res.data })
            }
        })

        this.accountService.getMe().then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    profile: res.data,
                    company: {
                        ...this.state.company,
                        companyName: res.data.companyHR,
                        branchName: res.data.businessID,
                    },
                })
            }
        })

        this.accountService.getMyCompany().then((res) => {
            if (res && res.data) {
                this.setCurrentStep(1)
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        alreadySet: true,
                        companyName: res.data.name,
                        invoiceEmail: res.data.email,
                        street: res.data.street,
                        housenumber: res.data.housenumber,
                        addressAdditional: res.data.adress_additional
                            ? res.data.adress_additional
                            : '',
                        zipcode: res.data.zipcode,
                        city: res.data.city,
                        state: res.data.state,
                        country: res.data.country,
                        phone: res.data.phone,
                        website: res.data.website ? res.data.website : '',
                        isBranch: 'no',
                        branchName: '',
                    },
                })
            }
        })
    }

    getSetupNavigation = (currentStep) => {
        return (
            <div style={{ display: 'flex', position: 'relative' }}>
                <LineWrapper>
                    {this.steps.map((stepDescription, index) => {
                        if (index === this.steps.length - 1) return
                        return (
                            <StepLine
                                key={index}
                                active={currentStep >= index + 1}
                            ></StepLine>
                        )
                    })}
                </LineWrapper>
                {this.steps.map((stepDescription, index) => {
                    return (
                        <Step
                            key={index}
                            onClick={() => this.setCurrentStep(index)}
                        >
                            <StepCircle active={currentStep >= index}>
                                {index + 1}
                            </StepCircle>
                            <StepDescription active={currentStep >= index}>
                                {stepDescription}
                            </StepDescription>
                        </Step>
                    )
                })}
            </div>
        )
    }

    setIsLoading = (loading) => {
        this.setState({ ...this.state, isLoading: loading })
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show })
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message })
    }

    setCurrentStep = (step) => {
        this.setState({ ...this.state, currentStep: step })
    }

    changeValue = (type, key, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                [key]: value,
            },
        })
    }

    finishSetup = async () => {
        const { company, branch } = this.state
        const {
            alreadySet,
            companyName,
            invoiceEmail,
            phone,
            website,
            street,
            housenumber,
            zipcode,
            city,
            state,
            country,
            addressAdditional,
            isBranch,
        } = company
        const { t, i18n } = this.props

        this.setIsLoading(true)

        try {
            if (!alreadySet) {
                const companyResult = await this.accountService.addCompany(
                    companyName,
                    invoiceEmail,
                    phone,
                    website,
                    street,
                    housenumber,
                    zipcode,
                    city,
                    state,
                    country,
                    isBranch === 'yes',
                    branch.name,
                    branch.email,
                    addressAdditional
                )

                this.setIsLoading(false)
                if (companyResult.success === false) {
                    this.setShowMessage(true)
                    this.setMessage(`<div>${companyResult.message}</div>`)
                    return
                } else {
                    if (isBranch === 'yes') {
                        const me = await this.accountService.getMe()
                        const u = me.data

                        if (u && u.role === 'chef') {
                            try {
                                const company =
                                    await this.accountService.getMyCompany()
                                const c = company.data
                                u.company = c
                            } catch (error) {
                                u.company = null
                            }
                        }

                        this.props.updateUser(u)

                        return
                    }

                    this.setState({
                        ...this.state,
                        company: {
                            alreadySet: true,
                        },
                    })
                }
            }

            this.setIsLoading(true)
            const branchResult = await this.branchService.addBranch(branch)
            this.setIsLoading(false)
            if (branchResult.success) {
                const me = await this.accountService.getMe()
                const u = me.data

                if (u && u.role === 'chef') {
                    try {
                        const company = await this.accountService.getMyCompany()
                        const c = company.data
                        u.company = c
                    } catch (error) {
                        u.company = null
                    }
                }

                this.props.updateUser(u)
            } else {
                this.setShowMessage(true)
                this.setMessage(`<div>${branchResult.message}</div>`)
            }
        } catch (error) {
            this.setIsLoading(false)
            this.setShowMessage(true)

            if (!error) {
                this.setMessage(`<div>${t('forms.unknownError')}</div>`)
            }

            if (error.message) {
                this.setMessage(`<div>${error.message}</div>`)
            }

            if (error.data) {
                this.setMessage(
                    `<div>${Object.values(error.data).map(
                        (error) => `<div>${error}</div>`
                    )}</div>`
                )
            }
        }
    }

    render() {
        const {
            profile,
            currentStep,
            company,
            branch,
            isLoading,
            showMessage,
            message,
            countries,
        } = this.state
        const { t, i18n } = this.props

        return (
            <>
                <StyledWrapper style={{ maxWidth: 1050 }}>
                    <SetupDiv>
                        <SetupFormsWrapper>
                            {this.getSetupNavigation(currentStep)}
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {currentStep === 0 && (
                                    <>
                                        <StyledDescriptionHeader>
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].title
                                            }
                                        </StyledDescriptionHeader>
                                        <StyledDescription
                                            style={{ marginBottom: 25 }}
                                        >
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].label
                                            }
                                        </StyledDescription>
                                        <CompanyDataForm
                                            company={company}
                                            countries={countries}
                                            changeValue={this.changeValue}
                                            nextStep={() =>
                                                this.setCurrentStep(1)
                                            }
                                            logout={this.props.logout}
                                        />
                                    </>
                                )}
                                {currentStep === 1 && (
                                    <>
                                        <StyledDescriptionHeader>
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].title
                                            }
                                        </StyledDescriptionHeader>
                                        <StyledDescription
                                            style={{ marginBottom: 25 }}
                                        >
                                            {company.isBranch === 'yes'
                                                ? this.stepDescriptions[
                                                      currentStep
                                                  ].label
                                                : this.stepDescriptions[
                                                      currentStep
                                                  ].labelAlt}
                                        </StyledDescription>
                                        <BranchDataForm
                                            branch={branch}
                                            company={company}
                                            countries={countries}
                                            isBranch={company.isBranch}
                                            changeValue={this.changeValue}
                                            finishSetup={this.finishSetup}
                                            logout={this.props.logout}
                                        />
                                    </>
                                )}
                            </div>
                        </SetupFormsWrapper>

                        <InfoPanel>
                            <img
                                src={JobbelIcon}
                                width={35}
                                style={{ marginBottom: 20 }}
                            />
                            <h1 style={{ color: '#ffffff', marginTop: 5 }}>
                                {t('setup.yourJobbel')}
                            </h1>
                            <p>
                                {t('setup.welcome')}{' '}
                                {profile &&
                                    `${profile.firstname} ${profile.lastname}`}
                                !
                            </p>
                            <p>{profile && `${profile.email}`}</p>
                        </InfoPanel>
                    </SetupDiv>
                </StyledWrapper>

                {isLoading && <Loader />}

                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
            </>
        )
    }
}

export default withTranslation()(SetupWidget)
