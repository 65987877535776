import React, { Component } from 'react';
import { StyledButton, SubTitle } from "./styled-components";
import Popup from "./popup";
import styled from "styled-components";
import { formatAMPM, formatMoney, formatTimeString } from "../utils/utility-functions";
import { MdPhone, MdEmail } from "react-icons/md";
import dateFormat from "dateformat";
import { withTranslation } from "react-i18next";
import { SPACING } from '../../assets/constants';

const PopupSection = styled.div`
    margin-bottom: 15px;
    flex: 1 1 0;
`;

class BookingsPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            booking: this.props.booking,
        }
    }


    render() {
        const { hidePopup, t, currentLanguage } = this.props;
        const { booking } = this.state;

        let dateformat = 'd. mmmm yyyy';
        let convertedStarttime = formatTimeString(booking.starttime);
        let convertedEndtime = formatTimeString(booking.endtime);

        if (currentLanguage === 'en') {
            dateformat = 'mm/dd/yyyy';
            convertedStarttime = formatAMPM(convertedStarttime, true);
            convertedEndtime = formatAMPM(convertedEndtime, true);
        }

        return <Popup title={t('popup.bookingDetails')} hidePopup={hidePopup}>
            <p>{t('popup.bookingDescription')}</p>
            <div style={{ marginTop: SPACING.medium }}>
                {
                    booking &&
                    <>
                        <PopupSection>
                            <SubTitle>{t('popup.trainer')}</SubTitle>
                            <div>
                                <p>
                                    {booking.fl_firstname} {booking.fl_lastname}<br />
                                </p>
                                <div>
                                    <MdPhone style={{ marginBottom: -2, color: '#db64ff' }} />
                                    <a href={`tel:${booking.fl_phone}`}>{booking.fl_phone ? booking.fl_phone : '-'}</a><br />
                                    <MdEmail style={{ marginBottom: -2, color: '#db64ff' }} />
                                    <a style={{ wordWrap: 'break-word' }} href={`mailto:${booking.fl_email}`}>{booking.fl_email ? booking.fl_email : '-'}</a>
                                </div>
                            </div>
                        </PopupSection>
                        <PopupSection>
                            <SubTitle>{t('popup.cost')}</SubTitle>
                            <p>{formatMoney(booking.costs + booking.surcharge)} {booking.currency}</p>
                        </PopupSection>
                    </>
                }
                <PopupSection>
                    <SubTitle>{t('popup.job')}</SubTitle>
                    <p>{booking && booking.course}</p>
                </PopupSection>
                <PopupSection>
                    <SubTitle>{t('popup.dateAndTime')}</SubTitle>
                    <p>{booking && convertedStarttime} - {booking && convertedEndtime} {t('popup.clock')}<br />{booking && dateFormat(booking.date, dateformat)}
                    </p>

                </PopupSection>
            </div>
            <StyledButton tertiary onClick={hidePopup}
                style={{ width: '100%', marginTop: 15 }}>OK</StyledButton>
        </Popup>
    }
}

export default withTranslation()(BookingsPopup);