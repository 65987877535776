import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import SearchService from "../services/search-service";

const Start = () => {

    const searchService = new SearchService();
    const { t } = useTranslation();
    const { user } = useAuth();
    const [fRequests, setFRequests] = useState([]);

    useEffect(() => {
        const initData = async () => {
            const response = await searchService.getFreelancerRequests();
            if (response && response.data) setFRequests(response.data);
        };

        if (user.role === 'freelancer') initData();
    }, []);


    return (
        user.role === 'freelancer' && fRequests.length === 0
            ? <Redirect to={`/${t('routes.bookings')}`} />
            : user.role === 'freelancer'
                ? <Redirect to={`/${t('routes.openRequests')}`} />
                : <Redirect to={`/${t('routes.openSearchRequests')}`} />
    );

}

export default Start;