import { useTranslation } from "react-i18next";
import Courses from "../../legacy/components/courses/courses";

const Jobs = () => {

    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.courses')}
        </h1>

        <p>
            {t('pages.description.courses')}
        </p>

        <Courses />

    </>;

}

export default Jobs;