import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import BookingsWidget from "../../legacy/components/widgets/bookings-widget";
import FreelancerBookingsWidget from "../../legacy/components/widgets/freelancer-bookings-widget";

const Bookings = () => {

    const { user } = useAuth();
    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.bookings')}
        </h1>

        <p>
            {/* empty but used for generic spacing */}
        </p>

        {user && user.role === 'freelancer'
            ? <FreelancerBookingsWidget />
            : <BookingsWidget />}

    </>;

}

export default Bookings;