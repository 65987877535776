import React, { Component } from 'react'
import styled from 'styled-components'
import { ListElement, ListElementWrapper } from './styled-components'
import ReactHtmlParser from 'react-html-parser'
import { DEVICE } from '../assets/constants'
import Pagination from "./pagination";

const ListHeaderWrapper = styled.div`
    display: flex;
    padding: 0 30px;

    & > * {
        flex: 1 1 0;
        width: 0;
        min-width: 0;
        padding-right: 0.9em;

        &:last-child {
            flex: 0.2 1 0;
            padding: 0;
        }
    }

    @media ${DEVICE.phone} {
        padding: 0 15px;

        & > * {
            font-size: 0.7em !important;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`

const ListHeaderElement = styled.div`
    font-size: 1em;
    font-weight: 600;
    color: #bdbdbd;
    font-family: Montserrat, sans-serif;
    margin-bottom: 5px;
`

const NoItemMessageWrapper = styled.div`
    font-family: Montserrat, sans-serif;
    padding: 30px;

    i {
        font-size: 0.7em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-style: normal;
        color: #bdbdbd;
        flex: 1 !important;
        display: flex;
        justify-content: center !important;
        align-items: center;
    }
`

class List extends Component {
    constructor(props) {
        super(props)

        this.state = {
            items: this.props.items,
            currentPage: 1,
            pageSize: this.props.pageSize ?? 10
        }
    }

    setCurrentPage = (page) => {
        this.setState({ currentPage: page })
    }

    getCurrentItems = () => {
        const firstPageIndex = (this.state.currentPage - 1) * this.state.pageSize;
        const lastPageIndex = firstPageIndex + this.state.pageSize;
        return this.state.items.slice(firstPageIndex, lastPageIndex);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.items !== this.props.items) {
            this.setState({ items: this.props.items })
        }
    }

    render() {
        const { items, currentPage, pageSize } = this.state
        const {
            headings,
            getListItemStructure,
            noItemsMessage,
            itemsAutoHeight,
            height,
            noBackground,
            sameWidthElements,
        } = this.props

        return (
            <>
                <div style={this.props.style}>
                {items && items.length !== 0 && (
                    <ListHeaderWrapper>
                        {headings &&
                            headings.map((heading, index) => {
                                return (
                                    <ListHeaderElement key={index}>
                                        {heading}
                                    </ListHeaderElement>
                                )
                            })}
                    </ListHeaderWrapper>
                )}
                {items &&
                    this.getCurrentItems().map((item, wrapperIndex) => {
                        const itemStructure = getListItemStructure(
                            item,
                            wrapperIndex
                        )
                        return (
                            <ListElementWrapper
                                key={wrapperIndex}
                                noBackground={noBackground}
                                noMargin={item.noMargin}
                                height={height}
                                isSubItem={
                                    this.getCurrentItems()[wrapperIndex - 1] &&
                                    this.getCurrentItems()[wrapperIndex - 1].noMargin
                                }
                                itemsAutoHeight={itemsAutoHeight}
                            >
                                {itemStructure.map((element, elementIndex) => {
                                    return (
                                        <ListElement
                                            style={{
                                                ...(sameWidthElements
                                                    ? { flex: 1 }
                                                    : {}),
                                            }}
                                            key={elementIndex}
                                            itemsAutoHeight={itemsAutoHeight}
                                            hidden={
                                                elementIndex === 0 &&
                                                this.getCurrentItems()[wrapperIndex-1] &&
                                                this.getCurrentItems()[wrapperIndex-1].noMargin
                                            }
                                        >
                                            {element.isHTML
                                                ? ReactHtmlParser(
                                                      element.markdown
                                                  )
                                                : element.markdown}
                                        </ListElement>
                                    )
                                })}
                            </ListElementWrapper>
                        )
                    })}

                {(!items || (items && items.length === 0)) && (
                    <NoItemMessageWrapper>
                        <i>{noItemsMessage}</i>
                    </NoItemMessageWrapper>
                )}
            </div>
                {(items && items.length >0) &&
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={this.props.items.length}
                            pageSize={pageSize}
                            onPageChange={page => this.setCurrentPage(page)}
                        />
                    </div>
                }
            </>
        )
    }
}

export default List
