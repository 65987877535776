import axios from 'axios'

class AccountService {
    constructor() {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async login(email, password, rememberMe = false) {
        return axios.post(`${this.apiBase}/auth/login`, {
            email: email,
            password: password,
            remember_me: rememberMe,
        })
    }

    async resetPassword(email) {
        return axios.post(`${this.apiBase}/password/create`, {
            email: email,
        })
    }

    async confirmResetPassword(email, password, password_confirmation, token) {
        return axios.post(`${this.apiBase}/password/reset`, {
            email,
            password,
            password_confirmation,
            token,
        })
    }

    async getCountries() {
        try {
            const response = await axios.get(
                `${this.apiBase}/countries`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getLocales() {
        try {
            const response = await axios.get(
                `${this.apiBase}/locales`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getMe(token = null) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me`,
                token
                    ? {
                          headers: { Authorization: `Bearer ${token}` },
                      }
                    : this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getMyCompany(token = null) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/company`,
                token
                    ? {
                          headers: { Authorization: `Bearer ${token}` },
                      }
                    : this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getMyQualifications() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/files`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getMyQualification(id) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/files/${id}/base64`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getMyQualificationFile(id, name) {
        axios.get(
            `${this.apiBase}/me/files/${id}/download`,
            {...this.config, responseType: 'blob'}
        ).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((error) => {
            throw error.response
        })
    }

    async registerAccount(data) {
        try {
            const body = {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                password: data.password,
                password_confirmation: data.password_confirm,
                locale: data.locale,
                role: data.role,
            }

            if (data.company) {
                body.companyHR = data.company
            }

            const response = await axios.post(
                `${this.apiBase}/auth/register`,
                body
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async finishFreelancerSetup(data) {
        try {
            const response = await axios.post(
                `${this.apiBase}/me`,
                {
                    currency: data.currency,
                    salary: data.salary,
                    phone: `+${data.phone}`,
                    birth: data.birth,
                    street: data.street,
                    housenumber: data.housenumber,
                    zipcode: data.zipcode,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                    radius: data.radius,
                    radius_unit: data.radiusUnit,
                },
                this.config
            )

            const courses = await this.setFreelancerCourses(data.courses)

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async updateUserData(dataObject) {
        try {
            const body = {
                email: dataObject.email,
                firstname: dataObject.firstname,
                lastname: dataObject.lastname,
                birth: dataObject.birth,
                currency: dataObject.currency,
                salary: dataObject.salary,
                street: dataObject.street,
                housenumber: dataObject.housenumber,
                zipcode: dataObject.zipcode,
                city: dataObject.city,
                state: dataObject.state,
                country: dataObject.country,
                adress_additional: dataObject.adress_additional,
                radius: dataObject.radius,
                radius_unit: dataObject.radius_unit,
                paused: dataObject.paused,
                phone: dataObject.phone,
                locale: dataObject.locale,
                bank: dataObject.bank,
                iban: dataObject.iban,
                bic: dataObject.bic,
                ban: dataObject.ban,
                blz: dataObject.blz,
                paypal: dataObject.paypal,
                check_payment: dataObject.check_payment,
                tax_number: dataObject.tax_number,
                ustid: dataObject.ustid,
            }

            const response = await axios.put(
                `${this.apiBase}/me`,
                body,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async updateLocale(locale) {
        try {
            const response = await axios.put(
                `${this.apiBase}/me/locale`,
                {
                    locale,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async addMyQualification(title, file) {
        try {
            const formData = new FormData()
            formData.append('name', title)
            formData.append('path', file)
            const response = await axios.post(
                `${this.apiBase}/me/files`,
                formData,
                {
                    ...this.config,
                    headers: {
                        ...this.config.headers,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async deleteQualification(id) {
        try {
            const response = await axios.delete(
                `${this.apiBase}/me/files/${id} `,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async updateCompanyData(id, dataObject) {

        try {
            const response = await axios.put(
                `${this.apiBase}/company/${id}`,
                dataObject,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response.data
        }
    }

    async setFreelancerCourses(courses) {
        try {
            const response = await axios.post(
                `${this.apiBase}/me/courses/add`,
                {
                    courses: courses,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async addCompany(
        company,
        invoiceMail,
        phone,
        website,
        street,
        housenumber,
        zipcode,
        city,
        state,
        country,
        isBranch,
        branchName,
        branchEmail,
        addressAdditional
    ) {
        try {
            const body = {
                name: company,
                email: invoiceMail,
                phone: `+${phone}`,
                website: website,
                street: street,
                housenumber: housenumber,
                zipcode: zipcode,
                city: city,
                state: state,
                country: country,
                adress_additional: addressAdditional,
                isBranch: isBranch,
                branchname: branchName,
                branchmail: branchEmail,
            }

            const response = await axios.post(
                `${this.apiBase}/company`,
                body,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async changePassword(oldPassword, newPassword, newPasswordConfirm) {
        try {
            const response = await axios.put(
                `${this.apiBase}/me/password`,
                {
                    old_password: oldPassword,
                    password: newPassword,
                    password_confirmation: newPasswordConfirm,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async deleteAccount() {
        try {
            const response = await axios.delete(
                `${this.apiBase}/me`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }
}

export default AccountService
