import React, { useState } from 'react'
import styled from 'styled-components'
import {
    BREAKPOINT_TABLET,
    COLORS,
    MENU_WIDTH,
    NO_SCROLL_BARS,
    SHADOW,
    SPACING,
} from '../assets/constants'
import logo from '../assets/images/logo.png'
import dog from '../assets/images/dog.gif'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import { useAuth } from '../context/AuthContext'
import FreelancerMenu from './menus/FreelancerMenu'
import ChefMenu from './menus/ChefMenu'
import ManagerMenu from './menus/ManagerMenu'
import EmployeeMenu from './menus/EmployeeMenu'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { HOME_PAGE, HOME_PAGE_EN } from '../assets/config'

const MenuOuterWrapper = styled.div`
    width: ${MENU_WIDTH}px;
`

const MenuInnerWrapper = styled.div`
    position: fixed;
    transition: all 0.5s;
    padding-top: ${SPACING.xlarge}px;
    padding-bottom: ${SPACING.xlarge}px;
    width: ${MENU_WIDTH}px;
    height: 100vh;
    background-color: ${COLORS.primary};
    border-top-right-radius: 34px;
    border-bottom-right-radius: 34px;
    color: ${COLORS.light};

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: ${SPACING.small}px;
        margin-bottom: ${SPACING.large}px;

        li {
            width: calc(100% + ${2 * SPACING.large}px);
            display: flex;
            align-items: center;
        }

        a {
            width: calc(100% + ${2 * SPACING.large}px);
            text-decoration: none;
            color: ${COLORS.light};
            padding: ${SPACING.xsmall}px ${SPACING.large}px;
            border-radius: 6px;
            transform: translateX(${-SPACING.large}px);
        }
    }
`

const IconWrapper = styled.div`
    position: absolute;
    right: -13px;
    top: 64px;
    width: 26px;
    height: 26px;
    background-color: ${COLORS.light};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.dark};
    ${SHADOW.sharp}
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: ${COLORS.primary};
        filter: brightness(1.2);
        color: ${COLORS.light};
    }
`

const MenuItems = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 185px); /* Room for dog GIF */
    padding-right: ${SPACING.xlarge}px;
    padding-left: ${SPACING.xlarge}px;
    ${NO_SCROLL_BARS}
`

const Menu = () => {
    const { user } = useAuth()
    const { t } = useTranslation()
    const [menuOpen, setMenuOpen] = useState<boolean>(
        window.innerWidth > BREAKPOINT_TABLET ? true : false
    )
    const history = useHistory()

    history.listen((location, action) => {
        if (window.innerWidth <= BREAKPOINT_TABLET) setMenuOpen(false)
        if (window.innerWidth > BREAKPOINT_TABLET) setMenuOpen(true)
    })

    const locale = user.locale

    return (
        <MenuOuterWrapper
            style={{
                maxWidth: menuOpen ? 600 : 16,
            }}
        >
            <MenuInnerWrapper
                style={{
                    maxWidth: menuOpen ? 600 : 16,
                }}
            >
                <IconWrapper
                    onClick={() => {
                        setMenuOpen(!menuOpen)
                    }}
                >
                    {menuOpen ? (
                        <MdKeyboardArrowLeft />
                    ) : (
                        <MdKeyboardArrowRight />
                    )}
                </IconWrapper>

                <MenuItems>
                    {menuOpen && (
                        <>
                            <img
                                src={logo}
                                width={140}
                                style={{
                                    marginBottom: SPACING.xlarge,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    window.open(
                                        locale === 'en'
                                            ? HOME_PAGE_EN
                                            : HOME_PAGE,
                                        '_blank'
                                    )
                                    return null
                                }}
                            />

                            {user && user.role === 'freelancer' ? (
                                <FreelancerMenu />
                            ) : user && user.role === 'chef' ? (
                                <ChefMenu />
                            ) : user && user.role === 'manager' ? (
                                <ManagerMenu />
                            ) : (
                                <EmployeeMenu />
                            )}

                            <div
                                style={{
                                    position: 'absolute',
                                    width: `calc(100% - ${SPACING.xlarge}px)`,
                                    bottom: SPACING.medium,
                                    left: SPACING.xlarge,
                                    paddingBottom: SPACING.small,
                                }}
                            >
                                <h3 style={{ marginBottom: 0, width: '63%' }}>
                                    {t('pages.greeting')}, {user.firstname}!
                                </h3>
                                {user.companyHR && (
                                    <p style={{ width: '63%' }}>
                                        {user.companyHR}
                                    </p>
                                )}
                                <img
                                    src={dog}
                                    width="33%"
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                    }}
                                />
                            </div>
                        </>
                    )}
                </MenuItems>
            </MenuInnerWrapper>
        </MenuOuterWrapper>
    )
}

export default Menu
