import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import FreelancerRequestsWidget from "../../legacy/components/widgets/freelancer-requests-widget";
import RequestsWidget from "../../legacy/components/widgets/requests-widget";
import FreelancerOtherRequestsWidget from "../../legacy/components/widgets/freelancer-other-requests-widget";
import FreelancerConfirmedRequestsWidget from "../../legacy/components/widgets/freelancer-confirmed-requests-widget";

const Requests = () => {

    const { user } = useAuth();
    const { t } = useTranslation();

    return <>

        <h1>
            {user && user.role === 'freelancer'
                ? t('pages.title.openRequests')
                : t('pages.title.openSearchRequests')}
        </h1>

        <p>
            {user && user.role === 'freelancer'
                ? t('pages.description.openRequests')
                : t('pages.description.openSearchRequests')}
        </p>

        {user && user.role === 'freelancer'
            ? <FreelancerRequestsWidget />
            : <RequestsWidget />}

        {(user && user.role === 'freelancer') &&
            <>
                <h1 style={{marginTop: '80px'}}>{t('pages.title.otherOpenRequests')}</h1>
                <p>{t('pages.description.otherOpenRequests')}</p>
                <FreelancerOtherRequestsWidget/>

                <h1 style={{marginTop: '80px'}}>{t('pages.title.confirmedRequests')}</h1>
                <FreelancerConfirmedRequestsWidget/>
            </>
        }

    </>;

}

export default Requests;