import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import '../../assets/less/react-tabs.scss'
import '../../assets/less/strong-password.scss'
import {
    InputSection,
    StyledButton,
    StyledLabel,
    StyledSelect,
    StyledWrapper,
} from '../styled-components'
import ReactPasswordStrength from 'react-password-strength'
import { MdInfoOutline } from 'react-icons/md'
import styled from 'styled-components'
import Loader from '../loader'
import { Trans, withTranslation } from 'react-i18next'
import AccountService from '../../../services/account-service'
import { COLORS, DEVICE, SPACING } from '../../../assets/constants'
import i18n from '../../../i18n/config'
import { Link } from 'react-router-dom'
import {
    HOME_PAGE, HOME_PAGE_EN,
    PRIVACY_PAGE,
    PRIVACY_PAGE_EN,
    TERMS_PAGE,
    TERMS_PAGE_EN,
} from '../../../assets/config'

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const Wrapper = styled.div`
    @media ${DEVICE.phone} {
        > div {
            flex-direction: column;
            gap: 12px;
        }
    }
`

class RegistrationWidget extends Component {
    constructor(props) {
        super(props)

        console.log(i18n.language)

        this.state = {
            passLength: 5,
            passwordIsValid: false,
            isFreelancer: true,
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            password: '',
            passwordConfirm: '',
            privacyConfirmed: false,
            tosConfirmed: false,
            isLoading: false,
            showError: false,
            errorMessage: '',
            locale: i18n.language,
        }

        this.accountService = new AccountService()
    }

    setIsLoading = (loading) =>
        this.setState({ ...this.state, isLoading: loading })

    setShowError = (showError) =>
        this.setState({ ...this.state, showError: showError })

    setErrorMessage = (errorMessage) =>
        this.setState({ ...this.state, errorMessage: errorMessage })

    setIsFreelancer = (isFreelancer) =>
        this.setState({ ...this.state, isFreelancer: isFreelancer })

    setFirstName = (firstName) =>
        this.setState({ ...this.state, firstName: firstName })

    setLastName = (lastName) =>
        this.setState({ ...this.state, lastName: lastName })

    setCompany = (company) => this.setState({ ...this.state, company: company })

    setEmail = (email) => this.setState({ ...this.state, email: email })

    setPrivacyConfirmed = (privacyConfirmed) =>
        this.setState({ ...this.state, privacyConfirmed: privacyConfirmed })

    setTosConfirmed = (tosConfirmed) =>
        this.setState({ ...this.state, tosConfirmed: tosConfirmed })

    setPassword = (input) => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            password: input.password,
            passwordIsValid: input.isValid,
        })
    }

    setPasswordConfirm = (input) => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            passwordConfirm: input.password,
        })
    }

    clear = () => this.ReactPasswordStrength.clear()

    getMutualFormElements = () => {
        const {
            isFreelancer,
            firstName,
            lastName,
            company,
            email,
            password,
            passwordConfirm,
            passwordIsValid,
            privacyConfirmed,
            tosConfirmed,
            showError,
            errorMessage,
            locale,
        } = this.state
        const siteurl = ''
        const { t, history } = this.props

        const isValidFreelancer =
            isFreelancer &&
            firstName &&
            lastName &&
            email &&
            password &&
            passwordConfirm &&
            password === passwordConfirm &&
            passwordIsValid &&
            privacyConfirmed &&
            tosConfirmed

        const isValidCompany =
            !isFreelancer &&
            firstName &&
            lastName &&
            email &&
            company &&
            password &&
            passwordConfirm &&
            password === passwordConfirm &&
            passwordIsValid &&
            privacyConfirmed &&
            tosConfirmed

        return (
            <>
                <Wrapper>
                    <div
                        style={{
                            display: 'flex',
                            margin: '0 -5px',
                            marginTop: 25,
                        }}
                    >
                        <InputSection
                            style={{ flex: '1 1 0', margin: '0 5px' }}
                        >
                            <StyledLabel>{t('forms.firstName')}</StyledLabel>
                            <input
                                type={'text'}
                                value={firstName}
                                onChange={(event) =>
                                    this.setFirstName(event.target.value)
                                }
                            />
                        </InputSection>
                        <InputSection
                            style={{ flex: '1 1 0', margin: '0 5px' }}
                        >
                            <StyledLabel>{t('forms.lastName')}</StyledLabel>
                            <input
                                type={'text'}
                                value={lastName}
                                onChange={(event) =>
                                    this.setLastName(event.target.value)
                                }
                            />
                        </InputSection>
                    </div>

                    <InputSection style={{ marginTop: 15 }}>
                        <StyledLabel>{t('forms.email')}</StyledLabel>
                        <input
                            type={'email'}
                            value={email}
                            onChange={(event) =>
                                this.setEmail(event.target.value)
                            }
                        />
                    </InputSection>

                    <div
                        style={{
                            display: 'flex',
                            margin: '0 -5px',
                            marginTop: 15,
                        }}
                    >
                        <InputSection
                            style={{ flex: '1 1 0', margin: '0 5px' }}
                        >
                            <StyledLabel>{t('forms.password')}</StyledLabel>
                            <ReactPasswordStrength
                                ref={(ref) =>
                                    (this.ReactPasswordStrength = ref)
                                }
                                minLength={8}
                                inputProps={{ id: 'password' }}
                                changeCallback={this.setPassword}
                                scoreWords={[
                                    t('forms.passwordWeak'),
                                    t('forms.passwordMedium'),
                                    t('forms.passwordGood'),
                                    t('forms.passwordStrong'),
                                    t('forms.passwordVeryStrong'),
                                ]}
                                tooShortWord={t('forms.passwordTooShort')}
                            />
                        </InputSection>
                        <InputSection
                            style={{ flex: '1 1 0', margin: '0 5px' }}
                        >
                            <StyledLabel>
                                {t('forms.passwordConfirm')}
                            </StyledLabel>
                            <ReactPasswordStrength
                                ref={(ref) =>
                                    (this.ReactPasswordStrength = ref)
                                }
                                minLength={8}
                                inputProps={{ id: 'password-confirm' }}
                                changeCallback={this.setPasswordConfirm}
                                scoreWords={[
                                    t('forms.passwordWeak'),
                                    t('forms.passwordMedium'),
                                    t('forms.passwordGood'),
                                    t('forms.passwordStrong'),
                                    t('forms.passwordVeryStrong'),
                                ]}
                                tooShortWord={t('forms.passwordTooShort')}
                            />
                        </InputSection>
                    </div>
                    {password &&
                        passwordConfirm &&
                        passwordIsValid &&
                        password !== passwordConfirm && (
                            <p
                                style={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '0.8em',
                                    marginTop: 10,
                                }}
                            >
                                <InfoIcon />
                                {t('forms.passwordMismatch')}
                            </p>
                        )}

                    <InputSection style={{ fontSize: '0.8rem', marginTop: 25 }}>
                        <p>
                            <input
                                type={'checkbox'}
                                id={'data-privacy'}
                                onChange={(event) =>
                                    this.setPrivacyConfirmed(
                                        event.target.checked
                                    )
                                }
                                checked={privacyConfirmed}
                            />
                            <label htmlFor={'data-privacy'}>
                                <Trans
                                    i18nKey="registration.privacyPolicy"
                                    components={{
                                        privacy: (
                                            <a
                                                href={
                                                    this.state.locale === 'en'
                                                        ? PRIVACY_PAGE_EN
                                                        : PRIVACY_PAGE
                                                }
                                                style={{ textDecoration: 'none' }}
                                            />
                                        ),
                                    }}
                                />
                            </label>
                        </p>
                        <p style={{ marginTop: 10 }}>
                            <input
                                type={'checkbox'}
                                id={'tos'}
                                checked={tosConfirmed}
                                onChange={(event) =>
                                    this.setTosConfirmed(event.target.checked)
                                }
                            />
                            <label htmlFor={'tos'}>
                                <Trans
                                    i18nKey="registration.termsOfUse"
                                    components={{
                                        tos: (
                                            <a
                                                href={
                                                    this.state.locale === 'en'
                                                        ? TERMS_PAGE_EN
                                                        : TERMS_PAGE
                                                }
                                                style={{ textDecoration: 'none' }}
                                            />
                                        ),
                                    }}
                                />
                            </label>
                        </p>
                    </InputSection>

                    {showError && errorMessage && (
                        <p
                            style={{
                                fontFamily: 'Montserrat',
                                fontSize: '0.8em',
                                marginTop: 30,
                            }}
                        >
                            {errorMessage}
                        </p>
                    )}
                </Wrapper>

                <div
                    style={{
                        textAlign: 'center',
                        paddingBottom: SPACING.xlarge,
                    }}
                >
                    <StyledButton
                        primary
                        style={{ width: 250, marginTop: 35 }}
                        disabled={!(isValidFreelancer || isValidCompany)}
                        onClick={this.handleRegistration}
                    >
                        {t('registration.register')}
                    </StyledButton>
                    <StyledButton
                        tertiary
                        style={{ width: 250, marginTop: 35 }}
                        onClick={() => (window.location.href = i18n.language === 'en' ? HOME_PAGE_EN : HOME_PAGE)}
                    >
                        {t('registration.backHome')}
                    </StyledButton>
                </div>
            </>
        )
    }

    handleRegistration = async () => {
        const {
            isFreelancer,
            firstName,
            lastName,
            company,
            email,
            password,
            passwordConfirm,
            locale,
        } = this.state
        const { register, setRegistered } = this.props

        this.setShowError(false)
        this.setIsLoading(true)

        try {
            const data = {
                firstname: firstName,
                lastname: lastName,
                email: email,
                password: password,
                password_confirm: passwordConfirm,
                locale: locale,
                role: isFreelancer ? 'freelancer' : 'chef',
                company: company,
            }
            const response = await register(data)
            setRegistered(true)
            this.setIsLoading(false)
        } catch (error) {
            this.setIsLoading(false)
            this.setShowError(true)

            if (!error.data) {
                this.setErrorMessage(
                    <>
                        <InfoIcon />
                        Ein Fehler ist aufgetreten.
                    </>
                )
            }

            if (error.data.message) {
                this.setErrorMessage(
                    <>
                        <InfoIcon />
                        error.message
                    </>
                )
            }

            if (error.data.data) {
                this.setErrorMessage(
                    Object.values(error.data.data).map((e) => (
                        <div>
                            <InfoIcon />
                            {e}
                        </div>
                    ))
                )
            }
        }
    }

    render() {
        const { isFreelancer, company, isLoading } = this.state
        const { t, i18n } = this.props

        return (
            <>
                <div
                    style={{
                        padding: SPACING.xlarge,
                        paddingBottom: 0,
                        flex: 1,
                        height: 'auto',
                        minHeight: '730px',
                    }}
                >
                    <h3
                        style={{
                            color: COLORS.onRequest,
                            textTransform: 'uppercase',
                        }}
                    >
                        {t('registration.registerIntro')}
                    </h3>
                    <h1
                        style={{
                            marginBottom: SPACING.large,
                            textAlign: 'center',
                            paddingTop: '10px',
                        }}
                    >
                        {t('registration.welcome')}.
                    </h1>
                    <Tabs
                        defaultIndex={isFreelancer ? 0 : 1}
                        onSelect={(index) =>
                            this.setIsFreelancer(index === 0 ? true : false)
                        }
                    >
                        <StyledLabel>
                            {t('registration.looking')}
                        </StyledLabel>
                        <TabList>
                            <Tab style={{fontSize: '0.87rem'}}>{t('registration.freelancer')}</Tab>
                            <Tab style={{fontSize: '0.87rem'}}>{t('registration.chef')}</Tab>
                        </TabList>

                        <TabPanel>{this.getMutualFormElements()}</TabPanel>
                        <TabPanel>
                            <div
                                style={{
                                    display: 'flex',
                                    margin: '0 -5px',
                                    marginTop: 25,
                                }}
                            >
                                <InputSection
                                    style={{ flex: '1 1 0', margin: '0 5px' }}
                                >
                                    <StyledLabel>
                                        {t('forms.company')}
                                    </StyledLabel>
                                    <input
                                        type={'text'}
                                        value={company}
                                        onChange={(event) =>
                                            this.setCompany(event.target.value)
                                        }
                                    />
                                </InputSection>
                            </div>
                            {this.getMutualFormElements()}
                        </TabPanel>
                    </Tabs>
                </div>
                {isLoading && <Loader />}
            </>
        )
    }
}

export default withTranslation()(RegistrationWidget)
