import React, {Component} from 'react';
import {SubHeading} from "./styled-components";

class SectionHeading extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {title, description} = this.props;

        return <div style={{marginTop: 50, marginBottom: 25}}>
            <SubHeading>{title}</SubHeading>
            <p>{description}</p>
        </div>;
    }
}

export default SectionHeading;