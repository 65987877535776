import React, {ChangeEvent, useState} from 'react'
import {
    ClickableLink,
    InputSection,
    StyledButton,
    StyledDatePicker,
    StyledLabel, StyledPrimaryButton,
    StyledSelect, StyledTertiaryButton
} from "./styled-components";
import Popup from "./popup";
import {getI18n, useTranslation} from "react-i18next";
import styled from "styled-components";
import {MdInfoOutline} from "react-icons/md";
import AvailabilityService from "../../services/availability-service";
import dateFormat from "dateformat";

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

type Entry = {
    id: number;
    group_id: number|null;
    startdate: string;
    starttime: string;
    enddate: string;
    endtime: string;
    availability: string;
}

type AvailabilityPopupProps = {
    entry: Entry
    hidePopup: () => void
    reloadData: () => void
    showMessage: () => void
    addMessage: (message: string) => void
}

const AvailabilityPopup = ({entry, hidePopup, reloadData, showMessage, addMessage}: AvailabilityPopupProps) => {
    const getIntTime = (time: String) => {
        const hours = parseInt(time.split('0')[0])
        const minutes = parseInt(time.split('0')[1])
        return new Date().setHours(hours, minutes)
    }

    const { t } = useTranslation();
    const language = getI18n().language

    const getFormat = (period: string) => {
        if (language === 'de') {
            if (period === 'time') {
                return 'HH:mm'
            } else {
                return 'd. MMMM yyyy'
            }
        } else {
            if (period === 'time') {
                return 'hh:mm aa'
            } else {
                return 'MM/dd/yyyy'
            }
        }
    }

    const [date, setDate] = useState<Date>(new Date(entry.startdate))
    const [start, setStart] = useState<Date>(new Date(entry.startdate + 'T' + entry.starttime))
    const [end, setEnd] = useState<Date>(new Date(entry.enddate + 'T' + entry.endtime))
    const [availability, setAvailability] = useState<String>(entry.availability)
    const [overnight, setOvernight] = useState<Boolean>(new Date('2022-10-10T' + entry.starttime).getTime() > new Date('2022-10-10T' + entry.endtime).getTime())

    const availabilityService = new AvailabilityService();

    const handleDateChange = (newDate: Date) => {
        setDate(newDate)
    }

    const handleStartTimeChange = (newStart: Date) => {
        setStart(newStart)

        const testStart = new Date('2022-10-10T' + dateFormat(newStart, 'HH:MM'))
        const testEnd = new Date('2022-10-10T' + dateFormat(end, 'HH:MM'))

        setOvernight(testStart.getTime() > testEnd.getTime())
    }

    const handleEndTimeChange = (newEnd: Date) => {
        setEnd(newEnd)

        const testStart = new Date('2022-10-10T' + dateFormat(start, 'HH:MM'))
        const testEnd = new Date('2022-10-10T' + dateFormat(newEnd, 'HH:MM'))

        setOvernight(testStart.getTime() > testEnd.getTime())
    }

    const handleAvailabilityChange = (event: { target: HTMLSelectElement; }) => {
        setAvailability(event.target.value)
    }

    const saveEntry = () => {
        const timeDiff = (end.getTime() - start.getTime()) / 3600000;
        if (Math.abs(timeDiff) < 0.9 && end.getTime() >= start.getTime()) {
            alert(t('popup.addEntryWarning'));
        } else {
            const newAvailabilityData = {
                id: entry.id,
                date: dateFormat(date, 'yyyy-mm-dd'),
                starttime: dateFormat(start, 'HH:MM'),
                endtime: dateFormat(end, 'HH:MM'),
                availability,
                overnight
            };
            availabilityService.editAvailability(newAvailabilityData).then((res: any) => {
                if (res && !res.success && res.message) {
                    showMessage();
                    addMessage(`<div>${res.message}</div>`);
                }
                hidePopup();
                reloadData();
            });
        }
    }

    const deleteEntryGroup = () => {
        const deleteAll = window.confirm(t('popup.deleteGroupConfirm'));

        if (deleteAll) {
            availabilityService.removeAvailabilityGroup(entry.group_id).then(() => {
                hidePopup();
                reloadData();
            });
        }
    }

    const deleteEntry = () => {
        availabilityService.removeAvailability(entry.id).then(() => {
            hidePopup();
            reloadData();
        });
    }

    return <>
        <Popup title={t('popup.editEntry')} hidePopup={hidePopup}>
            <InputSection>
                <StyledLabel>{t('forms.date')}</StyledLabel>
                <StyledDatePicker
                    // style={{ width: '100%' }}
                    selected={date}
                    onChange={(date: Date) => handleDateChange(date)}
                    locale={language}
                    dateFormat={getFormat('date')}
                    minDate={new Date()}
                />
            </InputSection>
            <div style={{ display: 'flex', marginTop: 15 }}>
                <InputSection
                    style={{ flex: '1 1 0', marginRight: 5, width: 'calc(50% - 5px)' }}
                >
                    <StyledLabel>{t('forms.from')}</StyledLabel>
                    <StyledDatePicker
                        className={'time-picker'}
                        selected={start}
                        onChange={(startTime: Date) => handleStartTimeChange(startTime)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        locale={language}
                        dateFormat={getFormat('time')}
                    />
                </InputSection>
                <InputSection
                    style={{ flex: '1 1 0', marginLeft: 5 , width: 'calc(50% - 5px)'}}
                >
                    <StyledLabel>{t('forms.to')}</StyledLabel>
                    <StyledDatePicker
                        className={'time-picker'}
                        selected={end}
                        onChange={(endTime: Date) => handleEndTimeChange(endTime)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        locale={language}
                        dateFormat={getFormat('time')}
                    />
                </InputSection>
            </div>
            {overnight && (
                <p style={{ fontSize: '0.8em', marginTop: 10 }}>
                    <InfoIcon />
                    {t('forms.overnight')}
                </p>
            )}

            <InputSection style={{ marginTop: 15, marginBottom: 25 }}>
                <StyledLabel>{t('forms.availability')}</StyledLabel>
                <StyledSelect
                    style={{ width: '100%' }}
                    onChange={(event:ChangeEvent<HTMLSelectElement>) =>
                        handleAvailabilityChange(event)
                    }
                    defaultValue={entry.availability}
                >
                    <option value={'high'}>{t('forms.high')}</option>
                    <option value={'medium'}>{t('forms.medium')}</option>
                    <option value={'onrequest'}>{t('forms.onrequest')}</option>
                </StyledSelect>
            </InputSection>
            {entry.group_id && (
                <ClickableLink onClick={() => deleteEntryGroup()}>
                    {t('popup.deleteGroup')}
                </ClickableLink>
            )}
            <StyledPrimaryButton
                style={{ marginRight: 5, width: 'calc(50% - 5px)' }}
                onClick={() => saveEntry()}>
                {t('popup.save')}
            </StyledPrimaryButton>
            <StyledButton
                style={{ marginLeft: 5, width: 'calc(50% - 5px)' }}
                onClick={() => deleteEntry()}
            >
                {t('popup.delete')}
            </StyledButton>
            <StyledTertiaryButton
                style={{ width: '100%', marginTop: 15 }}
                onClick={hidePopup}>
                {t('popup.abort')}
            </StyledTertiaryButton>
        </Popup>
    </>


}

export default AvailabilityPopup;
