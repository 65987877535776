import React, { Component } from 'react'
import {
    FormWrapper,
    InputSection,
    StyledButton,
    StyledDatePicker,
    StyledLabel,
    StyledSelect,
    StyledWrapper,
} from '../styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { MdInfoOutline } from 'react-icons/md'
import styled from 'styled-components'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { de, enIN } from 'date-fns/locale'
import Loader from '../loader'
import PopupMessage from '../popup-message'
import dateFormat from 'dateformat'
import { withTranslation } from 'react-i18next'
import '../../assets/less/datepicker.scss'
import AvailabilityService from '../../../services/availability-service'
import i18n from '../../../i18n/config'

registerLocale('de', de)
registerLocale('en', enIN)
setDefaultLocale('de')

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

class AvailabilityFormWidget extends Component {
    constructor(props) {
        super(props)

        this.availabilityService = new AvailabilityService()

        this.state = {
            type: props.type,
            mindate: new Date().setDate(new Date().getDate() + 3),
            startdate: new Date().setDate(new Date().getDate() + 3),
            enddate: new Date().setDate(new Date().getDate() + 4),
            starttime: new Date().setHours(10, 0),
            endtime: new Date().setHours(18, 0),
            availability: 'high',
            interval: props.type === 'single' ? '1' : '7',
            isLoading: false,
            showMessage: false,
            message: undefined,
            overnight: false,
            currentLanguage: i18n.language,
            currentTimeFormat: undefined,
            currentDateFormat: undefined,
        }
    }

    componentDidMount() {
        this.setCurrentDateformat()
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading })
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show })
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message })
    }

    setStartDate = (date) => {
        this.setState({
            ...this.state,
            startdate: date,
            enddate: date > this.state.enddate ? date : this.state.enddate,
        })
    }

    setCurrentDateformat = () => {
        if (i18n.language === 'en') {
            this.setState({
                ...this.state,
                currentDateFormat: 'eeee, MM/dd/yyyy',
                currentTimeFormat: 'hh:mm aa',
            })
        } else if (i18n.language === 'de') {
            this.setState({
                ...this.state,
                currentDateFormat: 'eeee, d. MMMM yyyy',
                currentTimeFormat: 'HH:mm',
            })
        }
    }

    setOnlyStartDate = (date) => {
        this.setState({
            ...this.state,
            startdate: date,
            enddate: new Date().setDate(new Date(date).getDate() + 1),
        })
    }

    setEndDate = (date) => {
        this.setState({
            ...this.state,
            startdate:
                date < this.state.startdate ? date : this.state.startdate,
            enddate: date,
        })
    }

    setStartTime = (date) => {
        this.setState({
            ...this.state,
            starttime: date,
            overnight: date > this.state.endtime,
        })
    }

    setEndTime = (date) => {
        this.setState({
            ...this.state,
            endtime: date,
            overnight: date < this.state.starttime,
        })
    }

    setAvailability = (availability) => {
        this.setState({ ...this.state, availability: availability })
    }

    setInterval = (interval) => {
        this.setState({ ...this.state, interval: interval })
    }

    addAvailability = () => {
        const firstdate = dateFormat(this.state.startdate, 'yyyy-mm-dd')
        const lastdate = dateFormat(this.state.enddate, 'yyyy-mm-dd')
        const starttime = dateFormat(this.state.starttime, 'HH:MM')
        const endtime = dateFormat(this.state.endtime, 'HH:MM')
        const interval = this.state.interval
        const availability = this.state.availability
        const overnight = this.state.overnight
        const { type } = this.state

        this.setIsLoading(true)
        this.availabilityService
            .addAvailabilities(
                firstdate,
                lastdate,
                starttime,
                endtime,
                interval,
                availability,
                overnight,
                type
            )
            .then(
                (res) => {
                    this.setIsLoading(false)

                    if (res && !res.success && res.message) {
                        this.setShowMessage(true)
                        this.setMessage(`<div>${res.message}</div>`)
                    }

                    if (res && res.success && res.message) {
                        this.setShowMessage(true)
                        this.setMessage(`<div>${res.message}</div>`)
                    }
                },
                (err) => {
                    this.setIsLoading(false)

                    if (err && !err.success) {
                        if (err.data) {
                            this.setShowMessage(true)
                            this.setMessage(
                                `<div>${Object.values(err.data).map(
                                    (error) => `<div>${error}</div>`
                                )}</div>`
                            )
                        }
                    }
                }
            )
    }

    datesAreOnSameDay = (date1, date2) =>
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()

    hasDays = (date1, date2, days) => {
        let hasDay = false

        while (date1 <= date2) {
            const day = date1.getDay()
            hasDay = days.includes(day)
            if (hasDay) {
                return true
            }
            date1.setDate(date1.getDate() + 1)
        }
        return false
    }

    render() {
        const {
            mindate,
            startdate,
            enddate,
            starttime,
            endtime,
            availability,
            interval,
            overnight,
            isLoading,
            showMessage,
            message,
            currentDateFormat,
            currentTimeFormat,
            type,
        } = this.state
        const { t } = this.props
        const isSameDay = this.datesAreOnSameDay(
            new Date(startdate),
            new Date(enddate)
        )
        const hasWeekend = this.hasDays(
            new Date(startdate),
            new Date(enddate),
            [0, 6]
        )
        const hasWeekdays = this.hasDays(
            new Date(startdate),
            new Date(enddate),
            [1, 2, 3, 4, 5]
        )
        const currentLanguage = i18n.language
        return (
            <>
                <StyledWrapper>
                    <FormWrapper>
                        {type === 'single' && (
                            <div style={{ display: 'flex', margin: '0 -5px' }}>
                                <InputSection
                                    style={{ flex: '1 1 0', margin: '0 5px' }}
                                >
                                    <StyledLabel>
                                        {t('forms.dateFrom')}
                                    </StyledLabel>
                                    <StyledDatePicker
                                        style={{ width: '100%' }}
                                        selected={startdate}
                                        onChange={(date) =>
                                            this.setStartDate(date)
                                        }
                                        selectsStart
                                        dateFormat={currentDateFormat}
                                        minDate={mindate}
                                        startDate={startdate}
                                        endDate={enddate}
                                        locale={currentLanguage}
                                    />
                                </InputSection>
                                <InputSection
                                    style={{ flex: '1 1 0', margin: '0 5px' }}
                                >
                                    <StyledLabel>
                                        {t('forms.dateTo')}
                                    </StyledLabel>
                                    <StyledDatePicker
                                        style={{ width: '100%' }}
                                        selected={enddate}
                                        onChange={(date) =>
                                            this.setEndDate(date)
                                        }
                                        selectsEnd
                                        dateFormat={currentDateFormat}
                                        minDate={mindate}
                                        startDate={startdate}
                                        endDate={enddate}
                                        locale={currentLanguage}
                                    />
                                </InputSection>
                            </div>
                        )}

                        {type === 'regular' && (
                            <InputSection>
                                <StyledLabel>{t('forms.startDay')}</StyledLabel>
                                <StyledDatePicker
                                    selected={startdate}
                                    onChange={(date) =>
                                        this.setOnlyStartDate(date)
                                    }
                                    minDate={mindate}
                                    locale={currentLanguage}
                                    dateFormat={currentDateFormat}
                                />
                            </InputSection>
                        )}

                        <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                            <InputSection
                                style={{ flex: '1 1 0', margin: '0 5px' }}
                            >
                                <StyledLabel>{t('forms.timeFrom')}</StyledLabel>
                                <StyledDatePicker
                                    selected={starttime}
                                    onChange={(startTime) =>
                                        this.setStartTime(startTime)
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    locale={currentLanguage}
                                    dateFormat={currentTimeFormat}
                                    lowercase
                                />
                            </InputSection>
                            <InputSection
                                style={{ flex: '1 1 0', margin: '0 5px' }}
                            >
                                <StyledLabel>{t('forms.timeTo')}</StyledLabel>
                                <StyledDatePicker
                                    selected={endtime}
                                    onChange={(endTime) =>
                                        this.setEndTime(endTime)
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    locale={currentLanguage}
                                    dateFormat={currentTimeFormat}
                                    lowercase
                                />
                            </InputSection>
                        </div>
                        {overnight && (
                            <p style={{ fontSize: '0.8em', marginTop: 10 }}>
                                <InfoIcon />
                                {t('forms.overnight')}
                            </p>
                        )}
                        <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                            <InputSection
                                style={{ flex: '0.5 1 0', margin: '0 5px' }}
                            >
                                <StyledLabel>
                                    {t('forms.availability')}
                                </StyledLabel>
                                <StyledSelect
                                    style={{ width: '100%' }}
                                    value={availability}
                                    onChange={(event) =>
                                        this.setAvailability(event.target.value)
                                    }
                                >
                                    <option value={'high'}>
                                        {t('forms.high')}
                                    </option>
                                    <option value={'medium'}>
                                        {t('forms.medium')}
                                    </option>
                                    <option value={'onrequest'}>
                                        {t('forms.onrequest')}
                                    </option>
                                </StyledSelect>
                            </InputSection>
                            <InputSection
                                style={{ flex: '1 1 0', margin: '0 5px' }}
                            >
                                <StyledLabel>{t('forms.interval')}</StyledLabel>
                                <StyledSelect
                                    style={{ width: '100%' }}
                                    value={interval}
                                    disabled={
                                        type === 'single' &&
                                        (isSameDay ||
                                            (hasWeekdays && !hasWeekend) ||
                                            (!hasWeekdays && hasWeekend))
                                    }
                                    onChange={(event) =>
                                        this.setInterval(event.target.value)
                                    }
                                >
                                    {type === 'single' && (
                                        <>
                                            <option value={'1'}>
                                                {t('forms.daily')}
                                            </option>
                                            {hasWeekend && (
                                                <option value={'weekend'}>
                                                    {t('forms.weekends')}
                                                </option>
                                            )}
                                            {hasWeekdays && (
                                                <option value={'weekdays'}>
                                                    {t('forms.weekdays')}
                                                </option>
                                            )}
                                        </>
                                    )}
                                    {type === 'regular' && (
                                        <>
                                            <option value={'7'}>
                                                {t('forms.everyWeek')}
                                            </option>
                                            <option value={'14'}>
                                                {t('forms.every2Weeks')}
                                            </option>
                                            <option value={'21'}>
                                                {t('forms.every3Weeks')}
                                            </option>
                                        </>
                                    )}
                                </StyledSelect>
                            </InputSection>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                                marginTop: 35,
                            }}
                        >
                            <p style={{ fontSize: '0.8em', margin: 0 }}>
                                <InfoIcon />
                                {t('forms.entryRemark')}
                            </p>
                            <StyledButton
                                primary
                                style={{ width: 250 }}
                                onClick={this.addAvailability}
                            >
                                {t('forms.addEntry')}
                            </StyledButton>
                        </div>
                    </FormWrapper>
                </StyledWrapper>
                {isLoading && <Loader />}
                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
            </>
        )
    }
}

export default withTranslation()(AvailabilityFormWidget)
