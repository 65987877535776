import { useTranslation } from "react-i18next";
import AvailabilityListWidget from "../../legacy/components/availability-list/availability-list";

const AvailabilityList = () => {

    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.events')}
        </h1>

        <p>
            {t('pages.description.events')}
        </p>

        <AvailabilityListWidget />

    </>;

}

export default AvailabilityList;