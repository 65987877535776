import React, {Component} from 'react'
import List from '../list'
import {formatTimeString, sortByDateAndTimeProperty, sortByDateProperty,} from '../../utils/utility-functions'
import dateFormat from 'dateformat'
import {ReactComponent as MoreButton} from '../../assets/images/more.svg'
import Loader from '../loader'
import PopupMessage from '../popup-message'
import SearchService from '../../../services/search-service'
import i18n from '../../../i18n/config'
import FreelancerRequestPopupNew from "../freelancer-request-popup-new";
import {withTranslation} from "react-i18next";

class FreelancerRequestsWidget extends Component {
    constructor(props) {
        super(props)

        this.searchService = new SearchService()

        this.state = {
            requests: undefined,
            showPopup: false,
            showMessage: false,
            message: undefined,
            selectedRequest: undefined,
            isLoading: false,
            currentLanguage: i18n.language,
            dateformat: i18n.language === 'en' ? 'mm/dd/yyyy' : 'd. mmm yyyy',
        }
    }

    componentDidMount() {
        this.updateRequests()
    }

    setShowPopup = (show) => {
        this.setState({...this.state, showPopup: show})
    }

    setShowMessage = (show) => {
        this.setState({...this.state, showMessage: show})
    }

    setMessage = (message) => {
        this.setState({...this.state, message: message})
    }

    setIsLoading = (isLoading) => {
        this.setState({...this.state, isLoading: isLoading})
    }

    hidePopup = () => {
        this.setState({...this.state, showPopup: false})
    }

    updateRequests = () => {
        this.searchService.getFreelancerRequests().then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    requests: sortByDateAndTimeProperty(res.data),
                })
            }
        })
    }

    openRequestDetails = (request) => {
        this.setState({...this.state, selectedRequest: request}, () =>
            this.setShowPopup(true)
        )
    }

    confirmRequest = (requestId) => {
        const {t} = this.props
        this.setIsLoading(true)
        this.searchService.confirmBranch(requestId).then((res) => {
            this.setIsLoading(false)
            this.setShowPopup(false)

            if (res) {
                this.setShowMessage(true)
                this.setMessage(`<div>${t('booking.freelancerAccepted')}</div>`)
            }

            this.updateRequests()
        })
    }

    declineRequest = (requestId) => {
        const {t} = this.props
        this.setIsLoading(true)
        this.searchService.declineBranch(requestId).then((res) => {
            this.setIsLoading(false)
            this.setShowPopup(false)

            if (res) {
                this.setShowMessage(true)
                this.setMessage(`<div>${t('booking.freelancerDeclined')}</div>`)
            }

            this.updateRequests()
        })
    }

    getListItemStructure = (item) => {
        const {course, date, starttime, endtime, br_name} = item
        const {dateformat} = this.state
        const currentLanguage = i18n.language

        const courseMarkdown = `<b>${course}</b>`
        const branchMarkdown = `<b>${br_name}</b>`
        const timeMarkdown = `<span>${formatTimeString(
            starttime,
            currentLanguage
        )} - ${formatTimeString(endtime, currentLanguage)}</span>`

        return [
            {markdown: courseMarkdown, isHTML: true},
            {markdown: branchMarkdown, isHTML: true},
            {markdown: timeMarkdown, isHTML: true},
            {markdown: dateFormat(date, dateformat), isHTML: false},
            {
                markdown: (
                    <MoreButton
                        style={{cursor: 'pointer'}}
                        onClick={() => this.openRequestDetails(item)}
                    />
                ),
                isHTML: false,
            },
        ]
    }

    render() {
        const {
            requests,
            showPopup,
            selectedRequest,
            isLoading,
            showMessage,
            message,
        } = this.state
        const {t} = this.props

        return (
            <>
                <List
                    items={requests}
                    noItemsMessage={t('list.noRequests')}
                    headings={[
                        t('list.job'),
                        t('list.company'),
                        t('list.time'),
                        t('list.date'),
                        '',
                    ]}
                    getListItemStructure={this.getListItemStructure}
                    style={{marginTop: '30px'}}
                />
                {showPopup && (
                    <FreelancerRequestPopupNew
                        request={selectedRequest}
                        hidePopup={this.hidePopup}
                        addMessage={(message) => this.setMessage(message)}
                        showMessage={() => this.setShowMessage(true)}
                        reloadData={() => this.updateRequests()}
                    />
                )}
                {isLoading && <Loader/>}
                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
            </>
        )
    }
}

export default withTranslation()(FreelancerRequestsWidget)
