import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import FreelancerGivenRatingsWidget from "../../legacy/components/widgets/freelancer-given-ratings-widget";
import GivenRatingsWidget from "../../legacy/components/widgets/given-ratings-widget";

const GivenRatings = () => {

    const { user } = useAuth();
    const { t } = useTranslation();

    return <>

        <h1>
            {user && user.role === 'freelancer'
                ? t('pages.title.givenRatingsFreelancer')
                : t('pages.title.givenRatingsChef')}
        </h1>

        <p>
            {user && user.role === 'freelancer'
                ? t('pages.description.givenRatingsFreelancer')
                : t('pages.description.givenRatingsChef')}
        </p>

        {user && user.role === 'freelancer'
            ? <FreelancerGivenRatingsWidget />
            : <GivenRatingsWidget />}
    </>;
}

export default GivenRatings;