import { useTranslation } from "react-i18next";
import HourlyRateWidget from "../../legacy/components/widgets/hourly-rate-widget";

const Salaries = () => {

    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.salaries')}
        </h1>

        <p>
            {t('pages.description.salaries')}
        </p>

        <HourlyRateWidget />

    </>;

}

export default Salaries;