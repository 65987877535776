import moment from "moment";

export const formatMoney = (
    number,
    decPlaces = 2,
    decSep = ',',
    thouSep = '.'
) => {
    decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces
    decSep = typeof decSep === 'undefined' ? '.' : decSep
    thouSep = typeof thouSep === 'undefined' ? ',' : thouSep
    const sign = number < 0 ? '-' : ''
    const i = String(
        parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    )
    const j = i.length > 3 ? i.length % 3 : 0

    return (
        sign +
        (j ? i.substr(0, j) + thouSep : '') +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
        (decPlaces
            ? decSep +
              Math.abs(number - i)
                  .toFixed(decPlaces)
                  .slice(2)
            : '')
    )
}

export const getCurrency = (countryCode) => {
    const currencies = {
        BD: 'BDT',
        BE: 'EUR',
        BF: 'XOF',
        BG: 'BGN',
        BA: 'BAM',
        BB: 'BBD',
        WF: 'XPF',
        BL: 'EUR',
        BM: 'BMD',
        BN: 'BND',
        BO: 'BOB',
        BH: 'BHD',
        BI: 'BIF',
        BJ: 'XOF',
        BT: 'BTN',
        JM: 'JMD',
        BV: 'NOK',
        BW: 'BWP',
        WS: 'WST',
        BQ: 'USD',
        BR: 'BRL',
        BS: 'BSD',
        JE: 'GBP',
        BY: 'BYR',
        BZ: 'BZD',
        RU: 'RUB',
        RW: 'RWF',
        RS: 'RSD',
        TL: 'USD',
        RE: 'EUR',
        TM: 'TMT',
        TJ: 'TJS',
        RO: 'RON',
        TK: 'NZD',
        GW: 'XOF',
        GU: 'USD',
        GT: 'GTQ',
        GS: 'GBP',
        GR: 'EUR',
        GQ: 'XAF',
        GP: 'EUR',
        JP: 'JPY',
        GY: 'GYD',
        GG: 'GBP',
        GF: 'EUR',
        GE: 'GEL',
        GD: 'XCD',
        GB: 'GBP',
        GA: 'XAF',
        SV: 'USD',
        GN: 'GNF',
        GM: 'GMD',
        GL: 'DKK',
        GI: 'GIP',
        GH: 'GHS',
        OM: 'OMR',
        TN: 'TND',
        JO: 'JOD',
        HR: 'HRK',
        HT: 'HTG',
        HU: 'HUF',
        HK: 'HKD',
        HN: 'HNL',
        HM: 'AUD',
        VE: 'VEF',
        PR: 'USD',
        PS: 'ILS',
        PW: 'USD',
        PT: 'EUR',
        SJ: 'NOK',
        PY: 'PYG',
        IQ: 'IQD',
        PA: 'PAB',
        PF: 'XPF',
        PG: 'PGK',
        PE: 'PEN',
        PK: 'PKR',
        PH: 'PHP',
        PN: 'NZD',
        PL: 'PLN',
        PM: 'EUR',
        ZM: 'ZMK',
        EH: 'MAD',
        EE: 'EUR',
        EG: 'EGP',
        ZA: 'ZAR',
        EC: 'USD',
        IT: 'EUR',
        VN: 'VND',
        SB: 'SBD',
        ET: 'ETB',
        SO: 'SOS',
        ZW: 'ZWL',
        SA: 'SAR',
        ES: 'EUR',
        ER: 'ERN',
        ME: 'EUR',
        MD: 'MDL',
        MG: 'MGA',
        MF: 'EUR',
        MA: 'MAD',
        MC: 'EUR',
        UZ: 'UZS',
        MM: 'MMK',
        ML: 'XOF',
        MO: 'MOP',
        MN: 'MNT',
        MH: 'USD',
        MK: 'MKD',
        MU: 'MUR',
        MT: 'EUR',
        MW: 'MWK',
        MV: 'MVR',
        MQ: 'EUR',
        MP: 'USD',
        MS: 'XCD',
        MR: 'MRO',
        IM: 'GBP',
        UG: 'UGX',
        TZ: 'TZS',
        MY: 'MYR',
        MX: 'MXN',
        IL: 'ILS',
        FR: 'EUR',
        IO: 'USD',
        SH: 'SHP',
        FI: 'EUR',
        FJ: 'FJD',
        FK: 'FKP',
        FM: 'USD',
        FO: 'DKK',
        NI: 'NIO',
        NL: 'EUR',
        NO: 'NOK',
        NA: 'NAD',
        VU: 'VUV',
        NC: 'XPF',
        NE: 'XOF',
        NF: 'AUD',
        NG: 'NGN',
        NZ: 'NZD',
        NP: 'NPR',
        NR: 'AUD',
        NU: 'NZD',
        CK: 'NZD',
        XK: 'EUR',
        CI: 'XOF',
        CH: 'CHF',
        CO: 'COP',
        CN: 'CNY',
        CM: 'XAF',
        CL: 'CLP',
        CC: 'AUD',
        CA: 'CAD',
        CG: 'XAF',
        CF: 'XAF',
        CD: 'CDF',
        CZ: 'CZK',
        CY: 'EUR',
        CX: 'AUD',
        CR: 'CRC',
        CW: 'ANG',
        CV: 'CVE',
        CU: 'CUP',
        SZ: 'SZL',
        SY: 'SYP',
        SX: 'ANG',
        KG: 'KGS',
        KE: 'KES',
        SS: 'SSP',
        SR: 'SRD',
        KI: 'AUD',
        KH: 'KHR',
        KN: 'XCD',
        KM: 'KMF',
        ST: 'STD',
        SK: 'EUR',
        KR: 'KRW',
        SI: 'EUR',
        KP: 'KPW',
        KW: 'KWD',
        SN: 'XOF',
        SM: 'EUR',
        SL: 'SLL',
        SC: 'SCR',
        KZ: 'KZT',
        KY: 'KYD',
        SG: 'SGD',
        SE: 'SEK',
        SD: 'SDG',
        DO: 'DOP',
        DM: 'XCD',
        DJ: 'DJF',
        DK: 'DKK',
        VG: 'USD',
        DE: 'EUR',
        YE: 'YER',
        DZ: 'DZD',
        US: 'USD',
        UY: 'UYU',
        YT: 'EUR',
        UM: 'USD',
        LB: 'LBP',
        LC: 'XCD',
        LA: 'LAK',
        TV: 'AUD',
        TW: 'TWD',
        TT: 'TTD',
        TR: 'TRY',
        LK: 'LKR',
        LI: 'CHF',
        LV: 'EUR',
        TO: 'TOP',
        LT: 'LTL',
        LU: 'EUR',
        LR: 'LRD',
        LS: 'LSL',
        TH: 'THB',
        TF: 'EUR',
        TG: 'XOF',
        TD: 'XAF',
        TC: 'USD',
        LY: 'LYD',
        VA: 'EUR',
        VC: 'XCD',
        AE: 'AED',
        AD: 'EUR',
        AG: 'XCD',
        AF: 'AFN',
        AI: 'XCD',
        VI: 'USD',
        IS: 'ISK',
        IR: 'IRR',
        AM: 'AMD',
        AL: 'ALL',
        AO: 'AOA',
        AQ: '',
        AS: 'USD',
        AR: 'ARS',
        AU: 'AUD',
        AT: 'EUR',
        AW: 'AWG',
        IN: 'INR',
        AX: 'EUR',
        AZ: 'AZN',
        IE: 'EUR',
        ID: 'IDR',
        UA: 'UAH',
        QA: 'QAR',
        MZ: 'MZN',
    }

    return currencies[countryCode]
}

export const sortByDateProperty = (
    dataArray,
    ascending = true,
    key = 'date'
) => {
    dataArray.sort((a, b) => {
        a = new Date(a[key])
        b = new Date(b[key])

        if (ascending) {
            return a < b ? -1 : a > b ? 1 : 0
        } else {
            return a > b ? -1 : a < b ? 1 : 0
        }
    })

    return dataArray
}

export const sortByDateAndTimeProperty = (dataArray, ascending = true) => {
    dataArray.sort((a, b) => {
        if (a['startdate']) {
            a = moment(a['startdate']+'T'+a['starttime'])
            b = moment(b['startdate']+'T'+b['starttime'])
        } else {
            a = moment(a['date']+'T'+a['starttime'])
            b = moment(b['date']+'T'+b['starttime'])
        }

        if (ascending) {
            return a.isSameOrBefore(b) ? -1 : 1
        } else {
            return a.isSameOrBefore(b) ? 1 : -1
        }
    })

    return dataArray
}

export const formatTimeString = (time, currentLanguage = 'de') => {
    const theTime = time.substring(0, 5)

    if (currentLanguage === 'en') {
        return formatAMPM(theTime, true)
    }

    return theTime
}

export const formatAMPM = (time, showPeriod) => {
    let hours = time.substr(0, 2)
    let minutes = time.substr(3, 5)
    let ampm = hours >= 12 ? 'pm' : 'am'

    hours = hours % 12
    hours = hours ? hours : 12
    let strTime = hours + ':' + minutes

    if (showPeriod === true) {
        strTime = strTime + ' ' + ampm
    }

    return strTime
}

export const openBase64File = (base64URL) => {
    const win = window.open("about:blank","_blank")
    win.document.write(
        '<iframe src="' +
            base64URL +
            '" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>'
    )

}
