import AvailabilityForm from '../features/availabilities/AvailabilityForm'
import AvailabilityList from '../features/availabilities/AvailabilityList'
import Blacklist from '../features/bookings/Blacklist'
import Bookings from '../features/bookings/Bookings'
import Branches from '../features/branches/Branches'
import Calendar from '../features/availabilities/Calendar'
import EmployeeAccess from '../features/branches/EmployeeAccess'
import ForgotPassword from '../features/login/ForgotPassword'
import GivenRatings from '../features/ratings/GivenRatings'
import i18n from '../i18n/config'
import Jobs from '../features/jobs/Jobs'
import Login from '../features/login/Login'
import NewCalendar from "../features/availabilities/NewCalendar"
import NotFound from '../features/NotFound'
import OpenRatings from '../features/ratings/OpenRatings'
import PersonalData from '../features/settings/PersonalData'
import ReceivedRatings from '../features/ratings/ReceivedRatings'
import Registration from '../features/login/Registration'
import Requests from '../features/bookings/Requests'
import ResetPassword from '../features/login/ResetPassword'
import Salaries from '../features/jobs/Salaries'
import Search from '../features/search/Search'
import Setup from '../features/login/Setup'
import Start from '../features/Start'

const routes = [
    {
        path: '/',
        exact: true,
        auth: true,
        component: <Start />,
    },
    {
        path: `/${i18n.t('routes.login')}`,
        exact: true,
        auth: false,
        component: <Login />,
    },
    {
        path: `/${i18n.t('routes.register')}`,
        exact: true,
        auth: false,
        component: <Registration />,
    },
    {
        path: `/${i18n.t('routes.forgotPassword')}`,
        exact: true,
        auth: false,
        component: <ForgotPassword />,
    },
    {
        path: `/${i18n.t('routes.resetPassword')}`,
        exact: true,
        auth: false,
        component: <ResetPassword />,
    },
    {
        path: `/${i18n.t('routes.setup')}`,
        exact: true,
        auth: true,
        component: (
            <>
                <Setup />
            </>
        ),
    },
    {
        path: `/${i18n.t('routes.regularEvents')}`,
        key: 'regular-entry',
        exact: true,
        auth: true,
        component: <AvailabilityForm type="regular" />,
    },
    {
        path: `/${i18n.t('routes.irregularEvents')}`,
        key: 'single-entry',
        exact: true,
        auth: true,
        component: <AvailabilityForm type="single" />,
    },
    {
        path: `/${i18n.t('routes.calendar')}`,
        exact: true,
        auth: true,
        component: <Calendar />,
    },
    {
        path: `/${i18n.t('routes.calendar-new')}`,
        exact: true,
        auth: true,
        component: <NewCalendar />,
    },
    {
        path: `/${i18n.t('routes.events')}`,
        exact: true,
        auth: true,
        component: <AvailabilityList />,
    },
    {
        path: `/${i18n.t('routes.openRequests')}`,
        exact: true,
        auth: true,
        component: <Requests />,
    },
    {
        path: `/${i18n.t('routes.bookings')}`,
        exact: true,
        auth: true,
        component: <Bookings />,
    },
    {
        path: `/${i18n.t('routes.blacklist')}`,
        exact: true,
        auth: true,
        component: <Blacklist />,
    },
    {
        path: `/${i18n.t('routes.openRatings')}`,
        exact: true,
        auth: true,
        component: <OpenRatings />,
    },
    {
        path: `/${i18n.t('routes.givenRatings')}`,
        exact: true,
        auth: true,
        component: <GivenRatings />,
    },
    {
        path: `/${i18n.t('routes.receivedRatings')}`,
        exact: true,
        auth: true,
        component: <ReceivedRatings />,
    },
    {
        path: `/${i18n.t('routes.courses')}`,
        exact: true,
        auth: true,
        component: <Jobs />,
    },
    {
        path: `/${i18n.t('routes.salaries')}`,
        exact: true,
        auth: true,
        component: <Salaries />,
    },
    {
        path: `/${i18n.t('routes.personalData')}`,
        exact: true,
        auth: true,
        component: <PersonalData />,
    },
    {
        path: `/${i18n.t('routes.personalDataChef')}`,
        exact: true,
        auth: true,
        component: <PersonalData />,
    },
    {
        path: `/${i18n.t('routes.search')}`,
        exact: true,
        auth: true,
        component: (
            <>
                <Search />{' '}
            </>
        ),
    },
    {
        path: `/${i18n.t('routes.openSearchRequests')}`,
        exact: true,
        auth: true,
        component: <Requests />,
    },
    {
        path: `/${i18n.t('routes.branches')}`,
        exact: true,
        auth: true,
        component: <Branches />,
    },
    {
        path: `/${i18n.t('routes.employees')}`,
        exact: true,
        auth: true,
        component: <EmployeeAccess />,
    },
    {
        path: `/${i18n.t('routes.employees')}`,
        exact: true,
        auth: true,
        component: <EmployeeAccess />,
    },
    {
        path: '*',
        exact: false,
        auth: false,
        component: <NotFound />,
    },
]

export default routes
