import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { SPACING } from '../../assets/constants'
import { Heading } from '../../common/text'
import { MdSettings, MdAccountBalance } from 'react-icons/md'
import { BiCalendarCheck } from 'react-icons/bi'
import { AiFillStar } from 'react-icons/ai'
import { useAuth } from '../../context/AuthContext'
import { CSSProperties } from 'styled-components'
import { useEffect, useState } from 'react'
import BranchService from '../../services/branch-service'
import LegalPagesMenu from './LegalPagesMenu'

const ChefMenu = () => {
    const branchService = new BranchService()

    const { user } = useAuth()
    const { t } = useTranslation()
    const [branchCount, setBranchCount] = useState<number>(0)

    useEffect(() => {
        if (!user || (user && user.role !== 'chef')) {
            return
        }

        const getBranches = async () => {
            try {
                const response = await branchService.getAllBranches()
                setBranchCount(response.length)
            } catch (error) {}
        }

        getBranches()
    }, [])

    const activeLinkStyle: CSSProperties = {
        fontWeight: 'bold',
        backgroundColor: '#ffffff29',
    }

    return (
        <>
            <ul>
                <li>
                    <NavLink
                        exact
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.search')}`}
                    >
                        {t('pages.title.searchAlt')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <BiCalendarCheck style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.bookingsManage')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.openSearchRequests')}`}
                    >
                        {t('pages.title.openSearchRequests')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.bookings')}`}
                    >
                        {t('pages.title.bookings')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.blacklist')}`}
                    >
                        {t('pages.title.contra')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <AiFillStar style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.ratings')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.openRatings')}`}
                    >
                        {t('pages.title.openRatings')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.givenRatings')}`}
                    >
                        {t('pages.title.givenRatingsChef')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.receivedRatings')}`}
                    >
                        {t('pages.title.receivedRatingsChef')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <MdAccountBalance
                    style={{ marginRight: SPACING.xsmall }}
                />{' '}
                {t('pages.section.company')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.branches')}`}
                    >
                        {t('pages.title.branchesAlt')} {`(${branchCount})`}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.employees')}`}
                    >
                        {t('pages.title.employees')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <MdSettings style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.settings')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.personalDataChef')}`}
                    >
                        {t('pages.title.personalDataChef')}
                    </NavLink>
                </li>
            </ul>

            <LegalPagesMenu />
        </>
    )
}

export default ChefMenu
