import React from 'react';
import {SubTitle} from "./styled-components";
import Popup from "./popup";
import styled from "styled-components";
import { ReactComponent as StarIcon } from '../assets/images/star.svg';
import { formatAMPM, formatMoney, formatTimeString } from "../utils/utility-functions";
import dateFormat from "dateformat";
import { SPACING } from '../../assets/constants';
import {getI18n, useTranslation} from "react-i18next";

const PopupSection = styled.div`
    margin-bottom: 15px;
    flex: 1 1 0;
`;

type Suggestion = {
    id: number;
    date: string;
    starttime: string;
    endtime: string;
    enddate: string;
    course: string;
    br_name: number;
    total: number;
    currency: string;
    br_total_avg: number|null;
}

type FreelancerConfirmedRequestPopupProps = {
    suggestion: Suggestion
    hidePopup: () => void
}

const FreelancerConfirmedRequestPopupNew = ({suggestion, hidePopup}: FreelancerConfirmedRequestPopupProps) => {
    const { t } = useTranslation();
    const language = getI18n().language;

    let dateformat = 'd. mmmm yyyy';
    let convertedStarttime = formatTimeString(suggestion.starttime);
    let convertedEndtime = formatTimeString(suggestion.endtime);

    if (language === 'en') {
        dateformat = 'mm/dd/yyyy';
        convertedStarttime = formatAMPM(convertedStarttime, true);
        convertedEndtime = formatAMPM(convertedEndtime, true);
    }

    return <>
        <Popup title={t('popup.applyDetails')} hidePopup={hidePopup}>
            <p>{t('popup.applyDetailsDescription')}</p>
            <div style={{ marginTop: SPACING.medium }}>
                <PopupSection>
                    <SubTitle>{t('popup.company')}</SubTitle>
                    <div style={{ display: 'flex' }}>
                        <p style={{ flex: '1 1 0' }}>{suggestion && suggestion.br_name}</p>
                        <p>
                            {suggestion && suggestion.br_total_avg ? suggestion.br_total_avg.toFixed(2) : '-'} <StarIcon
                                style={{ marginBottom: 2, transform: 'scale(1.3)' }} />
                        </p>
                    </div>
                </PopupSection>
                <PopupSection>
                    <SubTitle>{t('popup.pay')}</SubTitle>
                    <p>{suggestion && formatMoney(suggestion.total)} {suggestion && suggestion.currency}</p>
                </PopupSection>
                <PopupSection>
                    <SubTitle>{t('popup.job')}</SubTitle>
                    <p>{suggestion && suggestion.course}</p>
                </PopupSection>
                <PopupSection>
                    <SubTitle>{t('popup.dateAndTime')}</SubTitle>
                    <p>{suggestion && convertedStarttime} - {suggestion && convertedEndtime}</p>
                    <p>{suggestion && dateFormat(suggestion.date, dateformat)}</p>
                </PopupSection>
            </div>
        </Popup>
    </>
}

export default FreelancerConfirmedRequestPopupNew;