import axios from 'axios'

class BlacklistService {
    constructor(options = {}) {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async getBranchBlacklist(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/blacklist`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async blockFreelancer(branchId, freelancerId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/blacklist/add/${freelancerId}`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async unblockFreelancer(branchId, freelancerId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/blacklist/remove/${freelancerId}`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getFreelancerBlacklist() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/blacklist`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async blockBranch(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/blacklist/add/${branchId}`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async unblockBranch(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/blacklist/remove/${branchId}`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }
}

export default BlacklistService
