import React, { Component } from 'react';
import BranchDropdown from "../branch-dropdown";
import List from "../list";
import dateFormat from "dateformat";
import { ReactComponent as MoreButton } from "../../assets/images/more.svg";
import RequestPopup from "../request-popup";
import Loader from "../loader";
import PopupMessage from "../popup-message";
import { withTranslation } from "react-i18next";
import SearchService from '../../../services/search-service';
import i18n from '../../../i18n/config';
import {formatAMPM, formatTimeString, sortByDateAndTimeProperty} from "../../utils/utility-functions";

class RequestsWidget extends Component {

    constructor(props) {
        super(props);

        this.searchService = new SearchService();

        this.state = {
            selectedBranchId: undefined,
            requests: undefined,
            showPopup: false,
            showMessage: false,
            message: undefined,
            selectedRequest: undefined,
            isLoading: false,
            currentLanguage: i18n.language,
        }
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show });
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show });
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message });
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading });
    }

    hidePopup = () => {
        this.setState({ ...this.state, showPopup: false });
    }

    updateRequests = (branchId) => {
        this.searchService.getBranchRequests(
            branchId
        ).then(res => {
            if (res && res.data) {
                this.setState({ ...this.state, requests: sortByDateAndTimeProperty(res.data) });
            }
        });
    }

    setSelectedBranch = (branchId) => {
        this.setState({ ...this.state, selectedBranchId: branchId });
    }

    updateRequest = (branchId, requestId, showPopup = true) => {
        this.searchService.getRequest(
            branchId,
            requestId
        ).then(res => {
            if (res && res.data) {
                this.setShowPopup(showPopup);
                this.setState({ ...this.state, selectedRequest: res.data });
            }
        });
    }

    openRequestDetails = (request) => {
        this.setState(
            { ...this.state, selectedRequest: request },
            () => this.setShowPopup(true)
        );
    }

    requestingRequest = (requestId) => {
        this.setIsLoading(true);
        this.searchService.requestFreelancer(
            this.state.selectedBranchId,
            requestId
        ).then(res => {
            this.setIsLoading(false);
            this.updateRequest(this.state.selectedBranchId, requestId);
            this.updateRequests(this.state.selectedBranchId);
        });
    }

    confirmRequest = (requestId) => {
        const { t } = this.props;

        this.setIsLoading(true);
        this.searchService.bookFreelancer(
            this.state.selectedBranchId,
            requestId
        ).then(res => {
            this.setIsLoading(false);
            this.setShowPopup(false);

            if (res && res.data) {
                const { course, fl_firstname, fl_lastname, date, starttime, endtime } = res.data;
                this.setShowMessage(true);
                //this.setMessage(`<b>${fl_firstname} ${fl_lastname}</b> wurde verbindlich für <b>${course}</b> am <b>${dateFormat(date, 'd. mmm yyyy')}</b> von <b>${formatTimeString(starttime)} bis ${formatTimeString(endtime)} Uhr</b> gebucht.`);
                this.setMessage(`<div>${t('search.booking')}</div>`);
            }

            this.updateRequests(this.state.selectedBranchId);
        });
    }

    declineRequest = (requestId, suggestion, second) => {
        const { t } = this.props;

        this.setIsLoading(true);
        this.searchService.declineFreelancer(
            this.state.selectedBranchId,
            requestId
        ).then(res => {
            this.setIsLoading(false);
            this.setShowPopup(false);

            console.log(res)
            console.log(suggestion)
            console.log(second)

            if (res && res.data) {
                if (suggestion === 1 && second) {
                    this.setShowPopup(true);
                    this.setState({ ...this.state, selectedRequest: res.data });
                }
            }

            if (suggestion === 2 || (suggestion === 1 && second === false)) {
                this.setShowPopup(false);
                this.setShowMessage(true);
                this.setMessage(`<div>${t('search.declined')}</div>`);
            }

            // TODO delete request if suggestion 2 was declined

            this.updateRequest(this.state.selectedBranchId, requestId, false);
            this.updateRequests(this.state.selectedBranchId);
        });
    }

    deleteRequest = (requestId) => {
        const { t } = this.props;

        this.setIsLoading(true);
        this.searchService.deleteRequest(
            this.state.selectedBranchId,
            requestId
        ).then(res => {
            this.setIsLoading(false);
            this.setShowPopup(false);

            this.setShowMessage(true);
            this.setMessage(`<div>${t('search.deleted')}</div>`);

            this.updateRequests(this.state.selectedBranchId);
        });
    }

    getListItemStructure = (item) => {
        const { course, fl_firstname, fl_lastname, date, starttime, endtime, requested, requestables } = item;
        const { t } = this.props;

        let dateformat = 'd. mmm yyyy';
        let convertedStarttime = formatTimeString(starttime);
        let convertedEndtime = formatTimeString(endtime);

        if (i18n.language === 'en') {
            dateformat = 'mm/dd/yyyy';
            convertedStarttime = formatAMPM(convertedStarttime, true);
            convertedEndtime = formatAMPM(convertedEndtime, true);
        }

        const courseMarkdown = `<b>${course}</b>`;
        const trainerMarkdown = fl_firstname
            ? `<b>${fl_firstname} ${fl_lastname}</b>`
            : requestables === 0
                ? `<i>${t('search.noFreelancersAlt')}</i>`
                : requested > 0
                    ? `<i>${t('search.requested')}</i>`
                    : `<i>${t('popup.requestable')}</i>`;
        const timeMarkdown = `<span>${convertedStarttime} - ${convertedEndtime}</span>`;

        return [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: trainerMarkdown, isHTML: true },
            { markdown: timeMarkdown, isHTML: true },
            { markdown: dateFormat(date, dateformat), isHTML: false },
            {
                markdown: <MoreButton style={{ cursor: 'pointer' }} onClick={() => this.openRequestDetails(item)} />,
                isHTML: false
            },
        ];
    }

    render() {
        const { requests, showPopup, selectedRequest, isLoading, showMessage, message } = this.state;
        const { t } = this.props;
        const currentLanguage = i18n.language;

        return <>
            <BranchDropdown updateItems={this.updateRequests}
                setSelectedBranch={this.setSelectedBranch} />

            <List items={requests}
                noItemsMessage={t('list.noSearchRequests')}
                headings={[t('list.job'), t('list.trainer'), t('list.time'), t('list.date'), '']}
                getListItemStructure={this.getListItemStructure}
                style={{ marginTop: '30px' }} />
            {
                showPopup &&
                <RequestPopup request={selectedRequest}
                    hidePopup={this.hidePopup}
                    updateRequest={this.updateRequest}
                    requestingRequest={this.requestingRequest}
                    confirmRequest={this.confirmRequest}
                    declineRequest={this.declineRequest}
                    deleteRequest={this.deleteRequest}
                    currentLanguage={currentLanguage} />
            }
            {
                isLoading &&
                <Loader />
            }
            {
                showMessage && message &&
                <PopupMessage message={message} setShowMessage={this.setShowMessage} />
            }

        </>;
    }
}

export default withTranslation()(RequestsWidget);
