import { useTranslation } from 'react-i18next'
import { SPACING } from '../../assets/constants'
import {
    COOKIES_PAGE,
    COOKIES_PAGE_EN,
    FAQ_PAGE,
    FAQ_PAGE_EN,
    IMPRINT_PAGE,
    IMPRINT_PAGE_EN,
    PRIVACY_PAGE,
    PRIVACY_PAGE_EN,
    TERMS_PAGE,
    TERMS_PAGE_EN,
} from '../../assets/config'
import styled from 'styled-components'
import { useAuth } from '../../context/AuthContext'

const Wrapper = styled.div`
    h3 {
        margin-bottom: 0;
    }
    ul {
        margin-top: 4px;
    }
    ul li a {
        padding-top: 3px;
        padding-bottom: 3px;
        cursor: pointer;
        font-size: 0.8rem;
    }
`

const LegalPagesMenu = () => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const locale = user.locale

    return (
        <Wrapper>
            <h3 style={{ fontSize: '0.8rem', marginTop: SPACING.xlarge * 2, textTransform: 'uppercase', letterSpacing: '1px' }}>
                {' '}
                {t('pages.section.legal')}
            </h3>
            <ul>
                <li>
                    <a
                        onClick={() => {
                            window.open(
                                locale === 'en' ? FAQ_PAGE_EN : FAQ_PAGE,
                                '_blank'
                            )
                            return null
                        }}
                        style={{ fontSize: '1.0rem', padding: '8px 22px'}}
                    >
                        {t('routes.faq')}
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            window.open(
                                locale === 'en' ? TERMS_PAGE_EN : TERMS_PAGE,
                                '_blank'
                            )
                            return null
                        }}
                        style={{ fontSize: '1.0rem', padding: '8px 22px'}}
                    >
                        {t('routes.tos')}
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            window.open(
                                locale === 'en'
                                    ? PRIVACY_PAGE_EN
                                    : PRIVACY_PAGE,
                                '_blank'
                            )
                            return null
                        }}
                        style={{ fontSize: '1.0rem', padding: '8px 22px'}}
                    >
                        {t('routes.privacy')}
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            window.open(
                                locale === 'en'
                                    ? COOKIES_PAGE_EN
                                    : COOKIES_PAGE,
                                '_blank'
                            )
                            return null
                        }}
                        style={{ fontSize: '1.0rem', padding: '8px 22px'}}
                    >
                        {t('routes.cookies')}
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            window.open(
                                locale === 'en'
                                    ? IMPRINT_PAGE_EN
                                    : IMPRINT_PAGE,
                                '_blank'
                            )
                            return null
                        }}
                        style={{ fontSize: '1.0rem', padding: '8px 22px'}}
                    >
                        {t('routes.imprint')}
                    </a>
                </li>
            </ul>
        </Wrapper>
    )
}

export default LegalPagesMenu
