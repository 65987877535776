import { useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import styled from 'styled-components'
import { COLORS, SPACING } from '../assets/constants'
import { StyledWrapper } from '../legacy/components/styled-components'
import flagDe from '../assets/images/flags/de.svg'
import flagGb from '../assets/images/flags/gb.svg'
import { useAuth } from '../context/AuthContext'
import AccountService from '../services/account-service'
import i18n from './config'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
    position: 'relative';

    > div {
        cursor: pointer;
    }
`

const Popup = styled(StyledWrapper)`
    padding: 0;
    position: absolute;
    top: 45px;
    left: -50px;
    overflow: hidden;

    button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        cursor: pointer;
        padding: ${SPACING.xsmall}px;
        width: 100%;

        &:hover {
            background: ${COLORS.lightgrey};
        }
    }
`

const LanguageSwitch = ({
    style,
    color,
}: {
    style?: Object
    color?: string
}) => {
    const accountService = new AccountService()

    const [opened, setOpened] = useState<boolean>(false)
    const { token, user, updateUser } = useAuth()
    const { t } = useTranslation() // Needed for component to update on language change

    const changeLanguage = async (lang: string) => {
        i18n.changeLanguage(lang)
        localStorage.setItem('jg-lang', lang)

        if (token) {
            const locale = await accountService.updateLocale(lang)
            user.locale = lang

            updateUser(user)
        }
    }

    return (
        <Wrapper style={style}>
            <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => setOpened(!opened)}
            >
                <FiGlobe
                    color={color}
                    size={20}
                    style={{
                        cursor: 'pointer',
                        marginRight: SPACING.xsmall / 2,
                    }}
                />
                <span
                    style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: '0.8rem',
                        color: color,
                    }}
                >
                    {i18n.language}
                </span>
            </div>

            {opened && (
                <Popup>
                    <button
                        onClick={() => {
                            changeLanguage('de')
                            setOpened(false)
                        }}
                    >
                        Deutsch
                    </button>

                    <button
                        onClick={() => {
                            changeLanguage('en')
                            setOpened(false)
                        }}
                    >
                        English
                    </button>
                </Popup>
            )}
        </Wrapper>
    )
}

export default LanguageSwitch
