import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SPACING } from "../assets/constants";

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const NotFound = () => {

    const { t } = useTranslation();

    return <Wrapper>
        <h1 style={{ fontSize: '4rem', marginBottom: SPACING.large }}>
            404
        </h1>
        <h2 style={{ maxWidth: 500, textAlign: 'center' }}>
            {t('pages.notFound')}
        </h2>
    </Wrapper>;

}

export default NotFound;