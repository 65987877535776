import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import BranchesWidget from "../../legacy/components/widgets/branches-widget";

const Requests = () => {

    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.branches')}
        </h1>

        <p>
            {t('pages.description.branches')}
        </p>

        <BranchesWidget />

    </>;

}

export default Requests;