import React, { Component } from 'react'
import List from '../list'
import {
    formatTimeString,
    sortByDateProperty,
} from '../../utils/utility-functions'
import dateFormat from 'dateformat'
import { ReactComponent as MoreButton } from '../../assets/images/more.svg'
import { withTranslation } from 'react-i18next'
import SearchService from '../../../services/search-service'
import i18n from '../../../i18n/config'
import FreelancerConfirmedRequestPopupNew from "../freelancer-confirmed-request-popup-new";

class FreelancerConfirmedRequestsWidget extends Component {
    constructor(props) {
        super(props)

        this.searchService = new SearchService()

        this.state = {
            suggestions: undefined,
            showPopup: false,
            showMessage: false,
            message: undefined,
            selectedSuggestion: undefined,
            isLoading: false,
            currentLanguage: i18n.language,
            dateformat: i18n.language === 'en' ? 'mm/dd/yyyy' : 'd. mmm yyyy',
        }
    }

    componentDidMount() {
        this.updateSuggestions()
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show })
    }

    hidePopup = () => {
        this.setState({ ...this.state, showPopup: false })
    }

    updateSuggestions = () => {
        this.searchService.getFreelancerSuggestions().then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    suggestions: sortByDateProperty(res.data),
                })
            }
        })
    }

    openSuggestionDetails = (request) => {
        this.setState({ ...this.state, selectedSuggestion: request }, () =>
            this.setShowPopup(true)
        )
    }

    getListItemStructure = (item) => {
        const { course, date, starttime, endtime, br_name } = item
        const { dateformat } = this.state
        const currentLanguage = i18n.language

        const courseMarkdown = `<b>${course}</b>`
        const branchMarkdown = `<b>${br_name}</b>`
        const timeMarkdown = `<span>${formatTimeString(
            starttime,
            currentLanguage
        )} - ${formatTimeString(endtime, currentLanguage)}</span>`

        const markdown = [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: branchMarkdown, isHTML: true },
            { markdown: timeMarkdown, isHTML: true },
            { markdown: dateFormat(date, dateformat), isHTML: false },
            {
                markdown: (
                    <MoreButton
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openSuggestionDetails(item)}
                    />
                ),
                isHTML: false,
            },
        ]
        console.log(markdown)
        return markdown
    }

    render() {
        const {
            suggestions,
            showPopup,
            selectedSuggestion,
        } = this.state
        const { t } = this.props

        console.log(suggestions)
        return (
            <>
                <List
                    items={suggestions}
                    noItemsMessage={t('list.noRequests')}
                    headings={[
                        t('list.job'),
                        t('list.company'),
                        t('list.time'),
                        t('list.date'),
                        '',
                    ]}
                    getListItemStructure={this.getListItemStructure}
                    style={{ marginTop: '30px' }}
                />
                {showPopup && (
                    <FreelancerConfirmedRequestPopupNew
                        suggestion={selectedSuggestion}
                        hidePopup={this.hidePopup}
                    />
                )}
            </>
        )
    }
}

export default withTranslation()(FreelancerConfirmedRequestsWidget)

