import i18n from './i18n/config';
import LanguageSwitch from './i18n/LanguageSwitch';
import Menu from './navigation/Menu';
import Pages from './navigation/Pages';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { COLORS, DEVICE, MENU_WIDTH, SPACING } from './assets/constants';
import { FiLogOut } from 'react-icons/fi';
import { StyledWrapper } from './legacy/components/styled-components';
import { useAuth } from './context/AuthContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './App.scss';

const AppWrapper = styled.div`
  display: flex;
  max-width: 100vw;
  background-color: ${COLORS.background};
`;

const Header = styled.header`
  position: relative;
  z-index: 99;
  height: 100vh;

  @media ${DEVICE.tablet} {
    position: fixed;
  }
`;

const Main = styled.main`
  position: relative;
  flex: 1;
  min-height: 100vh;
  background: white;
`;

const App = () => {

  const { token, user, logout } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) return;

    localStorage.setItem('jg-lang', user.locale);
    i18n.changeLanguage(user.locale);
  }, [user]);

  const setupFinished = user && ((['employee', 'manager'].includes(user.role)) || (user.freelancer_id || user.company));

  return <AppWrapper>

    <Router>

      {setupFinished && <Header>
        <Menu />
      </Header>}

      <Main>
        <Switch>
          <Pages />
        </Switch>
      </Main>

    </Router>

    <StyledWrapper style={{
      padding: SPACING.xsmall,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: SPACING.medium,
      right: SPACING.medium,
      zIndex: 9,
    }} >
      <LanguageSwitch color="#000000" />
      {token && user && <button style={{
        display: 'flex',
        alignItems: 'center',
        background: 'none',
        border: 'none',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        cursor: 'pointer',
      }}
        onClick={() => logout()}
      >
        <FiLogOut style={{ marginLeft: SPACING.medium, marginRight: 5, fontSize: '1.1rem' }} /> {t('forms.logout')}
      </button>}
    </StyledWrapper>

  </AppWrapper>;
}

export default App;
