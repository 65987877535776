import axios from 'axios'

class BookingsService {
    constructor(options = {}) {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async getUpcomingBookings(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/bookings/all?mode=future`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getPastBookings(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/bookings/all?mode=past`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getBlacklistableBookings(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/bookings/all?mode=blacklistable`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getBooking(branchId, bookingId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/bookings/${bookingId}`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getFreelancerAllBookings() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/bookings/all`,
                this.config
            )

            return response.data.data
        } catch (error) {
            throw error.response
        }
    }

    async getFreelancerUpcomingBookings() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/bookings/all?mode=future`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getFreelancerPastBookings() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/bookings/all?mode=past`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getFreelancerInvoiceBookings() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/bookings/all?mode=invoiceable`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getFreelancerNextBooking() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/bookings/next`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async sendInvoices() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/invoices/generate`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async deleteInvoice(bookingId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/bookings/${bookingId}/invoice`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async cancelBookingAsBranch(branchId, bookingId, reason) {
        try {
            const response = await axios.put(
                `${this.apiBase}/branch/${branchId}/bookings/${bookingId}/cancelling`,
                {
                    reason,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async cancelBookingAsFreelancer(bookingId, reason) {
        try {
            const response = await axios.put(
                `${this.apiBase}/me/bookings/${bookingId}/cancelling`,
                {
                    reason,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }
}

export default BookingsService
