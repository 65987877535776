import styled from "styled-components";
import { COLORS, RADIUS, SPACING } from "../assets/constants";
import { MdInfoOutline } from "react-icons/md";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    label {
        display: block;
        width: 100%;
        font-size: 0.7rem;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: ${COLORS.primaryFaded};
        margin-bottom: ${SPACING.medium}px;

        input {
            width: 100%;
            padding: ${SPACING.small}px;
            border-width: 1px;
            border-color: ${COLORS.faded};
            border-style: solid;
            border-radius: ${RADIUS.tiny}px;

            &[type="checkbox"] {
                display: none;

                & + label {
                    cursor: pointer;
                }

                & + label:before {
                    width: 15px;
                    height: 15px;
                    background-color: #e9e9e9;
                    display: block;
                    content: "";
                    float: left;
                    margin-right: 12px;
                    cursor: pointer;
                }

                &:checked + label:before {
                    background-color: ${COLORS.primary};
                }
            }
        }
    }

    button {
        font-size: 14px;
        margin-top: ${SPACING.medium}px;
        padding: ${SPACING.small}px ${SPACING.xlarge}px;
        font-weight: bold;
        color: ${COLORS.light};
        background-color: ${COLORS.primary};
        border-radius: ${RADIUS.large * 2}px;
        border: none;
        transition: filter 0.1s;

        &:disabled {
            background-color: ${COLORS.grey};
        }

        &:hover:not(:disabled) {
            cursor: pointer;
            filter: brightness(1.2);
        }
    }
`;

export const Button = styled.button`
    margin-top: ${SPACING.medium}px;
    padding: ${SPACING.small}px ${SPACING.xlarge}px;
    font-weight: bold;
    color: ${COLORS.light};
    background-color: ${COLORS.primary};
    border-radius: ${RADIUS.large}px;
    border: none;
    float: right;
    transition: filter 0.1s;

    &:hover {
        cursor: pointer;
        filter: brightness(1.2);
    }
`;

export const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`;

export const TextArea = styled.textarea`
    width: 100%;
    border: 1px solid ${COLORS.lightgrey};
    resize: none;
    padding: ${SPACING.small}px;
    border-radius: 4px;
`;