import React, { Component } from 'react';
import { InputSection, StyledLabel, StyledSelect } from "../styled-components";
import { withTranslation } from 'react-i18next';
import { SALARY_STEP, MIN_SALARY, MAX_SALARY } from '../../assets/constants';

class FreelancerSalaryForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { salary, currency, changeValue, t, countries } = this.props;
        const currencies = new Set();

        if (countries) countries.forEach(country => currencies.add(country.acronym));

        return <>
            <div style={{ display: 'flex', margin: '0 -5px' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px', maxWidth: 150 }}>
                    <StyledLabel>{t('forms.salary')}</StyledLabel>
                    <input type={'number'}
                        min={MIN_SALARY}
                        max={MAX_SALARY}
                        step={SALARY_STEP}
                        value={salary}
                        onChange={(event) => changeValue('salary', event.target.value)} />
                </InputSection>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px', maxWidth: 125 }}>
                    <StyledLabel>{t('forms.currency')}</StyledLabel>
                    <StyledSelect
                        style={{ width: '100%' }}
                        value={currency}
                        onChange={(event) => changeValue('currency', event.target.value)}>
                        {currencies && Array.from(currencies).map(currency => <option value={currency}>{currency}</option>)}
                    </StyledSelect>
                </InputSection>
            </div>
        </>;
    }
}

export default withTranslation()(FreelancerSalaryForm);