import axios from 'axios'

import { add, format, isBefore } from 'date-fns'

class AvailabilityService {
    constructor(options = {}) {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async getAvailabilities() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/calendar`,
                this.config
            )

            return response.data.data
        } catch (error) {
            return error
        }
    }

    async addAvailabilities(
        firstdate,
        lastdate,
        starttime,
        endtime,
        interval,
        availability,
        overnight,
        type
    ) {
        try {
            const calculatedLastDate =
                type === 'single'
                    ? lastdate
                    : format(
                          add(new Date(), { months: 6, days: 0 }),
                          'yyy-MM-dd'
                      )
            const calculatedInterval =
                interval === 'weekdays' || interval === 'weekend'
                    ? 1
                    : parseInt(interval)
            const calculatedWeek =
                interval === 'weekdays'
                    ? 'weekdays'
                    : interval === 'weekend'
                    ? 'weekend'
                    : 'all'
            const dateFromStartTime = new Date(
                `01 Jan 1970 ${starttime}:00 GMT`
            )
            const dateFromEndTime = new Date(`01 Jan 1970 ${endtime}:00 GMT`)

            const response = await axios.post(
                `${this.apiBase}/me/calendar`,
                {
                    firstdate: firstdate,
                    lastdate: calculatedLastDate,
                    starttime: starttime,
                    endtime: endtime,
                    interval: calculatedInterval,
                    availability: availability,
                    overnight: isBefore(dateFromEndTime, dateFromStartTime),
                    week: calculatedWeek,
                },
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async removeAvailability(availabilityId) {
        try {
            const response = await axios.delete(
                `${this.apiBase}/me/calendar/${availabilityId}`,
                this.config
            )

            return response
        } catch (error) {
            return error
        }
    }

    async removeAvailabilityGroup(availabilityGroupId) {
        try {
            const response = await axios.delete(
                `${this.apiBase}/me/calendar-group/${availabilityGroupId}`,
                this.config
            )

            return response
        } catch (error) {
            return error
        }
    }

    async editAvailability(availability) {
        try {
            const response = await axios.put(
                `${this.apiBase}/me/calendar/${availability.id}`,
                availability,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }
}

export default AvailabilityService
