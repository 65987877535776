import React, { Component } from 'react'
import BranchDropdown from '../branch-dropdown'
import {
    formatAMPM,
    formatTimeString,
    sortByDateProperty,
} from '../../utils/utility-functions'
import List from '../list'
import dateFormat from 'dateformat'
import { ReactComponent as MoreButton } from '../../assets/images/more.svg'
import Loader from '../loader'
import BookingsPopup from '../bookings-popup'
import { SubHeading } from '../styled-components'
import { withTranslation } from 'react-i18next'
import BookingsService from '../../../services/bookings-service'
import i18n from '../../../i18n/config'

class BookingsWidget extends Component {
    constructor(props) {
        super(props)

        this.bookingsService = new BookingsService()

        this.state = {
            selectedBranchId: undefined,
            upcomingBookings: undefined,
            pastBookings: undefined,
            selectedBooking: undefined,
            isLoading: false,
            showPopup: false,
            currentLanguage: i18n.language,
        }
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading })
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show })
    }

    updateBookings = (branchId) => {
        this.bookingsService.getUpcomingBookings(branchId).then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    upcomingBookings: sortByDateProperty(res.data),
                })
            }
        })
        this.bookingsService.getPastBookings(branchId).then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    pastBookings: sortByDateProperty(res.data, false),
                })
            }
        })
    }

    setSelectedBranch = (branchId) => {
        this.setState({ ...this.state, selectedBranchId: branchId })
    }

    openBookingDetails = (booking) => {
        this.setState({ ...this.state, selectedBooking: booking }, () =>
            this.setShowPopup(true)
        )
    }

    getListItemStructure = (item) => {
        const { course, fl_firstname, fl_lastname, date, starttime, endtime } =
            item
        const { t } = this.props

        let dateformat = 'd. mmm yyyy'
        let convertedStarttime = formatTimeString(starttime)
        let convertedEndtime = formatTimeString(endtime)

        if (i18n.language === 'en') {
            dateformat = 'mm/dd/yyyy'
            convertedStarttime = formatAMPM(convertedStarttime, true)
            convertedEndtime = formatAMPM(convertedEndtime, true)
        }

        const courseMarkdown = `<b>${course}</b>`
        const trainerMarkdown =
            fl_firstname && fl_lastname
                ? `<b>${fl_firstname} ${fl_lastname}</b>`
                : `<b>${t('booking.userDeleted')}</b>`
        const timeMarkdown = `<span>${convertedStarttime} - ${convertedEndtime}</span>`

        return [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: trainerMarkdown, isHTML: true },
            { markdown: timeMarkdown, isHTML: true },
            { markdown: dateFormat(date, dateformat), isHTML: false },
            {
                markdown: (
                    <MoreButton
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openBookingDetails(item)}
                    />
                ),
                isHTML: false,
            },
        ]
    }

    render() {
        const {
            upcomingBookings,
            pastBookings,
            isLoading,
            selectedBooking,
            showPopup,
        } = this.state
        const { t } = this.props
        const currentLanguage = i18n.language

        return (
            <>
                <BranchDropdown
                    updateItems={this.updateBookings}
                    setSelectedBranch={this.setSelectedBranch}
                />

                <div style={{ marginTop: 50 }}>
                    <SubHeading>{t('booking.upcomingBookings')}</SubHeading>
                    <p>{t('booking.upcomingBookingsDescription')}</p>
                </div>

                <List
                    items={upcomingBookings}
                    noItemsMessage={t('booking.noUpcoming')}
                    headings={[
                        t('list.job'),
                        t('list.trainer'),
                        t('list.time'),
                        t('list.date'),
                        '',
                    ]}
                    getListItemStructure={this.getListItemStructure}
                    style={{ marginTop: '30px' }}
                />

                <div style={{ marginTop: 50 }}>
                    <SubHeading>{t('booking.pastAlt')}</SubHeading>
                    <p>{t('booking.pastDescriptionAlt')}</p>
                </div>

                <List
                    items={pastBookings}
                    noItemsMessage={t('booking.noPast')}
                    headings={[
                        t('list.job'),
                        t('list.trainer'),
                        t('list.time'),
                        t('list.date'),
                        '',
                    ]}
                    getListItemStructure={this.getListItemStructure}
                    style={{ marginTop: '30px' }}
                />

                {showPopup && (
                    <BookingsPopup
                        booking={selectedBooking}
                        hidePopup={() => this.setShowPopup(false)}
                        currentLanguage={currentLanguage}
                    />
                )}

                {isLoading && <Loader />}
            </>
        )
    }
}

export default withTranslation()(BookingsWidget)
