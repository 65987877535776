import React, {Component} from 'react';
import styled from "styled-components";
import {Backdrop, StyledWrapper} from "./styled-components";
import LoadingSpinner from '../assets/images/loading.gif';

const LoaderWrapper = styled(StyledWrapper)`
    padding: 20px;
`;

class Loader extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Backdrop>
            <LoaderWrapper>
                <img src={LoadingSpinner} width={55} height={45}/>
            </LoaderWrapper>
        </Backdrop>;
    }
}

export default Loader;