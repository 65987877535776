import React, { Component } from 'react'
import List from '../list'
import {
    FormValue,
    InputSection,
    ListElement,
    ListElementWrapper,
    StyledButton,
    StyledLabel,
    TextButton,
} from '../styled-components'
import styled from 'styled-components'
import Loader from '../loader'
import { ReactComponent as StarIcon } from '../../assets/images/star.svg'
import SectionHeading from '../section-heading'
import PopupMessage from '../popup-message'
import Form from '../form'
import { PHONE_CODES, PRIMARY_COLOR } from '../../assets/constants'
import { withTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import Popup from '../popup'
import BranchService from '../../../services/branch-service'
import AccountService from '../../../services/account-service'

const InfoMessage = styled.span`
    display: block;
    font-size: 0.9em;
    font-weight: normal;
`

const DeleteIcon = styled(MdDelete)`
    color: ${PRIMARY_COLOR};
    transform: scale(1.3);
    cursor: pointer;
`

class BranchesWidget extends Component {
    constructor(props) {
        super(props)

        this.state = {
            countries: [],
            branches: {},
            initialBranches: {},
            isLoading: false,
            showMessage: false,
            message: '',
            addingBranch: false,
            newBranch: {
                name: '',
                email: '',
                street: '',
                housenumber: '',
                city: '',
                zipcode: '',
                state: '',
                country: 'DE',
                adress_additional: '',
                phone: '',
                website: '',
            },
            currentBranchId: undefined,
            showPopup: false,
        }

        this.branchService = new BranchService()
        this.accountService = new AccountService()
    }

    componentDidMount() {
        this.updateBranches()
        this.accountService.getCountries().then((res) => {
            if (res && res.data) {
                this.setState({ ...this.state, countries: res.data })
            }
        })
    }

    setIsLoading = (isLoading) => {
        this.setState({ isLoading: isLoading })
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show })
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show })
    }

    showDeleteBranchPopup = (show, branchId) => {
        this.setState({
            ...this.state,
            showPopup: show,
            currentBranchId: branchId,
        })
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message })
    }

    updateBranches() {
        this.branchService.getAllBranches().then((res) => {
            const branches = {}
            res.forEach((branch) => {
                branches[branch.id] = branch
                branches[branch.id].editing = false

                branches[branch.id].initialName = branch.name
                branches[branch.id].initialEmail = branch.email
                branches[branch.id].phone = branch.phone

                branches[branch.id].country = branch.countrycode

                branches[branch.id].website = branch.website
                    ? branch.website
                    : ''
            })
            this.setState({
                branches: branches,
                initialBranches: branches,
            })
        })
    }

    getListStructure = (item, index) => {
        const { id, name, street, housenumber, total_avg, manager, editing } =
            item
        const { t } = this.props

        const ratingMarkdown = total_avg ? (
            <>
                {total_avg.toFixed(2)}
                <StarIcon style={{ marginBottom: -1 }} />
            </>
        ) : (
            <InfoMessage>{t('rating.noRatingYet')}</InfoMessage>
        )

        // TODO
        const managerMarkdown = manager ? (
            <>{manager.id}</>
        ) : (
            <InfoMessage>{t('branch.noManager')}</InfoMessage>
        )

        const fieldMarkdown = (
            <div style={{ width: '100%' }}>
                <StyledLabel>{t('branch.branch')}</StyledLabel>
                {editing ? (
                    this.editBranchForm(id)
                ) : (
                    <FormValue>
                        {name} ({street} {housenumber})<br />
                        {ratingMarkdown}
                    </FormValue>
                )}
            </div>
        )

        const actionsMarkdown = (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                {index !== 0 &&
                    editing &&
                    Object.keys(this.state.branches).length > 1 && (
                        <DeleteIcon
                            onClick={() => this.showDeleteBranchPopup(true, id)}
                            style={{ transform: 'translate(-15px, 2px)' }}
                        />
                    )}
                <TextButton onClick={() => this.toggleEdit(id, editing)}>
                    {editing ? t('button.abort') : t('button.edit')}
                </TextButton>
            </div>
        )

        return [
            { markdown: fieldMarkdown, isHTML: false },
            { markdown: actionsMarkdown, isHTML: false },
        ]
    }

    toggleEdit = (id, editing) => {
        // reset form fields on closing the form
        if (editing === true) {
            this.setState({
                branches: {
                    ...this.state.branches,
                    [id]: {
                        ...this.state.initialBranches[id],
                    },
                },
            })
            return
        }

        this.setState({
            branches: {
                ...this.state.branches,
                [id]: {
                    ...this.state.branches[id],
                    editing: !this.state.branches[id].editing,
                },
            },
        })
    }

    toggleAddingBranch = () => {
        this.setState({
            addingBranch: !this.state.addingBranch,
        })
    }

    getBranchFormElements = (id, values) => {
        const {
            name,
            street,
            housenumber,
            zipcode,
            city,
            state,
            country,
            adress_additional,
            email,
            phone,
            website,
        } = values
        const { t } = this.props
        const { countries } = this.state

        return [
            [
                {
                    label: t('forms.branch'),
                    type: 'text',
                    value: name,
                    onChange: (value) => this.changeValue(id, 'name', value),
                    size: 1,
                    required: true,
                },
                {
                    label: t('forms.email'),
                    type: 'email',
                    value: email || '',
                    onChange: (value) => this.changeValue(id, 'email', value),
                    size: 1,
                    required: true,
                },
            ],
            [
                {
                    label: t('forms.street'),
                    type: 'text',
                    value: street || '',
                    onChange: (value) => this.changeValue(id, 'street', value),
                    size: 1,
                    required: true,
                },
                {
                    label: t('forms.housenumber'),
                    type: 'text',
                    value: housenumber || '',
                    onChange: (value) =>
                        this.changeValue(id, 'housenumber', value),
                    size: 0.3,
                    required: true,
                },
            ],
            [
                {
                    label: t('forms.zipcode'),
                    type: 'text',
                    value: zipcode || '',
                    onChange: (value) => this.changeValue(id, 'zipcode', value),
                    size: 0.3,
                    required: true,
                },
                {
                    label: t('forms.city'),
                    type: 'text',
                    value: city,
                    onChange: (value) => this.changeValue(id, 'city', value),
                    size: 1,
                    required: true,
                },
                {
                    label: t('forms.addressAdditional'),
                    type: 'text',
                    value: adress_additional || '',
                    onChange: (value) =>
                        this.changeValue(id, 'adress_additional', value),
                    size: 1,
                    required: false,
                },
            ],
            [
                {
                    label: t('forms.state'),
                    type: 'text',
                    value: state || '',
                    onChange: (value) => this.changeValue(id, 'state', value),
                    size: 1,
                    required: true,
                },
                {
                    label: t('forms.country'),
                    type: 'select',
                    value: country,
                    options: countries.map((country) => ({
                        value: country.locale,
                        label: country.name,
                    })),
                    onChange: (value) => this.changeValue(id, 'country', value),
                    size: 1,
                    required: true,
                },
            ],
            [
                {
                    label: t('forms.phone'),
                    type: 'phone',
                    value: phone || '',
                    onChange: (value) => this.changeValue(id, 'phone', value),
                },
                {
                    label: t('forms.website'),
                    type: 'text',
                    value: website || '',
                    onChange: (value) => this.changeValue(id, 'website', value),
                    size: 1,
                    required: false,
                },

            ]
        ]
    }

    editBranchForm = (id) => {
        const { t } = this.props
        const elements = this.getBranchFormElements(id, this.state.branches[id])
        const submit = {
            label: t('button.saveChanges'),
            onSubmit: (event) => this.updateBranch(event, id),
        }

        return (
            <>
                <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>
                    {t('forms.branchDescription')}
                </InfoMessage>
                <Form
                    style={{ display: 'block' }}
                    elements={elements}
                    submit={submit}
                />
            </>
        )
    }

    addBranchForm = () => {
        const { t } = this.props
        const elements = this.getBranchFormElements(-1, this.state.newBranch)
        const submit = {
            label: t('forms.addBranch'),
            onSubmit: (event) => this.addBranch(event),
        }

        return (
            <>
                <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>
                    {t('forms.addBranchDescription')}
                </InfoMessage>
                <Form
                    style={{ display: 'block' }}
                    elements={elements}
                    submit={submit}
                />
            </>
        )
    }

    changeValue = (id, key, value) => {
        if (id === -1) {
            // new branch
            this.setState({
                newBranch: {
                    ...this.state.newBranch,
                    [key]: value,
                },
            })
        } else {
            // existing branch
            this.setState({
                branches: {
                    ...this.state.branches,
                    [id]: {
                        ...this.state.branches[id],
                        [key]: value,
                    },
                },
            })
        }
    }

    updateBranch = (event, id) => {
        event.preventDefault()

        const { t } = this.props

        const branchInfo = this.state.branches[id]

        if (branchInfo.name === branchInfo.initialName) {
            delete branchInfo.name
        }
        if (branchInfo.email === branchInfo.initialEmail) {
            delete branchInfo.email
        }

        this.setIsLoading(true)
        this.branchService.updateBranchInfo(id, branchInfo).then(
            (res) => {
                this.setIsLoading(false)
                this.toggleEdit(id)
                this.updateBranches()
            },
            (error) => {
                this.setIsLoading(false)
                this.setShowMessage(true)
                branchInfo.name = branchInfo.initialName
                branchInfo.email = branchInfo.initialEmail

                if (!error) {
                    this.setMessage(`<div>${t('forms.unknownError')}</div>`)
                }

                if (error.message) {
                    this.setMessage(`<div>${error.message}</div>`)
                }

                if (error.data) {
                    this.setMessage(
                        `<div>${Object.values(error.data).map(
                            (error) => `<div>${error}</div>`
                        )}</div>`
                    )
                }
            }
        )
    }

    addBranch = (event) => {
        event.preventDefault()

        const { t } = this.props

        const { newBranch } = this.state

        this.setIsLoading(true)
        this.branchService.addBranch(newBranch).then(
            (res) => {
                this.setIsLoading(false)

                if (res.success) {
                    this.toggleAddingBranch()
                    this.updateBranches()
                    this.setState({
                        ...this.state,
                        newBranch: {
                            name: '',
                            email: '',
                            street: '',
                            housenumber: '',
                            city: '',
                            zipcode: '',
                            state: '',
                            country: 'DE',
                            adress_additional: '',
                            phone: '',
                            website: '',
                        },
                    })
                } else {
                    this.setShowMessage(true)
                    this.setMessage(`<div>${res.message}</div>`)
                }
            },
            (error) => {
                this.setIsLoading(false)
                this.setShowMessage(true)

                if (!error) {
                    this.setMessage(`<div>${t('forms.unknownError')}</div>`)
                }

                if (error.message) {
                    this.setMessage(`<div>${error.message}</div>`)
                }

                if (error.data) {
                    this.setMessage(
                        `<div>${Object.values(error.data).map(
                            (error) => `<div>${error}</div>`
                        )}</div>`
                    )
                }
            }
        )
    }

    deleteBranch = () => {
        const { t } = this.props

        const { currentBranchId } = this.state

        this.toggleEdit(currentBranchId)
        this.setIsLoading(true)
        this.branchService.deleteBranch(currentBranchId).then(
            (res) => {
                this.setIsLoading(false)

                if (res.success) {
                    this.updateBranches()
                    this.setShowPopup(false)
                    this.setShowMessage(true)
                    this.setMessage(`<div>${res.message}</div>`)
                } else {
                    this.setShowMessage(true)
                    this.setMessage(`<div>${res.message}</div>`)
                }
            },
            (error) => {
                this.setIsLoading(false)
                this.setShowMessage(true)

                if (!error) {
                    this.setMessage(`<div>${t('forms.unknownError')}</div>`)
                }

                if (error.message) {
                    this.setMessage(`<div>${error.message}</div>`)
                }

                if (error.data) {
                    this.setMessage(
                        `<div>${Object.values(error.data).map(
                            (error) => `<div>${error}</div>`
                        )}</div>`
                    )
                }
            }
        )
    }

    render() {
        const {
            branches,
            isLoading,
            addingBranch,
            showMessage,
            message,
            showPopup,
        } = this.state
        const { t } = this.props

        return (
            <>
                <List
                    items={Object.values(branches)}
                    itemsAutoHeight
                    noItemsMessage={t('branch.noBranches')}
                    getListItemStructure={this.getListStructure}
                />

                <SectionHeading
                    title={t('branch.addBranches')}
                    description={t('branch.addBranchesDescription')}
                />
                <ListElementWrapper itemsAutoHeight>
                    <ListElement itemsAutoHeight>
                        <InputSection
                            style={{ flex: '1 1 0', margin: '0 5px' }}
                        >
                            <StyledLabel>{t('forms.addBranch')}</StyledLabel>
                            {addingBranch ? (
                                this.addBranchForm()
                            ) : (
                                <InfoMessage>
                                    {t('branch.addMoreBranches')}
                                </InfoMessage>
                            )}
                        </InputSection>
                    </ListElement>
                    <ListElement itemsAutoHeight>
                        <TextButton onClick={this.toggleAddingBranch}>
                            {addingBranch ? t('button.abort') : t('button.add')}
                        </TextButton>
                    </ListElement>
                </ListElementWrapper>

                {showPopup && (
                    <Popup
                        title={t('popup.deleteBranchTitle')}
                        hidePopup={() => this.showDeleteBranchPopup(false)}
                        size={'large'}
                    >
                        <InfoMessage style={{ marginBottom: 25 }}>
                            {t('popup.deleteBranchDescription')}
                        </InfoMessage>
                        <StyledButton
                            primary
                            style={{ width: '100%', marginTop: 20 }}
                            onClick={() => this.deleteBranch()}
                        >
                            {t('popup.deleteBranch')}
                        </StyledButton>
                        <StyledButton
                            tertiary
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={() => this.setShowPopup(false)}
                        >
                            {t('popup.abort')}
                        </StyledButton>
                    </Popup>
                )}
                {isLoading && <Loader />}

                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
            </>
        )
    }
}

export default withTranslation()(BranchesWidget)
