import React from 'react';
import {StyledButton, StyledPrimaryButton, StyledTertiaryButton, SubTitle} from "./styled-components";
import Popup from "./popup";
import styled from "styled-components";
import { ReactComponent as StarIcon } from '../assets/images/star.svg';
import { formatAMPM, formatMoney, formatTimeString } from "../utils/utility-functions";
import dateFormat from "dateformat";
import { SPACING } from '../../assets/constants';
import {getI18n, useTranslation} from "react-i18next";
import SearchService from "../../services/search-service";

const PopupSection = styled.div`
    margin-bottom: 15px;
    flex: 1 1 0;
`;

type Request = {
    id: number;
    date: string;
    starttime: string;
    endtime: string;
    enddate: string;
    course: string;
    duration: number;
    superexpress: boolean;
    costs: number;
    surcharge: number;
    surcharge_value: number;
    total: number;
    currency: string;
    br_id: number;
    br_name: string;
    br_email: string;
    br_phone: string;
    br_street: string;
    br_housenumber: string;
    br_adress_additional: string;
    br_zipcode: string;
    br_city: string;
    br_state: string;
    br_country: string;
    br_total_avg: number|null;
    br_equipment_avg: number|null;
    br_support_avg: number|null;
    br_neatness_avg: number|null;
    br_rating_count: number|null;
}

type FreelancerRequestPopupNewProps = {
    request: Request
    hidePopup: () => void
    reloadData: () => void
    showMessage: () => void
    addMessage: (message: string) => void
}

const FreelancerRequestPopupNew = ({request, hidePopup, reloadData, showMessage, addMessage}: FreelancerRequestPopupNewProps) => {
    const { t } = useTranslation();
    const language = getI18n().language;
    const requestService = new SearchService();

    let dateformat = 'd. mmmm yyyy';
    let convertedStarttime = formatTimeString(request.starttime);
    let convertedEndtime = formatTimeString(request.endtime);

    if (language === 'en') {
        dateformat = 'mm/dd/yyyy';
        convertedStarttime = formatAMPM(convertedStarttime, true);
        convertedEndtime = formatAMPM(convertedEndtime, true);
    }

    const confirmRequest = () => {
        requestService.confirmBranch(request.id).then((res: any) => {
            console.log(res)
            if (res && !res.success && res.message) {
                showMessage();
                addMessage(`<div>${res.message}</div>`);
            }
            if (res && res.success) {
                showMessage()
                addMessage(`<div>${t('booking.freelancerAccepted')}</div>`)
            }
            hidePopup();
            reloadData();
        });
    }

    const declineRequest = () => {
        requestService.declineBranch(request.id).then((res: any) => {
            if (res) {
                showMessage();
                addMessage("<div>"+t('newCalendar.popupDeclined')+"</div>");
            }
            hidePopup();
            reloadData();
        });
    }

    return <>
        <Popup title={t('popup.requestDetails')} hidePopup={hidePopup}>
            <p>{t('popup.requestDetailsDescription')}</p>
            <div style={{ marginTop: SPACING.medium }}>
                <PopupSection>
                    <SubTitle>{t('popup.company')}</SubTitle>
                    <div style={{ display: 'flex' }}>
                        <p style={{ flex: '1 1 0' }}>{request && request.br_name}</p>
                        <p>
                            {request && request.br_total_avg ? request.br_total_avg.toFixed(2) : '-'} <StarIcon
                                style={{ marginBottom: 2, transform: 'scale(1.3)' }} />
                        </p>
                    </div>
                    <p>{request.br_street+' '+request.br_housenumber}</p>
                    <p>{request.br_adress_additional}</p>
                    <p>{request.br_zipcode+' '+request.br_city}</p>
                    <p>{request.br_state+', '+request.br_country}</p>
                </PopupSection>
                <PopupSection>
                    <SubTitle>{t('popup.pay')}</SubTitle>
                    <p>{request && formatMoney(request.costs + request.surcharge)} {request && request.currency}</p>
                </PopupSection>
                <PopupSection>
                    <SubTitle>{t('popup.job')}</SubTitle>
                    <p>{request && request.course}</p>
                </PopupSection>
                <PopupSection>
                    <SubTitle>{t('popup.dateAndTime')}</SubTitle>
                    <p>{request && convertedStarttime} - {request && convertedEndtime}</p>
                    <p>{request && dateFormat(request.date, dateformat)}</p>
                </PopupSection>
            </div>
            <div style={{ textAlign: 'center', marginTop: 35 }}>
                <StyledPrimaryButton
                    style={{ marginBottom: 5, width: '100%' }}
                    onClick={() => confirmRequest()}>{t('popup.confirm')}</StyledPrimaryButton>
                <StyledButton style={{ marginBottom: 5, width: '100%' }}
                    onClick={() => declineRequest()}>{t('popup.decline')}</StyledButton>
            </div>
            <StyledTertiaryButton onClick={hidePopup}
                style={{ width: '100%', marginTop: 15 }}>{t('popup.abort')}</StyledTertiaryButton>
        </Popup>
    </>
}

export default FreelancerRequestPopupNew;