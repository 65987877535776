import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import EmployeeAccessWidget from "../../legacy/components/widgets/employee-access-widget";

const EmployeeAccess = () => {

    const { user } = useAuth();
    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.employees')}
        </h1>

        <p>
            {t('pages.description.employees')}
        </p>

        <EmployeeAccessWidget role={user.role} />

    </>;
}

export default EmployeeAccess;