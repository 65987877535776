import React, { Component } from 'react'
import {
    FlexDiv,
    InputSection,
    StyledLabel,
    StyledSelect,
    StyledSubmit,
} from './styled-components'
import styled from 'styled-components'
import { DEVICE } from '../assets/constants'
import PhoneInput from "react-phone-input-2";

const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const FormInputSection = styled(InputSection)`
    flex: ${(props) => props.fieldSize} 1 0;
    margin: 0 5px;

    @media ${DEVICE.phone} {
        flex: 1 1 50%;
    }
`

const InputWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: ${(props) => (props.key === 0 ? '0 -5px' : '15px -5px 0')};

    @media ${DEVICE.phone} {
        margin: 0;

        > div {
            margin-top: 15px;
        }
    }
`

class Form extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { elements, submit, children, style } = this.props

        return (
            <FormWrapper
                style={style}
                onSubmit={(event) => submit.onSubmit(event)}
            >
                {elements.map((row, index) => {
                    return (
                        <InputWrapper key={index}>
                            {row.map((field, index) => {
                                return (
                                    <FormInputSection
                                        key={index}
                                        fieldSize={field.size}
                                    >
                                        <StyledLabel>{field.label}</StyledLabel>
                                        {field.type === 'phone' && (
                                            <PhoneInput
                                                country={'us'}
                                                preferredCountries={['us', 'ch', 'at', 'gb', 'de', 'au']}
                                                countryCodeEditable={false}
                                                enableSearch={true}
                                                disableSearchIcon={true}
                                                value={field.value}
                                                onChange={phone => field.onChange(phone)}
                                                inputClass={'phoneInput'}
                                            />
                                        )}
                                        {field.type === 'select' && (
                                            <StyledSelect
                                                style={{ width: '100%' }}
                                                value={field.value}
                                                required={field.required}
                                                disabled={field.disabled}
                                                onChange={(event) =>
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                {field.options.map(
                                                    (option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    )
                                                )}
                                            </StyledSelect>
                                        )}
                                        {(field.type !== 'select' && field.type !== 'phone')  && (
                                            <input
                                                value={field.value}
                                                type={field.type}
                                                required={field.required}
                                                disabled={field.disabled}
                                                onChange={(event) =>
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        )}
                                    </FormInputSection>
                                )
                            })}
                        </InputWrapper>
                    )
                })}

                {submit.label && (
                    <StyledSubmit
                        type={'submit'}
                        value={submit.label}
                        primary
                        style={{ width: 275, marginTop: 25, float: 'right' }}
                    />
                )}

                {children}
            </FormWrapper>
        )
    }
}

export default Form
