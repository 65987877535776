import React, { Component } from "react";
import Select, { components } from "react-select";
import { StyledWrapper } from "./styled-components";
import Loader from "./loader";
import { withTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "../assets/constants";
import { AiFillCaretDown } from "react-icons/ai";
import BranchService from "../../services/branch-service";
import styled from "styled-components";

const Wrapper = styled.div`
  font-weight: bold;
  font-size: 1.5rem;

  /* > .input.studio > div > div:first-child {
        padding: 5px 12px;
    } */

  label {
    text-transform: uppercase;
    font-weight: 700;
    color: #c3b6c7;
    font-size: 0.7rem;
    letter-spacing: 0.06em;
    display: block;
    margin-bottom: 5px;
    margin-left: 3px;
    white-space: nowrap;
  }
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <AiFillCaretDown size={14} style={{ marginRight: 3 }} />
    </components.DropdownIndicator>
  );
};

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

class BranchDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      selectedBranch: undefined,
      loadingBranches: false,
      isLoading: false,
    };

    this.branchService = new BranchService();
  }

  setIsLoading = (isLoading) => {
    this.setState({ ...this.state, isLoading: isLoading });
  };

  componentDidMount() {
    this.setIsLoading(true);
    this.setState({ ...this.state, loadingBranches: true });
    this.branchService.getAllBranches().then((branches) => {
      this.setIsLoading(false);
      this.setState({
        ...this.state,
        branches: branches.length
          ? branches.map((branch) => {
              return {
                label: `${branch.name} (${branch.street} ${branch.housenumber}, ${branch.city})`,
                value: branch.id,
              };
            })
          : [
              {
                label: `${branches.name} (${branches.street} ${branches.housenumber}, ${branches.city})`,
                value: branches.id,
              },
            ],
        selectedBranch: branches.length
          ? {
              label: `${branches[0].name} (${branches[0].street} ${branches[0].housenumber}, ${branches[0].city})`,
              value: branches[0].id,
            }
          : {
              label: `${branches.name} (${branches.street} ${branches.housenumber}, ${branches.city})`,
              value: branches.id,
            },
        loadingBranches: false,
      });
      if (branches.length) {
        this.props.updateItems(branches[0].id);
        this.props.setSelectedBranch(branches[0].id);
      } else if (branches) {
        this.props.updateItems(branches.id);
        this.props.setSelectedBranch(branches.id);
      }
    });
  }

  handleBranchSelect = (branch) => {
    this.setState({ selectedBranch: branch });
    this.props.updateItems(branch.value);
    this.props.setSelectedBranch(branch.value);
  };

  render() {
    const { branches, selectedBranch, loadingBranches, isLoading } = this.state;
    const { t } = this.props;

    return (
      <Wrapper style={{ marginBottom: 35 }}>
        <div className={"input studio"} style={{fontSize: '1rem', fontWeight: '500'}}>
          <label>{t("forms.studio")}</label>
          <Select
            value={selectedBranch}
            onChange={(branch) => this.handleBranchSelect(branch)}
            options={branches}
            isSearchable
            components={{ Placeholder, DropdownIndicator }}
            styles={{
              dropdownIndicator: (provided, state) => ({
                ...provided,
                color: PRIMARY_COLOR,
              }),
              control: (provided, state) => ({
                ...provided,
                borderColor:
                  state.isFocused || state.isSelected
                    ? PRIMARY_COLOR
                    : "rgba(0,0,0,0.10)",
                boxShadow:
                  state.isFocused || state.isSelected
                    ? `0 0 0 1px ${PRIMARY_COLOR}`
                    : `none`,

                "&:hover": {
                  borderColor: "rgba(0,0,0,0.10)",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                fontWeight: "normal",
                fontSize: "1rem",
              }),
            }}
            isLoading={loadingBranches}
            isDisabled={branches.length === 1}
            placeholder={t("forms.studioPlaceholder")}
          />
        </div>
        {isLoading && <Loader />}
      </Wrapper>
    );
  }
}

export default withTranslation()(BranchDropdown);
