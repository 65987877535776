import axios from 'axios'

class BranchService {
    constructor(options = {}) {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async getAllBranches() {
        try {
            let response = await axios.get(
                `${this.apiBase}/me/branches`,
                this.config
            )

            if (!response.data.success && !response.data.data) {
                try {
                    let response = await axios.get(
                        `${this.apiBase}/me/branch`,
                        this.config
                    )

                    return response.data.data
                } catch (error) {
                    throw error.response
                }
            }

            return response.data.data
        } catch (error) {
            if (error.response.status === 404) {
                try {
                    let response = await axios.get(
                        `${this.apiBase}/me/branch`,
                        this.config
                    )

                    return response.data.data
                } catch (error) {
                    throw error.response
                }
            }

            throw error.response
        }
    }

    async getBranchInfo(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async updateBranchInfo(branchId, info) {
        try {
            info.phone = `+${info.phone}`

            const response = await axios.put(
                `${this.apiBase}/branch/${branchId}`,
                info,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async addBranch(info) {
        try {
            info.phone = `+${info.phone}`

            const response = await axios.post(
                `${this.apiBase}/branch`,
                info,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async deleteBranch(branchId) {
        try {
            const response = await axios.delete(
                `${this.apiBase}/branch/${branchId} `,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getBranchManager(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/manager`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getBranchEmployees(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/employees`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async addBranchManager(firstName, lastName, email, branchId) {
        try {
            const response = await axios.post(
                `${this.apiBase}/branch/${branchId}/manager`,
                {
                    firstname: firstName,
                    lastname: lastName,
                    email: email,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async addBranchEmployee(firstName, lastName, email, branchId) {
        try {
            const response = await axios.post(
                `${this.apiBase}/branch/${branchId}/employees`,
                {
                    firstname: firstName,
                    lastname: lastName,
                    email: email,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async deleteBranchManager(branchId) {
        try {
            const response = await axios.delete(
                `${this.apiBase}/branch/${branchId}/manager`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async deleteBranchEmployee(branchId, employeeId) {
        try {
            const response = await axios.delete(
                `${this.apiBase}/branch/${branchId}/employees/${employeeId}`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }
}

export default BranchService
