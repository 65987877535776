import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'
import {SPACING} from '../../assets/constants'
import {Heading} from '../../common/text'
import {MdSettings} from 'react-icons/md'
import {BiCalendar, BiCalendarCheck, BiCalendarPlus} from 'react-icons/bi'
import {AiFillStar} from 'react-icons/ai'
import {VscListSelection} from 'react-icons/vsc'
import {CSSProperties} from 'styled-components'
import LegalPagesMenu from './LegalPagesMenu'

const FreelancerMenu = () => {
    const { t } = useTranslation()

    const activeLinkStyle: CSSProperties = {
        fontWeight: 'bold',
        backgroundColor: '#ffffff29',
    }

    return (
        <>
            <Heading>
                {' '}
                <BiCalendarPlus style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.calendarEntries')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.regularEvents')}`}
                    >
                        {t('pages.title.regularEvents')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.irregularEvents')}`}
                    >
                        {t('pages.title.irregularEvents')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <BiCalendar style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.calendarManage')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.calendar-new')}`}
                    >
                        {t('pages.title.calendar')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.events')}`}
                    >
                        {t('pages.title.events')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <BiCalendarCheck style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.bookingsManage')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.openRequests')}`}
                    >
                        {t('pages.title.openRequests')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.bookings')}`}
                    >
                        {t('pages.title.bookings')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.blacklist')}`}
                    >
                        {t('pages.title.contra')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <AiFillStar style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.ratings')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.openRatings')}`}
                    >
                        {t('pages.title.openRatings')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.givenRatings')}`}
                    >
                        {t('pages.title.givenRatingsFreelancer')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.receivedRatings')}`}
                    >
                        {t('pages.title.receivedRatingsFreelancer')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <VscListSelection
                    style={{ marginRight: SPACING.xsmall }}
                />{' '}
                {t('pages.section.courses')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.courses')}`}
                    >
                        {t('pages.title.courses')}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.salaries')}`}
                    >
                        {t('pages.title.salaries')}
                    </NavLink>
                </li>
            </ul>

            <Heading>
                {' '}
                <MdSettings style={{ marginRight: SPACING.xsmall }} />{' '}
                {t('pages.section.settings')}
            </Heading>
            <ul>
                <li>
                    <NavLink
                        activeStyle={activeLinkStyle}
                        to={`/${t('routes.personalData')}`}
                    >
                        {t('pages.title.personalData')}
                    </NavLink>
                </li>
            </ul>

            <LegalPagesMenu />
        </>
    )
}

export default FreelancerMenu
