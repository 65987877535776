import React, { Component } from 'react'
import { StyledButton, SubTitle, PopupInfo } from './styled-components'
import Popup from './popup'
import styled from 'styled-components'
import { ReactComponent as StarIcon } from '../assets/images/star.svg'
import { MdInfoOutline } from 'react-icons/md'
import { withTranslation } from 'react-i18next'
import { COLORS } from '../../assets/constants'

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const PopupSection = styled.div`
    margin-bottom: 15px;
    flex: 1 1 0;
    padding-right: ${(props) => (props.noPadding ? '0' : '0.5em')};
    word-break: break-word;
    hyphens: auto;

    h3 {
        font-size: 0.9em;
    }
`

const Category = styled(SubTitle)`
    margin: 5px 0;
`

const CategoryDetails = styled.h6`
    font-size: 0.8em;
    font-weight: normal;
`

const RatingStar = styled(StarIcon)`
    cursor: pointer;
    transform: scale(2);
    margin: 0 17px 8px;
`

class RatingsPopup extends Component {
    MAX_RATING = 4

    constructor(props) {
        super(props)

        this.state = {
            booking: this.props.booking,
            equipment: this.props.booking.equipment,
            neatness: this.props.booking.neatness,
            support: this.props.booking.support,
            punctuality: this.props.booking.punctuality,
            kindness: this.props.booking.kindness,
            quality: this.props.booking.quality,
            total: this.props.booking.total,
        }
    }

    renderStarRating = (rating) => {
        let stars = []
        for (let i = 0; i < this.MAX_RATING; i++) {
            if (i < rating) {
                stars.push(<RatingStar key={i} />)
            } else {
                stars.push(
                    <RatingStar key={i} style={{ filter: 'grayscale(1)' }} />
                )
            }
        }
        return stars
    }

    render() {
        const { hidePopup, role, t } = this.props
        const {
            booking,
            equipment,
            neatness,
            support,
            punctuality,
            kindness,
            quality,
            total,
        } = this.state

        const isFreelancer = role === 'freelancer'

        return (
            <Popup title={t('popup.ratingDetails')} hidePopup={hidePopup}>
                <p>{t('popup.ratingDetailsDescription')}</p>
                <PopupInfo>
                    <PopupSection>
                        <div>
                            <SubTitle>
                                {isFreelancer
                                    ? t('list.company')
                                    : t('list.trainer')}
                            </SubTitle>
                            <p style={{ flex: '1 1 0' }}>
                                {isFreelancer ? (
                                    <>
                                        {booking.br_name}
                                        <br />
                                        {booking.br_total_avg
                                            ? booking.br_total_avg.toFixed(2)
                                            : '-'}{' '}
                                        <StarIcon
                                            style={{
                                                marginBottom: 2,
                                                transform: 'scale(1.3)',
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {booking.fl_firstname}{' '}
                                        {booking.fl_lastname}
                                        <br />
                                        {booking.fl_total_avg
                                            ? booking.fl_total_avg.toFixed(2)
                                            : '-'}{' '}
                                        <StarIcon
                                            style={{
                                                marginBottom: 2,
                                                transform: 'scale(1.3)',
                                            }}
                                        />
                                    </>
                                )}
                            </p>
                        </div>
                    </PopupSection>
                    <PopupSection>
                        <SubTitle>{t('list.job')}</SubTitle>
                        <p>{booking && booking.course}</p>
                    </PopupSection>
                </PopupInfo>

                <div style={{ marginTop: 35, textAlign: 'center' }}>
                    <PopupSection noPadding>
                        {neatness && (
                            <>
                                {this.renderStarRating(neatness)}
                                <Category>{t('popup.neatness')}</Category>
                                <CategoryDetails>
                                    ({t('popup.neatnessDetails')})
                                </CategoryDetails>
                            </>
                        )}
                        {punctuality && (
                            <>
                                {this.renderStarRating(punctuality)}
                                <Category>{t('popup.punctuality')}</Category>
                                <CategoryDetails>
                                    ({t('popup.punctualityDetails')})
                                </CategoryDetails>
                            </>
                        )}
                    </PopupSection>
                    <PopupSection noPadding style={{ marginTop: 30 }}>
                        {equipment && (
                            <>
                                {this.renderStarRating(equipment)}
                                <Category>{t('popup.equipment')}</Category>
                                <CategoryDetails>
                                    ({t('popup.equipmentDetails')})
                                </CategoryDetails>
                            </>
                        )}
                        {kindness && (
                            <>
                                {this.renderStarRating(kindness)}
                                <Category>{t('popup.kindness')}</Category>
                                <CategoryDetails>
                                    ({t('popup.kindnessDetails')})
                                </CategoryDetails>
                            </>
                        )}
                    </PopupSection>
                    <PopupSection noPadding style={{ marginTop: 30 }}>
                        {support && (
                            <>
                                {this.renderStarRating(support)}
                                <Category>{t('popup.support')}</Category>
                                <CategoryDetails>
                                    ({t('popup.supportDetails')})
                                </CategoryDetails>
                            </>
                        )}
                        {quality && (
                            <>
                                {this.renderStarRating(quality)}
                                <Category>{t('popup.quality')}</Category>
                                <CategoryDetails>
                                    ({t('popup.qualityDetails')})
                                </CategoryDetails>
                            </>
                        )}
                    </PopupSection>
                    <PopupSection noPadding style={{ marginTop: 20 }}>
                        <span style={{ fontSize: '2em', fontWeight: 'bold' }}>
                            {total && total.toFixed(2)}
                        </span>{' '}
                        <StarIcon
                            style={{
                                transform: 'scale(1.5',
                                marginBottom: 4,
                                marginLeft: 3,
                            }}
                        />
                    </PopupSection>
                </div>

                <StyledButton
                    tertiary
                    onClick={hidePopup}
                    style={{ width: '100%', marginTop: 15 }}
                >
                    OK
                </StyledButton>
            </Popup>
        )
    }
}

export default withTranslation()(RatingsPopup)
