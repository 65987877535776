import React, { Component } from 'react'
import {
    formatTimeString,
    sortByDateProperty,
} from '../../utils/utility-functions'
import List from '../list'
import dateFormat from 'dateformat'
import { ReactComponent as MoreButton } from '../../assets/images/more.svg'
import Loader from '../loader'
import {
    StyledButton,
    StyledWrapper,
    SubHeading,
    SubTitle,
} from '../styled-components'
import { formatMoney, getCurrency } from '../../utils/utility-functions'
import FreelancerBookingsPopup from '../freelancer-bookings-popup'
import GoogleMapReact from 'google-map-react'
import { MdPhone, MdEmail } from 'react-icons/md'
import { GOOGLE_API_KEY, PRIMARY_COLOR } from '../../assets/constants'
import { MdDelete } from 'react-icons/md'
import styled from 'styled-components'
import PopupMessage from '../popup-message'
import { withTranslation } from 'react-i18next'
import Popup from '../popup'
import BookingsService from '../../../services/bookings-service'
import i18n from '../../../i18n/config'
import { DEVICE, SPACING } from '../../../assets/constants'
import AccountService from "../../../services/account-service";

const DeleteIcon = styled(MdDelete)`
    color: ${PRIMARY_COLOR};
    transform: scale(1.3);
    cursor: pointer;
`

const InfoMessage = styled.span`
    display: block;
    font-size: 0.9em;
    font-weight: normal;
`

const NextBookingWrapper = styled.div`
    h6 {
        margin-top: ${SPACING.xsmall}px;
    }

    @media ${DEVICE.phone} {
        & > div {
            flex-direction: column;
        }
    }
`

class FreelancerBookingsWidget extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33,
        },
        zoom: 13,
    }

    constructor(props) {
        super(props)

        this.bookingsService = new BookingsService()
        this.accountService = new AccountService()

        this.state = {
            upcomingBookings: undefined,
            pastBookings: undefined,
            invoiceBookings: undefined,
            nextBooking: undefined,
            selectedBooking: undefined,
            isLoading: false,
            showPopup: false,
            showInvoicePopup: false,
            message: undefined,
            showMessage: false,
            currentLanguage: i18n.language,
            dateformat: i18n.language === 'en' ? 'mm/dd/yyyy' : 'd. mmm yyyy',
            canInvoice: false,
        }
    }

    componentDidMount() {
        this.updateBookings()
        this.checkCanInvoice()
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading })
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show })
    }

    setShowInvoicePopup = (show) => {
        this.setState({ ...this.state, showInvoicePopup: show })
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message })
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show })
    }

    checkCanInvoice = () => {
        this.accountService.getMe().then(res => {
            if (res && res.data) {
                console.log(res.data)
                this.setState({
                    ...this.state,
                    canInvoice: res.data.can_invoice ?? false,
                })
            }
        })
    }

    updateBookings = () => {
        this.bookingsService.getFreelancerUpcomingBookings().then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    upcomingBookings: sortByDateProperty(res.data),
                })
            }
        })
        this.bookingsService.getFreelancerPastBookings().then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    pastBookings: sortByDateProperty(res.data, false),
                })
            }
        })
        this.bookingsService.getFreelancerInvoiceBookings().then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    invoiceBookings: sortByDateProperty(res.data, false),
                })
            }
        })
        this.bookingsService.getFreelancerNextBooking().then((res) => {
            if (res && res.data) {
                this.setState({ ...this.state, nextBooking: res.data })
            }
        })
    }

    sendInvoices = () => {
        this.setIsLoading(true)
        this.bookingsService.sendInvoices().then((res) => {
            this.setIsLoading(false)
            if (res && res.message) {
                this.setShowMessage(true)
                this.displayInvoicePopup(false)
                this.setMessage(`<div>${res.message}</div>`)
                this.updateBookings()
            }
        })
    }

    deleteInvoice = (bookingId) => {
        this.setIsLoading(true)
        this.bookingsService.deleteInvoice(bookingId).then((res) => {
            this.setIsLoading(false)
            if (res && res.message) {
                this.setShowMessage(true)
                this.setMessage(`<div>${res.message}</div>`)
                this.updateBookings()
            }
        })
    }

    openBookingDetails = (booking) => {
        this.setState({ ...this.state, selectedBooking: booking }, () =>
            this.setShowPopup(true)
        )
    }

    getListItemStructure = (item) => {
        const { course, br_name, date, starttime, endtime } = item
        const { t } = this.props
        const { dateformat } = this.state
        const currentLanguage = i18n.language

        const courseMarkdown = `<b>${course}</b>`
        const branchMarkdown = br_name
            ? `<b>${br_name}</b>`
            : `<b>${t('booking.branchDeleted')}</b>`
        const timeMarkdown = `<span>${formatTimeString(
            starttime,
            currentLanguage
        )} - ${formatTimeString(endtime, currentLanguage)}</span>`

        return [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: branchMarkdown, isHTML: true },
            { markdown: timeMarkdown, isHTML: true },
            { markdown: dateFormat(date, dateformat), isHTML: false },
            {
                markdown: (
                    <MoreButton
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openBookingDetails(item)}
                    />
                ),
                isHTML: false,
            },
        ]
    }

    getInvoiceListItemStructure = (item) => {
        const {
            id,
            costs,
            surcharge,
            currency,
            br_name,
            date,
            starttime,
            endtime,
        } = item
        const { dateformat } = this.state
        const currentLanguage = i18n.language

        const amountMarkdown = `<b>${formatMoney(
            costs + surcharge
        )} ${currency}</b>`
        const branchMarkdown = `<b>${br_name}</b>`
        const timeMarkdown = `<span>${formatTimeString(
            starttime,
            currentLanguage
        )} - ${formatTimeString(endtime, currentLanguage)}</span>`

        return [
            { markdown: amountMarkdown, isHTML: true },
            { markdown: branchMarkdown, isHTML: true },
            { markdown: timeMarkdown, isHTML: true },
            { markdown: dateFormat(date, dateformat), isHTML: false },
            {
                markdown: <DeleteIcon onClick={() => this.deleteInvoice(id)} />,
                isHTML: false,
            },
        ]
    }

    mapsIsLoaded = (map, maps, nextBooking) => {
        const geocoder = new maps.Geocoder()
        geocoder.geocode(
            {
                address: `${nextBooking.br_street} ${nextBooking.br_housenumber} ${nextBooking.br_zipcode} ${nextBooking.br_city}`,
            },
            (results, status) => {
                if (status === 'OK') {
                    map.setCenter(results[0].geometry.location)
                    const marker = new maps.Marker({
                        map: map,
                        position: results[0].geometry.location,
                    })
                } else {
                    alert(
                        'Geocode was not successful for the following reason: ' +
                            status
                    )
                }
            }
        )
    }

    displayInvoicePopup = (show) => {
        this.setShowInvoicePopup(show)
    }

    render() {
        const {
            upcomingBookings,
            pastBookings,
            invoiceBookings,
            nextBooking,
            isLoading,
            selectedBooking,
            showPopup,
            showInvoicePopup,
            showMessage,
            message,
            canInvoice
        } = this.state
        const { t } = this.props

        const currentLanguage = i18n.language

        let dateformatLong = 'd. mmmm yyyy'

        if (currentLanguage === 'en') {
            dateformatLong = 'mm/dd/yyyy'
        }

        return (
            <>
                {nextBooking && (
                    <NextBookingWrapper>
                        <div>
                            <SubHeading>{t('booking.next')}</SubHeading>
                            <p>{t('booking.nextDescription')}</p>
                        </div>

                        <StyledWrapper
                            style={{
                                display: 'flex',
                                marginTop: 15,
                                padding: 0,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                    padding: SPACING.xlarge,
                                }}
                            >
                                <div>
                                    <h2>{nextBooking.course}</h2>
                                    <h3>
                                        {formatTimeString(
                                            nextBooking.starttime,
                                            currentLanguage
                                        )}{' '}
                                        -{' '}
                                        {formatTimeString(
                                            nextBooking.endtime,
                                            currentLanguage
                                        )}{' '}
                                        <br />
                                        {dateFormat(
                                            nextBooking.date,
                                            dateformatLong
                                        )}
                                    </h3>
                                </div>

                                <div style={{}}>
                                    <SubTitle>
                                        {t('booking.companyAddress')}
                                    </SubTitle>
                                    <div>
                                        {nextBooking.br_name}
                                        <br />
                                        {nextBooking.br_street}{' '}
                                        {nextBooking.br_housenumber}
                                        <br />
                                        {nextBooking.br_adress_additional && (
                                            <>
                                                {
                                                    nextBooking.br_adress_additional
                                                }
                                                <br />
                                            </>
                                        )}
                                        {nextBooking.br_zipcode}{' '}
                                        {nextBooking.br_city}
                                        <br />
                                        {nextBooking.br_state},{' '}
                                        {nextBooking.br_country}
                                    </div>
                                </div>

                                <div style={{}}>
                                    <SubTitle>{t('booking.contact')}</SubTitle>
                                    <div>
                                        <MdPhone
                                            style={{
                                                marginBottom: -2,
                                                color: '#db64ff',
                                            }}
                                        />{' '}
                                        <a href={`tel:${nextBooking.br_phone}`}>
                                            {nextBooking.br_phone}
                                        </a>
                                        <br />
                                        <MdEmail
                                            style={{
                                                marginBottom: -2,
                                                color: '#db64ff',
                                            }}
                                        />{' '}
                                        <a
                                            href={`mailto:${nextBooking.br_email}`}
                                        >
                                            {nextBooking.br_email}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div style={{ flex: 1.7, height: 450 }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                                    defaultCenter={this.props.center}
                                    defaultZoom={this.props.zoom}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) =>
                                        this.mapsIsLoaded(
                                            map,
                                            maps,
                                            nextBooking
                                        )
                                    }
                                />
                            </div>
                        </StyledWrapper>
                    </NextBookingWrapper>
                )}

                <>
                    <div style={{ marginTop: 50 }}>
                        <SubHeading>{t('booking.upcoming')}</SubHeading>
                        <p>{t('booking.upcomingDescription')}</p>
                    </div>

                    <List
                        items={upcomingBookings}
                        noItemsMessage={t('booking.noUpcoming')}
                        headings={[
                            t('list.job'),
                            t('list.company'),
                            t('list.time'),
                            t('list.date'),
                            '',
                        ]}
                        getListItemStructure={this.getListItemStructure}
                        style={{ marginTop: '30px' }}
                    />
                </>

                <>
                    <div style={{ marginTop: 50 }}>
                        <SubHeading>{t('booking.invoice')}</SubHeading>
                        <p>{t('booking.invoiceDescription')}</p>
                    </div>

                    <List
                        items={invoiceBookings}
                        noItemsMessage={t('booking.noInvoice')}
                        headings={[
                            t('list.value'),
                            t('list.company'),
                            t('list.time'),
                            t('list.date'),
                            '',
                        ]}
                        getListItemStructure={this.getInvoiceListItemStructure}
                        style={{ marginTop: '30px' }}
                    />

                    {invoiceBookings && invoiceBookings.length > 0 && (
                        <div style={{ textAlign: 'right', marginTop: 15 }}>
                            <StyledButton
                                primary
                                style={{ width: 250 }}
                                onClick={() => this.displayInvoicePopup(true)}
                                disabled={!canInvoice}
                            >
                                {t('booking.sendInvoice')}
                            </StyledButton>
                        </div>
                    )}

                    {showInvoicePopup && (
                        <Popup
                            title={t('booking.sendInvoice')}
                            hidePopup={() => this.displayInvoicePopup(false)}
                            size={'large'}
                        >
                            <InfoMessage style={{ marginBottom: 25 }}>
                                {t('booking.sendInvoiceDescription')}
                            </InfoMessage>
                            <StyledButton
                                primary
                                style={{ width: '100%', marginTop: 20 }}
                                onClick={() => this.sendInvoices()}
                            >
                                {t('popup.confirm')}
                            </StyledButton>
                            <StyledButton
                                tertiary
                                style={{ width: '100%', marginTop: 10 }}
                                onClick={() => this.displayInvoicePopup(false)}
                            >
                                {t('popup.abort')}
                            </StyledButton>
                        </Popup>
                    )}
                </>

                <>
                    <div style={{ marginTop: 50 }}>
                        <SubHeading>{t('booking.past')}</SubHeading>
                        <p>{t('booking.pastDescription')}</p>
                    </div>

                    <List
                        items={pastBookings}
                        noItemsMessage={t('booking.noPast')}
                        headings={[
                            t('list.job'),
                            t('list.company'),
                            t('list.time'),
                            t('list.date'),
                            '',
                        ]}
                        getListItemStructure={this.getListItemStructure}
                        style={{ marginTop: '30px' }}
                    />
                </>

                {showPopup && (
                    <FreelancerBookingsPopup
                        booking={selectedBooking}
                        hidePopup={() => this.setShowPopup(false)}
                        currentLanguage={currentLanguage}
                    />
                )}

                {isLoading && <Loader />}

                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
            </>
        )
    }
}

export default withTranslation()(FreelancerBookingsWidget)
