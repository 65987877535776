import React, { Component } from 'react'
import {
    InputSection,
    StyledButton,
    StyledDatePicker,
    StyledLabel,
    StyledSelect,
    ClickableLink,
} from './styled-components'
import Popup from './popup'
import styled from 'styled-components'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { MdInfoOutline } from 'react-icons/md'
import { withTranslation } from 'react-i18next'
import '../assets/less/datepicker.scss'

registerLocale('de', de)
setDefaultLocale('de')

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

class EditAvailabilityPopup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: props.entry.id,
            groupId: props.entry.group_id,
            date: new Date(props.entry.startdate),
            startTime: this.setTime(props.entry.starttime),
            endTime: this.setTime(props.entry.endtime),
            availability: props.entry.availability,
            overnight:
                this.setTime(props.entry.starttime) >
                this.setTime(props.entry.endtime),
        }
    }

    setTime = (time) => {
        const hours = time.split(':')[0]
        const minutes = time.split(':')[1]
        const date = new Date().setHours(hours, minutes)
        console.log(date)
        return date
    }

    handleDateChange = (date) => {
        this.setState({
            ...this.state,
            date: date,
        })
    }

    handleStartTimeChange = (startTime) => {
        console.log(startTime)
        this.setState({
            ...this.state,
            startTime: startTime,
            overnight: startTime > this.state.endTime,
        })
    }

    handleEndTimeChange = (endTime) => {
        this.setState({
            ...this.state,
            endTime: endTime,
            overnight: this.state.startTime > endTime,
        })
    }

    handleAvailabilityChange = (event) => {
        this.setState({
            ...this.state,
            availability: event.target.value,
        })
    }

    render() {
        const { hidePopup, saveEntry, deleteEntry, deleteEntryGroup, t } =
            this.props
        const {
            id,
            groupId,
            date,
            startTime,
            endTime,
            availability,
            overnight,
        } = this.state

        return (
            <Popup title={t('popup.editEntry')} hidePopup={hidePopup}>
                <InputSection>
                    <StyledLabel>{t('forms.date')}</StyledLabel>
                    <StyledDatePicker
                        style={{ width: '100%' }}
                        selected={date}
                        onChange={(date) => this.handleDateChange(date)}
                        locale={this.props.currentLanguage}
                        dateFormat={this.props.currentDateFormat}
                        minDate={new Date()}
                    />
                </InputSection>
                <div style={{ display: 'flex', marginTop: 15 }}>
                    <InputSection
                        style={{ flex: '1 1 0', marginRight: 5 }}
                        width={'calc(50% - 5px)'}
                    >
                        <StyledLabel>{t('forms.from')}</StyledLabel>
                        <StyledDatePicker
                            selected={startTime}
                            onChange={(startTime) =>
                                this.handleStartTimeChange(startTime)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            locale={this.props.currentLanguage}
                            dateFormat={this.props.currentTimeFormat}
                            lowercase
                        />
                    </InputSection>
                    <InputSection
                        style={{ flex: '1 1 0', marginLeft: 5 }}
                        width={'calc(50% - 5px)'}
                    >
                        <StyledLabel>{t('forms.to')}</StyledLabel>
                        <StyledDatePicker
                            selected={endTime}
                            onChange={(endTime) =>
                                this.handleEndTimeChange(endTime)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            locale={this.props.currentLanguage}
                            dateFormat={this.props.currentTimeFormat}
                            lowercase
                        />
                    </InputSection>
                </div>
                {overnight && (
                    <p style={{ fontSize: '0.8em', marginTop: 10 }}>
                        <InfoIcon />
                        {t('forms.overnight')}
                    </p>
                )}

                <InputSection style={{ marginTop: 15, marginBottom: 25 }}>
                    <StyledLabel>{t('forms.availability')}</StyledLabel>
                    <StyledSelect
                        style={{ width: '100%' }}
                        value={availability}
                        onChange={(event) =>
                            this.handleAvailabilityChange(event)
                        }
                    >
                        <option value={'high'}>{t('forms.high')}</option>
                        <option value={'medium'}>{t('forms.medium')}</option>
                        <option value={'onrequest'}>
                            {t('forms.onrequest')}
                        </option>
                    </StyledSelect>
                </InputSection>
                {groupId && (
                    <ClickableLink onClick={() => deleteEntryGroup(groupId)}>
                        {t('popup.deleteGroup')}
                    </ClickableLink>
                )}
                <StyledButton
                    primary
                    style={{ marginRight: 5, width: 'calc(50% - 5px)' }}
                    onClick={() =>
                        saveEntry(id, date, startTime, endTime, availability)
                    }
                >
                    {t('popup.save')}
                </StyledButton>
                <StyledButton
                    style={{ marginLeft: 5, width: 'calc(50% - 5px)' }}
                    onClick={() => deleteEntry(id)}
                >
                    {t('popup.delete')}
                </StyledButton>
                <StyledButton
                    tertiary
                    style={{ width: '100%', marginTop: 15 }}
                    onClick={hidePopup}
                >
                    {t('popup.abort')}
                </StyledButton>
            </Popup>
        )
    }
}

export default withTranslation()(EditAvailabilityPopup)
