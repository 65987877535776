import { createContext, useContext, useState } from "react"
import AccountService from "../services/account-service";

const AuthContext = createContext<any>({});

const AuthProvider = props => {

    const accountService = new AccountService();

    const [token, setToken] = useState<any>(localStorage.getItem('jg-token'));
    const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem('jg-user')));

    // code for pre-loading the user's information if we have their token in
    // localStorage goes here
    // 🚨 this is the important bit.
    // Normally your provider components render the context provider with a value.
    // But we post-pone rendering any of the children until after we've determined
    // whether or not we have a user token and if we do, then we render a spinner
    // while we go retrieve that user's information.
    if (false) {
        return <div>Hallo Welt</div>;
    }

    const login = async (email, password, rememberme) => {
        const login = await accountService.login(email, password, rememberme);
        const t = login.data.access_token;
        const me = await accountService.getMe(t);
        const u = me.data;

        if (u && u.role === 'chef') {
            try {
                const company = await accountService.getMyCompany(t);
                const c = company.data;
                u.company = c;
            } catch (error) {
                u.company = null;
            }
        }

        localStorage.setItem('jg-token', t);
        localStorage.setItem('jg-user', JSON.stringify(u));

        setToken(t);
        setUser(u);
    }

    const logout = async () => {
        localStorage.removeItem('jg-token');
        localStorage.removeItem('jg-user');

        setToken(null);
        setUser(null);
    }

    const register = async (data) => {
        const response = await accountService.registerAccount(data);

        return response;
    }

    const setup = async (data) => {
        try {
            const response = await accountService.finishFreelancerSetup(data);
            const me = await accountService.getMe(token);
            const u = me.data;

            localStorage.setItem('jg-user', JSON.stringify(u));
            setUser(u);
        } catch (error) {
            // TODO error handling
            return error;
        }
    }

    const updateUser = async (userData) => {
        const user = JSON.parse(JSON.stringify(userData));
        if (user && user.role === 'chef') {
            try {
                const company = await accountService.getMyCompany();
                const c = company.data;
                user.company = c;
            } catch (error) {
                user.company = null;
            }
        }

        setUser(user);
        localStorage.setItem('jg-user', JSON.stringify(user));
    }

    // note, I'm not bothering to optimize this `value` with React.useMemo here
    // because this is the top-most component rendered in our app and it will very
    // rarely re-render/cause a performance problem.
    return (
        <AuthContext.Provider value={{ token, user, updateUser, login, logout, register, setup }} {...props} />
    )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }