import React, { Component } from 'react'
import {
    PopupInfo,
    StyledButton,
    SubTitle,
    RatingRemark,
    RatingSection,
} from './styled-components'
import Popup from './popup'
import styled from 'styled-components'
import { ReactComponent as StarIcon } from '../assets/images/star.svg'
import { MdInfoOutline } from 'react-icons/md'
import { formatAMPM, formatTimeString } from '../utils/utility-functions'
import dateFormat from 'dateformat'
import { withTranslation } from 'react-i18next'

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const PopupSection = styled.div`
    margin-bottom: 15px;
    flex: 1 1 0;
    padding-right: ${(props) => (props.noPadding ? '0' : '0.5em')};
    word-break: break-word;
    hyphens: auto;

    h3 {
        font-size: 0.9em;
    }

    p {
        font-size: 0.9em;
    }
`

const RatingStar = styled(StarIcon)`
    cursor: pointer;
    transform: scale(2);
    margin: 0 17px 8px;
`

const CategoryDetails = styled.h6`
    font-size: 0.8em;
    font-weight: normal;
`

class OpenRatingsPopup extends Component {
    MAX_RATING = 4

    constructor(props) {
        super(props)

        this.state = {
            booking: this.props.booking,
            punctuality: 0,
            kindness: 0,
            quality: 0,
            equipment: 0,
            neatness: 0,
            support: 0,
        }
    }

    renderStarRating = (rating, category) => {
        let stars = []
        for (let i = 0; i < this.MAX_RATING; i++) {
            if (i < rating) {
                stars.push(
                    <RatingStar
                        key={i}
                        onClick={() => this.setRating(i + 1, category)}
                    />
                )
            } else {
                stars.push(
                    <RatingStar
                        key={i}
                        style={{ filter: 'grayscale(1)' }}
                        onClick={() => this.setRating(i + 1, category)}
                    />
                )
            }
        }
        return stars
    }

    setRating = (rating, category) => {
        this.setState({ ...this.state, [category]: rating })
    }

    render() {
        const { addRating, hidePopup, t, currentLanguage } = this.props
        const {
            booking,
            punctuality,
            kindness,
            quality,
            equipment,
            neatness,
            support,
        } = this.state

        const isFreelancer = booking && booking.fl_firstname
        const ratingIsValid =
            (punctuality !== 0 && kindness !== 0 && quality !== 0) ||
            (equipment !== 0 && neatness !== 0 && support !== 0)

        let dateformat = 'd. mmmm yyyy'
        let convertedStarttime = formatTimeString(booking.starttime)
        let convertedEndtime = formatTimeString(booking.endtime)

        if (currentLanguage === 'en') {
            dateformat = 'mm/dd/yyyy'
            convertedStarttime = formatAMPM(convertedStarttime, true)
            convertedEndtime = formatAMPM(convertedEndtime, true)
        }

        return (
            <Popup
                title={t('popup.giveRating')}
                hidePopup={hidePopup}
                size={'large'}
            >
                <p>{t('popup.giveRatingDescription')}</p>
                <PopupInfo>
                    <PopupSection>
                        <div style={{}}>
                            <SubTitle>
                                {isFreelancer
                                    ? t('popup.trainer')
                                    : t('popup.company')}
                            </SubTitle>
                            <p style={{ flex: '1 1 0' }}>
                                {isFreelancer ? (
                                    <>
                                        {booking.fl_firstname}{' '}
                                        {booking.fl_lastname}
                                        <br />
                                        {booking.fl_total_avg
                                            ? booking.fl_total_avg.toFixed(2)
                                            : '-'}{' '}
                                        <StarIcon
                                            style={{
                                                marginBottom: 2,
                                                transform: 'scale(1.3)',
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {booking.br_name}
                                        <br />
                                        {booking.br_total_avg
                                            ? booking.br_total_avg.toFixed(2)
                                            : '-'}{' '}
                                        <StarIcon
                                            style={{
                                                marginBottom: 2,
                                                transform: 'scale(1.3)',
                                            }}
                                        />
                                    </>
                                )}
                            </p>
                        </div>
                    </PopupSection>
                    <PopupSection>
                        <SubTitle>{t('popup.job')}</SubTitle>
                        <p>{booking && booking.course}</p>
                    </PopupSection>
                    <PopupSection>
                        <SubTitle>{t('popup.dateAndTime')}</SubTitle>
                        <p>
                            {booking && convertedStarttime} -{' '}
                            {booking && convertedEndtime}
                            <br />
                            {booking && dateFormat(booking.date, dateformat)}
                        </p>
                    </PopupSection>
                </PopupInfo>

                <RatingSection>
                    <PopupSection noPadding>
                        {isFreelancer ? (
                            <>
                                {this.renderStarRating(
                                    punctuality,
                                    'punctuality'
                                )}
                                <SubTitle>{t('popup.punctuality')}</SubTitle>
                                <CategoryDetails>
                                    ({t('popup.punctualityDetails')})
                                </CategoryDetails>
                            </>
                        ) : (
                            <>
                                {this.renderStarRating(neatness, 'neatness')}
                                <SubTitle>{t('popup.neatness')}</SubTitle>
                                <CategoryDetails>
                                    ({t('popup.neatnessDetails')})
                                </CategoryDetails>
                            </>
                        )}
                    </PopupSection>
                    <PopupSection noPadding style={{ marginTop: 25 }}>
                        {isFreelancer ? (
                            <>
                                {this.renderStarRating(kindness, 'kindness')}
                                <SubTitle>{t('popup.kindness')}</SubTitle>
                                <CategoryDetails>
                                    ({t('popup.kindnessDetails')})
                                </CategoryDetails>
                            </>
                        ) : (
                            <>
                                {this.renderStarRating(equipment, 'equipment')}
                                <SubTitle>{t('popup.equipment')}</SubTitle>
                                <CategoryDetails>
                                    ({t('popup.equipmentDetails')})
                                </CategoryDetails>
                            </>
                        )}
                    </PopupSection>
                    <PopupSection noPadding style={{ marginTop: 25 }}>
                        {isFreelancer ? (
                            <>
                                {this.renderStarRating(quality, 'quality')}
                                <SubTitle>{t('popup.quality')}</SubTitle>
                                <CategoryDetails>
                                    ({t('popup.qualityDetails')})
                                </CategoryDetails>
                            </>
                        ) : (
                            <>
                                {this.renderStarRating(support, 'support')}
                                <SubTitle>{t('popup.support')}</SubTitle>
                                <CategoryDetails>
                                    ({t('popup.supportDetails')})
                                </CategoryDetails>
                            </>
                        )}
                    </PopupSection>
                </RatingSection>

                {!ratingIsValid && (
                    <RatingRemark>
                        <InfoIcon />
                        {t('popup.ratingRemark')}
                    </RatingRemark>
                )}
                <StyledButton
                    primary
                    onClick={hidePopup}
                    style={{ width: '100%', marginTop: 15 }}
                    disabled={!ratingIsValid}
                    onClick={
                        isFreelancer
                            ? () =>
                                  addRating(
                                      booking.br_id,
                                      booking.id,
                                      punctuality,
                                      kindness,
                                      quality
                                  )
                            : () =>
                                  addRating(
                                      booking.id,
                                      equipment,
                                      neatness,
                                      support
                                  )
                    }
                >
                    {t('popup.rate')}
                </StyledButton>
                <StyledButton
                    tertiary
                    onClick={hidePopup}
                    style={{ width: '100%', marginTop: 15 }}
                >
                    {t('popup.abort')}
                </StyledButton>
            </Popup>
        )
    }
}

export default withTranslation()(OpenRatingsPopup)
