import React, {Component} from 'react';
import styled from "styled-components";
import {MdClose} from "react-icons/md";
import {Backdrop, StyledWrapper, StyledButton} from "./styled-components";
import ReactHtmlParser from 'react-html-parser';
import {withTranslation} from "react-i18next";
import {DEVICE} from "../assets/constants";


const MessageWrapper = styled(StyledWrapper)`
    position: relative;
    padding: 55px 50px 35px;
    width: 350px;
    text-align: center;
    
     @media ${DEVICE.phone} {
        
    }
`;

const CloseButton = styled(MdClose)`
    position: absolute;
    top: 12px;
    left: 12px;
    height: 20px;
    width: 20px;
    cursor: pointer;
`;


const OkayButton = styled(StyledButton)`
    width: 150px; 
    margin-top: 25px;

    
     @media ${DEVICE.phone} {
            width: 100%; 
    }
`;

class PopupMessage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {setShowMessage, message} = this.props;

        return <Backdrop>
            <MessageWrapper>
                <CloseButton onClick={() => setShowMessage(false)}/>
                {ReactHtmlParser(message)}
                <OkayButton onClick={() => setShowMessage(false)}>OK</OkayButton>
            </MessageWrapper>
        </Backdrop>;
    }
}

export default withTranslation()(PopupMessage);