import AccountService from '../../services/account-service';
import Box from '../../common/Box';
import Loader from '../../legacy/components/loader';
import logo from '../../assets/images/logo.png';
import ReactPasswordStrength from 'react-password-strength';
import styled from 'styled-components';
import { COLORS, SPACING } from '../../assets/constants';
import { Form } from '../../common/ui-elements';
import {InputSection, StyledButton, StyledLabel} from '../../legacy/components/styled-components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const Background = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 572px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ResetPassword = () => {

    const accountService = new AccountService();

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<any>();
    const [passwordConfirm, setPasswordConfirm] = useState<any>();
    const [token, setToken] = useState<string>();
    const [reset, setReset] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setEmail(params.get('email'));
        setToken(params.get('token'));
    }, []);

    return <Background>

        <img src={logo} width={165} style={{ marginBottom: SPACING.large }} />

        {
            reset
                ? <Box size="medium">
                    <h1 style={{ marginBottom: SPACING.medium }}>{t('registration.newPassword')}.</h1>
                    <p>{t('registration.newPasswordText')}</p>
                    <StyledButton
                        style={{ width: 200, marginTop: SPACING.large, float: 'right', backgroundColor: COLORS.primary }}
                        onClick={() => history.push(`/${t('routes.login')}`)}>
                        Zum Login
                    </StyledButton>
                </Box>
                : <Box size="medium">
                    <h1>{t('registration.resetPassword')}</h1>

                    {message &&
                        <p style={{ fontSize: '0.8em', marginTop: 10, width: '100%', textAlign: 'center' }}>
                            {message}</p>}

                    <Form style={{ marginTop: SPACING.large }} onSubmit={async (event) => {
                        event.preventDefault();
                        setLoading(true);
                        try {
                            const response = await accountService.confirmResetPassword(email, password.password, passwordConfirm.password, token);
                            setLoading(false);
                            if (response.status === 200) {
                                setReset(true);
                            }
                        } catch (error) {
                            setLoading(false);
                            setMessage(t('registration.error'));
                        }
                    }}>
                        <InputSection style={{width: '100%'}}>
                            <StyledLabel>
                                {t('forms.email')}
                            </StyledLabel>
                            <input
                                type={'text'}
                                value={email}
                                disabled
                                style={{marginTop: '-10px', marginBottom: '10px'}}
                            />
                        </InputSection>
                        <label>
                            <p>{t('forms.password')}</p>
                            <ReactPasswordStrength
                                required
                                minLength={8}
                                inputProps={{ id: 'password' }}
                                changeCallback={setPassword}
                                scoreWords={[t('forms.passwordWeak'), t('forms.passwordMedium'), t('forms.passwordGood'), t('forms.passwordStrong'), t('forms.passwordVeryStrong')]}
                                tooShortWord={t('forms.passwordTooShort')}
                            />
                        </label>
                        <label>
                            <p>{t('forms.passwordConfirm')}</p>
                            <ReactPasswordStrength
                                required
                                minLength={8}
                                inputProps={{ id: 'password' }}
                                changeCallback={setPasswordConfirm}
                                scoreWords={[t('forms.passwordWeak'), t('forms.passwordMedium'), t('forms.passwordGood'), t('forms.passwordStrong'), t('forms.passwordVeryStrong')]}
                                tooShortWord={t('forms.passwordTooShort')}
                            />
                        </label>
                        <input type="hidden" value={token} />
                        <div>
                            <button type="submit" disabled={!email || !token || (password && !password.isValid) || (passwordConfirm && !passwordConfirm.isValid)}>{t('registration.forgotPassword')}</button>
                        </div>
                    </Form>
                </Box>
        }

        {
            loading &&
            <Loader />
        }
    </Background>;
}

export default ResetPassword;