import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";
import SearchWidget from "../../legacy/components/search-form/search-widget";
import { StyledWrapper } from "../../legacy/components/styled-components";
import background from '../../assets/images/background.png';
import dog from '../../assets/images/dog.gif';
import { SPACING } from "../../assets/constants";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
    padding-top: 35px;

    input, select, textarea {
        padding: 11.5px 20px;
        font-size: 12px;
        line-height: 1.6;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        border: 1px solid;
        border-radius: 3px;
        background-color: transparent;
        font-style: normal;
        font-weight: 400;
        font-family: Roboto, sans-serif;
        border-color: #ebebeb;
        color: #1c1c21;
    }

    .input label {
        text-transform: uppercase;
        font-weight: 700;
        color: #c3b6c7;
        font-size: 0.8em;
        letter-spacing: 0.06em;
        display: block;
        margin-bottom: 5px;
        white-space: nowrap;
    }

    .input {
        margin-bottom: 20px;
        width: 100%;
    }

    .search-form .time-wrapper {
        display: flex;
    }

    .search-form .time-wrapper > div:first-child {
        margin-right: 10px;
    }

    .search-form input {
        font-family: Montserrat;
        width: 100%;
        font-size: 16px;
        padding: 8px 10px;
    }
`;

const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: radial-gradient(at bottom left, #DB64FF, #4B0060);
    border-radius: 5px;
    opacity: 0.8;
`;

const Search = () => {

    const { t } = useTranslation();

    return <Wrapper>
        <StyledWrapper style={{ backgroundImage: `background-image: url(${background}), radial-gradient(at bottom left, #DB64FF 33%, #4B0060 68%);`, marginBottom: SPACING.large }}>
            <Overlay />
            <div style={{ position: 'relative', width: '75%' }}>
                <h2 style={{ color: 'white', marginBottom: SPACING.small }}>
                    {t('search.title')}
                </h2>
                <p style={{ color: 'white' }}>
                    {t('pages.description.search')}
                </p>
            </div>
        </StyledWrapper>
        <SearchWidget />
    </Wrapper>;

}

export default Search;