import {useAuth} from "../../context/AuthContext";
import FreelancerPersonalDataWidget from "../../legacy/components/widgets/freelancer-personal-data-widget";
import EmployeePersonalDataWidget from "../../legacy/components/widgets/employee-personal-data-widget";
import PersonalDataWidget from "../../legacy/components/widgets/personal-data-widget";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

const PersonalData = () => {

    const {user, logout, updateUser} = useAuth();
    const {t} = useTranslation();
    const history = useHistory();

    return <>

        <h1>
            {user && user.role === 'freelancer'
                ? t('pages.title.personalData')
                : user && user.role === 'chef'
                    ? t('pages.title.personalDataChef')
                    : t('pages.title.personalData')}
        </h1>

        <p>
            {user && user.role === 'freelancer'
                ? t('pages.description.personalData')
                : user && user.role === 'chef'
                    ? t('pages.description.personalDataChef')
                    : t('pages.description.personalData')}
        </p>

        {user && user.role === 'freelancer'
            ? <FreelancerPersonalDataWidget logout={logout}/>
            : user && (user.role === 'manager' || user.role === 'employee')
                ? <EmployeePersonalDataWidget logout={logout} updateUser={updateUser}/>
                : <PersonalDataWidget logout={logout} updateUser={updateUser}/>}

    </>;

}

export default PersonalData;