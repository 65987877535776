import React, { Component } from 'react'
import { Backdrop, StyledButton, SubTitle } from './styled-components'
import Popup from './popup'
import styled from 'styled-components'
import { ReactComponent as StarIcon } from '../assets/images/star.svg'
import {
    formatAMPM,
    formatMoney,
    formatTimeString,
    getCurrency,
} from '../utils/utility-functions'
import { MdInfoOutline } from 'react-icons/md'
import dateFormat from 'dateformat'
import { withTranslation } from 'react-i18next'
import { SPACING } from '../../assets/constants'

const PopupSection = styled.div`
    margin-bottom: 15px;
    flex: 1 1 0;
`

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

class RequestPopup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            request: this.props.request,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.request !== this.props.request) {
            this.setState({ request: this.props.request })
        }
    }

    render() {
        const {
            hidePopup,
            requestingRequest,
            confirmRequest,
            declineRequest,
            deleteRequest,
            t,
            currentLanguage,
        } = this.props
        const { request } = this.state

        let dateformat = 'd. mmmm yyyy'
        let convertedStarttime = formatTimeString(request.starttime)
        let convertedEndtime = formatTimeString(request.endtime)

        if (currentLanguage === 'en') {
            dateformat = 'mm/dd/yyyy'
            convertedStarttime = formatAMPM(convertedStarttime, true)
            convertedEndtime = formatAMPM(convertedEndtime, true)
        }

        return (
            <Popup title={t('popup.searchDetails')} hidePopup={hidePopup}>
                <p>{t('popup.searchDetailsDescription')}</p>
                <div style={{ marginTop: SPACING.medium }}>
                    {request && request.suggestion > 0 && (
                        <>
                            <PopupSection>
                                <SubTitle>
                                    {t('popup.trainerProposal')}
                                </SubTitle>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ flex: '1 1 0' }}>
                                        {request.fl_firstname}{' '}
                                        {request.fl_lastname}
                                        <br />
                                        {request.fl_total_avg
                                            ? request.fl_total_avg.toFixed(2)
                                            : '-'}{' '}
                                        <StarIcon
                                            style={{
                                                marginBottom: 2,
                                                transform: 'scale(1.3)',
                                            }}
                                        />
                                    </p>
                                </div>
                            </PopupSection>
                            <PopupSection>
                                <SubTitle>{t('popup.cost')}</SubTitle>
                                <p>
                                    {formatMoney(
                                        request.costs + request.surcharge
                                    )}{' '}
                                    {request.currency}
                                </p>
                            </PopupSection>
                        </>
                    )}
                    <PopupSection>
                        <SubTitle>{t('popup.job')}</SubTitle>
                        <p>{request && request.course}</p>
                    </PopupSection>
                    <PopupSection>
                        <SubTitle>{t('popup.dateAndTime')}</SubTitle>
                        <p>
                            {request && convertedStarttime} -{' '}
                            {request && convertedEndtime} {t('popup.clock')}
                            <br />
                            {request && dateFormat(request.date, dateformat)}
                        </p>
                    </PopupSection>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '0.8em',
                        marginTop: 35,
                    }}
                >
                    {request &&
                        request.requested === true &&
                        (!request.suggestion || request.suggestion === 0) &&
                        request.requestables > 0 && (
                            <p>
                                <InfoIcon />
                                {t('popup.requested')}
                            </p>
                        )}
                    {
                        (
                            request &&
                            request.requestables > 0 &&
                            request.requested === false &&
                            !request.fl_firstname
                        ) && (
                            <p>
                                <InfoIcon />
                                {t('popup.requestable')}
                            </p>
                        )}
                    {request &&
                        request.requestables === 0 &&
                        !request.suggestion && (
                            <p>
                                <InfoIcon />
                                {t('popup.noTrainers')}
                            </p>
                        )}
                </div>
                <div style={{ textAlign: 'center', marginTop: 15 }}>
                    {(
                        request &&
                        request.requestables > 0 &&
                        request.requested === false &&
                        !request.fl_firstname
                    ) && (
                            <StyledButton
                                primary
                                style={{ width: '100%' }}
                                onClick={() => requestingRequest(request.id)}
                            >
                                {t('popup.request')}
                            </StyledButton>
                        )}

                    {request && request.suggestion > 0 && (
                        <StyledButton
                            primary
                            style={{ marginRight: 5, width: 'calc(50% - 5px)' }}
                            onClick={() => confirmRequest(request.id)}
                        >
                            {t('popup.book')}
                        </StyledButton>
                    )}

                    {request && request.suggestion > 0 && (
                        <StyledButton
                            style={{ marginLeft: 5, width: 'calc(50% - 5px)' }}
                            onClick={() => {
                                const confirmed = window.confirm(
                                    t('popup.declineConfirm')
                                )
                                if (confirmed) {
                                    declineRequest(
                                        request.id,
                                        request.suggestion,
                                        request.second
                                    )
                                }
                            }}
                        >
                            {request &&
                            request.second &&
                            request.suggestion === 1
                                ? t('popup.second')
                                : t('popup.decline')}
                        </StyledButton>
                    )}
                </div>
                <StyledButton
                    tertiary
                    color="#db64ff"
                    onClick={() => deleteRequest(request.id)}
                    style={{ width: '100%', marginTop: 15 }}
                >
                    {t('popup.delete')}
                </StyledButton>
            </Popup>
        )
    }
}

export default withTranslation()(RequestPopup)
