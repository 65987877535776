import i18n from "i18next"
import { PHONE_CODES } from "../assets/constants";

export const companyNameField = (name, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.company'),
        type: 'text',
        value: name,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const branchNameField = (name, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.branch'),
        type: 'text',
        value: name,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const emailField = (email, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.email'),
        type: 'email',
        value: email,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const invoiceEmailField = (email, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.invoiceEmail'),
        type: 'email',
        value: email,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const addressField = (address, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.address'),
        type: 'text',
        value: address,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const streetField = (street, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.street'),
        type: 'text',
        value: street,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const housenumberField = (housenumber, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.housenumber'),
        type: 'text',
        value: housenumber,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const addressAdditionalField = (addressAdditional, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.addressAdditional'),
        type: 'text',
        value: addressAdditional,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const zipcodeField = (zipcode, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.zipcode'),
        type: 'text',
        value: zipcode,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const cityField = (city, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.city'),
        type: 'text',
        value: city,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const stateField = (state, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.state'),
        type: 'text',
        value: state,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const countryField = (country, onChange, size, required, disabled, countries) => {
    return {
        label: i18n.t('forms.country'),
        type: 'select',
        value: country,
        options: countries.map(country => ({ value: country.locale, label: country.name })),
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const phoneCountryField = (phoneCountry, onChange, size, required, disabled, countries) => {
    return {
        label: i18n.t('forms.phoneCountry'),
        type: 'select',
        value: phoneCountry,
        options: countries.map(country => ({ value: `+${PHONE_CODES[country.locale.toUpperCase()]}`, label: `+${PHONE_CODES[country.locale.toUpperCase()]}` })),
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const phoneField = (phone, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.phone'),
        type: 'text',
        value: phone,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const phoneFieldNew = (phone, onChange) => {
    return {
        label: i18n.t('forms.phone'),
        type: 'phone',
        value: phone,
        onChange: value => onChange(value),
    };
};

export const websiteField = (website, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.website'),
        type: 'text',
        value: website,
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};

export const isBranchField = (isBranch, onChange, size, required, disabled) => {
    return {
        label: i18n.t('forms.isBranch'),
        type: 'select',
        value: isBranch,
        options: [
            {value: 'no', label: i18n.t('forms.isBranchNo')},
            {value: 'yes', label: i18n.t('forms.isBranchYes')},
        ],
        onChange: value => onChange(value),
        size: size,
        required: required,
        disabled: disabled,
    };
};