import React, { Component } from 'react';
import List from "../list";
import dateFormat from "dateformat";
import Loader from "../loader";
import PopupMessage from "../popup-message";
import { ReactComponent as StarIcon } from '../../assets/images/star.svg';
import { ReactComponent as MoreButton } from "../../assets/images/more.svg";
import OpenRatingsPopup from "../open-ratings-popup";
import { withTranslation } from "react-i18next";
import RatingsService from '../../../services/ratings-service';
import i18n from '../../../i18n/config';


class FreelancerOpenRatingsWidget extends Component {

    constructor(props) {
        super(props);

        this.ratingsService = new RatingsService();

        this.state = {
            selectedBooking: undefined,
            ratings: undefined,
            isLoading: false,
            showMessage: false,
            message: undefined,
            showPopup: false,
            currentLanguage: i18n.language,
            dateformat: i18n.language === 'en' ? 'mm/dd/yyyy' : 'd. mmm yyyy',
        }
    }

    componentDidMount() {
        this.updateRatings();
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show });
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading });
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show });
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message });
    }

    updateRatings = () => {
        this.ratingsService.getFreelancerOpenRatings().then(res => {
            if (res && res.data) {
                this.setState({ ...this.state, ratings: res.data });
            }
        });
    }

    openRatingsPopup = (item) => {
        this.setState(
            { ...this.state, selectedBooking: item },
            () => this.setShowPopup(true)
        );
    }

    addRating = (bookingId, equipment, neatness, support) => {
        const { t } = this.props;

        this.setIsLoading(true);
        this.ratingsService.freelancerAddRating(bookingId, equipment, neatness, support).then(res => {
            this.setIsLoading(false);
            this.setShowPopup(false);

            if (res && res.data) {
                this.setShowMessage(true);
                this.setMessage(`<div>${t('rating.thanks')}</div>`)
            }

            if (res && !res.success && res.message) {
                this.setShowMessage(true);
                this.setMessage(`<div>${res.message}</div>`);
            }

            this.updateRatings();
        });
    }

    getRatingsListStructure = (item) => {
        const { t } = this.props;
        const { dateformat } = this.state;
        const { course, br_name, date, br_total_avg } = item;

        const courseMarkdown = `<b>${course}</b>`;
        const branchMarkdown = `<b>${br_name}</b>`;
        const dateMarkdown = `<span>${dateFormat(date, dateformat)}</span>`;
        const ratingMarkdown = br_total_avg ?
            (<>{br_total_avg.toFixed(2)} <StarIcon
                style={{ marginBottom: 3, marginLeft: 3 }} /></>) :
            <i>{t('rating.noRatings')}</i>;

        return [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: branchMarkdown, isHTML: true },
            { markdown: dateMarkdown, isHTML: true },
            { markdown: ratingMarkdown, isHTML: false },
            {
                markdown: <MoreButton style={{ cursor: 'pointer' }} onClick={() => this.openRatingsPopup(item)} />,
                isHTML: false
            },
        ];
    }

    render() {
        const { ratings, isLoading, showMessage, message, showPopup, selectedBooking } = this.state;
        const { t } = this.props;
        const currentLanguage = i18n.language;

        return <>
            <List items={ratings}
                noItemsMessage={t('list.noOpenRatings')}
                headings={[t('list.job'), t('list.company'), t('list.date'), t('list.rating'), '']}
                getListItemStructure={this.getRatingsListStructure}
                style={{ marginTop: '30px' }} />

            {
                showPopup &&
                <OpenRatingsPopup booking={selectedBooking}
                    hidePopup={() => this.setShowPopup(false)}
                    addRating={this.addRating}
                    currentLanguage={currentLanguage} />
            }
            {
                isLoading &&
                <Loader />
            }
            {
                showMessage && message &&
                <PopupMessage message={message} setShowMessage={this.setShowMessage} />
            }
        </>;
    }
}

export default withTranslation()(FreelancerOpenRatingsWidget);