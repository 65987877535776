import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import FreelancerReceivedRatingsWidget from "../../legacy/components/widgets/freelancer-received-ratings-widget";
import ReceivedRatingsWidget from "../../legacy/components/widgets/received-ratings-widget";

const ReceivedRatings = () => {

    const { user } = useAuth();
    const { t } = useTranslation();

    return <>

        <h1>
            {user && user.role === 'freelancer'
                ? t('pages.title.receivedRatingsFreelancer')
                : t('pages.title.receivedRatingsChef')}
        </h1>

        <p>
            {user && user.role === 'freelancer'
                ? t('pages.description.receivedRatingsFreelancer')
                : t('pages.description.receivedRatingsChef')}
        </p>

        {user && user.role === 'freelancer'
            ? <FreelancerReceivedRatingsWidget />
            : <ReceivedRatingsWidget />}

    </>;

}

export default ReceivedRatings;