import styled from "styled-components";
import { COLORS, RADIUS, SHADOW, SPACING } from "../assets/constants";

const Wrapper = styled.div`
    display: inline-block;
    padding: ${SPACING.xlarge}px;
    background-color: ${COLORS.light};
    border-radius: ${RADIUS.small}px;
    ${SHADOW.sharp};
`;

const Box = ({ children, size }: any) => {

    return <Wrapper style={{ width: size === 'medium' ? 450 : 'auto', maxWidth: '90vw' }}>
        {children}
    </Wrapper>;
}

export default Box;