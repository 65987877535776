import React, { Component } from 'react'
import { DEVICE } from '../../assets/constants'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import EditAvailabilityPopup from '../edit-availability-popup'
import Loader from '../loader'
import PopupMessage from '../popup-message'
import dateFormat from 'dateformat'
import '../../assets/less/calendar.scss'
import { AVAILABILITY_COLORS, PRIMARY_COLOR } from '../../assets/constants'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import FreelancerBookingsPopup from '../freelancer-bookings-popup'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { enIN, de } from 'date-fns/locale'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import AvailabilityService from '../../../services/availability-service'
import BookingsService from '../../../services/bookings-service'
import i18n from '../../../i18n/config'

registerLocale('de', de)
registerLocale('en', enIN)
setDefaultLocale('de')

const CalendarWrapper = styled.div`
    /* @media ${DEVICE.phone} {
        padding: 5px 15px 15px; 
    } */
`

class CalendarWidget extends Component {
    constructor(props) {
        super(props)

        this.state = {
            entriesDE: [],
            entriesEN: [],
            bookings: [],
            showPopup: false,
            selectedEvent: undefined,
            isLoading: false,
            showMessage: false,
            message: undefined,
            showAddEntryPopup: false,
            currentLanguage: i18n.language,
            currentTimeFormat: undefined,
            currentDateFormat: undefined,
            calendarFormat: undefined,
        }

        this.availabilityService = new AvailabilityService()
        this.bookingService = new BookingsService()
    }

    componentDidMount() {
        this.getAvailabilities()
        this.getBookings()
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading })
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show })
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message })
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show })
    }

    setShowAddEntryPopup = (show) => {
        this.setState({ ...this.state, showAddEntryPopup: show })
    }

    getAvailabilities = () => {
        this.availabilityService.getAvailabilities().then((availabilities) => {
            const entriesDE = availabilities.map((availability) => {
                const start = new Date(
                    `${availability.startdate}T${availability.starttime}`
                )
                const end = new Date(
                    `${availability.enddate}T${availability.endtime}`
                )

                const format = 'HH:MM'

                return {
                    source: availability,
                    title: `${dateFormat(start, format)} - ${dateFormat(
                        end,
                        format
                    )}`,
                    start: start,
                    end: end,
                }
            })
            const entriesEN = availabilities.map((availability) => {
                const start = new Date(
                    `${availability.startdate}T${availability.starttime}`
                )
                const end = new Date(
                    `${availability.enddate}T${availability.endtime}`
                )

                const format = 'h:MM tt'

                return {
                    source: availability,
                    title: `${dateFormat(start, format)} - ${dateFormat(
                        end,
                        format
                    )}`,
                    start: start,
                    end: end,
                }
            })
            this.setState({
                entriesDE: entriesDE,
                entriesEN: entriesEN,
            })
        })
    }

    getBookings = () => {
        this.bookingService.getFreelancerUpcomingBookings().then((res) => {
            if (res && res.data) {
                const bookings = res.data.map((booking) => {
                    return {
                        source: booking,
                        title: `${booking.course} - ${booking.br_name}`,
                        start: new Date(`${booking.date}T${booking.starttime}`),
                        end: new Date(`${booking.date}T${booking.endtime}`),
                    }
                })
                this.setState({
                    bookings: bookings,
                })
            }
        })
    }

    openAvailabilityPopup = (availability) => {
        this.setState({
            ...this.state,
            showPopup: true,
            selectedEvent: availability,
        })
    }

    openBookingPopup = (booking) => {
        this.setState({
            ...this.state,
            showPopup: true,
            selectedEvent: booking,
        })
    }

    closePopup = () => {
        this.setState({
            ...this.state,
            showPopup: false,
        })
    }

    deleteEntry = (availabilityId) => {
        this.setIsLoading(true)
        this.availabilityService
            .removeAvailability(availabilityId)
            .then((res) => {
                this.setIsLoading(false)
                this.setShowPopup(false)
                this.setState({
                    ...this.state,
                    entriesDE: this.state.entriesDE.filter((entry) => {
                        return entry.source.id !== availabilityId
                    }),
                    entriesEN: this.state.entriesEN.filter((entry) => {
                        return entry.source.id !== availabilityId
                    }),
                })
            })
    }

    deleteEntryGroup = (availabilityGroupId) => {
        const { t } = this.props
        const deleteAll = window.confirm(t('popup.deleteGroupConfirm'))

        if (deleteAll) {
            this.setIsLoading(true)
            this.availabilityService
                .removeAvailabilityGroup(availabilityGroupId)
                .then((res) => {
                    this.setIsLoading(false)
                    this.setShowPopup(false)
                    this.setState({
                        ...this.state,
                        entriesDE: this.state.entriesDE.filter((entry) => {
                            return entry.source.group_id !== availabilityGroupId
                        }),
                        entriesEN: this.state.entriesEN.filter((entry) => {
                            return entry.source.group_id !== availabilityGroupId
                        }),
                    })
                })
        }
    }

    saveEntry = (id, date, startTime, endTime, availability) => {
        const { t } = this.props
        const timeDiff =
            (new Date(endTime).getTime() - new Date(startTime).getTime()) /
            3600000

        if (Math.abs(timeDiff) < 0.9) {
            alert(t('popup.addEntryWarning'))
        } else {
            this.setIsLoading(true)
            const newAvailabilityData = {
                id: id,
                date: dateFormat(date, 'yyyy-mm-dd'),
                starttime: dateFormat(startTime, 'HH:MM'),
                endtime: dateFormat(endTime, 'HH:MM'),
                availability: availability,
                overnight: startTime > endTime,
            }
            this.availabilityService
                .editAvailability(newAvailabilityData)
                .then((res) => {
                    this.setIsLoading(false)
                    this.setShowPopup(false)

                    if (res && !res.success && res.message) {
                        this.setShowMessage(true)
                        this.setMessage(`<div>${res.message}</div>`)
                    }

                    this.getAvailabilities()
                })
        }
    }

    eventStyleGetter = (event, start, end, isSelected) => {
        const isAvailability = event.source.availability

        const style = {
            backgroundColor: isAvailability
                ? AVAILABILITY_COLORS[event.source.availability]
                : PRIMARY_COLOR,
            borderRadius: '5px',
            opacity: 0.8,
            color: isAvailability ? '#000' : '#fff',
            border: '0px',
            display: 'block',
            fontSize: '0.8em',
            fontWeight: isAvailability ? 'bold' : 'normal',
        }
        return {
            style: style,
        }
    }

    onClickEvent = (event, syntheticEvent) => {
        if (event.source.availability) {
            this.openAvailabilityPopup(event.source)
        } else {
            this.openBookingPopup(event.source)
        }
    }

    render() {
        const {
            entriesDE,
            entriesEN,
            bookings,
            showPopup,
            selectedEvent,
            isLoading,
            showMessage,
            message,
            showAddEntryPopup,
        } = this.state
        const { t } = this.props
        const currentLanguage = i18n.language

        let currentDateFormat = 'd. MMMM yyyy'
        let currentTimeFormat = 'HH:mm'
        let calendarFormat = 'd. mmmm yyyy HH:MM'

        if (i18n.language === 'en') {
            currentDateFormat = 'MM/dd/yyyy'
            currentTimeFormat = 'hh:mm aa'
            calendarFormat = 'MM/dd/yyyy hh:mm aa'
        }

        moment.locale(currentLanguage)
        this.localizer = momentLocalizer(moment)

        return (
            <>
                <CalendarWrapper>
                    {/*<PlusIcon onMouseEnter={() => this.setShowAddEntryPopup(true)}*/}
                    {/*          onMouseLeave={() => this.setShowAddEntryPopup(false)}/>*/}
                    <Calendar
                        localizer={this.localizer}
                        dateFormat={calendarFormat}
                        events={[
                            ...(currentLanguage === 'de'
                                ? entriesDE
                                : entriesEN),
                            ...bookings,
                        ]}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 650 }}
                        eventPropGetter={this.eventStyleGetter}
                        onSelectEvent={this.onClickEvent}
                        views={['month', 'week', 'day']}
                        messages={{
                            next: <MdChevronRight />,
                            previous: <MdChevronLeft />,
                            today: t('calendar.today'),
                            month: t('calendar.month'),
                            week: t('calendar.week'),
                            day: t('calendar.day'),
                            showMore: (total) =>
                                `+${total} ${t('calendar.more')}`,
                        }}
                    />
                </CalendarWrapper>

                {showPopup && selectedEvent.availability && (
                    <EditAvailabilityPopup
                        entry={selectedEvent}
                        hidePopup={() => this.setShowPopup(false)}
                        saveEntry={this.saveEntry}
                        deleteEntry={this.deleteEntry}
                        deleteEntryGroup={this.deleteEntryGroup}
                        currentLanguage={currentLanguage}
                        currentDateFormat={currentDateFormat}
                        currentTimeFormat={currentTimeFormat}
                    />
                )}

                {showPopup && !selectedEvent.availability && (
                    <FreelancerBookingsPopup
                        booking={selectedEvent}
                        hidePopup={() => this.setShowPopup(false)}
                        currentLanguage={currentLanguage}
                    />
                )}

                {isLoading && <Loader />}

                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
            </>
        )
    }
}

export default withTranslation()(CalendarWidget)
