import React, { Component } from 'react';
import {
    InfoMessage,
    InputSection, ListElement, ListElementWrapper, SecondarySubHeading,
    StyledLabel,
    StyledSelect, TextButton,
    StyledSubmit,
    StyledButton
} from "../styled-components";
import Loader from "../loader";
import PopupMessage from "../popup-message";
import BranchDropdown from "../branch-dropdown";
import List from "../list";
import { MdDelete } from "react-icons/md";
import { PRIMARY_COLOR } from "../../assets/constants";
import SectionHeading from "../section-heading";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import Popup from "../popup";
import BranchService from '../../../services/branch-service';
import {t} from "react-select/dist/index-4bd03571.esm";

const DeleteIcon = styled(MdDelete)`
    color: ${PRIMARY_COLOR};
    transform: scale(1.3);
    cursor: pointer;
`;

class EmployeeAccessWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            employeeSelection: 'createEmployee',
            branch: undefined,
            selectedBranchId: undefined,
            managerList: [],
            employeeList: [],
            message: undefined,
            isLoading: false,
            showMessage: false,
            addingEmployee: false,
            showPopup: false,
            deletePerson: undefined,
        }

        this.branchService = new BranchService();

        this.role = props.role;
    }

    setShowPopup = show => {
        this.setState({ ...this.state, showPopup: show });
    }

    setSelectedBranch = (branchId) => {
        this.setState({ ...this.state, selectedBranchId: branchId });
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading });
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message });
    }

    updateItems = (branchId) => {
        this.updateStaffList(branchId);
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show });
    }

    updateStaffList = (branchId) => {

        this.branchService.getBranchManager(branchId)
            .then(res => {
                if (res && res.data) {
                    let array = [];
                    array.push(res.data);
                    this.setState({ ...this.state, managerList: array })
                } else {
                    this.setState({ ...this.state, managerList: [] })
                }
            })

        this.branchService.getBranchEmployees(branchId)
            .then(res => {
                if (res && res.data) {
                    this.setState({ ...this.state, employeeList: res.data })
                }
            })
    }

    setFirstName = firstName => {
        this.setState({
            ...this.state,
            firstName: firstName,
        });
    }

    setLastName = lastName => {
        this.setState({
            ...this.state,
            lastName: lastName,
        });
    }

    setEmail = email => {
        this.setState({
            ...this.state,
            email: email,
        });
    }

    setEmployeeSelection = (selection) => {
        this.setState({ ...this.state, employeeSelection: selection });
    }

    emptyInputFields = () => {
        this.setState({
            ...this.state,
            firstName: '',
            lastName: '',
            email: '',
        });
    }

    addStaff = (firstName, lastName, email, branchId, employeeSelection) => {
        if (employeeSelection === 'createEmployee') {
            this.setIsLoading(true);
            this.branchService.addBranchEmployee(firstName, lastName, email, branchId)
                .then(
                    res => {
                        console.log('yeay3')
                        if (res && res.message) {
                            this.setIsLoading(false);
                            this.toggleAddingEmployee(false);
                            this.setShowMessage(true);
                            this.emptyInputFields();
                            this.setMessage(`<div>${res.message}</div>`);
                            this.updateItems(branchId);
                        }
                    },
                    error => {
                        this.setIsLoading(false);
                        this.toggleAddingEmployee(false);
                        this.setShowMessage(true);

                        if (!error) {
                            this.setMessage(`<div>${t('forms.unknownError')}</div>`);
                        }

                        if (error.message) {
                            this.setMessage(`<div>${error.message}</div>`)
                        }


                        if (error.data) {
                            this.setMessage(`<div>${Object.values(error.data).map(error => `<div>${error}</div>`)}</div>`);
                        }

                        if (error.data.data) {
                            this.setMessage(`<div>${Object.values(error.data.data).map(error => `<div>${error}</div>`)}</div>`);
                        }
                    }
                )
        }

        if (employeeSelection === 'createManager') {
            this.setIsLoading(true);
            this.branchService.addBranchManager(firstName, lastName, email, branchId)
                .then(
                    res => {
                        if (res && res.message) {
                            this.setIsLoading(false);
                            this.toggleAddingEmployee(false);
                            this.setShowMessage(true);
                            this.emptyInputFields();
                            this.setMessage(`<div>${res.message}</div>`);
                            this.updateItems(this.state.selectedBranchId);
                        }
                    },
                    error => {
                        console.log('yeay')
                        this.setIsLoading(false);
                        this.toggleAddingEmployee(false);
                        this.setShowMessage(true);

                        if (!error) {
                            this.setMessage(`<div>${t('forms.unknownError')}</div>`);
                        }

                        if (error.message) {
                            this.setMessage(`<div>${error.message}</div>`)
                        }

                        if (error.data) {
                            this.setMessage(`<div>${Object.values(error.data).map(error => `<div>${error}</div>`)}</div>`);
                        }

                        if (error.data.data) {
                            this.setMessage(`<div>${Object.values(error.data.data).map(error => `<div>${error}</div>`)}</div>`);
                        }
                    }
                )
        }

    }

    deleteManager = (branchId) => {
        this.setIsLoading(true);
        this.branchService.deleteBranchManager(branchId)
            .then(
                res => {
                    this.setShowPopup(false);
                    this.setIsLoading(false);
                    this.setShowMessage(true);
                    this.setMessage(`<div>${res.message}</div>`);
                    this.updateItems(branchId);
                },
                error => {
                    this.setShowPopup(false);
                    this.setIsLoading(false);
                    this.setShowMessage(true);

                    if (!error) {
                        this.setMessage(`<div>${t('forms.unknownError')}</div>`);
                    }

                    if (error.message) {
                        this.setMessage(`<div>${error.message}</div>`)
                    }

                    if (error.data) {
                        this.setMessage(`<div>${Object.values(error.data).map(error => `<div>${error}</div>`)}</div>`);
                    }
                }
            )
    }

    deleteEmployee = (branchId, employeeId) => {
        this.setIsLoading(true);
        this.branchService.deleteBranchEmployee(branchId, employeeId)
            .then(
                res => {
                    this.setShowPopup(false);
                    this.setIsLoading(false);
                    this.setShowMessage(true);
                    this.setMessage(`<div>${res.message}</div>`);
                    this.updateItems(branchId);
                },
                error => {
                    this.setShowPopup(false);
                    this.setIsLoading(false);
                    this.setShowMessage(true);

                    if (!error) {
                        this.setMessage(`<div>${t('forms.unknownError')}</div>`);
                    }

                    if (error.message) {
                        this.setMessage(`<div>${error.message}</div>`)
                    }

                    if (error.data) {
                        this.setMessage(`<div>${Object.values(error.data).map(error => `<div>${error}</div>`)}</div>`);
                    }
                }
            )
    }

    getManagerListStructure = (item) => {

        const { firstname, lastname, email } = item;

        const textStyle = {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        };

        const managerMarkdown = <b style={textStyle}>{firstname} {lastname}</b>;
        const emailMarkdown = <b style={textStyle}>{email}</b>;

        return [
            { markdown: managerMarkdown, isHTML: false },
            { markdown: emailMarkdown, isHTML: false },
            {
                markdown: <DeleteIcon onClick={() => {
                    this.setState({
                        ...this.state,
                        showPopup: true,
                        deletePerson: () => this.deleteManager(this.state.selectedBranchId)
                    })
                }} />,
                isHTML: false
            },
        ];
    }

    getEmployeeListStructure = (item) => {
        const { firstname, lastname, email, branch_id, id, employee_id } = item;

        const textStyle = {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        };

        const employeeMarkdown = <b style={textStyle}>{firstname} {lastname}</b>;
        const emailMarkdown = <b style={textStyle}>{email}</b>;

        return [
            { markdown: employeeMarkdown, isHTML: false },
            { markdown: emailMarkdown, isHTML: false },
            {
                markdown: <DeleteIcon onClick={() => {
                    this.setShowPopup(true);
                    this.setState({
                        ...this.state,
                        showPopup: true,
                        deletePerson: () => this.deleteEmployee(branch_id, employee_id)
                    })
                }} />,
                isHTML: false
            },
        ];
    }

    toggleAddingEmployee = () => {
        this.setState({
            addingEmployee: !this.state.addingEmployee
        });
    }

    addEmployeeForm = () => {
        const { firstName, lastName, email, employeeSelection, selectedBranchId } = this.state;
        const { t } = this.props;

        return <div>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{t('branch.addPersonell')}</InfoMessage>

            <form onSubmit={(event) => {
                event.preventDefault();
                this.addStaff(firstName, lastName, email, selectedBranchId, employeeSelection);
            }}>
                <div style={{ display: 'flex', margin: '0 -5px' }}>
                    <InputSection style={{ flex: 1, margin: '0 5px' }}>
                        <StyledLabel>{t('forms.firstName')}</StyledLabel>
                        <input value={firstName} onChange={(event) => this.setFirstName(event.target.value)} />
                    </InputSection>

                    <InputSection style={{ flex: 1, margin: '0 5px' }}>
                        <StyledLabel>{t('forms.lastName')}</StyledLabel>
                        <input value={lastName} onChange={(event) => this.setLastName(event.target.value)} />
                    </InputSection>
                </div>

                <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                    <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.email')}</StyledLabel>
                        <input type="email" value={email} onChange={(event) => this.setEmail(event.target.value)} />
                    </InputSection>
                </div>

                {
                    this.role === 'chef' &&
                    <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                        <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                            <StyledLabel>{t('forms.function')}</StyledLabel>
                            <StyledSelect
                                style={{ width: '100%' }}
                                value={employeeSelection}
                                onChange={(event) => this.setEmployeeSelection(event.target.value)}>
                                <option value={'createManager'}>{t('forms.isManager')}</option>
                                <option value={'createEmployee'}>{t('forms.isEmployee')}</option>
                            </StyledSelect>
                        </InputSection>
                    </div>
                }

                <StyledSubmit primary
                    type="submit"
                    disabled={!firstName || !lastName || !email || !selectedBranchId || !employeeSelection}
                    style={{ width: 200, marginTop: 25, float: 'right' }}
                    value={t('branch.addPerson')} />
            </form>
        </div>;
    }

    render() {
        const { employeeList, managerList, message, showMessage, isLoading, addingEmployee, showPopup, deletePerson } = this.state;
        const { t } = this.props;

        return <>

            <BranchDropdown updateItems={this.updateItems}
                setSelectedBranch={this.setSelectedBranch} />

            <SectionHeading title={t('branch.addNewPersonell')}
                description={t('branch.addNewPersonellDescription')} />

            <ListElementWrapper itemsAutoHeight>
                <ListElement itemsAutoHeight>
                    <InputSection style={{ flex: '1 1 0', margin: '0 5px', width: '100%' }}>
                        <StyledLabel>{t('branch.addPerson')}</StyledLabel>
                        {
                            addingEmployee
                                ? this.addEmployeeForm()
                                : <InfoMessage>{t('branch.addNewPersonellAlt')}</InfoMessage>
                        }
                    </InputSection>
                </ListElement>
                <ListElement itemsAutoHeight>
                    <TextButton
                        onClick={this.toggleAddingEmployee}>{addingEmployee ? t('button.abort') : t('button.add')}</TextButton>
                </ListElement>
            </ListElementWrapper>

            <SectionHeading title={t('branch.managePersonell')}
                description={t('branch.managePersonellDescription')} />

            {
                this.role === 'chef' &&
                <>
                    <SecondarySubHeading style={{ marginTop: 30 }}>{t('forms.isManager')}</SecondarySubHeading>
                    <List items={managerList}
                        noItemsMessage={t('branch.addManager')}
                        headings={[t('forms.name'), t('forms.email'), '']}
                        getListItemStructure={this.getManagerListStructure}
                        style={{ marginTop: '30px' }} />

                    <SecondarySubHeading style={{ marginTop: 50 }}>{t('forms.isEmployee')}</SecondarySubHeading>
                </>
            }

            <List items={employeeList}
                noItemsMessage={t('branch.addPersonAlt')}
                headings={[t('forms.name'), t('forms.email'), '']}
                getListItemStructure={this.getEmployeeListStructure}
                style={{ marginTop: '30px' }} />

            {
                isLoading &&
                <Loader />
            }

            {
                showMessage && message &&
                <PopupMessage
                    message={message}
                    setShowMessage={this.setShowMessage}
                />
            }

            {
                showPopup &&
                <Popup
                    title={t('branch.removePersonTitle')}
                    hidePopup={() => this.setShowPopup(false)}
                    size={'small'}>
                    <InfoMessage
                        style={{ marginBottom: 25 }}>{t('branch.removePersonDescription')}</InfoMessage>
                    <StyledButton primary
                        style={{ width: '100%', marginTop: 20 }}
                        onClick={() => {
                            deletePerson();
                        }}
                    >{t('popup.delete')}
                    </StyledButton>
                    <StyledButton
                        tertiary
                        style={{ width: '100%', marginTop: 10 }}
                        onClick={() => {
                            this.setShowPopup(false);
                        }}
                    >{t('popup.abort')}</StyledButton>
                </Popup>
            }
        </>
    }
}

export default withTranslation()(EmployeeAccessWidget);
