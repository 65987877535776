import axios from 'axios'

class CoursesService {
    constructor(options = {}) {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async getAllCourses() {
        try {
            const response = await axios.get(
                `${this.apiBase}/courses`,
                this.config
            )

            const input = response.data.data,
                keys = ['id'],
                uniqueCourses = input.filter(
                    (
                        (s) => (o) =>
                            ((k) => !s.has(k) && s.add(k))(
                                keys.map((k) => o[k]).join('|')
                            )
                    )(new Set())
                )
            return uniqueCourses
        } catch (error) {
            return error
        }
    }

    async getMyCourses() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/courses`,
                this.config
            )

            const input = response.data.data,
                keys = ['id'],
                uniqueCourses = input.filter(
                    (
                        (s) => (o) =>
                            ((k) => !s.has(k) && s.add(k))(
                                keys.map((k) => o[k]).join('|')
                            )
                    )(new Set())
                )
            return uniqueCourses
        } catch (error) {
            return error
        }
    }

    async addToMyCourses(courseId) {
        try {
            const response = await axios.post(
                `${this.apiBase}/me/courses/add`,
                {
                    courses: [courseId],
                },
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async removeFromMyCourses(courseId) {
        try {
            const response = await axios.post(
                `${this.apiBase}/me/courses/remove`,
                {
                    courses: [courseId],
                },
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    updateCourseSalary = async (courseId, salary) => {
        try {
            const response = await axios.post(
                `${this.apiBase}/me/courses/${courseId}/salary`,
                {
                    salary: salary,
                },
                this.config
            )

            return response.data
        } catch (error) {
            return error.response.data
        }
    }
}

export default CoursesService
