import React, {ChangeEvent, useState} from 'react'
import {
    InputSection,
    StyledDatePicker,
    StyledLabel,
    StyledPrimaryButton,
    StyledSelect,
    StyledTertiaryButton
} from "./styled-components";
import Popup from "./popup";
import {getI18n, useTranslation} from "react-i18next";
import styled from "styled-components";
import {MdInfoOutline} from "react-icons/md";
import AvailabilityService from "../../services/availability-service";
import dateFormat from "dateformat";
import AnimateHeight from "react-animate-height";
import {COLORS, RADIUS, SPACING} from "../../assets/constants";

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const Checkbox = styled.div`
        display: block;
        width: 100%;
        font-size: 0.7rem;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: ${COLORS.primaryFaded};
        margin-bottom: ${SPACING.medium}px;

        input {
            width: 100%;
            padding: ${SPACING.small}px;
            border-width: 1px;
            border-color: ${COLORS.faded};
            border-style: solid;
            border-radius: ${RADIUS.tiny}px;

            &[type="checkbox"] {
                display: none;

                & + label {
                    cursor: pointer;
                }

                & + label:before {
                    width: 15px;
                    height: 15px;
                    background-color: #e9e9e9;
                    display: block;
                    content: "";
                    float: left;
                    margin-right: 12px;
                    cursor: pointer;
                }

                &:checked + label:before {
                    background-color: ${COLORS.primary};
                }
            }
        }
    }
`;

type NewAvailabilityPopupProps = {
    datetime: string
    hidePopup: () => void
    reloadData: () => void
    showMessage: () => void
    addMessage: (message: string) => void
}

const NewAvailabilityPopup = ({
                                  datetime,
                                  hidePopup,
                                  reloadData,
                                  showMessage,
                                  addMessage
                              }: NewAvailabilityPopupProps) => {
    const {t} = useTranslation();
    const language = getI18n().language

    const getFormat = (period: string) => {
        if (language === 'de') {
            if (period === 'time') {
                return 'HH:mm'
            } else {
                return 'd. MMMM yyyy'
            }
        } else {
            if (period === 'time') {
                return 'hh:mm aa'
            } else {
                return 'MM/dd/yyyy'
            }
        }
    }

    const getMaxDate = () => {
        let current = new Date();
        const addYear = current.getMonth()+6 > 12 ? 1 : 0
        let timeInt = new Date().setMonth((current.getMonth()+6) % 12)

        current = new Date(timeInt)
        timeInt = current.setFullYear(current.getFullYear() + addYear);

        return new Date(timeInt)
    }

    const maxDate = getMaxDate();
    const isWeekend = [0, 6].includes(new Date(datetime).getDay())

    const [date, setDate] = useState<Date>(new Date(datetime))
    const [until, setUntil] = useState<Date>(new Date(datetime))
    const [start, setStart] = useState<Date>(new Date(datetime + 'T10:00:00'))
    const [end, setEnd] = useState<Date>(new Date(datetime + 'T18:00:00'))
    const [availability, setAvailability] = useState<string>('high')
    const [interval, setInterval] = useState<string>('1')
    const [overnight, setOvernight] = useState<Boolean>(false)
    const [repeatable, setRepeatable] = useState<any>(0)
    const availabilityService = new AvailabilityService();

    const handleDateChange = (newDate: Date) => {
        setDate(newDate)
        if (newDate.getDate() > until.getDate()) {
            setUntil(newDate)
        }
    }

    const handleUntilChange = (newDate: Date) => {
        setUntil(newDate)
    }

    const handleStartTimeChange = (newStart: Date) => {
        setStart(newStart)

        const testStart = new Date('2022-10-10 ' + dateFormat(newStart, 'HH:MM'))
        const testEnd = new Date('2022-10-10 ' + dateFormat(end, 'HH:MM'))

        setOvernight(testStart.getTime() > testEnd.getTime())
    }

    const handleEndTimeChange = (newEnd: Date) => {
        setEnd(newEnd)

        const testStart = new Date('2022-10-10 ' + dateFormat(start, 'HH:MM'))
        const testEnd = new Date('2022-10-10 ' + dateFormat(newEnd, 'HH:MM'))

        setOvernight(testStart.getTime() > testEnd.getTime())
    }

    const handleAvailabilityChange = (event: { target: HTMLSelectElement; }) => {
        setAvailability(event.target.value)
    }

    const handleIntervalChange = (event: { target: HTMLSelectElement; }) => {
        setInterval(event.target.value)
    }

    const handleRepeatableChange = (event: { target: any; }) => {
        if (event.target.checked) {
            setRepeatable('auto')
        } else {
            setRepeatable(0)
        }
    }

    const saveEntry = () => {
        const timeDiff = (end.getTime() - start.getTime()) / 3600000;

        if (Math.abs(timeDiff) < 0.9 && end.getTime() >= start.getTime()) {
            alert(t('popup.addEntryWarning'));
        } else {
            availabilityService.addAvailabilities(
                dateFormat(date, 'yyyy-mm-dd'),
                dateFormat(until, 'yyyy-mm-dd'),
                dateFormat(start, 'HH:MM'),
                dateFormat(end, 'HH:MM'),
                interval,
                availability,
                overnight,
                'single'
            ).then((res: any) => {
                if (res && !res.success && res.message) {
                    showMessage();
                    addMessage(`<div>${res.message}</div>`);
                }
                hidePopup();
                reloadData();
            });
        }
    }

    return <>
        <Popup title={t('popup.newEntry')} hidePopup={hidePopup}>
            <InputSection>
                <StyledLabel>{t('forms.date')}</StyledLabel>
                <StyledDatePicker
                    // style={{ width: '100%' }}
                    selected={date}
                    onChange={(date: Date) => handleDateChange(date)}
                    locale={language}
                    dateFormat={getFormat('date')}
                    minDate={new Date()}
                    disabled={true}
                />
            </InputSection>
            <div style={{display: 'flex', marginTop: 15}}>
                <InputSection
                    style={{flex: '1 1 0', marginRight: 5, width: 'calc(50% - 5px)'}}
                >
                    <StyledLabel>{t('forms.from')}</StyledLabel>
                    <StyledDatePicker
                        className={'time-picker'}
                        selected={start}
                        onChange={(startTime: Date) => handleStartTimeChange(startTime)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        locale={language}
                        dateFormat={getFormat('time')}
                    />
                </InputSection>
                <InputSection
                    style={{flex: '1 1 0', marginLeft: 5, width: 'calc(50% - 5px)'}}
                >
                    <StyledLabel>{t('forms.to')}</StyledLabel>
                    <StyledDatePicker
                        className={'time-picker'}
                        selected={end}
                        onChange={(endTime: Date) => handleEndTimeChange(endTime)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        locale={language}
                        dateFormat={getFormat('time')}
                    />
                </InputSection>
            </div>
            {overnight && (
                <p style={{fontSize: '0.8em', marginTop: 10}}>
                    <InfoIcon/>
                    {t('forms.overnight')}
                </p>
            )}

            <InputSection style={{marginTop: 15}}>
                <StyledLabel>{t('forms.availability')}</StyledLabel>
                <StyledSelect
                    style={{width: '100%'}}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                        handleAvailabilityChange(event)
                    }
                    defaultValue={availability}
                >
                    <option value={'high'}>{t('forms.high')}</option>
                    <option value={'medium'}>{t('forms.medium')}</option>
                    <option value={'onrequest'}>{t('forms.onrequest')}</option>
                </StyledSelect>
            </InputSection>
            <Checkbox
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: SPACING.large,
                    justifyContent: 'center',
                }}
            >
                <input
                    id="repeatable"
                    type="checkbox"
                    onChange={(event) =>
                        handleRepeatableChange(event)
                    }
                />
                <label htmlFor="repeatable">
                    {t('forms.repeatQuestion')}
                </label>
            </Checkbox>
            <AnimateHeight height={repeatable} duration={200}>
                <InputSection style={{marginTop: 15}}>
                    <StyledLabel>{t('forms.repeat')}</StyledLabel>
                    <StyledDatePicker
                        // style={{ width: '100%' }}
                        selected={until}
                        onChange={(date: Date) => handleUntilChange(date)}
                        locale={language}
                        dateFormat={getFormat('date')}
                        minDate={date}
                        maxDate={maxDate}
                    />
                </InputSection>
                <InputSection style={{marginTop: 15}}>
                    <StyledLabel>{t('forms.repeatInterval')}</StyledLabel>
                    <StyledSelect
                        style={{ width: '100%' }}
                        value={interval}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleIntervalChange(event)
                        }
                    >
                        <option value={'1'}>
                            {t('forms.daily')}
                        </option>
                        {isWeekend && (
                            <option value={'weekend'}>
                                {t('forms.weekends')}
                            </option>
                        )}
                        {!isWeekend && (
                            <option value={'weekdays'}>
                                {t('forms.weekdays')}
                            </option>
                        )}
                        <option value={'7'}>
                            {t('forms.everyWeek')}
                        </option>
                        <option value={'14'}>
                            {t('forms.every2Weeks')}
                        </option>
                        <option value={'21'}>
                            {t('forms.every3Weeks')}
                        </option>
                    </StyledSelect>
                </InputSection>
            </AnimateHeight>
            <StyledPrimaryButton
                style={{width: '100%', marginTop: 40}}
                onClick={() => saveEntry()}>
                {t('forms.addEntry')}
            </StyledPrimaryButton>
            <StyledTertiaryButton
                style={{width: '100%', marginTop: 15}}
                onClick={hidePopup}>
                {t('popup.abort')}
            </StyledTertiaryButton>
        </Popup>
    </>


}

export default NewAvailabilityPopup;
