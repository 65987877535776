import React, { Component } from 'react';
import SectionHeading from "../section-heading";
import styled from "styled-components";
import {
    FormValue,
    InputSection, StyledButton, StyledLabel,
    StyledWrapper, SubTitle, TextButton
} from "../styled-components";
import List from "../list";
import ReactPasswordStrength from "react-password-strength";
import '../../assets/less/strong-password.scss';
import Loader from "../loader";
import { MdInfoOutline } from "react-icons/md";
import "react-toggle/style.css";
import "../../assets/less/react-toggle.scss";
import Popup from "../popup";
import GoogleMapReact from "google-map-react";
import { MdEmail, MdPhone } from "react-icons/md";
import { GOOGLE_API_KEY } from "../../assets/constants";
import { withTranslation } from "react-i18next";
import AccountService from '../../../services/account-service';
import BranchService from '../../../services/branch-service';

const InfoMessage = styled.p`
    font-size: 0.9em;
    font-weight: normal;
`;

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`;

class EmployeePersonalDataWidget extends Component {

    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 13
    };

    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            isLoading: false,
            passLength: 5,
            passwordIsValid: false,
            oldPassword: '',
            password: '',
            passwordConfirm: '',
            changePasswordError: undefined,
            errorText: undefined,
            deletePopup: false,
            deletePopupValue: '',
            branch: undefined,
            personalData: {
                name: {
                    type: 'personalData', key: 'name',
                    label: t('forms.name'),
                    buttonLabel: t('button.edit'),
                    description: '',
                    data: undefined,
                    value: '',
                    editForm: this.editNameForm,
                    editing: false,
                },
            },
            accountData: {
                email: {
                    type: 'accountData', key: 'email',
                    label: t('forms.email'),
                    buttonLabel: t('button.edit'),
                    description: '',
                    data: undefined,
                    value: '',
                    editForm: this.editSingleTextForm,
                    editing: false,
                },
                password: {
                    type: 'accountData', key: 'password',
                    label: t('forms.password'),
                    buttonLabel: t('button.update'),
                    description: '',
                    value: <InfoMessage>{t('forms.passwordDescription')}</InfoMessage>,
                    editForm: this.editPasswordForm,
                    editing: false,
                },
            },
        };

        this.accountService = new AccountService();
        this.branchService = new BranchService();
    }

    componentDidMount() {
        this.updateData();
    }

    updateData = () => {
        const { t, updateUser } = this.props;

        this.accountService.getMe().then(res => {
            if (res && res.data) {
                updateUser(res.data);
                const name = res.data.firstname && res.data.lastname
                    ? <FormValue>{res.data.firstname} {res.data.lastname}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const email = res.data.email
                    ? <FormValue>{res.data.email}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;


                this.setState({
                    ...this.state,
                    paused: res.data.paused !== 0,
                    personalData: {
                        ...this.state.personalData,
                        name: {
                            ...this.state.personalData.name,
                            value: name,
                            data: {
                                firstname: res.data.firstname,
                                lastname: res.data.lastname,
                            },
                        },
                    },
                    accountData: {
                        ...this.state.accountData,
                        email: {
                            ...this.state.accountData.email,
                            value: email,
                            data: {
                                email: res.data.email,
                            },
                        },
                    },
                });
            }
        });
        this.branchService.getAllBranches().then(res => {
            if (res) {
                this.setState({ branch: res });
            }
        });
    }

    getListStructure = (item) => {
        const { type, key, label, value, buttonLabel, buttonCancelLabel, editForm, editing } = item;
        const { t } = this.props;

        const fieldMarkdown = <div style={{ width: '100%' }}>
            <StyledLabel>{label}</StyledLabel>
            {
                editing
                    ? editForm(type, key, key)
                    : (value ? value : <InfoMessage>{t('forms.notSet')}</InfoMessage>)
            }

        </div>;

        return [
            { markdown: fieldMarkdown, isHTML: false },
            {
                markdown: buttonLabel
                    ? <TextButton
                        onClick={() => this.toggleEditForm(type, key)}>{editing ? buttonCancelLabel ? buttonCancelLabel : t('button.abort') : buttonLabel}</TextButton>
                    : '',
                isHTML: false
            },
        ];
    }

    setIsLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    toggleEditForm = (type, key) => {
        this.setState({
            ...this.state,
            oldPassword: '',
            password: '',
            passwordConfirm: '',
            changePasswordError: undefined,
            errorText: undefined,
            [type]: {
                ...this.state[type],
                [key]: {
                    ...this.state[type][key],
                    editing: !this.state[type][key].editing,
                },
            }
        });
    }

    changeValue = (type, key, field, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                [key]: {
                    ...this.state[type][key],
                    data: {
                        ...this.state[type][key].data,
                        [field]: value,
                    },
                },
            }
        });
    }

    updateValue = (type, key, data) => {
        this.setIsLoading(true);

        this.accountService.updateUserData(data).then(
            res => {
                if (res && !res.success) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        changePasswordError: res,
                    });

                    if (res.data) {
                        this.setState({
                            ...this.state,
                            errorText: Object.values(res.data).map(error => `${error}`),
                        });
                    }

                    return;
                }

                this.setIsLoading(false);
                this.updateData();
                this.setState({
                    [type]: {
                        ...this.state[type],
                        [key]: {
                            ...this.state[type][key],
                            editing: false,
                        },
                    }
                });
            },
            err => {
                if (err && err.data && !err.data.success) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                    });

                    if (err.data.data) {
                        this.setState({
                            ...this.state,
                            errorText: Object.values(err.data.data).map(error => `${error}`),
                        });
                    }
                }
            }
        );
    }

    updatePassword = () => {
        const { oldPassword, password, passwordConfirm } = this.state;

        this.setIsLoading(true);
        this.accountService.changePassword(oldPassword, password, passwordConfirm).then(
            res => {
                if (res && !res.success) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        changePasswordError: res,
                    });

                    return;
                }

                this.setIsLoading(false);
                this.updateData();
                this.setState({
                    oldPassword: '',
                    password: '',
                    passwordConfirm: '',
                    changePasswordError: undefined,
                    accountData: {
                        ...this.state.accountData,
                        password: {
                            ...this.state.accountData.password,
                            editing: false,
                        }
                    }
                });
            });
    }

    editNameForm = () => {
        const { firstname, lastname } = this.state.personalData.name.data;
        const { t } = this.props;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{t('forms.nameDescriptionAlt')}</InfoMessage>
            <div style={{ display: 'flex', margin: '0 -5px' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.firstName')}</StyledLabel>
                    <input value={firstname} type={'text'}
                        onChange={(event) => this.changeValue('personalData', 'name', 'firstname', event.target.value)} />
                </InputSection>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.lastName')}</StyledLabel>
                    <input value={lastname} type={'text'}
                        onChange={(event) => this.changeValue('personalData', 'name', 'lastname', event.target.value)} />
                </InputSection>
            </div>
            <StyledButton primary
                style={{ width: 225, marginTop: 25, float: 'right' }}
                onClick={() => this.updateValue('personalData', 'name', {
                    firstname: firstname,
                    lastname: lastname,
                })}>{t('forms.name')} {t('button.change')}</StyledButton>
        </>;
    }

    editSingleTextForm = (type, key, field) => {
        const { t } = this.props;
        const { errorText } = this.state;
        const value = this.state[type][key].data[field];
        const label = this.state[type][key].label;
        const description = this.state[type][key].description;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{description}</InfoMessage>
            <div style={{ display: 'flex', margin: '0 -5px' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    {label !== t('forms.email') && <StyledLabel>{label}</StyledLabel>}
                    <input value={value} type={'text'}
                        onChange={(event) => this.changeValue(type, key, field, event.target.value)} />
                </InputSection>
            </div>

            {
                errorText &&
                <p style={{ fontFamily: 'Montserrat', fontSize: '0.8em', marginTop: 10 }}>
                    <InfoIcon />{errorText}</p>
            }

            <StyledButton primary
                style={{ width: 275, marginTop: 25, float: 'right' }}
                onClick={() => this.updateValue(type, key, {
                    [field]: value,
                })}>{label} {t('button.change')}</StyledButton>
        </>;
    }

    editPasswordForm = () => {
        const { oldPassword, password, passwordConfirm, passwordIsValid, changePasswordError } = this.state;
        const { t } = this.props;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{t('forms.passwordDescriptionAlt')}</InfoMessage>
            <InputSection>
                <StyledLabel>{t('forms.oldPassword')}</StyledLabel>
                <input type={'password'}
                    value={oldPassword}
                    onChange={(event) => this.setState({ oldPassword: event.target.value })} />
            </InputSection>
            <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.newPassword')}</StyledLabel>
                    <ReactPasswordStrength
                        ref={ref => this.ReactPasswordStrength = ref}
                        minLength={8}
                        inputProps={{ id: 'password' }}
                        changeCallback={this.setPassword}
                        scoreWords={[t('forms.passwordWeak'), t('forms.passwordMedium'), t('forms.passwordGood'), t('forms.passwordStrong'), t('forms.passwordVeryStrong')]}
                        tooShortWord={t('forms.passwordTooShort')}
                    />
                </InputSection>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.passwordConfirm')}</StyledLabel>
                    <ReactPasswordStrength
                        ref={ref => this.ReactPasswordStrength = ref}
                        minLength={8}
                        inputProps={{ id: 'password' }}
                        changeCallback={this.setPasswordConfirm}
                        scoreWords={[t('forms.passwordWeak'), t('forms.passwordMedium'), t('forms.passwordGood'), t('forms.passwordStrong'), t('forms.passwordVeryStrong')]}
                        tooShortWord={t('forms.passwordTooShort')}
                    />
                </InputSection>
            </div>

            {
                password && passwordConfirm && passwordIsValid && (password !== passwordConfirm) &&
                <p style={{ fontFamily: 'Montserrat', fontSize: '0.8em', marginTop: 10 }}>
                    <InfoIcon />{t('forms.passwordMismatch')}</p>
            }

            {
                changePasswordError &&
                <p style={{ fontFamily: 'Montserrat', fontSize: '0.8em', marginTop: 10 }}>
                    <InfoIcon />{changePasswordError.message}</p>
            }

            <StyledButton primary style={{ width: 225, marginTop: 25, float: 'right' }}
                onClick={() => this.updatePassword(oldPassword, password, passwordConfirm)}
                disabled={!(password && passwordConfirm && passwordIsValid && (password === passwordConfirm))}>{t('forms.password')} {t('button.change')}</StyledButton>
        </>;
    }

    setPassword = input => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            password: input.password,
            passwordIsValid: input.isValid,
        })
    };

    setPasswordConfirm = input => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            passwordConfirm: input.password,
        })
    };

    clear = () => this.ReactPasswordStrength.clear();

    setDeletePopup = (show) => {
        this.setState({ deletePopup: show, deletePopupValue: '' });
    }

    changeDeletePopupValue = (value) => {
        this.setState({ deletePopupValue: value });
    }

    deleteUserAccount = () => {
        const { logout } = this.props;

        this.setIsLoading(true);
        this.accountService.deleteAccount().then(res => {
            this.setIsLoading(false);
            logout();
        });
    }

    mapsIsLoaded = (map, maps, branch) => {
        const geocoder = new maps.Geocoder();
        geocoder.geocode(
            {
                'address': `${branch.street} ${branch.housenumber} ${branch.zipcode} ${branch.city}`
            },
            (results, status) => {
                if (status === 'OK') {
                    map.setCenter(results[0].geometry.location);
                    const marker = new maps.Marker({
                        map: map,
                        position: results[0].geometry.location
                    });
                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            }
        );
    }

    render() {
        const { personalData, accountData, isLoading, deletePopup, deletePopupValue, branch } = this.state;
        const { t } = this.props;

        return <>
            <SectionHeading title={t('personalData.account')}
                description={t('personalData.accountDescriptionAlt')} />
            <List items={Object.values(accountData)}
                itemsAutoHeight
                getListItemStructure={this.getListStructure} />

            <SectionHeading title={t('personalData.contact')} description={t('personalData.contactDescriptionAlt')} />
            <List items={Object.values(personalData)}
                itemsAutoHeight
                getListItemStructure={this.getListStructure} />


            <SectionHeading title={t('branch.yourBranch')}
                description={t('branch.yourBranchDescription')} />
            {
                branch &&
                <StyledWrapper style={{ marginTop: 15 }}>
                    <SubTitle>{t('branch.branch')}</SubTitle>
                    <h2>
                        {branch.name}
                    </h2>

                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <div style={{ flex: '0.6 1 0' }}>
                            <div>
                                {branch.street} {branch.housenumber}<br />
                                {branch.adress_additional && <>{branch.adress_additional}<br /></>}
                                {branch.zipcode} {branch.city}<br />
                                {branch.state && <>{branch.state}, </>}{branch.country}
                            </div>
                        </div>
                        <div style={{ flex: '1 1 0' }}>
                            <SubTitle>{t('booking.contact')}</SubTitle>
                            <div>
                                <MdPhone style={{ marginBottom: -2, color: '#db64ff' }} /> <a
                                    href={`tel:${branch.phone}`}>{branch.phone}</a><br />
                                <MdEmail style={{ marginBottom: -2, color: '#db64ff' }} /> <a
                                    href={`mailto:${branch.email}`}>{branch.email}</a>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: 400, width: '100%', marginTop: 25 }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.mapsIsLoaded(map, maps, branch)} />
                    </div>
                </StyledWrapper>
            }

            <div style={{ textAlign: 'center', marginTop: 65 }}>
                <p style={{ fontSize: '0.9em', marginBottom: 5, color: '#666666' }}>{t('personalData.wantDelete')}</p>
                <TextButton onClick={() => this.setDeletePopup(true)}>{t('personalData.confirmDelete')}</TextButton>
            </div>

            {
                isLoading &&
                <Loader />
            }

            {
                deletePopup &&
                <Popup title={t('personalData.wantDeleteAlt')} hidePopup={() => this.setDeletePopup(false)}
                    size={'large'}>
                    <p>{t('personalData.notReversable')}</p>

                    <p>{t('personalData.areYouSure')}</p>

                    <InputSection style={{ marginTop: 25 }}>
                        <StyledLabel>{t('forms.name')}</StyledLabel>
                        <input value={deletePopupValue} type={'text'}
                            onChange={(e) => this.changeDeletePopupValue(e.target.value)} />
                    </InputSection>

                    <StyledButton primary
                        style={{ width: '100%', marginTop: 15, marginBottom: 15, backgroundColor: '#d01a1a' }}
                        disabled={personalData && (`${personalData.name.data.firstname} ${personalData.name.data.lastname}` !== deletePopupValue)}
                        onClick={() => this.deleteUserAccount()}>{t('personalData.delete')}</StyledButton>
                    <StyledButton tertiary style={{ width: '100%' }}
                        onClick={() => this.setDeletePopup(false)}>{t('popup.abort')}</StyledButton>
                </Popup>
            }
        </>;
    }
}

export default withTranslation()(EmployeePersonalDataWidget);
