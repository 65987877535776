import Box from '../../common/Box'
import logo from '../../assets/images/logo.png'
import registerGif from '../../assets/images/register.gif'
import RegistrationWidget from '../../legacy/components/widgets/registration-widget'
import styled from 'styled-components'
import { Button } from '../../common/ui-elements'
import { COLORS, DEVICE, SPACING } from '../../assets/constants'
import { StyledWrapper } from '../../legacy/components/styled-components'
import { useAuth } from '../../context/AuthContext'
import { useHistory, useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n/config'

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${SPACING.xlarge}px;
    flex-direction: column;

    @media ${DEVICE.phone} {
        padding: ${SPACING.small}px;
        padding-top: 55px;
        min-height: auto;
    }

    & > div {
        width: 100%;
    }
`

const RegisterWrapper = styled(StyledWrapper)`
    display: flex;
    padding: 0;
    max-width: 1050px;
`

const RegisterInfo = styled.div`
    display: flex;

    @media ${DEVICE.phone} {
        display: none;
    }
`

const Registration = () => {
    const { register } = useAuth()
    const history = useHistory()
    const { t } = useTranslation()
    const location = useLocation()
    const locale = new URLSearchParams(location.search).get('locale')

    useEffect(() => {
        if (!locale) return
        i18n.changeLanguage(locale === 'de' ? locale : 'en')
        localStorage.setItem('jg-lang', locale === 'de' ? locale : 'en')
    }, [])

    const [registered, setRegistered] = useState<boolean>(false)

    return (
        <Wrapper>
            <img
                src={logo}
                width={165}
                style={{ marginBottom: SPACING.large }}
            />

            {registered ? (
                <Box size="medium">
                    <h2 style={{ marginBottom: SPACING.small }}>
                        {t('registration.registerDone')}
                    </h2>
                    <p>{t('registration.registerDoneText')}</p>
                    <Button
                        style={{ marginTop: SPACING.xlarge }}
                        onClick={() => history.push(`/${t('routes.login')}`)}
                    >
                        {t('registration.goToLogin')}
                    </Button>
                </Box>
            ) : (
                <RegisterWrapper>
                    <RegistrationWidget
                        register={register}
                        setRegistered={setRegistered}
                        history={history}
                    />
                    <RegisterInfo
                        style={{
                            background: COLORS.primary,
                            width: '40%',
                            minWidth: 350,
                            maxWidth: 450,
                            color: 'white',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '0px 5px 5px 0px',
                        }}
                    >
                        <div
                            style={{
                                width: '70%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <img
                                src={registerGif}
                                width={100}
                                style={{ marginBottom: SPACING.xlarge }}
                            />
                            <h2>{t('registration.registerInfo')}</h2>
                            <ul>
                                <li>{t('registration.register1')}</li>
                                <li>{t('registration.register2')}</li>
                                <li>
                                    {t('registration.register3')} <br />{' '}
                                    &#10132;
                                    {t('registration.register4')}
                                </li>
                            </ul>
                        </div>
                    </RegisterInfo>
                </RegisterWrapper>
            )}
        </Wrapper>
    )
}

export default Registration
