import React, { Component } from 'react';
import SectionHeading from "../section-heading";
import styled from "styled-components";
import { FormValue, InputSection, StyledButton, StyledLabel, StyledSelect, TextButton, SaveButton, StyledSubmit } from "../styled-components";
import { DEVICE, PHONE_CODES } from "../../assets/constants";
import List from "../list";
import ReactPasswordStrength from "react-password-strength";
import '../../assets/less/strong-password.scss';
import Loader from "../loader";
import { MdInfoOutline } from "react-icons/md";
import Popup from "../popup";
import { withTranslation } from "react-i18next";
import AccountService from '../../../services/account-service';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const InfoMessage = styled.p`
    font-size: 0.9em;
    font-weight: normal;
`;

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`;

const StreetInput = styled(InputSection)`
    flex: 1 1 0;
    margin: 0 5px;
    
     @media ${DEVICE.phone} {
        flex: 1 1 100% !important;
    }
`;

const HouseNumberInput = styled(InputSection)`
    flex: 0.3 1 0;
    margin: 0 5px;

     @media ${DEVICE.phone} {
        flex: 1 1 20% !important;
        margin-top: 15px !important;
    }
`;

const StreetAdditionalInput = styled(InputSection)`
    flex: 1 1 0;
    margin: 0 5px;

     @media ${DEVICE.phone} {
        flex: 1 1 40% !important;
        margin-top: 15px !important;

    }
`;

class PersonalDataWidget extends Component {

    constructor(props) {
        super(props);
        const { t } = props;

        this.state = {
            countries: [],
            isLoading: false,
            passLength: 5,
            passwordIsValid: false,
            oldPassword: '',
            password: '',
            passwordConfirm: '',
            changePasswordError: undefined,
            errorText: undefined,
            deletePopup: false,
            deletePopupValue: '',
            personalData: {
                name: {
                    type: 'personalData', key: 'name',
                    label: t('forms.name'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.nameDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editNameForm,
                    editing: false,
                },
                phone: {
                    type: 'personalData', key: 'phone',
                    label: t('forms.phone'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.phoneDescriptionAlt'),
                    value: '',
                    editForm: this.editPhoneForm,
                    editing: false,
                },
            },
            accountData: {
                email: {
                    type: 'accountData', key: 'email',
                    label: t('forms.email'),
                    buttonLabel: '',
                    description: t('forms.emailDescription'),
                    data: undefined,
                    value: '',
                    editForm: undefined,
                    editing: false,
                },
                password: {
                    type: 'accountData', key: 'password',
                    label: t('forms.password'),
                    buttonLabel: t('button.update'),
                    description: '',
                    value: <InfoMessage>{t('forms.passwordDescription')}</InfoMessage>,
                    editForm: this.editPasswordForm,
                    editing: false,
                },
            },
            companyData: {
                name: {
                    type: 'companyData', key: 'name',
                    label: t('forms.company'),
                    buttonLabel: '',
                    description: '',
                    data: undefined,
                    value: '',
                    editForm: undefined,
                    editing: false,
                },
                chef: {
                    type: 'companyData', key: 'chef',
                    label: t('forms.chef'),
                    buttonLabel: '',
                    description: '',
                    data: undefined,
                    value: '',
                    editForm: undefined,
                    editing: false,
                },
                com_register: {
                    type: 'companyData', key: 'com_register',
                    label: t('forms.hrNo'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.hrNoDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editSingleTextForm,
                    editing: false,
                },
                email: {
                    type: 'companyData', key: 'email',
                    label: t('forms.invoiceEmail'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.invoiceEmailDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editSingleTextForm,
                    editing: false,
                },
                address: {
                    type: 'companyData', key: 'address',
                    label: t('forms.address'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.addressDescriptionAlt'),
                    data: undefined,
                    value: '',
                    editForm: this.editCompanyAddress,
                    editing: false,
                },
                phone: {
                    type: 'companyData', key: 'phone',
                    label: t('forms.phone'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.phoneDescriptionAlt'),
                    value: '',
                    editForm: this.editCompanyPhoneForm,
                    editing: false,
                },
                website: {
                    type: 'companyData', key: 'website',
                    label: t('forms.website'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.websiteDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editSingleTextForm,
                    editing: false,
                },
            }
        };

        this.accountService = new AccountService();
    }

    componentDidMount() {
        this.updatePersonalData();
        this.updateCompanyData();

        this.accountService.getCountries().then(res => {
            if (res && res.data) {
                this.setState({ ...this.state, countries: res.data });
            }
        });
    }

    updatePersonalData = () => {
        const { t, updateUser } = this.props;

        this.accountService.getMe().then(res => {
            if (res && res.data) {
                updateUser(res.data);
                const name = res.data.firstname && res.data.lastname
                    ? <FormValue>{res.data.firstname} {res.data.lastname}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const phone = res.data.phone
                    ? <FormValue>
                        {`${res.data.phone}`}
                    </FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const email = res.data.email
                    ? <FormValue>{res.data.email}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;

                this.setState({
                    ...this.state,
                    personalData: {
                        ...this.state.personalData,
                        name: {
                            ...this.state.personalData.name,
                            value: name,
                            data: {
                                firstname: res.data.firstname,
                                lastname: res.data.lastname,
                            },
                        },
                        phone: {
                            ...this.state.personalData.phone,
                            value: phone,
                            data: {
                                phone: res.data.phone
                            },
                        },
                    },
                    accountData: {
                        ...this.state.accountData,
                        email: {
                            ...this.state.accountData.email,
                            value: email,
                            data: {
                                email: res.data.email,
                            },
                        },
                    }
                });
            }
        });
    }

    updateCompanyData = () => {
        const { t } = this.props;

        this.accountService.getMyCompany().then(res => {
            if (res && res.data) {
                const name = res.data.name
                    ? <FormValue>{res.data.name}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const comRegister = res.data.com_register
                    ? <FormValue>{res.data.com_register}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const chef = res.data.chef_firstname && res.data.chef_lastname
                    ? <FormValue>{res.data.chef_firstname} {res.data.chef_lastname}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const email = res.data.email
                    ? <FormValue>{res.data.email}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const address = res.data.street && res.data.housenumber && res.data.zipcode && res.data.city && res.data.country
                    ?
                    <FormValue>
                        {res.data.street} {res.data.housenumber}, {res.data.adress_additional && res.data.adress_additional !== '-' && res.data.adress_additional + ', '} {res.data.zipcode} {res.data.city}, {res.data.country}
                    </FormValue>
                    : <InfoMessage>{t('forms.notComplete')}</InfoMessage>;
                const phone = res.data.phone
                    ? <FormValue>
                        {`${res.data.phone}`}
                    </FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;
                const website = res.data.website
                    ? <FormValue>{res.data.website}</FormValue>
                    : <InfoMessage>{t('forms.notSet')}</InfoMessage>;

                this.setState({
                    ...this.state,
                    companyData: {
                        ...this.state.companyData,
                        name: { ...this.state.companyData.name, value: name, data: { id: res.data.id } },
                        com_register: {
                            ...this.state.companyData.com_register,
                            value: comRegister,
                            data: { com_register: res.data.com_register }
                        },
                        chef: {
                            ...this.state.companyData.chef,
                            value: chef,
                            data: {
                                chef_firstname: res.data.chef_firstname,
                                chef_lastname: res.data.chef_lastname
                            }
                        },
                        email: {
                            ...this.state.companyData.email,
                            value: email,
                            data: { email: res.data.email }
                        },
                        address: {
                            ...this.state.companyData.address,
                            value: address,
                            data: {
                                street: res.data.street,
                                housenumber: res.data.housenumber,
                                zipcode: res.data.zipcode,
                                city: res.data.city,
                                state: res.data.state,
                                country: res.data.countrycode,
                                addressAdditional: res.data.adress_additional,
                            }
                        },
                        phone: {
                            ...this.state.companyData.phone,
                            value: phone,
                            data: {
                                phone: res.data.phone
                            },
                        },
                        website: {
                            ...this.state.companyData.website,
                            value: website,
                            data: { website: res.data.website }
                        },
                    },
                });
            }
        });
    }

    getListStructure = (item) => {
        const { t } = this.props;
        const { type, key, label, value, buttonLabel, editForm, editing } = item;

        const fieldMarkdown = <div style={{ width: '100%' }}>
            <StyledLabel>{label}</StyledLabel>
            {
                editing
                    ? editForm(type, key, key)
                    : (value ? value : <InfoMessage>{t('forms.notSet')}</InfoMessage>)
            }

        </div>;

        return [
            { markdown: fieldMarkdown, isHTML: false },
            {
                markdown: buttonLabel
                    ? <TextButton
                        onClick={() => this.toggleEditForm(type, key)}>{editing ? t('button.abort') : buttonLabel}</TextButton>
                    : '',
                isHTML: false
            },
        ];
    }

    setIsLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    toggleEditForm = (type, key) => {
        this.setState({
            ...this.state,
            oldPassword: '',
            password: '',
            passwordConfirm: '',
            changePasswordError: undefined,
            errorText: '',
            [type]: {
                ...this.state[type],
                [key]: {
                    ...this.state[type][key],
                    editing: !this.state[type][key].editing,
                },
            }
        });
    }

    changeValue = (type, key, field, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                [key]: {
                    ...this.state[type][key],
                    data: {
                        ...this.state[type][key].data,
                        [field]: value,
                    },
                },
            }
        });
    }

    updateValue = (type, key, data) => {
        this.setIsLoading(true);

        if (type === 'companyData') {
            this.accountService.updateCompanyData(this.state.companyData.name.data.id, data).then(
                res => {
                    this.setIsLoading(false);
                    this.updatePersonalData();
                    this.updateCompanyData();
                    this.setState({
                        [type]: {
                            ...this.state[type],
                            [key]: {
                                ...this.state[type][key],
                                editing: false,
                            },
                        }
                    });
                },
                err => {
                    if (err && !err.success) {
                        this.setState({
                            ...this.state,
                            isLoading: false,
                        });

                        if (err.data) {
                            this.setState({
                                ...this.state,
                                errorText: Object.values(err.data).map(error => `${error}`),
                            });
                        }
                    }
                }
            );
        } else {
            this.accountService.updateUserData(data).then(
                res => {
                    this.setIsLoading(false);
                    this.updatePersonalData();
                    this.updateCompanyData();
                    this.setState({
                        [type]: {
                            ...this.state[type],
                            [key]: {
                                ...this.state[type][key],
                                editing: false,
                            },
                        }
                    });
                },
                err => {
                    if (err && !err.success) {
                        this.setState({
                            ...this.state,
                            isLoading: false,
                        });

                        if (err.data) {
                            this.setState({
                                ...this.state,
                                errorText: Object.values(err.data).map(error => `${error}`),
                            });
                        }
                    }
                }
            );
        }
    }

    updatePassword = () => {
        const { oldPassword, password, passwordConfirm } = this.state;

        this.setIsLoading(true);
        this.accountService.changePassword(oldPassword, password, passwordConfirm).then(
            res => {
                if (res && !res.success) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        changePasswordError: res,
                    });

                    return;
                }

                this.setIsLoading(false);
                this.updatePersonalData();
                this.updateCompanyData();
                this.setState({
                    oldPassword: '',
                    password: '',
                    passwordConfirm: '',
                    changePasswordError: undefined,
                    accountData: {
                        ...this.state.accountData,
                        password: {
                            ...this.state.accountData.password,
                            editing: false,
                        }
                    }
                });
            }
        );
    }

    editNameForm = () => {
        const { t } = this.props;
        const { firstname, lastname } = this.state.personalData.name.data;
        const description = this.state.personalData.name.description;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{description}</InfoMessage>
            <div style={{ display: 'flex', margin: '0 -5px' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.firstName')}</StyledLabel>
                    <input value={firstname} type={'text'}
                        onChange={(event) => this.changeValue('personalData', 'name', 'firstname', event.target.value)} />
                </InputSection>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.lastName')}</StyledLabel>
                    <input value={lastname} type={'text'}
                        onChange={(event) => this.changeValue('personalData', 'name', 'lastname', event.target.value)} />
                </InputSection>
            </div>
            <SaveButton primary
                onClick={() => this.updateValue('personalData', 'name', {
                    firstname: firstname,
                    lastname: lastname,
                })}>{t('forms.name')} {t('button.change')}</SaveButton>
        </>;
    }

    changePhone = (type, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                phone: {
                    ...this.state[type].phone,
                    data: {
                        phone: value,
                    },
                },
            }
        });
    }

    editPhoneForm = () => {
        const { t } = this.props;
        const { countries } = this.state;
        const { phone } = this.state.personalData.phone.data;
        const description = this.state.personalData.phone.description;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{description}</InfoMessage>
            <div style={{ display: 'flex', margin: '0 -5px' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.phone')}</StyledLabel>
                    <PhoneInput
                        country={'us'}
                        preferredCountries={['us', 'ch', 'at', 'gb', 'de', 'au']}
                        countryCodeEditable={false}
                        enableSearch={true}
                        disableSearchIcon={true}
                        value={phone}
                        onChange={phone => {this.changePhone('personalData', phone)}}
                        inputClass={'phoneInput'}
                    />
                </InputSection>
            </div>
            <SaveButton primary
                onClick={() => this.updateValue('personalData', 'phone', { phone: '+'+phone })}>{t('forms.phone')} {t('button.change')}</SaveButton>
        </>;
    }

    editCompanyPhoneForm = () => {
        const { t } = this.props;
        const { countries } = this.state;
        const { phone } = this.state.companyData.phone.data;
        const description = this.state.companyData.phone.description;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{description}</InfoMessage>
            <div style={{ display: 'flex', margin: '0 -5px' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.phone')}</StyledLabel>
                    <PhoneInput
                        country={'us'}
                        preferredCountries={['us', 'ch', 'at', 'gb', 'de', 'au']}
                        countryCodeEditable={false}
                        enableSearch={true}
                        disableSearchIcon={true}
                        value={phone}
                        onChange={phone => {this.changePhone('companyData', phone)}}
                        inputClass={'phoneInput'}
                    />
                </InputSection>
            </div>
            <SaveButton primary
                onClick={() => this.updateValue('companyData', 'phone', { phone: '+'+phone })}>{t('forms.phone')} {t('button.change')}</SaveButton>
        </>;
    }

    editSingleTextForm = (type, key, field) => {
        const { t } = this.props;
        const { errorText } = this.state;
        const value = this.state[type][key].data[field];
        const label = this.state[type][key].label;
        const description = this.state[type][key].description;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{description}</InfoMessage>
            <div style={{ display: 'flex', margin: '0 -5px' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{label}</StyledLabel>
                    <input value={value} type={'text'}
                        onChange={(event) => this.changeValue(type, key, field, event.target.value)} />
                </InputSection>
            </div>

            {
                errorText &&
                <p style={{ fontFamily: 'Montserrat', fontSize: '0.8em', marginTop: 10 }}>
                    <InfoIcon />{errorText}</p>
            }

            <SaveButton primary
                onClick={() => this.updateValue(type, key, {
                    [field]: value,
                })}>{label} {t('button.change')}</SaveButton>
        </>;
    }

    editCompanyAddress = () => {
        const { t } = this.props;
        const { street, housenumber, zipcode, city, state, country, addressAdditional } = this.state.companyData.address.data;
        const description = this.state.companyData.address.description;
        const { countries } = this.state;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{description}</InfoMessage>
            <form onSubmit={event => {
                event.preventDefault();
                this.updateValue('companyData', 'address', {
                    street: street,
                    housenumber: housenumber,
                    city: city,
                    state: state,
                    country: country,
                    adress_additional: addressAdditional,
                });
            }}>
                <div style={{ display: 'flex', margin: '0 -5px', flexWrap: 'wrap' }}>
                    <StreetInput style={{ flex: '1 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.street')}</StyledLabel>
                        <input value={street} type={'text'}
                            required
                            onChange={(event) => this.changeValue('companyData', 'address', 'street', event.target.value)} />
                    </StreetInput>
                    <HouseNumberInput style={{ flex: '0.3 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.housenumber')}</StyledLabel>
                        <input value={housenumber} type={'text'}
                            required
                            onChange={(event) => this.changeValue('companyData', 'address', 'housenumber', event.target.value)} />
                    </HouseNumberInput>
                    <StreetAdditionalInput style={{ flex: '1 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.addressAdditional')}</StyledLabel>
                        <input value={addressAdditional} type={'text'}
                            onChange={(event) => this.changeValue('companyData', 'address', 'addressAdditional', event.target.value)} />
                    </StreetAdditionalInput>
                </div>
                <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                    <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.zipcode')}</StyledLabel>
                        <input value={zipcode} type={'text'}
                            required
                            onChange={(event) => this.changeValue('companyData', 'address', 'zipcode', event.target.value)} />
                    </InputSection>
                    <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.city')}</StyledLabel>
                        <input value={city} type={'text'}
                            required
                            onChange={(event) => this.changeValue('companyData', 'address', 'city', event.target.value)} />
                    </InputSection>
                </div>
                <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                    <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.state')}</StyledLabel>
                        <input value={state} type={'text'}
                            required
                            onChange={(event) => this.changeValue('companyData', 'address', 'state', event.target.value)} />
                    </InputSection>
                    <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                        <StyledLabel>{t('forms.country')}</StyledLabel>
                        <StyledSelect
                            style={{ width: '100%' }}
                            value={country}
                            onChange={(event) => this.changeValue('companyData', 'address', 'country', event.target.value)}>
                            {countries.map(country => <option value={country.locale}>{country.name}</option>)}
                        </StyledSelect>
                    </InputSection>
                </div>
                <StyledSubmit
                    style={{ float: 'right', marginTop: 25, paddingLeft: 15, paddingRight: 15 }}
                    type="submit"
                    primary
                    value={`${t('forms.address')} ${t('button.change')}`} />
            </form>
        </>;
    }

    editPasswordForm = () => {
        const { oldPassword, password, passwordConfirm, passwordIsValid, changePasswordError } = this.state;
        const { t } = this.props;

        return <>
            <InfoMessage style={{ marginTop: 25, marginBottom: 15 }}>{t('forms.passwordDescriptionAlt')}</InfoMessage>
            <InputSection>
                <StyledLabel>{t('forms.oldPassword')}</StyledLabel>
                <input type={'password'}
                    value={oldPassword}
                    onChange={(event) => this.setState({ oldPassword: event.target.value })} />
            </InputSection>
            <div style={{ display: 'flex', margin: '15px -5px 0' }}>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.newPassword')}</StyledLabel>
                    <ReactPasswordStrength
                        ref={ref => this.ReactPasswordStrength = ref}
                        minLength={8}
                        inputProps={{ id: 'password' }}
                        changeCallback={this.setPassword}
                        scoreWords={[t('forms.passwordWeak'), t('forms.passwordMedium'), t('forms.passwordGood'), t('forms.passwordStrong'), t('forms.passwordVeryStrong')]}
                        tooShortWord={t('forms.passwordTooShort')}
                    />
                </InputSection>
                <InputSection style={{ flex: '1 1 0', margin: '0 5px' }}>
                    <StyledLabel>{t('forms.passwordConfirm')}</StyledLabel>
                    <ReactPasswordStrength
                        ref={ref => this.ReactPasswordStrength = ref}
                        minLength={8}
                        inputProps={{ id: 'password' }}
                        changeCallback={this.setPasswordConfirm}
                        scoreWords={[t('forms.passwordWeak'), t('forms.passwordMedium'), t('forms.passwordGood'), t('forms.passwordStrong'), t('forms.passwordVeryStrong')]}
                        tooShortWord={t('forms.passwordTooShort')}
                    />
                </InputSection>
            </div>

            {
                password && passwordConfirm && passwordIsValid && (password !== passwordConfirm) &&
                <p style={{ fontFamily: 'Montserrat', fontSize: '0.8em', marginTop: 10 }}>
                    <InfoIcon />{t('forms.passwordMismatch')}</p>
            }

            {
                changePasswordError &&
                <p style={{ fontFamily: 'Montserrat', fontSize: '0.8em', marginTop: 10 }}>
                    <InfoIcon />{changePasswordError.message}</p>
            }

            <SaveButton primary
                onClick={() => this.updatePassword(oldPassword, password, passwordConfirm)}
                disabled={!(password && passwordConfirm && passwordIsValid && (password === passwordConfirm))}>{t('forms.password')} {t('button.change')}</SaveButton>
        </>;
    }

    setPassword = input => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            password: input.password,
            passwordIsValid: input.isValid,
        })
    };

    setPasswordConfirm = input => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            passwordConfirm: input.password,
        })
    };

    clear = () => this.ReactPasswordStrength.clear();

    setDeletePopup = (show) => {
        this.setState({ deletePopup: show, deletePopupValue: '' });
    }

    changeDeletePopupValue = (value) => {
        this.setState({ deletePopupValue: value });
    }

    deleteUserAccount = () => {
        const { logout } = this.props;

        this.setIsLoading(true);
        this.accountService.deleteAccount().then(res => {
            this.setIsLoading(false);
            logout();
        });
    }

    render() {
        const { personalData, accountData, companyData, isLoading, deletePopup, deletePopupValue } = this.state;
        const { t } = this.props;

        return <>
            <SectionHeading title={t('personalData.account')}
                description={t('personalData.accountDescriptionAltAlt')} />
            <List items={Object.values(accountData)}
                itemsAutoHeight
                getListItemStructure={this.getListStructure} />

            <SectionHeading title={t('personalData.data')} description={t('personalData.dataDescription')} />
            <List items={Object.values(personalData)}
                itemsAutoHeight
                getListItemStructure={this.getListStructure} />

            <SectionHeading title={t('personalData.companyData')}
                description={t('personalData.companyDataDescription')} />
            <List items={Object.values(companyData)}
                itemsAutoHeight
                getListItemStructure={this.getListStructure} />

            <div style={{ textAlign: 'center', marginTop: 65 }}>
                <p style={{ fontSize: '0.9em', marginBottom: 5, color: '#666666' }}>{t('personalData.wantDelete')}</p>
                <TextButton onClick={() => this.setDeletePopup(true)}>{t('personalData.confirmDelete')}</TextButton>
            </div>

            {
                isLoading &&
                <Loader />
            }

            {
                deletePopup &&
                <Popup title={t('personalData.wantDeleteAlt')} hidePopup={() => this.setDeletePopup(false)}
                    size={'large'}>
                    <p>{t('personalData.notReversable')}</p>

                    <p>{t('personalData.areYouSure')}</p>

                    <InputSection style={{ marginTop: 25 }}>
                        <StyledLabel>{t('forms.name')}</StyledLabel>
                        <input value={deletePopupValue} type={'text'}
                            onChange={(e) => this.changeDeletePopupValue(e.target.value)} />
                    </InputSection>

                    <StyledButton primary
                        style={{ width: '100%', marginTop: 15, marginBottom: 15, backgroundColor: '#d01a1a' }}
                        disabled={personalData && (`${personalData.name.data.firstname} ${personalData.name.data.lastname}` !== deletePopupValue)}
                        onClick={() => this.deleteUserAccount()}>{t('personalData.delete')}</StyledButton>
                    <StyledButton tertiary style={{ width: '100%' }}
                        onClick={() => this.setDeletePopup(false)}>{t('popup.abort')}</StyledButton>
                </Popup>
            }
        </>;
    }
}

export default withTranslation()(PersonalDataWidget);