import styled from 'styled-components'
import { SPACING } from '../../assets/constants'
import logo from '../../assets/images/logo.png'
import Box from '../../common/Box'
import { Form } from '../../common/ui-elements'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation } from 'react-router-dom'
import Loader from '../../legacy/components/loader'
import i18n from '../../i18n/config'
import { HOME_PAGE_EN, HOME_PAGE } from '../../assets/config'

const Background = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 572px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const LinkWrapper = styled.div`
    a {
        margin-top: ${SPACING.xsmall}px;
        font-size: 0.8rem;
    }
`

const Login = () => {
    const [email, setEmail] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [rememberme, setRememberme] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const { t } = useTranslation()
    const { login } = useAuth()

    const location = useLocation()
    const locale = new URLSearchParams(location.search).get('locale')

    useEffect(() => {
        if (!locale) return
        localStorage.setItem('jg-lang', locale === 'de' ? locale : 'en')
        i18n.changeLanguage(locale === 'de' ? locale : 'en')
    }, [])

    return (
        <Background>
            <img
                src={logo}
                width={165}
                style={{ marginBottom: SPACING.large, cursor: 'pointer' }}
                onClick={() => {
                    window.open(
                        locale === 'en' ? HOME_PAGE_EN : HOME_PAGE,
                        '_blank'
                    )
                    return null
                }}
            />

            <Box size="medium">
                <h1>{t('setup.welcomeAlt')}!</h1>

                {message && (
                    <p
                        style={{
                            fontSize: '0.8em',
                            marginTop: 10,
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {message}
                    </p>
                )}

                <Form
                    style={{ marginTop: SPACING.large }}
                    onSubmit={async (event) => {
                        event.preventDefault()
                        setMessage('')
                        setLoading(true)
                        try {
                            const response = await login(
                                email,
                                password,
                                rememberme
                            )
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                            setMessage(t('forms.loginFailed'))
                        }
                    }}
                >
                    <label>
                        <p>{t('forms.email')}</p>
                        <input
                            type="text"
                            style={{
                                fontSize: '1rem',
                                fontWeight: 300,
                            }}
                            onChange={(event) => setEmail(event.target.value)}
                            required
                        />
                    </label>
                    <label>
                        <p>{t('forms.password')}</p>
                        <input
                            type="password"
                            style={{
                                fontSize: '1rem',
                                fontWeight: 300,
                            }}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                            required
                        />
                    </label>
                    <div>
                        <button type="submit">{t('forms.login')}</button>
                    </div>
                </Form>
                <LinkWrapper
                    style={{
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: SPACING.medium,
                    }}
                >
                    <Link to={`/${t('routes.forgotPassword')}`}>
                        {t('forms.forgotPassword')}
                    </Link>
                    <Link to={`/${t('routes.register')}`}>
                        {t('forms.register')}
                    </Link>
                </LinkWrapper>
            </Box>

            {loading && <Loader />}
        </Background>
    )
}

export default Login
