import React, {Component} from 'react';
import {InputSection, StyledLabel} from "./styled-components";
import styled from "styled-components";
import {withTranslation} from "react-i18next";

const SearchInput = styled.input`
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9;
    background: rgb(255, 255, 255) !important;
    width: 50% !important;
    padding: 7px 10px !important;
    font-size: 0.9em !important;
`;

class SearchBox extends Component {

    static defaultProps = {
        placeholder: 'Adresse eingeben...',
        onPlacesChanged: null,
    };

    constructor(props) {
        super(props);

        this.searchInput = React.createRef();
    }

    componentDidMount() {
        const {
            googlemaps: {places},
        } = this.props;

        if (places) {
            this.searchBox = new places.SearchBox(this.searchInput.current ?? '');
            this.searchBox.addListener('places_changed', this.onPlacesChanged);
        }

    }

    componentWillUnmount() {
        const {
            googlemaps: {event},
        } = this.props;

        event.clearInstanceListeners(this.searchBox);
    }

    onPlacesChanged = () => {
        const {onPlacesChanged, map, googlemaps} = this.props;

        if (onPlacesChanged) {
            onPlacesChanged(this.searchBox.getPlaces(), map, googlemaps);
        }
    };

    render() {
        const {t} = this.props;

        return (
            <InputSection>
                <SearchInput
                    ref={this.searchInput}
                    placeholder={t('forms.enterAddress')}
                    type="text"/>
            </InputSection>

        );
    }
}

export default withTranslation()(SearchBox);
