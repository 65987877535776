import React, { Component } from 'react';
import BranchDropdown from "../branch-dropdown";
import List from "../list";
import dateFormat from "dateformat";
import Loader from "../loader";
import PopupMessage from "../popup-message";
import { ReactComponent as StarIcon } from '../../assets/images/star.svg';
import { ReactComponent as MoreButton } from "../../assets/images/more.svg";
import RatingsPopup from "../ratings-popup";
import { sortByDateProperty } from "../../utils/utility-functions";
import { SubTitle } from "../styled-components";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import BranchService from '../../../services/branch-service';
import RatingsService from '../../../services/ratings-service';
import i18n from '../../../i18n/config';

const BoldNumber = styled.h2`
    color: #db64ff;
    font-size: 2em !important;
`;

class ReceivedRatingsWidget extends Component {

    constructor(props) {
        super(props);

        this.ratingsService = new RatingsService();
        this.branchService = new BranchService();

        this.state = {
            branch: undefined,
            selectedBranchId: undefined,
            selectedBooking: undefined,
            ratings: undefined,
            isLoading: false,
            showMessage: false,
            message: undefined,
            showPopup: false,
            dateformat: i18n.language === 'en' ? 'mm/dd/yyyy' : 'd. mmm yyyy',
        }
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show });
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading });
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show });
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message });
    }

    updateItems = (branchId) => {
        this.updateRatings(branchId);
        this.updateInfo(branchId);
    }

    updateRatings = (branchId) => {
        this.ratingsService.getBranchReceivedRatings(branchId).then(res => {
            if (res && res.data) {
                this.setState({ ...this.state, ratings: sortByDateProperty(res.data, false) });
            }
        });
    }

    updateInfo = (branchId) => {
        this.branchService.getBranchInfo(branchId).then(res => {
            if (res && res.data) {
                this.setState({ ...this.state, branch: res.data });
            }
        });
    }

    setSelectedBranch = (branchId) => {
        this.setState({ ...this.state, selectedBranchId: branchId });
    }

    openRatingsPopup = (item) => {
        this.setState(
            { ...this.state, selectedBooking: item },
            () => this.setShowPopup(true)
        );
    }

    getRatingsListStructure = (item) => {
        const { course, fl_firstname, fl_lastname, date, total } = item;
        const { t } = this.props;

        let dateformat = 'd. mmm yyyy';
        if (i18n.language === 'en') {
            dateformat = 'mm/dd/yyyy';
        }
        
        const courseMarkdown = `<b>${course}</b>`;
        const trainerMarkdown = `<b>${fl_firstname} ${fl_lastname}</b>`;
        const dateMarkdown = `<span>${dateFormat(date, dateformat)}</span>`;
        const ratingMarkdown = total ?
            (<>{total.toFixed(2)} <StarIcon
                style={{ marginBottom: 3, marginLeft: 3 }} /></>) :
            <i>{t('rating.noRatings')}</i>;

        return [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: trainerMarkdown, isHTML: true },
            { markdown: dateMarkdown, isHTML: true },
            { markdown: ratingMarkdown, isHTML: false },
            {
                markdown: <MoreButton style={{ cursor: 'pointer' }} onClick={() => this.openRatingsPopup(item)} />,
                isHTML: false
            },
        ];
    }

    render() {
        const { ratings, branch, isLoading, showMessage, message, showPopup, selectedBooking } = this.state;
        const { t } = this.props;

        return <>
            <BranchDropdown updateItems={this.updateItems}
                setSelectedBranch={this.setSelectedBranch} />

            {
                branch &&
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1 1 0' }}>
                        <BoldNumber>{branch && branch.rating_count}</BoldNumber>
                        <SubTitle>{t('rating.ratingCount')}</SubTitle>
                    </div>
                    {
                        branch && branch.total_avg &&
                        <div style={{ flex: '1 1 0' }}>
                            <BoldNumber>{branch && branch.total_avg && branch.total_avg.toFixed(2)} <StarIcon
                                style={{ transform: 'scale(2)', marginBottom: 7, marginLeft: 3 }} /></BoldNumber>
                            <SubTitle>{t('rating.average')}</SubTitle>
                        </div>
                    }
                </div>
            }

            <List items={ratings}
                noItemsMessage={t('list.noReceivedRatings')}
                headings={[t('list.job'), t('list.trainer'), t('list.date'), t('list.rating'), '']}
                getListItemStructure={this.getRatingsListStructure}
                style={{ marginTop: '30px' }} />

            {
                showPopup &&
                <RatingsPopup booking={selectedBooking}
                    role={'chef'}
                    hidePopup={() => this.setShowPopup(false)} />
            }
            {
                isLoading &&
                <Loader />
            }
            {
                showMessage && message &&
                <PopupMessage message={message} setShowMessage={this.setShowMessage} />
            }
        </>;
    }
}

export default withTranslation()(ReceivedRatingsWidget);