import { useAuth } from "../../context/AuthContext";
import FreelancerBlacklistWidget from "../../legacy/components/widgets/freelancer-blacklist-widget";
import BlacklistWidget from "../../legacy/components/widgets/blacklist-widget";
import { useTranslation } from "react-i18next";

const Blacklist = () => {

    const { user } = useAuth();
    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.contra')}
        </h1>

        <p>
            {user && user.role === 'freelancer'
                ? t('pages.description.blacklistFreelancer')
                : t('pages.description.blacklistChef')}
        </p>

        {user && user.role === 'freelancer'
            ? <FreelancerBlacklistWidget />
            : <BlacklistWidget />}

    </>;

}

export default Blacklist;