import React, { Component } from 'react'
import { StyledButton, StyledSubmit } from '../styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import {
    addressAdditionalField,
    cityField,
    companyNameField,
    countryField,
    housenumberField,
    invoiceEmailField,
    isBranchField,
    phoneCountryField,
    phoneField, phoneFieldNew,
    stateField,
    streetField,
    websiteField,
    zipcodeField,
} from '../form-fields'
import Form from '../form'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DEVICE } from '../../assets/constants'

const ButtonWrapper = styled.div`
    margin-top: auto;

    @media ${DEVICE.tablet} {
    }

    @media ${DEVICE.phone} {
        margin-top: 10%;
    }

    @media ${DEVICE.short_phone} {
        margin-top: 10%;
    }
`

const SetupButton = styled(StyledButton)`
    width: 250px;

    @media ${DEVICE.phone} {
        width: 100%;
    }
`

const SetupSubmit = styled(StyledSubmit)`
    width: 250px;

    @media ${DEVICE.phone} {
        width: 100%;
        height: 55px;
    }
`

class CompanyDataForm extends Component {
    constructor(props) {
        super(props)
    }

    onSubmit = (event) => {
        const { nextStep } = this.props
        event.preventDefault()
        nextStep()
    }

    render() {
        const { company, changeValue, siteurl, t, countries } = this.props
        const {
            alreadySet,
            companyName,
            invoiceEmail,
            street,
            housenumber,
            zipcode,
            city,
            state,
            country,
            addressAdditional,
            phone,
            website,
            isBranch,
        } = company

        const formElements = [
            [
                companyNameField(
                    companyName,
                    (value) => changeValue('company', 'companyName', value),
                    1,
                    true,
                    true
                ),
            ],
            [
                invoiceEmailField(
                    invoiceEmail,
                    (value) => changeValue('company', 'invoiceEmail', value),
                    1,
                    true,
                    alreadySet
                ),
            ],
            [
                streetField(
                    street,
                    (value) => changeValue('company', 'street', value),
                    1,
                    true,
                    alreadySet
                ),
                housenumberField(
                    housenumber,
                    (value) => changeValue('company', 'housenumber', value),
                    0.5,
                    true,
                    alreadySet
                ),
                addressAdditionalField(
                    addressAdditional,
                    (value) =>
                        changeValue('company', 'addressAdditional', value),
                    1,
                    false,
                    alreadySet
                ),
            ],
            [
                zipcodeField(
                    zipcode,
                    (value) => changeValue('company', 'zipcode', value),
                    0.5,
                    true,
                    alreadySet
                ),
                cityField(
                    city,
                    (value) => changeValue('company', 'city', value),
                    1,
                    true,
                    alreadySet
                ),
            ],
            [
                stateField(
                    state,
                    (value) => changeValue('company', 'state', value),
                    1,
                    true,
                    alreadySet
                ),
                countryField(
                    country,
                    (value) => changeValue('company', 'country', value),
                    1,
                    true,
                    alreadySet,
                    countries
                ),
            ],
            [
                phoneFieldNew(
                    phone,
                    (value) => changeValue('company', 'phone', value),
                ),
                websiteField(
                    website,
                    (value) => changeValue('company', 'website', value),
                    1,
                    false,
                    alreadySet
                ),
            ],
            [
                isBranchField(
                    isBranch,
                    (value) => changeValue('company', 'isBranch', value),
                    1,
                    true,
                    alreadySet
                ),
            ],
        ]

        return (
            <>
                <Form
                    elements={formElements}
                    submit={{ label: '', onSubmit: this.onSubmit }}
                >
                    <ButtonWrapper>
                        <SetupSubmit
                            type={'submit'}
                            value={t('setup.nextStep')}
                        />
                        {/* <SetupButton tertiary
                        onClick={() => {
                            const { logout } = this.props;

                            logout();
                        }}>
                        {t('setup.abort')}
                    </SetupButton> */}
                    </ButtonWrapper>
                </Form>
            </>
        )
    }
}

export default withTranslation()(CompanyDataForm)
