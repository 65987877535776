import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { InputSection, StyledLabel, StyledSelect } from '../styled-components'
import SearchBox from '../search-box'
import {
    DEVICE,
    GOOGLE_API_KEY,
    PRIMARY_COLOR,
    MIN_RADIUS,
    MAX_RADIUS,
    RADIUS_STEP,
} from '../../assets/constants'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

const FormWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 65%;
    width: 100%;
    margin-top: 25px;
    flex-wrap: wrap;

    @media ${DEVICE.phone} {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
        height: 88%;
    }
`

const InputWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 12px;

    @media ${DEVICE.phone} {
        width: 100%;
    }
`

const InputRange = styled(InputSection)`
    flex: 0.5;
    margin-top: 10px;

    @media ${DEVICE.phone} {
        margin: 5px 0;
    }
`

const InputDropdown = styled(InputSection)`
    flex: 1;
    margin-top: 10px;
    margin-left: 5px;

    @media ${DEVICE.phone} {
        margin: 5px 0;
    }
`

class FreelancerLocationForm extends Component {
    static defaultProps = {
        center: {
            lat: 51.05163,
            lng: 13.73351,
        },
        zoom: 11,
    }

    constructor(props) {
        super(props)

        this.state = {
            apiReady: false,
            map: undefined,
            googlemaps: undefined,
            selectedPlace: undefined,
            defaultAddress: 'Sophienstraße 1, 01067 Dresden',
            radius: 0,
        }

        this.markers = []
        this.circles = []
    }

    setSelectedPlace = (place) => {
        this.setState({ ...this.state, selectedPlace: place })
        this.props.setPlace(place)
    }

    setApiReady = (ready, map, maps) => {
        this.setState({
            ...this.state,
            apiReady: ready,
            map: map,
            googlemaps: maps,
        })
    }

    setRadius = (radius, update = false) => {
        if (!update) {
            this.setState({ ...this.state, radius: radius })
            this.props.changeValue('radius', radius)
        }

        const { map, googlemaps, selectedPlace } = this.state

        if (selectedPlace !== undefined) {
            this.circles.forEach((circle) => circle.setMap(null))
            this.circles.push(
                new googlemaps.Circle({
                    strokeColor: '#db64ff',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#db64ff',
                    fillOpacity: 0.35,
                    map: map,
                    center: selectedPlace.geometry.location,
                    radius: radius * 1000,
                })
            )
        }
    }

    updateRadiusUnit = (radiusUnit) => {
        const { radius } = this.state
        let newRadius = null

        if (radiusUnit === 'km') {
            newRadius = radius * 0.621371
            this.props.changeValue('radiusUnit', 'km')
        }

        if (radiusUnit === 'mi') {
            newRadius = radius * 1.60934
            this.props.changeValue('radiusUnit', 'mi')
        }

        this.setState({ ...this.state, radius: newRadius })
        this.setRadius(newRadius, true)
    }

    setPlace = (places, map, maps) => {
        this.setSelectedPlace(places[0])

        this.setRadius(this.props.theRadius)
        this.markers.forEach((marker) => marker.setMap(null))
        map.setCenter(places[0].geometry.location)
        this.markers.push(
            new maps.Marker({
                map: map,
                position: places[0].geometry.location,
            })
        )
    }

    mapsIsLoaded = (map, maps) => {
        const { place } = this.props
        this.setApiReady(true, map, maps)

        const geocoder = new maps.Geocoder()
        if (place) {
            geocoder.geocode(
                { address: `${place.formatted_address}` },
                (results, status) => {
                    if (status === 'OK') {
                        this.setSelectedPlace(results[0])
                        map.setCenter(results[0].geometry.location)
                        this.markers.push(
                            new maps.Marker({
                                map: map,
                                position: results[0].geometry.location,
                            })
                        )
                        this.setRadius(this.props.theRadius)
                    } else {
                        alert(
                            'Geocode was not successful for the following reason: ' +
                                status
                        )
                    }
                }
            )
        }
    }

    render() {
        const { apiReady, map, googlemaps, selectedPlace } = this.state
        const { theRadius, radiusUnit, changeValue, t } = this.props

        return (
            <>
                <FormWrapper>
                    {apiReady && map && googlemaps && (
                        <SearchBox
                            map={map}
                            googlemaps={googlemaps}
                            onPlacesChanged={this.setPlace}
                        />
                    )}

                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: GOOGLE_API_KEY,
                            libraries: ['places'],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                            this.mapsIsLoaded(map, maps)
                        }
                    ></GoogleMapReact>

                    <InputWrapper>
                        <InputRange>
                            <StyledLabel>{t('forms.radius')}</StyledLabel>
                            <input
                                type={'number'}
                                value={theRadius}
                                step={RADIUS_STEP}
                                min={MIN_RADIUS}
                                max={MAX_RADIUS}
                                onChange={(event) =>
                                    this.setRadius(event.target.value)
                                }
                                disabled={!selectedPlace}
                            />
                        </InputRange>

                        <InputDropdown style={{ flex: 1 }}>
                            <StyledLabel>{t('forms.radiusUnit')}</StyledLabel>
                            <StyledSelect
                                style={{ width: '100%' }}
                                value={radiusUnit}
                                onChange={(event) =>
                                    this.updateRadiusUnit(event.target.value)
                                }
                            >
                                <option value={'km'}>
                                    {t('forms.radiusKilometers')}
                                </option>
                                <option value={'mi'}>
                                    {t('forms.radiusMiles')}
                                </option>
                            </StyledSelect>
                        </InputDropdown>
                    </InputWrapper>
                </FormWrapper>
            </>
        )
    }
}

export default withTranslation()(FreelancerLocationForm)
