import React, {Component} from 'react'
import SectionHeading from '../section-heading'
import styled from 'styled-components'
import {
    FormValue,
    InputSection,
    ListElement,
    ListElementWrapper,
    SaveButton,
    StyledButton,
    StyledLabel,
    StyledSelect,
    TextButton,
} from '../styled-components'
import List from '../list'
import ReactPasswordStrength from 'react-password-strength'
import '../../assets/less/strong-password.scss'
import Loader from '../loader'
import {DEVICE, MAX_RADIUS, MIN_RADIUS, PHONE_CODES, PRIMARY_COLOR, RADIUS_STEP,} from '../../assets/constants'
import {MdDelete, MdInfoOutline, MdRemoveRedEye, MdFileDownload} from 'react-icons/md'
import dateFormat from 'dateformat'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import '../../assets/less/react-toggle.scss'
import Popup from '../popup'
import {withTranslation} from 'react-i18next'
import PopupMessage from '../popup-message'
import AccountService from '../../../services/account-service'
import i18n from '../../../i18n/config'
import {openBase64File} from '../../utils/utility-functions'
import {COLORS, RADIUS, SPACING} from "../../../assets/constants";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const DeleteIcon = styled(MdDelete)`
    color: ${PRIMARY_COLOR};
    transform: scale(1.3);
    cursor: pointer;
`

const ViewIcon = styled(MdRemoveRedEye)`
    color: ${PRIMARY_COLOR};
    transform: scale(1.3);
    cursor: pointer;
`

const DownloadIcon = styled(MdFileDownload)`
    color: ${PRIMARY_COLOR};
    transform: scale(1.3);
    cursor: pointer;
`

const InfoMessage = styled.p`
    font-size: 0.9em;
    font-weight: normal;
`

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const StreetInput = styled(InputSection)`
    flex: 1 1 0;
    margin: 0 5px;

    @media ${DEVICE.phone} {
        flex: 1 1 40% !important;
    }
`

const HouseNumberInput = styled(InputSection)`
    flex: 1 1 0;
    margin: 0 5px;

    @media ${DEVICE.phone} {
        flex: 1 1 10% !important;
    }
`

const Checkbox = styled.div`
        display: block;
        width: 100%;
        font-size: 0.7rem !important;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: ${COLORS.primaryFaded};
        margin-bottom: ${SPACING.medium}px;

        input {
            width: 100%;
            padding: ${SPACING.small}px;
            border-width: 1px;
            border-color: ${COLORS.faded};
            border-style: solid;
            border-radius: ${RADIUS.tiny}px;

            &[type="checkbox"] {
                display: none;

                & + label {
                    cursor: pointer;
                    display: flex;
                    align-items: end;
                }

                & + label:before {
                    width: 15px;
                    height: 15px;
                    background-color: #e9e9e9;
                    display: block;
                    content: "";
                    float: left;
                    margin-right: 12px;
                    cursor: pointer;
                }

                &:checked + label:before {
                    background-color: ${COLORS.primary};
                }
            }
        }
    }
`;

class FreelancerPersonalDataWidget extends Component {
    constructor(props) {
        super(props)
        const {t} = props

        this.state = {
            currentLanguage: i18n.language,
            countries: [],
            message: undefined,
            showMessage: false,
            isLoading: false,
            passLength: 5,
            passwordIsValid: false,
            oldPassword: '',
            password: '',
            passwordConfirm: '',
            paused: false,
            deletePopup: false,
            deletePopupValue: '',
            fileName: '',
            currentFile: '',
            currentFileSize: null,
            uploadQualificationPopup: false,
            bankUnion: 'insideUnion',
            personalData: {
                name: {
                    type: 'personalData',
                    key: 'name',
                    label: t('forms.name'),
                    buttonLabel: '',
                    description: '',
                    data: undefined,
                    value: '',
                    editForm: undefined,
                    editing: false,
                },
                birth: {
                    type: 'personalData',
                    key: 'birth',
                    label: t('forms.birth'),
                    buttonLabel: '',
                    description: '',
                    data: undefined,
                    value: '',
                    editForm: undefined,
                    editing: false,
                },
                phone: {
                    type: 'personalData',
                    key: 'phone',
                    label: t('forms.phone'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.phoneDescription'),
                    value: '',
                    editForm: this.editPhoneForm,
                    editing: false,
                },
                address: {
                    type: 'personalData',
                    key: 'address',
                    label: t('forms.address'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.addressDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editAddress,
                    editing: false,
                },
            },
            accountData: {
                email: {
                    type: 'accountData',
                    key: 'email',
                    label: t('forms.email'),
                    buttonLabel: '',
                    description: t('forms.emailDescription'),
                    data: undefined,
                    value: '',
                    editForm: undefined,
                    editing: false,
                },
                password: {
                    type: 'accountData',
                    key: 'password',
                    label: t('forms.password'),
                    buttonLabel: t('button.update'),
                    description: '',
                    value: (
                        <InfoMessage>
                            {t('forms.passwordDescription')}
                        </InfoMessage>
                    ),
                    editForm: this.editPasswordForm,
                    editing: false,
                },
            },
            locationData: {
                salary: {
                    type: 'locationData',
                    key: 'salary',
                    label: t('forms.currency'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.salaryDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editSalaryForm,
                    editing: false,
                },
                radius: {
                    type: 'locationData',
                    key: 'radius',
                    label: t('forms.radiusAlt'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.radiusDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editRadiusForm,
                    editing: false,
                },
            },
            invoiceData: {
                paymentInformation: {
                    type: 'invoiceData',
                    key: 'paymentInformation',
                    label: t('personalData.invoiceData'),
                    buttonLabel: t('button.edit'),
                    description: 'Gib bitte deine Zahlungsinformationen an',
                    data: undefined,
                    value: '',
                    editForm: this.editPaymentForm,
                    editing: false,
                },
                tax_number: {
                    type: 'invoiceData',
                    key: 'tax_number',
                    label: t('forms.taxId'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.taxIdDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editSingleTextForm,
                    editing: false,
                },
                ustid: {
                    type: 'invoiceData',
                    key: 'ustid',
                    label: t('forms.ustId'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.ustIdDescription'),
                    data: undefined,
                    value: '',
                    editForm: this.editSingleTextForm,
                    editing: false,
                },
            },
            qualificationData: {
                files: {
                    type: 'qualificationData',
                    key: 'files',
                    label: t('forms.filename'),
                    buttonLabel: t('button.edit'),
                    description: t('forms.filesDescription'),
                    data: [],
                    value: '',
                    editForm: undefined,
                    editing: false,
                },
            },
        }

        this.accountService = new AccountService()
    }

    componentDidMount() {
        this.updateData()

        this.accountService.getCountries().then((res) => {
            if (res && res.data) {
                this.setState({...this.state, countries: res.data})
            }
        })
    }

    setMessage = (message) => {
        this.setState({...this.state, message: message})
    }

    setShowMessage = (show) => {
        this.setState({...this.state, showMessage: show})
    }

    updateData = () => {
        const {t} = this.props
        const currentLanguage = i18n.language

        this.accountService.getMe().then((res) => {
            if (res && res.data) {
                const name =
                    res.data.firstname && res.data.lastname ? (
                        <FormValue>
                            {res.data.firstname} {res.data.lastname}
                        </FormValue>
                    ) : (
                        <InfoMessage>{t('forms.notSet')}</InfoMessage>
                    )
                const phone = res.data.phone ? (
                    <FormValue>
                        {`${res.data.phone}`}
                    </FormValue>
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )
                const birth = res.data.birth ? (
                    <FormValue>
                        {dateFormat(
                            res.data.birth,
                            currentLanguage === 'en'
                                ? 'mm/dd/yyyy'
                                : 'd. mmmm yyyy'
                        )}
                    </FormValue>
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )
                const email = res.data.email ? (
                    <FormValue>{res.data.email}</FormValue>
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )
                const salary = res.data.salary ? (
                    <FormValue>{res.data.currency}</FormValue>
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )
                const radius = res.data.radius ? (
                    <FormValue>
                        {res.data.radius.toString().replace('.', ',')}{' '}
                        {res.data.radius_unit.toUpperCase()}
                    </FormValue>
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )
                const address =
                    res.data.street &&
                    res.data.housenumber &&
                    res.data.zipcode &&
                    res.data.city &&
                    res.data.country ? (
                        <FormValue>
                            {res.data.street} {res.data.housenumber},{' '}
                            {res.data.adress_additional &&
                                res.data.adress_additional !== '-' &&
                                res.data.adress_additional + ', '}{' '}
                            {res.data.zipcode} {res.data.city},{' '}
                            {res.data.country}
                        </FormValue>
                    ) : (
                        <InfoMessage>{t('forms.notComplete')}</InfoMessage>
                    )
                const paymentInformation =
                    (res.data.iban && res.data.bic) ||
                    (res.data.bank &&
                        res.data.blz &&
                        res.data.bic &&
                        res.data.ban) ||
                    res.data.paypal || res.data.check_payment ? (
                        <FormValue>
                            {res.data.paypal ? t('forms.paypal') : ''}
                            {res.data.paypal &&
                            (
                                res.data.check_payment ||
                                (res.data.iban && res.data.bic) ||
                                (res.data.bank &&
                                    res.data.blz &&
                                    res.data.bic &&
                                    res.data.ban))
                                ? ', '
                                : ''}
                            {res.data.check_payment ? t('forms.checkPayment') : ''}
                            {res.data.check_payment &&
                            (
                                (res.data.iban && res.data.bic) ||
                                (res.data.bank &&
                                    res.data.blz &&
                                    res.data.bic &&
                                    res.data.ban))
                                ? ', '
                                : ''}
                            {(res.data.iban && res.data.bic) ||
                            (res.data.blz && res.data.bic && res.data.ban)
                                ? t('forms.bankAccount')
                                : ''}
                        </FormValue>
                    ) : (
                        <InfoMessage>{t('forms.notSet')}</InfoMessage>
                    )
                const tax_number = res.data.tax_number ? (
                    <FormValue>{res.data.tax_number}</FormValue>
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )
                const ustid = res.data.ustid ? (
                    <FormValue>{res.data.ustid}</FormValue>
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )
                const paused = res.data.paused ? (
                    <FormValue>{t('forms.accountPaused')}</FormValue>
                ) : (
                    <FormValue>{t('forms.accountActive')}</FormValue>
                )

                this.setState({
                    ...this.state,
                    paused: res.data.paused !== 0,
                    bankUnion:
                        res.data.blz && res.data.bic && res.data.ban
                            ? 'outsideUnion'
                            : 'insideUnion',
                    personalData: {
                        ...this.state.personalData,
                        name: {
                            ...this.state.personalData.name,
                            value: name,
                            data: {
                                firstname: res.data.firstname,
                                lastname: res.data.lastname,
                            },
                        },
                        phone: {
                            ...this.state.personalData.phone,
                            value: phone,
                            data: {
                                phone: res.data.phone,
                            },
                        },
                        birth: {
                            ...this.state.personalData.birth,
                            value: birth,
                            data: {
                                birth: res.data.birth,
                            },
                        },
                        address: {
                            ...this.state.personalData.address,
                            value: address,
                            data: {
                                street: res.data.street,
                                housenumber: res.data.housenumber,
                                zipcode: res.data.zipcode,
                                city: res.data.city,
                                state: res.data.state,
                                country: res.data.countrycode,
                                addressAdditional: res.data.adress_additional
                                    ? res.data.adress_additional
                                    : '',
                            },
                        },
                    },
                    accountData: {
                        ...this.state.accountData,
                        email: {
                            ...this.state.accountData.email,
                            value: email,
                            data: {
                                email: res.data.email,
                            },
                        },
                    },
                    locationData: {
                        ...this.state.locationData,
                        salary: {
                            ...this.state.locationData.salary,
                            value: salary,
                            data: {
                                salary: res.data.salary,
                                currency: res.data.currency,
                            },
                        },
                        radius: {
                            ...this.state.locationData.radius,
                            value: radius,
                            data: {
                                radius: res.data.radius,
                                radiusUnit: res.data.radius_unit,
                            },
                        },
                    },
                    invoiceData: {
                        paymentInformation: {
                            ...this.state.invoiceData.paymentInformation,
                            value: paymentInformation,
                            data: {
                                bank: res.data.bank,
                                iban: res.data.iban,
                                bic:
                                    res.data.iban && res.data.bic
                                        ? res.data.bic
                                        : '',
                                bic2:
                                    res.data.blz && res.data.bic && res.data.ban
                                        ? res.data.bic
                                        : '',
                                ban: res.data.ban,
                                blz: res.data.blz,
                                paypal: res.data.paypal,
                                check_payment: res.data.check_payment,
                            },
                        },
                        tax_number: {
                            ...this.state.invoiceData.tax_number,
                            value: tax_number,
                            data: {
                                tax_number: res.data.tax_number,
                            },
                        },
                        ustid: {
                            ...this.state.invoiceData.ustid,
                            value: ustid,
                            data: {
                                ustid: res.data.ustid,
                            },
                        },
                    },
                })
            }
        })

        this.accountService.getMyQualifications().then((res) => {
            this.setState({
                qualificationData: {
                    ...this.state.qualificationData,
                    files: {
                        ...this.state.qualificationData.files,
                        data: res.data,
                    },
                },
            })
        })
    }

    getListStructure = (item) => {
        const {t} = this.props

        const {
            type,
            key,
            label,
            value,
            buttonLabel,
            buttonCancelLabel,
            editForm,
            editing,
        } = item

        const fieldMarkdown = (
            <div style={{width: '100%'}}>
                <StyledLabel>{label}</StyledLabel>
                {editing ? (
                    editForm(type, key, key)
                ) : value ? (
                    value
                ) : (
                    <InfoMessage>{t('forms.notSet')}</InfoMessage>
                )}
            </div>
        )

        return [
            {markdown: fieldMarkdown, isHTML: false},
            {
                markdown: buttonLabel ? (
                    <TextButton onClick={() => this.toggleEditForm(type, key)}>
                        {editing
                            ? buttonCancelLabel
                                ? buttonCancelLabel
                                : t('popup.abort')
                            : buttonLabel}
                    </TextButton>
                ) : (
                    ''
                ),
                isHTML: false,
            },
        ]
    }

    getQualificationListStructure = (item) => {
        const {t} = this.props
        const {id, title, url} = item

        const fieldMarkdown = <FormValue>{title}</FormValue>
        const actionsMarkdown = (
            <div style={{display: 'flex', alignItems: 'baseline'}}>
                <a
                    style={{marginRight: 15}}
                    onClick={async () => {
                        const res = await this.accountService.getMyQualification(id)
                        openBase64File(res)
                    }}
                >
                    <ViewIcon/>
                </a>
                <a
                    style={{marginRight: 15}}
                    onClick={async() => {await this.accountService.getMyQualificationFile(id, title)}}
                >
                    <DownloadIcon/>
                </a>
                <DeleteIcon onClick={() => this.deleteQualification(id)}/>
            </div>
        )

        return [
            {markdown: fieldMarkdown, isHTML: false},
            {markdown: actionsMarkdown, isHTML: false},
        ]
    }

    setIsLoading = (isLoading) => {
        this.setState({isLoading: isLoading})
    }

    toggleEditForm = (type, key) => {
        this.setState({
            ...this.state,
            oldPassword: '',
            password: '',
            passwordConfirm: '',
            [type]: {
                ...this.state[type],
                [key]: {
                    ...this.state[type][key],
                    editing: !this.state[type][key].editing,
                    data: this.state[type][key].data,
                },
            },
        })
    }

    changeValue = (type, key, field, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                [key]: {
                    ...this.state[type][key],
                    data: {
                        ...this.state[type][key].data,
                        [field]: value,
                    },
                },
            },
        })
    }

    handleOptionChange = (changeEvent) => {
        this.setState({
            ...this.state,
            bankUnion: changeEvent.target.value,
        })
    }

    prepareFileupload = (currentFile) => {
        // const fileReader = new FileReader()

        var currentfilesizeInMb = (currentFile.size / 1024 / 1024).toFixed(4)
        this.setState({
            ...this.state,
            currentFile: currentFile,
            currentFileSize: currentfilesizeInMb,
            fileName: currentFile.name,
        })

        // fileReader.onload = (event) => {
        //     this.setState({
        //         ...this.state,
        //         currentFile: currentFile,
        //         currentFileSize: currentfilesizeInMb,
        //         fileName: currentFile.name,
        //     })
        // }

        // fileReader.readAsDataURL(currentFile)
    }

    updateValue = (type, key, data) => {
        this.setIsLoading(true)
        this.accountService.updateUserData(data).then(
            (res) => {
                this.setIsLoading(false)

                if (res && res.success) {
                    // this.setShowMessage(true);
                    // this.setMessage(`<div>${res.message}</div>`);
                    this.updateData()
                    this.setState({
                        [type]: {
                            ...this.state[type],
                            [key]: {
                                ...this.state[type][key],
                                editing: false,
                            },
                        },
                    })
                }
            },
            (error) => {
                this.setIsLoading(false)
                if (error.data && !error.data.success && error.data.errors) {
                    this.displayErrorPopup(error.data)
                }
            }
        )
    }

    updateQualification = (type, key, title, file) => {
        this.setIsLoading(true)

        this.accountService.addMyQualification(title, file).then(
            (res) => {
                this.setIsLoading(false)
                this.updateData()
                this.setQualificationPopup(false)
                this.setState({
                    [type]: {
                        ...this.state[type],
                        [key]: {
                            ...this.state[type][key],
                            editing: false,
                        },
                    },
                })
            },
            (err) => {
                this.setIsLoading(false)
            }
        )
    }

    deleteQualification = (qualificationId) => {
        this.setIsLoading(true)
        this.accountService.deleteQualification(qualificationId).then((res) => {
            this.updateData()
            this.setIsLoading(false)
        })
    }

    displayErrorPopup = (res) => {
        const errorMessages = []
        if (res.data) {
            for (var theKey in res.data) {
                var errorMessage = res.data[theKey][0]
                const htmlMessage = errorMessage + '<br><br>'
                errorMessages.push(htmlMessage)
            }
            const joinedMessages = errorMessages.join(' ')
            this.setShowMessage(true)
            this.setMessage(`<div>${joinedMessages}</div>`)
            return
        }
        if (res.errors) {
            for (var theKey in res.errors) {
                var errorMessage = res.errors[theKey][0]
                const htmlMessage = errorMessage + '<br><br>'
                errorMessages.push(htmlMessage)
            }
            const joinedMessages = errorMessages.join(' ')
            this.setShowMessage(true)
            this.setMessage(`<div>${joinedMessages}</div>`)
            return
        }
        this.setShowMessage(true)
        this.setMessage(`<div>${res.message}</div>`)
    }

    updatePassword = () => {
        const {oldPassword, password, passwordConfirm} = this.state

        this.setIsLoading(true)
        this.accountService
            .changePassword(oldPassword, password, passwordConfirm)
            .then((res) => {
                this.setIsLoading(false)
                if (res && !res.success) {
                    this.displayErrorPopup(res)
                }

                if (res && res.success) {
                    this.updateData()
                    this.setState({
                        oldPassword: '',
                        password: '',
                        passwordConfirm: '',
                        accountData: {
                            ...this.state.accountData,
                            password: {
                                ...this.state.accountData.password,
                                editing: false,
                            },
                        },
                    })
                }
            })
    }

    changePhone = (type, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                phone: {
                    ...this.state[type].phone,
                    data: {
                        ...this.state[type].phone.data,
                        phone: value,
                    },
                },
            },
        })
    }

    changePhoneNew = (phoneNumber) => {
        this.setState({
            personalData: {
                ...this.state.personalData,
                phone: {
                    ...this.state.personalData.phone,
                    data: {
                        ...this.state.personalData.phone.data,
                        phone: phoneNumber
                    },
                },
            },
        })
    };

    editPhoneForm = () => {
        const {phone} = this.state.personalData.phone.data
        const {countries} = this.state
        const {t} = this.props

        return (
            <>
                <InfoMessage style={{marginTop: 25, marginBottom: 15}}>
                    {this.state.personalData.phone.description}
                </InfoMessage>
                <div style={{display: 'flex', margin: '0 -5px'}}>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.phone')}</StyledLabel>
                        <PhoneInput
                            country={'us'}
                            preferredCountries={['us', 'ch', 'at', 'gb', 'de', 'au']}
                            countryCodeEditable={false}
                            enableSearch={true}
                            disableSearchIcon={true}
                            value={phone}
                            onChange={phone => {this.changePhoneNew(phone)}}
                            inputClass={'phoneInput'}
                        />
                    </InputSection>
                </div>
                <SaveButton
                    primary
                    onClick={() =>
                        this.updateValue('personalData', 'phone', {
                            phone: '+' + phone,
                        })
                    }
                >
                    {t('forms.phone')} {t('button.change')}
                </SaveButton>
            </>
        )
    }

    editSingleTextForm = (type, key, field) => {
        const {t} = this.props

        const value = this.state[type][key].data[field]
        const label = this.state[type][key].label
        const description = this.state[type][key].description

        return (
            <>
                <InfoMessage style={{marginTop: 25, marginBottom: 15}}>
                    {description}
                </InfoMessage>
                <div style={{display: 'flex', margin: '0 -5px'}}>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{label}</StyledLabel>
                        <input
                            value={value || ''}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    type,
                                    key,
                                    field,
                                    event.target.value
                                )
                            }
                        />
                    </InputSection>
                </div>
                <SaveButton
                    primary
                    onClick={() =>
                        this.updateValue(type, key, {
                            [field]: value,
                        })
                    }
                >
                    {label} {t('button.change')}
                </SaveButton>
            </>
        )
    }

    editAddress = () => {
        const {t} = this.props
        const {
            street,
            housenumber,
            zipcode,
            city,
            state,
            country,
            addressAdditional,
        } = this.state.personalData.address.data
        const {countries} = this.state

        return (
            <>
                <InfoMessage style={{marginTop: 25, marginBottom: 15}}>
                    {this.state.personalData.address.description}
                </InfoMessage>
                <div style={{display: 'flex', margin: '0 -5px'}}>
                    <StreetInput>
                        <StyledLabel>{t('forms.street')}</StyledLabel>
                        <input
                            value={street}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    'personalData',
                                    'address',
                                    'street',
                                    event.target.value
                                )
                            }
                        />
                    </StreetInput>
                    <HouseNumberInput>
                        <StyledLabel>{t('forms.housenumber')}</StyledLabel>
                        <input
                            value={housenumber}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    'personalData',
                                    'address',
                                    'housenumber',
                                    event.target.value
                                )
                            }
                        />
                    </HouseNumberInput>
                </div>
                <div style={{display: 'flex', margin: '15px -5px 0'}}>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.zipcode')}</StyledLabel>
                        <input
                            value={zipcode}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    'personalData',
                                    'address',
                                    'zipcode',
                                    event.target.value
                                )
                            }
                        />
                    </InputSection>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.city')}</StyledLabel>
                        <input
                            value={city}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    'personalData',
                                    'address',
                                    'city',
                                    event.target.value
                                )
                            }
                        />
                    </InputSection>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>
                            {t('forms.addressAdditional')}
                        </StyledLabel>
                        <input
                            value={addressAdditional}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    'personalData',
                                    'address',
                                    'addressAdditional',
                                    event.target.value
                                )
                            }
                        />
                    </InputSection>
                </div>
                <div style={{display: 'flex', margin: '15px -5px 0'}}>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.state')}</StyledLabel>
                        <input
                            value={state}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    'personalData',
                                    'address',
                                    'state',
                                    event.target.value
                                )
                            }
                        />
                    </InputSection>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.country')}</StyledLabel>
                        <StyledSelect
                            style={{width: '100%'}}
                            value={country}
                            onChange={(event) =>
                                this.changeValue(
                                    'personalData',
                                    'address',
                                    'country',
                                    event.target.value
                                )
                            }
                        >
                            {countries.map((country) => (
                                <option value={country.locale}>
                                    {country.name}
                                </option>
                            ))}
                        </StyledSelect>
                    </InputSection>
                </div>
                <SaveButton
                    primary
                    onClick={() =>
                        this.updateValue('personalData', 'address', {
                            street: street,
                            housenumber: housenumber,
                            zipcode: zipcode,
                            city: city,
                            state: state,
                            country: country,
                            adress_additional: addressAdditional,
                        })
                    }
                >
                    {t('forms.address')}{' '}
                    <span style={{textTransform: 'lowercase'}}>
                        {t('button.edit')}
                    </span>
                </SaveButton>
            </>
        )
    }

    editPasswordForm = () => {
        const {t} = this.props
        const {oldPassword, password, passwordConfirm, passwordIsValid} =
            this.state

        const currentPasswordNotEmpty = oldPassword !== ''

        return (
            <>
                <InfoMessage style={{marginTop: 25, marginBottom: 15}}>
                    {t('forms.passwordDescriptionAlt')}
                </InfoMessage>
                <InputSection>
                    <StyledLabel>{t('forms.oldPassword')}</StyledLabel>
                    <input
                        type={'password'}
                        value={oldPassword}
                        onChange={(event) =>
                            this.setState({oldPassword: event.target.value})
                        }
                    />
                </InputSection>
                <div style={{display: 'flex', margin: '15px -5px 0'}}>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.newPassword')}</StyledLabel>
                        <ReactPasswordStrength
                            ref={(ref) => (this.ReactPasswordStrength = ref)}
                            minLength={8}
                            inputProps={{id: 'password'}}
                            changeCallback={this.setPassword}
                            scoreWords={[
                                t('forms.passwordWeak'),
                                t('forms.passwordMedium'),
                                t('forms.passwordGood'),
                                t('forms.passwordStrong'),
                                t('forms.passwordVeryStrong'),
                            ]}
                            tooShortWord={t('forms.passwordTooShort')}
                        />
                    </InputSection>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.passwordConfirm')}</StyledLabel>
                        <ReactPasswordStrength
                            ref={(ref) => (this.ReactPasswordStrength = ref)}
                            minLength={8}
                            inputProps={{id: 'password'}}
                            changeCallback={this.setPasswordConfirm}
                            scoreWords={[
                                t('forms.passwordWeak'),
                                t('forms.passwordMedium'),
                                t('forms.passwordGood'),
                                t('forms.passwordStrong'),
                                t('forms.passwordVeryStrong'),
                            ]}
                            tooShortWord={t('forms.passwordTooShort')}
                        />
                    </InputSection>
                </div>

                {!currentPasswordNotEmpty && (
                    <p
                        style={{
                            fontFamily: 'Montserrat',
                            fontSize: '0.8em',
                            marginTop: 10,
                        }}
                    >
                        <InfoIcon/>
                        {t('forms.passwordEnter')}
                    </p>
                )}
                {password &&
                    passwordConfirm &&
                    passwordIsValid &&
                    password !== passwordConfirm && (
                        <p
                            style={{
                                fontFamily: 'Montserrat',
                                fontSize: '0.8em',
                                marginTop: 10,
                            }}
                        >
                            <InfoIcon/>
                            {t('forms.passwordMismatch')}
                        </p>
                    )}
                <SaveButton
                    primary
                    onClick={() =>
                        this.updatePassword(
                            oldPassword,
                            password,
                            passwordConfirm
                        )
                    }
                    disabled={
                        !(
                            password &&
                            passwordConfirm &&
                            passwordIsValid &&
                            password === passwordConfirm
                        ) || !currentPasswordNotEmpty
                    }
                >
                    {t('forms.password')} {t('button.change')}
                </SaveButton>
            </>
        )
    }

    editSalaryForm = () => {
        const {currency} = this.state.locationData.salary.data
        const {t} = this.props
        const {countries} = this.state

        const currencies = new Set()
        if (countries)
            countries.forEach((country) => currencies.add(country.acronym))

        return (
            <>
                <InfoMessage style={{marginTop: 25, marginBottom: 15}}>
                    {this.state.locationData.salary.description}
                </InfoMessage>
                <div style={{display: 'flex', margin: '0 -5px'}}>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledSelect
                            value={currency || ''}
                            onChange={(event) =>
                                this.changeSalaryCurrency(
                                    'locationData',
                                    event.target.value
                                )
                            }
                        >
                            {Array.from(currencies).map((currency) => (
                                <option value={currency}>{currency}</option>
                            ))}
                        </StyledSelect>
                    </InputSection>
                </div>
                <SaveButton
                    primary
                    onClick={() =>
                        this.updateValue('locationData', 'salary', {
                            currency: currency,
                        })
                    }
                >
                    {t('forms.currency')} {t('button.change')}
                </SaveButton>
            </>
        )
    }

    changeSalary = (type, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                salary: {
                    ...this.state[type].salary,
                    data: {
                        ...this.state[type].salary.data,
                        salary: value,
                    },
                },
            },
        })
    }

    changeSalaryCurrency = (type, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                salary: {
                    ...this.state[type].salary,
                    data: {
                        ...this.state[type].salary.data,
                        currency: value,
                    },
                },
            },
        })
    }

    editRadiusForm = () => {
        const {radius, radiusUnit} = this.state.locationData.radius.data
        const {t} = this.props

        const invalidRadius =
            radius == 0 || radius < MIN_RADIUS || radius > MAX_RADIUS

        return (
            <>
                <InfoMessage style={{marginTop: 25, marginBottom: 15}}>
                    {this.state.locationData.radius.description}
                </InfoMessage>
                <div style={{display: 'flex', margin: '0 -5px'}}>
                    <InputSection style={{flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.radius')}</StyledLabel>
                        <input
                            value={radius || ''}
                            type={'number'}
                            step={RADIUS_STEP}
                            min={MIN_RADIUS}
                            max={MAX_RADIUS}
                            onChange={(event) =>
                                this.changeRadius(
                                    'locationData',
                                    event.target.value
                                )
                            }
                        />
                    </InputSection>
                    <InputSection style={{flex: '0.5 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.radiusUnit')}</StyledLabel>
                        <StyledSelect
                            value={radiusUnit || ''}
                            onChange={(event) =>
                                this.changeRadiusUnit(
                                    'locationData',
                                    event.target.value
                                )
                            }
                        >
                            <option value={'km'}>
                                {t('forms.radiusKilometers')}
                            </option>
                            <option value={'mi'}>
                                {t('forms.radiusMiles')}
                            </option>
                        </StyledSelect>
                    </InputSection>
                </div>
                {invalidRadius && (
                    <p
                        style={{
                            textAlign: 'left',
                            fontSize: '0.8em',
                            marginTop: 15,
                            marginBottom: 0,
                        }}
                    >
                        <InfoIcon/>
                        {t('forms.radiusRemark')}
                    </p>
                )}
                <SaveButton
                    primary
                    disabled={invalidRadius}
                    onClick={() =>
                        this.updateValue('locationData', 'radius', {
                            radius: radius,
                            radius_unit: radiusUnit,
                        })
                    }
                >
                    {t('forms.radius')} {t('button.change')}
                </SaveButton>
            </>
        )
    }

    changeRadius = (type, value) => {
        value = value.replace(/,/g, '.')

        this.setState({
            [type]: {
                ...this.state[type],
                radius: {
                    ...this.state[type].radius,
                    data: {
                        ...this.state[type].radius.data,
                        radius: value,
                    },
                },
            },
        })
    }

    changeRadiusUnit = (type, value) => {
        this.setState({
            [type]: {
                ...this.state[type],
                radius: {
                    ...this.state[type].radius,
                    data: {
                        ...this.state[type].radius.data,
                        radiusUnit: value,
                    },
                },
            },
        })
    }

    editPaymentForm = () => {
        const {t} = this.props
        const {bank, bic, bic2, ban, blz, iban, paypal, check_payment} =
            this.state.invoiceData.paymentInformation.data
        const {bankUnion} = this.state

        const insideUnionHasValue = iban || bic
        const insideUnionComplete = iban && bic
        const insideUnionValid =
            !insideUnionHasValue || (insideUnionHasValue && insideUnionComplete) // Completely empty or completely filled is valid

        const outsideUnionHasValue = bank || ban || blz || bic2
        const outsideUnionComplete = bank && ban && blz && bic2
        const outsideUnionValid =
            !outsideUnionHasValue ||
            (outsideUnionHasValue && outsideUnionComplete) // Completely empty or completely filled is valid

        const atLeastOneComplete =
            check_payment || paypal || (iban && bic) || (bank && ban && blz && bic2)

        // Since a valid payment method is also an empty payment method, first check is that at least one is filled
        const validPaymentMethods =
            atLeastOneComplete && insideUnionValid && outsideUnionValid

        return (
            <>
                <InfoMessage style={{marginTop: 25, marginBottom: 15}}>
                    {t('personalData.invoiceDataDescription')}
                </InfoMessage>

                <form
                    onSubmit={(event) => {
                        event.preventDefault()
                        this.updateValue('invoiceData', 'paymentInformation', {
                            bank: !bank?.includes('*') ? bank : undefined,
                            iban: !iban?.includes('*') ? iban : undefined,
                            bic:
                                bankUnion === 'insideUnion'
                                    ? !bic?.includes('*')
                                        ? bic
                                        : undefined
                                    : !bic2?.includes('*')
                                        ? bic2
                                        : undefined,
                            ban: !ban?.includes('*') ? ban : undefined,
                            blz: !blz?.includes('*') ? blz : undefined,
                            paypal: !paypal?.includes('*') ? paypal : undefined,
                            check_payment: check_payment,
                        })
                    }}
                >
                    <InputSection>
                        <StyledLabel>{'PAYPAL'}</StyledLabel>
                        <input
                            value={paypal || ''}
                            type={'text'}
                            onChange={(event) =>
                                this.changeValue(
                                    'invoiceData',
                                    'paymentInformation',
                                    'paypal',
                                    event.target.value
                                )
                            }
                        />
                    </InputSection>
                    <InputSection>
                        <Checkbox
                            style={{
                                marginTop: SPACING.large,
                            }}
                        >
                            <input
                                id="check_payment"
                                type="checkbox"
                                checked={check_payment}
                                onChange={(event) => {
                                    this.changeValue(
                                        'invoiceData',
                                        'paymentInformation',
                                        'check_payment',
                                        !check_payment
                                    )
                                }}
                            />
                            <label htmlFor="check_payment">
                                {t('forms.checkPayment')}
                            </label>
                        </Checkbox>
                    </InputSection>
                    <div
                        style={{
                            marginBottom: 5,
                            marginTop: 35,
                            cursor: 'pointer',
                        }}
                    >
                        <input
                            style={{
                                marginLeft: 24,
                                marginRight: 5,
                                marginBottom: 1,
                                cursor: 'pointer',
                            }}
                            type="radio"
                            name="bank-selection"
                            value="insideUnion"
                            checked={this.state.bankUnion === 'insideUnion'}
                            onChange={() => {
                                this.setState({
                                    ...this.state,
                                    bankUnion: 'insideUnion',
                                    invoiceData: {
                                        ...this.state.invoiceData,
                                        paymentInformation: {
                                            ...this.state.invoiceData
                                                .paymentInformation,
                                            data: {
                                                ...this.state.invoiceData
                                                    .paymentInformation.data,
                                                bank: '',
                                                ban: '',
                                                blz: '',
                                                bic2: '',
                                            },
                                        },
                                    },
                                })
                            }}
                        />
                        <label htmlFor="bank-selection">
                            {t('forms.bankWithinUnion')}
                        </label>
                    </div>
                    <div style={{marginBottom: 15, cursor: 'pointer'}}>
                        <input
                            style={{
                                marginLeft: 24,
                                marginRight: 5,
                                marginBottom: 1,
                                cursor: 'pointer',
                            }}
                            type="radio"
                            name="bank-selection"
                            value="outsideUnion"
                            checked={this.state.bankUnion === 'outsideUnion'}
                            onChange={() => {
                                this.setState({
                                    ...this.state,
                                    bankUnion: 'outsideUnion',
                                    invoiceData: {
                                        ...this.state.invoiceData,
                                        paymentInformation: {
                                            ...this.state.invoiceData
                                                .paymentInformation,
                                            data: {
                                                ...this.state.invoiceData
                                                    .paymentInformation.data,
                                                iban: '',
                                                bic: '',
                                            },
                                        },
                                    },
                                })
                            }}
                        />
                        <label htmlFor="bank-selection">
                            {t('forms.bankOutsideUnion')}
                        </label>
                    </div>

                    {bankUnion === 'insideUnion' && (
                        <div style={{display: 'flex', margin: '0 -5px'}}>
                            <InputSection
                                style={{flex: '1 1 0', margin: '0 5px'}}
                            >
                                <StyledLabel>{t('forms.iban')}</StyledLabel>
                                <input
                                    value={iban || ''}
                                    type={'text'}
                                    onChange={(event) =>
                                        this.changeValue(
                                            'invoiceData',
                                            'paymentInformation',
                                            'iban',
                                            event.target.value
                                        )
                                    }
                                />
                            </InputSection>
                            <InputSection
                                style={{flex: '1 1 0', margin: '0 5px'}}
                            >
                                <StyledLabel>{t('forms.bic')}</StyledLabel>
                                <input
                                    value={bic || ''}
                                    type={'text'}
                                    onChange={(event) =>
                                        this.changeValue(
                                            'invoiceData',
                                            'paymentInformation',
                                            'bic',
                                            event.target.value
                                        )
                                    }
                                />
                            </InputSection>
                        </div>
                    )}

                    {bankUnion === 'outsideUnion' && (
                        <>
                            <div style={{display: 'flex', margin: '0 -5px'}}>
                                <InputSection
                                    style={{flex: '1 1 0', margin: '0 5px'}}
                                >
                                    <StyledLabel>{t('forms.bank')}</StyledLabel>
                                    <input
                                        value={bank || ''}
                                        type={'text'}
                                        onChange={(event) =>
                                            this.changeValue(
                                                'invoiceData',
                                                'paymentInformation',
                                                'bank',
                                                event.target.value
                                            )
                                        }
                                    />
                                </InputSection>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    margin: '0 -5px',
                                    marginTop: 15,
                                }}
                            >
                                <InputSection
                                    style={{flex: '1 1 0', margin: '0 5px'}}
                                >
                                    <StyledLabel>
                                        {t('forms.bankAccountNumber')}
                                    </StyledLabel>
                                    <input
                                        value={ban || ''}
                                        type={'text'}
                                        onChange={(event) =>
                                            this.changeValue(
                                                'invoiceData',
                                                'paymentInformation',
                                                'ban',
                                                event.target.value
                                            )
                                        }
                                    />
                                </InputSection>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    margin: '0 -5px',
                                    marginTop: 15,
                                }}
                            >
                                <InputSection
                                    style={{flex: '1 1 0', margin: '0 5px'}}
                                >
                                    <StyledLabel>{t('forms.blz')}</StyledLabel>
                                    <input
                                        value={blz || ''}
                                        type={'text'}
                                        onChange={(event) =>
                                            this.changeValue(
                                                'invoiceData',
                                                'paymentInformation',
                                                'blz',
                                                event.target.value
                                            )
                                        }
                                    />
                                </InputSection>
                                <InputSection
                                    style={{flex: '1 1 0', margin: '0 5px'}}
                                >
                                    <StyledLabel>{t('forms.bic')}</StyledLabel>
                                    <input
                                        value={bic2 || ''}
                                        type={'text'}
                                        onChange={(event) =>
                                            this.changeValue(
                                                'invoiceData',
                                                'paymentInformation',
                                                'bic2',
                                                event.target.value
                                            )
                                        }
                                    />
                                </InputSection>
                            </div>
                        </>
                    )}

                    <SaveButton primary type={'submit'}>
                        {t('personalData.invoiceData')}{' '}
                        <span style={{textTransform: 'lowercase'}}>
                            {t('button.edit')}
                        </span>
                    </SaveButton>
                </form>
            </>
        )
    }

    setPassword = (input) => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            password: input.password,
            passwordIsValid: input.isValid,
        })
    }

    setPasswordConfirm = (input) => {
        this.setState({
            ...this.state,
            passLength: input.password.length,
            passwordConfirm: input.password,
        })
    }

    clear = () => this.ReactPasswordStrength.clear()

    updatePaused = () => {
        const {paused} = this.state

        this.setIsLoading(true)
        this.accountService.updateUserData({paused: !paused}).then((res) => {
            this.setIsLoading(false)
            this.setState({paused: !paused})
        })
    }

    setDeletePopup = (show) => {
        this.setState({deletePopup: show, deletePopupValue: ''})
    }

    setQualificationPopup = (show) => {
        this.setState({
            uploadQualificationPopup: show,
            fileName: '',
            currentFile: '',
        })
    }

    changeDeletePopupValue = (value) => {
        this.setState({deletePopupValue: value})
    }

    deleteUserAccount = () => {
        const {logout} = this.props

        this.setIsLoading(true)
        this.accountService.deleteAccount().then((res) => {
            this.setIsLoading(false)
            logout()
        })
    }

    render() {
        const {
            personalData,
            accountData,
            locationData,
            invoiceData,
            qualificationData,
            paused,
            isLoading,
            deletePopup,
            deletePopupValue,
            uploadQualificationPopup,
            fileName,
            currentFile,
            currentFileSize,
            showMessage,
            message,
        } = this.state
        const {t} = this.props

        const validFileandName = currentFile !== '' && fileName !== ''
        const validFileSize = currentFileSize < 5
        const validUpload = validFileandName && validFileSize

        return (
            <>
                <SectionHeading
                    title={t('personalData.account')}
                    description={t('personalData.accountDescription')}
                />
                <List
                    items={Object.values(accountData)}
                    itemsAutoHeight
                    getListItemStructure={this.getListStructure}
                />

                <SectionHeading
                    title={t('personalData.contact')}
                    description={t('personalData.contactDescription')}
                />
                <List
                    items={Object.values(personalData)}
                    itemsAutoHeight
                    getListItemStructure={this.getListStructure}
                />

                <SectionHeading
                    title={t('personalData.salary')}
                    description={t('personalData.salaryDescription')}
                />
                <List
                    items={Object.values(locationData)}
                    itemsAutoHeight
                    getListItemStructure={this.getListStructure}
                />

                <SectionHeading
                    title={t('personalData.invoice')}
                    description={t('personalData.invoiceDescription')}
                />
                <List
                    items={Object.values(invoiceData)}
                    itemsAutoHeight
                    getListItemStructure={this.getListStructure}
                />

                <SectionHeading
                    title={t('personalData.qualification')}
                    description={t('personalData.qualificationDescription')}
                />
                <List
                    items={Object.values(qualificationData.files.data)}
                    noItemsMessage={t('personalData.noQualificationsUploaded')}
                    itemsAutoHeight
                    getListItemStructure={this.getQualificationListStructure}
                />
                <div style={{textAlign: 'right', marginTop: 15}}>
                    <StyledButton
                        primary
                        onClick={() => this.setQualificationPopup(true)}
                    >
                        {t('personalData.uploadQualification')}
                    </StyledButton>
                </div>
                {uploadQualificationPopup && (
                    <Popup
                        title={t('personalData.uploadQualification')}
                        hidePopup={() => this.setQualificationPopup(false)}
                        size={'large'}
                    >
                        <InfoMessage style={{marginBottom: 25}}>
                            {qualificationData.files.description}
                        </InfoMessage>
                        <div style={{display: 'flex', margin: '0 -5px'}}>
                            <InputSection
                                style={{flex: '1 1 0', margin: '0 5px'}}
                            >
                                <input
                                    type={'file'}
                                    accept={
                                        'application/pdf,image/jpeg,image/png'
                                    }
                                    style={{
                                        marginTop: 8,
                                        marginBottom: 15,
                                        paddingLeft: 0,
                                    }}
                                    onChange={(event) =>
                                        this.prepareFileupload(
                                            event.target.files[0]
                                        )
                                    }
                                />
                            </InputSection>
                        </div>
                        {!validFileSize && currentFile && (
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: '0.8em',
                                    marginTop: 15,
                                    marginBottom: 0,
                                }}
                            >
                                <InfoIcon/>
                                {t('popup.fileSizeRemark')}
                            </p>
                        )}
                        <StyledButton
                            primary
                            disabled={!validUpload}
                            style={{width: '100%', marginTop: 20}}
                            onClick={() =>
                                this.updateQualification(
                                    'qualificationData',
                                    'files',
                                    fileName,
                                    currentFile
                                )
                            }
                        >
                            {t('personalData.sendQualification')}
                        </StyledButton>
                        <StyledButton
                            tertiary
                            style={{width: '100%', marginTop: 10}}
                            onClick={() => this.setQualificationPopup(false)}
                        >
                            {t('popup.abort')}
                        </StyledButton>
                    </Popup>
                )}

                <SectionHeading
                    title={t('personalData.pause')}
                    description={t('personalData.pauseDescription')}
                />
                <ListElementWrapper itemsAutoHeight>
                    <ListElement itemsAutoHeight>
                        <InputSection
                            style={{flex: '1 1 0', margin: '0 5px'}}
                        >
                            <StyledLabel>Modus</StyledLabel>
                            <FormValue>
                                {paused
                                    ? t('personalData.inactive')
                                    : t('personalData.active')}
                            </FormValue>
                        </InputSection>
                    </ListElement>
                    <ListElement itemsAutoHeight>
                        <Toggle
                            checked={!paused}
                            onChange={(e) => this.updatePaused()}
                        />
                    </ListElement>
                </ListElementWrapper>

                <div style={{textAlign: 'center', marginTop: 65}}>
                    <p
                        style={{
                            fontSize: '0.9em',
                            marginBottom: 5,
                            color: '#666666',
                        }}
                    >
                        {t('personalData.wantDelete')}
                    </p>
                    <TextButton onClick={() => this.setDeletePopup(true)}>
                        {t('personalData.confirmDelete')}
                    </TextButton>
                </div>

                {isLoading && <Loader/>}
                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}

                {deletePopup && (
                    <Popup
                        title={t('personalData.wantDeleteAlt')}
                        hidePopup={() => this.setDeletePopup(false)}
                        size={'large'}
                    >
                        <p>{t('personalData.notReversable')}</p>

                        <p>{t('personalData.areYouSure')}</p>

                        <InputSection style={{marginTop: 25}}>
                            <StyledLabel>{t('forms.name')}</StyledLabel>
                            <input
                                value={deletePopupValue}
                                type={'text'}
                                onChange={(e) =>
                                    this.changeDeletePopupValue(e.target.value)
                                }
                            />
                        </InputSection>

                        <StyledButton
                            primary
                            style={{
                                width: '100%',
                                marginTop: 15,
                                marginBottom: 15,
                                backgroundColor: '#d01a1a',
                            }}
                            disabled={
                                personalData &&
                                `${personalData.name.data.firstname} ${personalData.name.data.lastname}` !==
                                deletePopupValue
                            }
                            onClick={() => this.deleteUserAccount()}
                        >
                            {t('personalData.delete')}
                        </StyledButton>
                        <StyledButton
                            tertiary
                            style={{width: '100%'}}
                            onClick={() => this.setDeletePopup(false)}
                        >
                            {t('popup.abort')}
                        </StyledButton>
                    </Popup>
                )}
            </>
        )
    }
}

export default withTranslation()(FreelancerPersonalDataWidget)
