import React, { Component } from 'react'
import { FlexDiv, StyledButton, StyledWrapper } from '../styled-components'
import styled from 'styled-components'
import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    DEVICE,
    MIN_RADIUS,
    MAX_RADIUS,
    MIN_SALARY,
    MAX_SALARY,
} from '../../assets/constants'
import FreelancerSalaryForm from '../account-setup/freelancer-salary-form'
import { MdInfoOutline } from 'react-icons/md'
import FreelancerCourseForm from '../account-setup/freelancer-course-form'
import FreelancerLocationForm from '../account-setup/freelancer-location-form'
import FreelancerPersonalDataForm from '../account-setup/freelancer-personal-data-form'
import JobbelIcon from '../../assets/images/jobbelganger-icon.png'
import { formatMoney } from '../../utils/utility-functions'
import Loader from '../loader'
import { withTranslation } from 'react-i18next'
import AccountService from '../../../services/account-service'
import CoursesService from '../../../services/courses-service'
import i18n, { locales } from '../../../i18n/config'
import { format } from 'date-fns'

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const InfoPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: ${SECONDARY_COLOR};
    color: #fff;
    flex: 0.4 1 0 !important;
    margin: -30px;
    padding: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media ${DEVICE.phone} {
        display: none;
    }

    @media ${DEVICE.tablet} {
        display: none;
    }
`

const Step = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    position: relative;
    margin: ${(props) => (props.active ? '0 -10px 0 -10px' : 'initial')};
    flex: 1 1 0;
    width: 0;
    cursor: pointer;
`

const SetupDiv = styled(FlexDiv)`
    min-height: 650px;
    height: 55vh;

    @media ${DEVICE.tablet} {
        height: 75vh;
    }

    @media ${DEVICE.phone} {
        height: calc(100vh - 200px);
        min-height: auto;
    }

    @media ${DEVICE.short_phone} {
        height: calc(100vh - 200px);
    }
`

const SetupFormsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 60px;

    @media ${DEVICE.tablet} {
        margin-right: 0;
    }

    @media ${DEVICE.phone} {
        margin-right: 0;
    }
`

const StyledDescriptionHeader = styled.h2`
    @media ${DEVICE.phone} {
        font-size: 1.2em;
    }
`

const StyledDescription = styled.p`
    @media ${DEVICE.phone} {
        font-size: 0.9em;
    }
`

const StepCircle = styled.div`
    color: #ffffff;
    font-weight: bold;
    font-size: 0.7em;
    width: ${(props) => (props.active ? '30px' : '10px')};
    height: ${(props) => (props.active ? '30px' : '10px')};
    color: ${(props) => (props.active ? '#ffffff' : 'transparent')};
    background-color: ${(props) => (props.active ? '#db64ff' : '#838383')};
    border-radius: ${(props) => (props.active ? '15px' : '5px')};
    display: flex;
    justify-content: center;
    align-items: center;
`

const StepDescription = styled.div`
    color: #c3b6c7;
    font-weight: bold;
    text-align: center;
    margin-top: 25px;
    line-height: 1.2;
    transform: ${(props) => (props.active ? 'translateY(-10px)' : 'initial')};
    font-size: 0.8em;
    letter-spacing: 0;
    height: 4em;

    @media ${DEVICE.phone} {
        opacity: 0;
        margin-top: -24px;
    }
`

const LineWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    padding: 13px 13%;
`

const StepLine = styled.div`
    width: 33.33%;
    height: 4px;
    background: ${(props) => (props.active ? '#db64ff' : '#838383')};
`

const MessageWrapper = styled.div`
    margin-bottom: 15px;

    @media ${DEVICE.phone} {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 5px !important;
        font-size: 0.7em;

        @media ${DEVICE.phone} {
            line-height: 1.7;
        }
    }

    a {
        color: ${PRIMARY_COLOR};
        cursor: pointer;
    }
`

const SetupButton = styled(StyledButton)`
    width: 250px;

    @media ${DEVICE.phone} {
        width: 100%;
        margin-top: 20px;
    }
`

class FreelancerSetupWidget extends Component {
    constructor(props) {
        super(props)
        const { t } = props

        this.steps = [
            t('setup.freelancer.salary'),
            t('setup.freelancer.courses'),
            t('setup.freelancer.location'),
            t('setup.freelancer.personalData'),
        ]
        this.stepDescriptions = [
            {
                title: t('setup.freelancer.salary'),
                label: t('setup.freelancer.salaryDescription'),
            },
            {
                title: t('setup.freelancer.coursesAlt'),
                label: t('setup.freelancer.coursesDescription'),
            },
            {
                title: t('setup.freelancer.locationAlt'),
                label: t('setup.freelancer.locationDescription'),
            },
            {
                title: t('setup.freelancer.personalDataAlt'),
                label: t('setup.freelancer.personalDataDescription'),
            },
        ]

        this.state = {
            profile: undefined,
            currentStep: 0,
            salary: 15,
            currency: 'EUR',
            unfilteredCourses: [],
            courses: [],
            selectedCourses: [],
            birth: '',
            street: '',
            housenumber: '',
            zipcode: '',
            city: '',
            state: '',
            country: '',
            phone: '',
            radius: 5,
            radiusUnit: 'km',
            place: '',
            isLoading: '',
            countries: [],
            fitnessFilter: true,
            gastronomyFilter: true,
        }

        this.accountService = new AccountService()
        this.coursesService = new CoursesService()
    }

    async componentDidMount() {
        this.accountService.getMe().then((res) => {
            if (res && res.data) {
                this.setState({ ...this.state, profile: res.data })
            }
        })

        const countries = await this.accountService.getCountries()
        this.setState({ ...this.state, countries: countries.data })

        this.getAllCourses()
    }

    getAllCourses() {
        this.coursesService.getAllCourses().then((courses) => {
            const sortedCourses = this.sortCoursesByCategory(courses)
            this.setState({
                unfilteredCourses: sortedCourses,
                courses: sortedCourses,
            })
        })
    }

    sortCoursesByCategory(courses) {
        let sortedCourses = []
        courses.forEach((course) => {
            course.categories.forEach((category) => {
                if (category.id in sortedCourses) {
                    sortedCourses[category.id].options.push({
                        label: course.name,
                        value: course.id,
                    })
                } else {
                    sortedCourses[category.id] = {
                        label: category.name,
                        typeId: course.type_id,
                        options: [
                            {
                                label: course.name,
                                value: course.id,
                            },
                        ],
                    }
                }
            })
        })

        const sortedCoursesArray = []
        Object.keys(sortedCourses).forEach((key) => {
            sortedCoursesArray.push(sortedCourses[key])
        })

        return sortedCoursesArray
    }

    getSetupNavigation = (currentStep) => {
        return (
            <div style={{ display: 'flex', position: 'relative' }}>
                <LineWrapper>
                    {this.steps.map((stepDescription, index) => {
                        if (index === this.steps.length - 1) return
                        return (
                            <StepLine
                                key={index}
                                active={currentStep >= index + 1}
                            ></StepLine>
                        )
                    })}
                </LineWrapper>
                {this.steps.map((stepDescription, index) => {
                    return (
                        <Step
                            key={index}
                            onClick={() => this.setCurrentStep(index)}
                        >
                            <StepCircle active={currentStep >= index}>
                                {index + 1}
                            </StepCircle>
                            <StepDescription
                                active={currentStep >= index}
                                focus={currentStep == index}
                            >
                                {stepDescription}
                            </StepDescription>
                        </Step>
                    )
                })}
            </div>
        )
    }

    setIsLoading = (loading) => {
        this.setState({ ...this.state, isLoading: loading })
    }

    setCurrentStep = (step) => {
        this.setState({ ...this.state, currentStep: step })
    }

    setFitnessFilter = (value) => {
        const { unfilteredCourses, gastronomyFilter } = this.state
        const activeFilters = [
            ...(value ? [1] : []),
            ...(gastronomyFilter ? [2] : []),
        ]

        this.setState({
            ...this.state,
            fitnessFilter: value,
            courses: unfilteredCourses.filter((category) =>
                activeFilters.includes(category.typeId)
            ),
        })
    }

    setGastronomyFilter = (value) => {
        const { unfilteredCourses, fitnessFilter } = this.state
        const activeFilters = [
            ...(fitnessFilter ? [1] : []),
            ...(value ? [2] : []),
        ]

        this.setState({
            ...this.state,
            gastronomyFilter: value,
            courses: unfilteredCourses.filter((category) =>
                activeFilters.includes(category.typeId)
            ),
        })
    }

    changeValue = (key, value) => {
        this.setState({ ...this.state, [key]: value })
    }

    setPlace = (place) => {
        const address = {}
        const short = {}
        place.address_components.forEach((component) => {
            address[component.types[0]] = component.long_name
            short[component.types[0]] = component.short_name
        })

        this.setState({
            ...this.state,
            place: place,
            street: address.route,
            housenumber: address.street_number ? address.street_number : 1,
            zipcode: address.postal_code,
            city:
                address.locality ||
                address.political ||
                address.sublocality_level_1,
            state: address.administrative_area_level_1
                ? address.administrative_area_level_1
                : '-',
            country: short.country,
        })
    }

    finishSetup = async () => {
        const {
            currency,
            salary,
            phone,
            birth,
            street,
            housenumber,
            zipcode,
            city,
            state,
            country,
            radius,
            radiusUnit,
            selectedCourses,
        } = this.state
        const courses = selectedCourses.map((course) => course.value)
        const { setup } = this.props

        this.setIsLoading(true)

        const data = {
            currency,
            salary,
            phone,
            birth,
            street,
            housenumber: housenumber.toString(),
            zipcode,
            city,
            state,
            country,
            radius,
            radiusUnit,
            courses,
        }

        try {
            const response = await setup(data)
            this.setIsLoading(false)
        } catch (error) {
            this.setIsLoading(false)
        }
    }

    render() {
        const {
            profile,
            currentStep,
            salary,
            currency,
            phone,
            birth,
            courses,
            selectedCourses,
            street,
            housenumber,
            zipcode,
            city,
            state,
            country,
            radius,
            radiusUnit,
            place,
            isLoading,
            countries,
            fitnessFilter,
            gastronomyFilter,
        } = this.state
        const { t } = this.props
        const currentLang = i18n.language

        const setupIsReady =
            salary &&
            phone &&
            birth &&
            street &&
            housenumber &&
            zipcode &&
            city &&
            country &&
            radius &&
            selectedCourses &&
            selectedCourses.length > 0

        return (
            <>
                <StyledWrapper style={{ maxWidth: 1050 }}>
                    <SetupDiv>
                        <SetupFormsWrapper>
                            {this.getSetupNavigation(currentStep)}
                            <div
                                style={{ height: '65%', marginBottom: 'auto' }}
                            >
                                {currentStep === 0 && (
                                    <>
                                        <StyledDescriptionHeader>
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].title
                                            }
                                        </StyledDescriptionHeader>
                                        <StyledDescription
                                            style={{ marginBottom: 25 }}
                                        >
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].label
                                            }
                                        </StyledDescription>
                                        <FreelancerSalaryForm
                                            salary={salary}
                                            countries={countries}
                                            currency={currency}
                                            changeValue={this.changeValue}
                                        />
                                    </>
                                )}
                                {currentStep === 1 && (
                                    <>
                                        <StyledDescriptionHeader>
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].title
                                            }
                                        </StyledDescriptionHeader>
                                        <StyledDescription
                                            style={{ marginBottom: 25 }}
                                        >
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].label
                                            }
                                        </StyledDescription>
                                        <FreelancerCourseForm
                                            courses={courses}
                                            selectedCourses={selectedCourses}
                                            fitnessFilter={fitnessFilter}
                                            setFitnessFilter={
                                                this.setFitnessFilter
                                            }
                                            gastronomyFilter={gastronomyFilter}
                                            setGastronomyFilter={
                                                this.setGastronomyFilter
                                            }
                                            changeValue={this.changeValue}
                                        />
                                    </>
                                )}
                                {currentStep === 2 && (
                                    <>
                                        <StyledDescriptionHeader>
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].title
                                            }
                                        </StyledDescriptionHeader>
                                        <StyledDescription
                                            style={{ marginBottom: 25 }}
                                        >
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].label
                                            }
                                        </StyledDescription>
                                        <FreelancerLocationForm
                                            place={place}
                                            theRadius={radius}
                                            radiusUnit={radiusUnit}
                                            setPlace={this.setPlace}
                                            changeValue={this.changeValue}
                                        />
                                    </>
                                )}

                                {currentStep === 3 && (
                                    <>
                                        <StyledDescriptionHeader>
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].title
                                            }
                                        </StyledDescriptionHeader>
                                        <StyledDescription
                                            style={{ marginBottom: 25 }}
                                        >
                                            {
                                                this.stepDescriptions[
                                                    currentStep
                                                ].label
                                            }
                                        </StyledDescription>
                                        <FreelancerPersonalDataForm
                                            phone={phone}
                                            currentLang={currentLang}
                                            birth={birth}
                                            countries={countries}
                                            changeValue={this.changeValue}
                                        />
                                    </>
                                )}
                            </div>

                            <div>
                                {currentStep === 0 && (
                                    <MessageWrapper>
                                        {(!salary ||
                                            salary < MIN_SALARY ||
                                            salary > MAX_SALARY) && (
                                            <p>
                                                <InfoIcon />
                                                {t('forms.chooseValidSalary')}
                                            </p>
                                        )}
                                    </MessageWrapper>
                                )}
                            </div>

                            <div>
                                {currentStep === 3 && (
                                    <MessageWrapper>
                                        {(!salary ||
                                            salary < MIN_SALARY ||
                                            salary > MAX_SALARY) && (
                                            <p>
                                                <InfoIcon />
                                                {t('forms.chooseValidSalary')}
                                            </p>
                                        )}
                                        {selectedCourses &&
                                            selectedCourses.length === 0 && (
                                                <p>
                                                    <InfoIcon />
                                                    {t(
                                                        'forms.chooseValidCourses'
                                                    )}
                                                </p>
                                            )}
                                        {!(
                                            street &&
                                            housenumber &&
                                            city &&
                                            zipcode &&
                                            radius &&
                                            radius >= MIN_RADIUS &&
                                            radius <= MAX_RADIUS
                                        ) && (
                                            <p>
                                                <InfoIcon />
                                                {t('forms.chooseValidAddress')}
                                            </p>
                                        )}
                                        {!phone && (
                                            <p>
                                                <InfoIcon />
                                                {t('forms.chooseValidPhone')}
                                            </p>
                                        )}
                                        {!birth && (
                                            <p>
                                                <InfoIcon />
                                                {t('forms.chooseValidBirthday')}
                                            </p>
                                        )}
                                    </MessageWrapper>
                                )}

                                {currentStep === 3 ? (
                                    <SetupButton
                                        primary
                                        onClick={this.finishSetup}
                                        disabled={!setupIsReady}
                                    >
                                        {t('setup.finish')}
                                    </SetupButton>
                                ) : (
                                    <SetupButton
                                        onClick={() =>
                                            this.setCurrentStep(currentStep + 1)
                                        }
                                    >
                                        {t('setup.nextStep')}
                                    </SetupButton>
                                )}
                                {/* <SetupButton tertiary
                                onClick={() => {
                                    const { logout } = this.props;

                                    logout();
                                }}>
                                {t('setup.abort')}
                            </SetupButton> */}
                            </div>
                        </SetupFormsWrapper>

                        <InfoPanel>
                            <img
                                src={JobbelIcon}
                                width={35}
                                style={{ marginBottom: 20 }}
                            />
                            <h1 style={{ color: '#ffffff', marginTop: 5 }}>
                                {t('setup.yourJobbel')}
                            </h1>
                            <p>
                                {profile &&
                                    `${profile.firstname} ${profile.lastname}`}
                            </p>
                            <p>
                                {birth
                                    ? `${format(new Date(birth), 'P', {
                                          locale: locales[currentLang],
                                      })}`
                                    : ''}
                            </p>
                            <br />
                            <p>{profile && `${profile.email}`}</p>
                            {phone && (
                                <p>{`+${phone}`}</p>
                            )}
                            <br />
                            <p>
                                {street
                                    ? `${street} ${housenumber}, ${city}, ${state}, ${country} + ${+parseFloat(
                                          radius
                                      ).toFixed(1)} ${radiusUnit}`
                                    : ''}
                            </p>
                            <br />
                            <p>
                                {formatMoney(salary)} {currency}{' '}
                                {t('setup.freelancer.perHour')}
                            </p>
                            <p>
                                {selectedCourses && selectedCourses.length
                                    ? `${selectedCourses.length} ${t(
                                          'setup.freelancer.coursesChosen'
                                      )}`
                                    : ''}
                            </p>
                            <br />
                        </InfoPanel>
                    </SetupDiv>
                </StyledWrapper>

                {isLoading && <Loader />}
            </>
        )
    }
}

export default withTranslation()(FreelancerSetupWidget)
