import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import FreelancerOpenRatingsWidget from "../../legacy/components/widgets/freelancer-open-ratings-widget";
import OpenRatingsWidget from "../../legacy/components/widgets/open-ratings-widget";

const OpenRatings = () => {

    const { user } = useAuth();
    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.openRatings')}
        </h1>

        <p>
            {user && user.role === 'freelancer'
                ? t('pages.description.openRatingsFreelancer')
                : t('pages.description.openRatingsChef')}
        </p>

        {user && user.role === 'freelancer'
            ? <FreelancerOpenRatingsWidget />
            : <OpenRatingsWidget />}
    </>;

}

export default OpenRatings;