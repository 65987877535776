import React, { Component } from 'react'
import './search-form.scss'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import Select, { components } from 'react-select'
import dateFormat from 'dateformat'
import { StyledButton } from '../styled-components'
import { DEVICE, PRIMARY_COLOR } from '../../assets/constants'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { MdInfoOutline } from 'react-icons/md'
import { AiFillCaretDown } from 'react-icons/ai'
import '../../assets/less/datepicker.scss'
import CoursesService from '../../../services/courses-service'
import SearchService from '../../../services/search-service'
import i18n from '../../../i18n/config'

const InfoIcon = styled(MdInfoOutline)`
    margin-bottom: -2px;
    margin-right: 5px;
    color: #db64ff;
`

const FilterContainer = styled.div``

const FilterSelection = styled.p`
    margin: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const FilterCheckbox = styled.input`
    width: 5% !important;
`

const FilterLabel = styled.span`
    margin: 0 5px !important;
`

const SearchButton = styled(StyledButton)`
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 50%;

    @media ${DEVICE.phone} {
        position: initial;
        width: 100%;
        padding: 10px;
        font-size: 13px;
        margin-top: 35px;
    }
`

const SearchFormWrapper = styled.div`
    padding-bottom: 50px;

    .react-datepicker-wrapper {
        width: 100%;
    }

    @media ${DEVICE.phone} {
        padding-bottom: 0px !important;
    }
`

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <AiFillCaretDown size={14} style={{ marginRight: 3 }} />
        </components.DropdownIndicator>
    )
}

const Placeholder = (props) => {
    return <components.Placeholder {...props} />
}

class SearchForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            courses: [],
            initialCourses: [],
            selectedCourse: undefined,
            selectedDate: new Date().setDate(new Date().getDate() + 1),
            selectedFrom: new Date().setHours(12, 0),
            selectedUntil: new Date().setHours(13, 0),
            overnight: false,
            currentLanguage: i18n.language,
            fitnessFilterActive: false,
            gastroFilterActive: false,
            activeFilters: [],
        }

        this.coursesService = new CoursesService()
        this.searchService = new SearchService()

        this.searchTrainer = this.searchTrainer.bind(this)
    }

    componentDidMount() {
        this.coursesService.getAllCourses().then((courses) => {
            const sortedCourses = this.sortCoursesByCategory(courses)
            this.setState({
                ...this.state,
                initialCourses: sortedCourses,
                courses: sortedCourses,
            })
        })
    }

    updateActiveFilters(filterId, filteractive) {
        const { activeFilters } = this.state
        let newFilters = [...this.state.activeFilters]

        if (!activeFilters.includes(filterId) && filteractive) {
            newFilters.push(filterId)
        }

        if (activeFilters.includes(filterId) && !filteractive) {
            newFilters = newFilters.filter((val) => val !== filterId)
        }
        return newFilters
    }

    updateFitnessFilter = (fitnessFilterConfirmed) => {
        const FITNESS_ID = 1
        const NewActiveFilters = this.updateActiveFilters(
            FITNESS_ID,
            fitnessFilterConfirmed
        )

        this.setState({
            ...this.state,
            fitnessFilterActive: fitnessFilterConfirmed,
            activeFilters: NewActiveFilters,
            selectedCourse: 0,
        })
    }

    updateGastroFilter = (gastroFilterConfirmed) => {
        const GASTRO_ID = 2
        const NewActiveFilters = this.updateActiveFilters(
            GASTRO_ID,
            gastroFilterConfirmed
        )

        this.setState({
            ...this.state,
            gastroFilterActive: gastroFilterConfirmed,
            activeFilters: NewActiveFilters,
            selectedCourse: 0,
        })
    }

    handleCourseSelect(course) {
        this.setState({
            selectedCourse: course,
        })
    }

    handleDateChange(date) {
        this.setState({
            selectedDate: date,
        })
    }

    handleFromChange(from) {
        this.setState({
            selectedFrom: from,
            overnight: from > this.state.selectedUntil,
        })
    }

    handleUntilChange(until) {
        this.setState({
            selectedUntil: until,
            overnight: this.state.selectedFrom > until,
        })
    }

    sortCoursesByCategory(courses) {
        let sortedCourses = []
        courses.forEach((course) => {
            course.categories.forEach((category) => {
                if (category.id in sortedCourses) {
                    sortedCourses[category.id].options.push({
                        label: course.name,
                        id: course.id,
                    })
                } else {
                    sortedCourses[category.id] = {
                        label: category.name,
                        typeId: course.type_id,
                        options: [
                            {
                                label: course.name,
                                id: course.id,
                            },
                        ],
                    }
                }
            })
        })

        const sortedCoursesArray = []
        Object.keys(sortedCourses).forEach((key) => {
            sortedCoursesArray.push(sortedCourses[key])
        })

        sortedCoursesArray.forEach((category) => {
            category.options.sort((a, b) => {
                let aName = a.label.toLowerCase(),
                    bName = b.label.toLowerCase()

                if (aName < bName) {
                    return -1
                }
                if (aName > bName) {
                    return 1
                }

                return 0
            })
        })

        return sortedCoursesArray
    }

    searchTrainer() {
        const {
            setSearchResult,
            setIsLoading,
            setLoadingMessage,
            selectedBranch,
            t,
        } = this.props
        const { overnight, selectedDate, selectedFrom, selectedUntil } =
            this.state

        let endDate = new Date(selectedDate)
        if (overnight === true) {
            endDate.setDate(endDate.getDate() + 1)
        }

        setIsLoading(true)
        setLoadingMessage(t('search.searching'))
        this.searchService
            .searchTrainer(
                selectedBranch.branchId,
                this.state.selectedCourse.id,
                dateFormat(selectedDate, 'yyyy-mm-dd'),
                dateFormat(endDate, 'yyyy-mm-dd'),
                dateFormat(selectedFrom, 'HH:MM'),
                dateFormat(selectedUntil, 'HH:MM')
            )
            .then((res) => {
                setIsLoading(false)
                setLoadingMessage(undefined)
                setSearchResult(res)
            })
    }

    checkIfFilterActive = (activeFilters, courses) => {
        if (activeFilters.length) {
            let filteredCourses = courses.filter((course) => {
                for (const key in activeFilters) {
                    let typeId = activeFilters[key]
                    if (typeId === course.typeId) {
                        return true
                    }
                }
            })
            return filteredCourses
        }

        return this.state.initialCourses
    }

    render() {
        const { handleBranchSelect, branches, selectedBranch, t } = this.props
        const {
            overnight,
            courses,
            fitnessFilterActive,
            gastroFilterActive,
            activeFilters,
        } = this.state

        let courseOptions = this.checkIfFilterActive(activeFilters, courses)

        const currentLanguage = i18n.language

        let dateformat = 'd. MMMM yyyy'
        let currentTimeFormat = 'HH:mm'
        if (currentLanguage === 'en') {
            dateformat = 'MM/dd/yyyy'
            currentTimeFormat = 'hh:mm aa'
        }

        return (
            <SearchFormWrapper
                className={'search-form'}
                style={{ paddingBottom: 50 }}
            >
                <div className={'input studio'}>
                    <label>{t('forms.studio')}</label>
                    <Select
                        value={selectedBranch}
                        onChange={(branch) => handleBranchSelect(branch)}
                        options={branches}
                        isSearchable
                        components={{ Placeholder, DropdownIndicator }}
                        styles={{
                            dropdownIndicator: (provided, state) => ({
                                ...provided,
                                color: PRIMARY_COLOR,
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                    state.isFocused || state.isSelected
                                        ? PRIMARY_COLOR
                                        : 'rgba(0,0,0,0.10)',
                                boxShadow:
                                    state.isFocused || state.isSelected
                                        ? `0 0 0 1px ${PRIMARY_COLOR}`
                                        : `none`,

                                '&:hover': {
                                    borderColor: 'rgba(0,0,0,0.10)',
                                },
                            }),
                        }}
                        isDisabled={branches.length === 1}
                        placeholder={'Standort auswählen...'}
                    />
                </div>
                <div className={'input date'}>
                    <label>{t('forms.date')}</label>
                    <DatePicker
                        selected={this.state.selectedDate}
                        onChange={(date) => this.handleDateChange(date)}
                        dateFormat={dateformat}
                        locale={currentLanguage}
                        minDate={new Date()}
                    />
                </div>
                <div className={'time-wrapper'}>
                    <div className={'input time'}>
                        <label>{t('forms.from')}</label>
                        <DatePicker
                            selected={this.state.selectedFrom}
                            onChange={(from) => this.handleFromChange(from)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption={t('list.time')}
                            locale={currentLanguage}
                            dateFormat={currentTimeFormat}
                        />
                    </div>
                    <div className={'input time'}>
                        <label>{t('forms.to')}</label>
                        <DatePicker
                            selected={this.state.selectedUntil}
                            onChange={(until) => this.handleUntilChange(until)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption={t('list.time')}
                            locale={currentLanguage}
                            dateFormat={currentTimeFormat}
                        />
                    </div>
                </div>

                {overnight && (
                    <p style={{ fontSize: '0.8em' }}>
                        <InfoIcon />
                        {t('forms.overnight')}
                    </p>
                )}

                <div className={'input course'}>
                    <label style={{ marginTop: 15 }}>
                        {t('search.filter')}
                    </label>
                    <FilterContainer>
                        <FilterSelection>
                            <FilterCheckbox
                                type={'checkbox'}
                                id={'fitnessFilter'}
                                checked={fitnessFilterActive}
                                onChange={(event) =>
                                    this.updateFitnessFilter(
                                        event.target.checked
                                    )
                                }
                            />
                            <FilterLabel htmlFor={'fitnessFilter'}>
                                {t('courses.fitness')}
                            </FilterLabel>
                        </FilterSelection>
                        <FilterSelection>
                            <FilterCheckbox
                                type={'checkbox'}
                                id={'gastroFilter'}
                                checked={gastroFilterActive}
                                onChange={(event) =>
                                    this.updateGastroFilter(
                                        event.target.checked
                                    )
                                }
                            />
                            <FilterLabel htmlFor={'gastroFilter'}>
                                {t('courses.gastronomy')}
                            </FilterLabel>
                        </FilterSelection>
                    </FilterContainer>
                </div>

                <div className={'input course'}>
                    <label>{t('forms.job')}</label>
                    <Select
                        value={this.state.selectedCourse}
                        onChange={(course) => this.handleCourseSelect(course)}
                        options={courseOptions}
                        isSearchable
                        components={{ Placeholder, DropdownIndicator }}
                        styles={{
                            dropdownIndicator: (provided, state) => ({
                                ...provided,
                                color: PRIMARY_COLOR,
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                    state.isFocused || state.isSelected
                                        ? PRIMARY_COLOR
                                        : 'rgba(0,0,0,0.10)',
                                boxShadow:
                                    state.isFocused || state.isSelected
                                        ? `0 0 0 1px ${PRIMARY_COLOR}`
                                        : `none`,

                                '&:hover': {
                                    borderColor: 'rgba(0,0,0,0.10)',
                                },
                            }),
                        }}
                        placeholder={t('forms.jobPlaceholder')}
                    />
                </div>
                <SearchButton
                    primary
                    onClick={this.searchTrainer}
                    disabled={!selectedBranch || !this.state.selectedCourse}
                >
                    {t('search.search')}
                </SearchButton>
            </SearchFormWrapper>
        )
    }
}

export default withTranslation()(SearchForm)
