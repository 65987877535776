import React, { Component } from 'react'
import { StyledLabel, StyledWrapper } from '../styled-components'
import { withTranslation } from 'react-i18next'
import SectionHeading from '../section-heading'
import List from '../list'
import styled from 'styled-components'
import Loader from '../loader'
import {
    AiFillMinusCircle,
    AiFillPlusCircle,
    AiFillCaretDown,
} from 'react-icons/ai'
import Select, { components } from 'react-select'
import { PRIMARY_COLOR } from '../../assets/constants'
import CoursesService from '../../../services/courses-service'
import { DEVICE, SPACING } from '../../../assets/constants'

const Wrapper = styled.div`
    font-weight: bold;
    font-size: 1rem;

    /* > .input.studio > div > div:first-child {
        padding: 5px 12px;
    } */

    > div {
        @media ${DEVICE.phone} {
            flex-direction: column;

            > div {
                margin-bottom: ${SPACING.medium}px;
            }
        }
    }

    label {
        text-transform: uppercase;
        font-weight: 700;
        color: #c3b6c7;
        font-size: 0.7rem;
        letter-spacing: 0.06em;
        display: block;
        margin-bottom: 5px;
    }

    input {
        border-radius: 3px;
        color: #1c1c21;
        border: 1px solid;
        border-color: #ebebeb;
        padding: 10px 12px;
    }
`

const Lists = styled.div`
    @media ${DEVICE.phone} {
        flex-direction: column-reverse;

        > div > div > div {
            padding: 0;
        }
    }
`

const ToggleButton = styled.button`
    position: relative;
    background-color: ${(props) => (props.active ? '#9bc74c' : '#c3b6c7C0')};
    border: 3px solid transparent;
    border-radius: 50px;
    padding: 3px 10px;
    color: white;
    font-size: 0.8em;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 12px;
    outline: 0;
    cursor: pointer;

    &:hover {
        filter: brightness(1.1);
    }
`

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <AiFillCaretDown size={14} style={{ marginRight: 3 }} />
        </components.DropdownIndicator>
    )
}

const Placeholder = (props) => {
    return <components.Placeholder {...props} />
}

class Courses extends Component {
    constructor(props) {
        super(props)

        const { t } = this.props

        this.state = {
            filters: [
                { label: t('courses.all'), value: 0 },
                { label: t('courses.fitness'), value: 1 },
                { label: t('courses.gastronomy'), value: 2 },
            ],
            filter: 0,
            courses: {
                unsortedCourses: [],
                sortedCourses: [],
            },
            myCourses: {
                unsortedCourses: [],
                sortedCourses: [],
            },
            isLoading: false,
            loadingFilter: false,
            search: '',
        }

        this.coursesService = new CoursesService()
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            filter: this.state.filters[0],
        })
        this.getAllCourses()
        this.getMyCourses()
    }

    setIsLoading = (isLoading) => {
        this.setState({ isLoading: isLoading })
    }

    setSearch = (search) => {
        this.setState({ search: search })
    }

    getCourseType = (course) => {
        const { t } = this.props

        const courseTypeId = course[0]['type_id']
        let courseType = null

        switch (courseTypeId) {
            case 1:
                courseType = t('courses.fitness')
                break
            case 2:
                courseType = t('courses.gastronomy')
                break
        }
        return courseType
    }

    getMyCoursesListStructure = (item) => {
        const { categoryName, courses } = item
        const courseType = this.getCourseType(courses)

        courses.sort((a, b) => {
            let aName = a.name.toLowerCase(),
                bName = b.name.toLowerCase()

            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }

            return 0
        })

        const fieldMarkdown = (
            <div style={{ width: '100%' }}>
                <StyledLabel style={{ marginLeft: 5, color: '#4a4a4a' }}>
                    {categoryName} - {courseType}
                </StyledLabel>
                {courses.map((course) => {
                    return (
                        <ToggleButton
                            key={course.id}
                            active
                            onClick={() => this.toggleCourse(course)}
                        >
                            {course.name}
                            <AiFillMinusCircle
                                style={{
                                    fontSize: '1.3em',
                                    marginBottom: -4,
                                    marginLeft: 10,
                                }}
                            />
                        </ToggleButton>
                    )
                })}
            </div>
        )

        return [
            { markdown: fieldMarkdown, isHTML: false },
            { markdown: '', isHTML: false },
        ]
    }

    getAllCoursesListStructure = (item) => {
        const { categoryName, courses } = item
        const courseType = this.getCourseType(courses)

        courses.sort((a, b) => {
            let aName = a.name.toLowerCase(),
                bName = b.name.toLowerCase()

            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }

            return 0
        })

        const fieldMarkdown = (
            <div style={{ width: '100%' }}>
                <StyledLabel style={{ marginLeft: 5, color: '#4a4a4a' }}>
                    {categoryName} - {courseType}
                </StyledLabel>
                {courses.map((course) => {
                    return (
                        <ToggleButton
                            key={course.id}
                            onClick={() => this.toggleCourse(course)}
                        >
                            {course.name}
                            <AiFillPlusCircle
                                style={{
                                    fontSize: '1.3em',
                                    marginBottom: -4,
                                    marginLeft: 10,
                                }}
                            />
                        </ToggleButton>
                    )
                })}
            </div>
        )

        return [
            { markdown: fieldMarkdown, isHTML: false },
            { markdown: '', isHTML: false },
        ]
    }

    updateFilter = (filter) => {
        this.setState({
            ...this.state,
            filter: filter,
        })
    }

    render() {
        const { t } = this.props
        const {
            isLoading,
            courses,
            myCourses,
            loadingFilter,
            filters,
            filter,
            search,
        } = this.state

        const remainingCourses = courses.unsortedCourses
            .filter((course) => !myCourses.unsortedCourses.some((innerCourse) => innerCourse.id === course.id))
            .filter((course) => course.name.toLowerCase().includes(search.toLowerCase().trim()));

        const sortedRemainingCourses = this.sortCoursesByCategory(remainingCourses);
        const sortedMyCourses = this.sortCoursesByCategory(myCourses.unsortedCourses);

        if (filter.value !== 0) {
            for (const [key, value] of Object.entries(sortedRemainingCourses)) {
                if (value.courses.length > 0) {
                    sortedRemainingCourses[key].courses = value.courses.filter(
                        (course) => course.type_id === filter.value
                    )
                    if (sortedRemainingCourses[key].courses.length === 0) {
                        delete sortedRemainingCourses[key]
                    }
                }
            }
            for (const [key, value] of Object.entries(sortedMyCourses)) {
                if (value.courses.length > 0) {
                    sortedMyCourses[key].courses = value.courses.filter(
                        (course) => course.type_id === filter.value
                    )
                    if (sortedMyCourses[key].courses.length === 0) {
                        delete sortedMyCourses[key]
                    }
                }
            }
        }

        return (
            <div>
                <Wrapper style={{ marginBottom: 35 }}>
                    <div
                        className={'input studio'}
                        style={{ display: 'flex', width: '100%' }}
                    >
                        <div style={{ flex: 1, marginRight: SPACING.xsmall }}>
                            <label>{t('courses.search')}</label>
                            <input
                                style={{
                                    borderRadius: '3px',
                                    backgroundColor: 'transparent',
                                    color: 'rgb(28, 28, 33)',
                                    border: '1px solid rgb(235, 235, 235)',
                                    padding: '10px 12px',
                                    width: '100%',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: '1em',
                                    fontWeight: 400,
                                }}
                                type="text"
                                placeholder={t('courses.searchPlaceholder')}
                                value={search}
                                onChange={(event) => {
                                    this.setSearch(event.target.value)
                                }}
                            />
                        </div>

                        <div style={{ flex: 0.5 }}>
                            <label>{t('courses.filter')}</label>
                            <Select
                                value={filter}
                                onChange={(filter) => this.updateFilter(filter)}
                                options={filters}
                                components={{ Placeholder, DropdownIndicator }}
                                styles={{
                                    dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        color: PRIMARY_COLOR,
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        borderColor:
                                            state.isFocused || state.isSelected
                                                ? 'black'
                                                : 'rgba(0,0,0,0.10)',
                                        boxShadow:
                                            state.isFocused || state.isSelected
                                                ? `0 0 0 1px black`
                                                : `none`,
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontSize: '1em',
                                        fontWeight: 400,

                                        '&:hover': {
                                            borderColor: 'rgba(0,0,0,0.10)',
                                        },
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        padding: '6px 13px;',
                                    }),
                                    menuList: (provided, state) => ({
                                        ...provided,
                                        fontSize: '1em',
                                        fontWeight: 400,
                                    }),
                                    option: (provided, { isSelected }) => ({
                                        ...provided,
                                        '&:hover': {
                                            color: '#C7FF61',
                                            background: '#c3b6c7',
                                        },
                                        background: isSelected
                                            ? '#c3b6c7'
                                            : 'white',
                                        color: isSelected ? '#C7FF61' : 'black',
                                    }),
                                }}
                                isLoading={loadingFilter}
                                placeholder={t('courses.placeholder')}
                            />
                        </div>
                    </div>
                    {isLoading && <Loader />}
                </Wrapper>

                <Lists
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: -SPACING.xlarge,
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <SectionHeading title={t('courses.inactive')} />
                        <List
                            items={Object.values(sortedRemainingCourses).sort(
                                function (a, b) {
                                    var textA = a.categoryName.toUpperCase()
                                    var textB = b.categoryName.toUpperCase()
                                    return textA < textB
                                        ? -1
                                        : textA > textB
                                        ? 1
                                        : 0
                                }
                            )}
                            noBackground
                            noItemsMessage={t('courses.noCoursesAvailable')}
                            itemsAutoHeight
                            getListItemStructure={
                                this.getAllCoursesListStructure
                            }
                        />
                    </div>

                    <div style={{ flex: 0.85 }}>
                        <SectionHeading title={t('courses.active')} />
                        <List
                            items={Object.values(sortedMyCourses).sort(
                                function (a, b) {
                                    var textA = a.categoryName.toUpperCase()
                                    var textB = b.categoryName.toUpperCase()
                                    return textA < textB
                                        ? -1
                                        : textA > textB
                                        ? 1
                                        : 0
                                }
                            )}
                            noBackground
                            noItemsMessage={t('courses.noCoursesSelected')}
                            itemsAutoHeight
                            getListItemStructure={
                                this.getMyCoursesListStructure
                            }
                        />
                    </div>
                </Lists>

                {isLoading && <Loader />}
            </div>
        )
    }

    getAllCourses() {
        this.coursesService.getAllCourses().then((courses) => {
            this.setState({
                courses: {
                    unsortedCourses: courses,
                },
            })
        })
    }

    getMyCourses() {
        this.coursesService.getMyCourses().then((courses) => {
            const input = courses,
                keys = ['id'],
                uniqueCourses = input.filter(
                    (
                        (s) => (o) =>
                            ((k) => !s.has(k) && s.add(k))(
                                keys.map((k) => o[k]).join('|')
                            )
                    )(new Set())
                )

            this.setState({
                myCourses: {
                    unsortedCourses: uniqueCourses,
                },
            })
        })
    }

    toggleCourse(course) {
        this.setIsLoading(true)
        if (
            this.state.myCourses.unsortedCourses.some((c) => c.id === course.id)
        ) {
            this.coursesService.removeFromMyCourses(course.id).then((res) => {
                this.setIsLoading(false)
                const newCourses = this.state.myCourses.unsortedCourses.filter(
                    (c) => c.id !== course.id
                )
                this.setState({
                    myCourses: {
                        unsortedCourses: newCourses,
                    },
                })
            })
        } else {
            this.coursesService.addToMyCourses(course.id).then((res) => {
                this.setIsLoading(false)
                let courses = this.state.myCourses.unsortedCourses
                courses.push(course)
                this.setState({
                    myCourses: {
                        unsortedCourses: courses,
                    },
                })
            })
        }
    }

    sortCoursesByCategory(courses) {
        let sortedCourses = {}
        courses.forEach((course) => {
            course.categories.forEach((category) => {
                if (category.id in sortedCourses) {
                    sortedCourses[category.id].courses.push(course)
                } else {
                    sortedCourses[category.id] = {
                        categoryName: category.name,
                        courses: [course],
                    }
                }
            })
        })

        return sortedCourses
    }
}

export default withTranslation()(Courses)
