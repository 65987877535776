import FreelancerSetupWidget from '../../legacy/components/widgets/freelancer-setup-widget'
import SetupWidget from '../../legacy/components/widgets/setup-widget'
import styled from 'styled-components'
import { DEVICE, SPACING } from '../../assets/constants'
import { useAuth } from '../../context/AuthContext'

const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 1080px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${SPACING.xlarge}px;

    @media ${DEVICE.phone} {
        padding: ${SPACING.small}px;
        min-height: auto;
        height: auto;
        padding-top: 70px;
        align-items: flex-start;
    }

    & > div {
        width: 100%;
    }
`

const Setup = () => {
    const { logout, setup, user, updateUser } = useAuth()

    return (
        <Wrapper>
            {user && user.role === 'freelancer' ? (
                <FreelancerSetupWidget logout={logout} setup={setup} />
            ) : (
                <SetupWidget logout={logout} updateUser={updateUser} />
            )}
        </Wrapper>
    )
}

export default Setup
