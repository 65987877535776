import React, {Component} from 'react';
import {
    formatAMPM,
    formatTimeString,
    sortByDateAndTimeProperty,
    sortByDateProperty
} from "../../utils/utility-functions";
import {ReactComponent as MoreButton} from '../../assets/images/more.svg';
import dateFormat from 'dateformat';
import './availability-list.scss';
import Loader from "../loader";
import PopupMessage from "../popup-message";
import List from "../list";
import {AvailabilityBadge, SubHeading} from "../styled-components";
import {withTranslation} from "react-i18next";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {de, enIN} from "date-fns/locale";
import AvailabilityService from '../../../services/availability-service';
import i18n from '../../../i18n/config';
import {SPACING} from '../../../assets/constants';
import EditAvailabilityPopupNew from "../edit-availability-popup-new";

registerLocale('de', de)
registerLocale('en', enIN)
setDefaultLocale('de')

class AvailabilityList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            availabilities: [],
            showPopup: false,
            currentAvailability: undefined,
            isLoading: false,
            showMessage: false,
            message: undefined,
            currentLanguage: i18n.language,
            currentTimeFormat: undefined,
            currentDateFormat: undefined,
        }

        this.availabilityService = new AvailabilityService()
    }

    componentDidMount() {
        this.getAvailabilities()
        this.setCurrentDateformat()
    }

    setCurrentDateformat = () => {
        if (i18n.language === 'en') {
            this.setState({
                ...this.state,
                currentDateFormat: 'MM/dd/yyyy',
                currentTimeFormat: 'hh:mm aa',
            })
        } else if (i18n.language === 'de') {
            this.setState({
                ...this.state,
                currentDateFormat: 'd. MMMM yyyy',
                currentTimeFormat: 'HH:mm',
            })
        }
    }

    setIsLoading = (isLoading) => {
        this.setState({...this.state, isLoading: isLoading})
    }

    setShowMessage = (show) => {
        this.setState({...this.state, showMessage: show})
    }

    setMessage = (message) => {
        this.setState({...this.state, message: message})
    }

    setShowPopup = (show) => {
        this.setState({...this.state, showPopup: show})
    }

    getAvailabilities = () => {
        this.availabilityService.getAvailabilities().then((availabilities) => {
            this.setState({
                availabilities: sortByDateAndTimeProperty(availabilities, true),
            })
        })
    }

    deleteEntry = (availabilityId) => {
        this.setIsLoading(true)
        this.availabilityService
            .removeAvailability(availabilityId)
            .then((res) => {
                this.setIsLoading(false)
                this.setShowPopup(false)
                this.setState({
                    availabilities: this.state.availabilities.filter(
                        (availability) => {
                            return availability.id !== availabilityId
                        }
                    ),
                })
            })
    }

    deleteEntryGroup = (availabilityGroupId) => {
        const {t} = this.props
        const deleteAll = window.confirm(t('popup.deleteGroupConfirm'))

        if (deleteAll) {
            this.setIsLoading(true)
            this.availabilityService
                .removeAvailabilityGroup(availabilityGroupId)
                .then((res) => {
                    this.setIsLoading(false)
                    this.setShowPopup(false)
                    this.setState({
                        availabilities: this.state.availabilities.filter(
                            (availability) => {
                                return (
                                    availability.group_id !==
                                    availabilityGroupId
                                )
                            }
                        ),
                    })
                })
        }
    }

    saveEntry = (id, date, startTime, endTime, availability) => {
        const {t} = this.props
        const timeDiff =
            (new Date(endTime).getTime() - new Date(startTime).getTime()) /
            3600000

        if (Math.abs(timeDiff) < 0.9) {
            alert(t('popup.addEntryWarning'))
        } else {
            this.setIsLoading(true)
            const newAvailabilityData = {
                id: id,
                date: dateFormat(date, 'yyyy-mm-dd'),
                starttime: dateFormat(startTime, 'HH:MM'),
                endtime: dateFormat(endTime, 'HH:MM'),
                availability: availability,
                overnight: startTime > endTime,
            }
            this.availabilityService
                .editAvailability(newAvailabilityData)
                .then((res) => {
                    this.setIsLoading(false)
                    this.setShowPopup(false)

                    if (res && !res.success && res.message) {
                        this.setShowMessage(true)
                        this.setMessage(`<div>${res.message}</div>`)
                    }

                    this.getAvailabilities()
                })
        }
    }

    openAvailabilityPopup = (availability) => {
        this.setState((prevState) => ({
            ...prevState,
            showPopup: true,
            currentAvailability: availability,
        }))
    }

    closePopup = () => {
        this.setState((prevState) => ({
            ...prevState,
            showPopup: false,
        }))
    }

    getListItemStructure = (item) => {
        const {availability, startdate, starttime, endtime} = item
        const {t} = this.props

        const availabilityColors = {
            high: '#deffa2',
            medium: '#c7ff61',
            onrequest: '#9bc74c',
        }

        const availabilityString = {
            high: t('forms.high'),
            medium: t('forms.medium'),
            onrequest: t('forms.onrequest'),
        }

        let dateformat = 'd. mmm yyyy'
        let convertedStarttime = formatTimeString(starttime)
        let convertedEndtime = formatTimeString(endtime)

        if (i18n.language === 'en') {
            dateformat = 'mm/dd/yyyy'
            convertedStarttime = formatAMPM(convertedStarttime, true)
            convertedEndtime = formatAMPM(convertedEndtime, true)
        }

        const dateMarkdown = `<b>${dateFormat(startdate, dateformat)}</b>`
        const timeMarkdown = `<span>${convertedStarttime} - ${convertedEndtime}</span>`
        const availabilityMarkdown = (
            <AvailabilityBadge
                style={{backgroundColor: availabilityColors[availability]}}
            >
                {availabilityString[availability]}
            </AvailabilityBadge>
        )

        return [
            {markdown: dateMarkdown, isHTML: true},
            {markdown: timeMarkdown, isHTML: true},
            {markdown: availabilityMarkdown, isHTML: false},
            {
                markdown: (
                    <MoreButton
                        style={{cursor: 'pointer'}}
                        onClick={() => this.openAvailabilityPopup(item)}
                    />
                ),
                isHTML: false,
            },
        ]
    }

    getEntriesByMonthAndYear = (entries) => {
        const entriesByMonthAndYear = {}

        entries.forEach((entry, index) => {
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            if (new Date(entry.startdate).getTime() > firstDay.getTime()) {
                const day = new Date(entry.startdate).getDate()
                const nextDay = entries[index + 1]
                    ? new Date(entries[index + 1].startdate).getDate()
                    : undefined

                const month = new Date(entry.startdate).getMonth()
                const year = new Date(entry.startdate).getFullYear()
                const key = `${year}-${('0' + (month + 1)).slice(-2)}-01`

                if (day === nextDay) {
                    entry['noMargin'] = true
                }

                if (entriesByMonthAndYear[key]) {
                    entriesByMonthAndYear[key].push(entry)
                } else {
                    entriesByMonthAndYear[key] = [entry]
                }
            }
        })

        Object.keys(entriesByMonthAndYear).forEach((key) => {
            entriesByMonthAndYear[key] = sortByDateProperty(
                entriesByMonthAndYear[key]
            )
        })

        return entriesByMonthAndYear
    }

    render() {
        const {
            showPopup,
            currentAvailability,
            isLoading,
            showMessage,
            message,
            availabilities,
            currentDateFormat,
            currentTimeFormat,
        } = this.state
        const {t} = this.props

        const currentLanguage = i18n.language
        const entriesByMonthAndYear =
            this.getEntriesByMonthAndYear(availabilities)

        return (
            <div style={{marginTop: 40}}>
                {Object.keys(entriesByMonthAndYear).map((key, index) => {
                    return (
                        <div key={index}>
                            <SubHeading
                                style={{
                                    marginTop: SPACING.xlarge,
                                    marginBottom: SPACING.medium,
                                }}
                            >
                                {dateFormat(key, 'mmmm yyyy')}
                            </SubHeading>
                            <List
                                items={entriesByMonthAndYear[key]}
                                noItemsMessage={t('list.noCalendarEntries')}
                                headings={[
                                    t('list.day'),
                                    t('list.time'),
                                    t('list.availability'),
                                    '',
                                ]}
                                getListItemStructure={this.getListItemStructure}
                                height={55}
                            />
                        </div>
                    )
                })}

                {Object.keys(entriesByMonthAndYear).length === 0 && (
                    <List
                        items={[]}
                        noItemsMessage={t('list.noCalendarEntries')}
                        headings={[
                            t('list.day'),
                            t('list.time'),
                            t('list.availability'),
                            '',
                        ]}
                        getListItemStructure={this.getListItemStructure}
                    />
                )}

                {showPopup && (
                    <EditAvailabilityPopupNew
                        entry={currentAvailability}
                        hidePopup={() => this.setShowPopup(false)}
                        saveEntry={this.saveEntry}
                        deleteEntry={this.deleteEntry}
                        deleteEntryGroup={this.deleteEntryGroup}
                        currentLanguage={currentLanguage}
                        currentDateFormat={currentDateFormat}
                        currentTimeFormat={currentTimeFormat}
                        reloadData={() => this.getAvailabilities()}
                        showMessage={() => this.setShowMessage(true)}
                        addMessage={(message) => this.setMessage(message)}
                    />
                )}

                {isLoading && <Loader/>}

                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
            </div>
        )
    }
}

export default withTranslation()(AvailabilityList)
