import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DEVICE, SPACING } from '../../assets/constants'
import { AVAILABILITY_COLORS } from '../../legacy/assets/constants'
import {
    InfoMessage,
    StyledLabel,
} from '../../legacy/components/styled-components'
import AvailabilityFormWidget from '../../legacy/components/widgets/availability-form-widget'

const Wrapper = styled.div`
    @media ${DEVICE.phone} {
        flex-direction: column;
    }
`

const AvailabilityForm = ({ type }: { type: 'regular' | 'single' }) => {
    const { t } = useTranslation()

    return (
        <>
            <h1>
                {type === 'regular'
                    ? t('pages.title.regularEvents')
                    : t('pages.title.irregularEvents')}
            </h1>

            <p>
                {type === 'regular'
                    ? t('pages.description.regularEvents')
                    : t('pages.description.irregularEvents')}
            </p>

            <AvailabilityFormWidget type={type} />

            <div style={{ marginTop: 50 }}>
                <StyledLabel>{t('booking.legend')}</StyledLabel>
                <Wrapper style={{ display: 'flex', fontSize: '0.8rem' }}>
                    <InfoMessage
                        style={{
                            marginTop: SPACING.medium,
                            flex: 1,
                            paddingRight: SPACING.large,
                        }}
                    >
                        <strong
                            style={{
                                backgroundColor: AVAILABILITY_COLORS.high,
                                borderRadius: 5,
                                width: 100,
                                display: 'block',
                                textAlign: 'center',
                                marginBottom: SPACING.small,
                            }}
                        >
                            {t('booking.availabilityHighTitle')}
                        </strong>
                        <span style={{fontSize: '1rem'}}>{t('booking.availabilityHighDesc')}</span>
                    </InfoMessage>
                    <InfoMessage
                        style={{
                            marginTop: SPACING.medium,
                            flex: 1,
                            paddingRight: SPACING.large,
                        }}
                    >
                        <strong
                            style={{
                                backgroundColor: AVAILABILITY_COLORS.medium,
                                borderRadius: 5,
                                width: 100,
                                display: 'block',
                                textAlign: 'center',
                                marginBottom: SPACING.small,
                            }}
                        >
                            {t('booking.availabilityMediumTitle')}
                        </strong>
                        <span style={{fontSize: '1rem'}}>{t('booking.availabilityMediumDesc')}</span>
                    </InfoMessage>
                    <InfoMessage
                        style={{
                            marginTop: SPACING.medium,
                            flex: 1,
                            paddingRight: SPACING.large,
                        }}
                    >
                        <strong
                            style={{
                                backgroundColor: AVAILABILITY_COLORS.onrequest,
                                borderRadius: 5,
                                width: 100,
                                display: 'block',
                                textAlign: 'center',
                                marginBottom: SPACING.small,
                            }}
                        >
                            {t('booking.availabilityRequestTitle')}
                        </strong>
                        <span style={{fontSize: '1rem'}}>{t('booking.availabilityRequestDesc')}</span>
                    </InfoMessage>
                </Wrapper>
            </div>

            <div style={{ marginTop: 50 }}>
                <StyledLabel>{t('booking.noteTitle')}</StyledLabel>
                <InfoMessage style={{ fontSize: '1rem' }}>
                    {t('booking.noteText')}
                </InfoMessage>
            </div>
        </>
    )
}

export default AvailabilityForm
