import AccountService from '../../services/account-service'
import Box from '../../common/Box'
import Loader from '../../legacy/components/loader'
import logo from '../../assets/images/logo.png'
import styled from 'styled-components'
import { Form } from '../../common/ui-elements'
import { SPACING } from '../../assets/constants'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    InputSection,
    StyledLabel,
} from '../../legacy/components/styled-components'
import { useLocation } from 'react-router-dom'
import i18n from '../../i18n/config'

const Background = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 572px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ForgotPassword = () => {
    const accountService = new AccountService()

    const [email, setEmail] = useState<string>()
    const [confirmed, setConfirmed] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const { t } = useTranslation()

    const location = useLocation()
    const locale = new URLSearchParams(location.search).get('locale')

    useEffect(() => {
        if (!locale) return
        localStorage.setItem('jg-lang', locale === 'de' ? locale : 'en')
        i18n.changeLanguage(locale === 'de' ? locale : 'en')
    }, [])

    return (
        <Background>
            <img
                src={logo}
                width={165}
                style={{ marginBottom: SPACING.large }}
            />

            {confirmed ? (
                <Box size="medium">
                    <h1 style={{ marginBottom: SPACING.medium }}>
                        {t('registration.forgotPasswordConfirm')}.
                    </h1>
                    <p>{t('registration.forgotPasswordConfirmText')}</p>
                </Box>
            ) : (
                <Box size="medium">
                    <h1>{t('registration.forgotPassword')}.</h1>

                    <Form
                        style={{ marginTop: SPACING.large }}
                        onSubmit={async (event) => {
                            event.preventDefault()
                            setLoading(true)
                            try {
                                const response =
                                    await accountService.resetPassword(email)
                                setLoading(false)
                                if (response.status === 200) {
                                    setConfirmed(true)
                                }
                            } catch (error) {
                                // TODO error handling
                                setLoading(false)
                            }
                        }}
                    >
                        <InputSection
                            style={{
                                flex: '1 1 0',
                                margin: '0 5px',
                                width: '100%',
                            }}
                        >
                            <StyledLabel>{t('forms.email')}</StyledLabel>
                            <input
                                type={'email'}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                style={{
                                    marginTop: '-10px',
                                    marginBottom: '10px',
                                }}
                            />
                        </InputSection>
                        <div>
                            <button type="submit" disabled={!email}>
                                {t('registration.forgotPassword')}
                            </button>
                        </div>
                    </Form>
                </Box>
            )}

            {loading && <Loader />}
        </Background>
    )
}

export default ForgotPassword
