import React, { Component } from 'react'
import {
    FlexDiv,
    StyledButton,
    StyledLabel,
    StyledSubmit,
} from '../styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import {
    addressAdditionalField,
    branchNameField,
    cityField,
    countryField,
    emailField,
    housenumberField,
    phoneFieldNew,
    stateField,
    streetField,
    websiteField,
    zipcodeField,
} from '../form-fields'
import Form from '../form'
import { DEVICE } from '../../assets/constants'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
    margin-top: auto;

    @media ${DEVICE.tablet} {
    }

    @media ${DEVICE.phone} {
        margin-top: 10%;
    }

    @media ${DEVICE.short_phone} {
        margin-top: 10%;
    }
`

const SetupButton = styled(StyledButton)`
    width: 250px;

    @media ${DEVICE.phone} {
        width: 100%;
    }
`

const SetupSubmit = styled(StyledSubmit)`
    width: 250px;

    @media ${DEVICE.phone} {
        width: 100%;
        height: 55px;
    }
`

class BranchDataForm extends Component {
    constructor(props) {
        super(props)
    }

    onSubmit = (event) => {
        const { finishSetup } = this.props
        event.preventDefault()
        finishSetup()
    }

    render() {
        const {
            branch,
            company,
            isBranch,
            changeValue,
            siteurl,
            t,
            countries,
        } = this.props
        const {
            name,
            email,
            street,
            housenumber,
            zipcode,
            city,
            state,
            country,
            addressAdditional,
            phone,
            website,
        } = branch

        const reducedFormElements = [
            [
                branchNameField(
                    name,
                    (value) => changeValue('branch', 'name', value),
                    1,
                    true,
                    false
                ),
                emailField(
                    email,
                    (value) => changeValue('branch', 'email', value),
                    1,
                    true,
                    false
                ),
            ],
        ]

        const formElements = [
            [
                branchNameField(
                    name,
                    (value) => changeValue('branch', 'name', value),
                    1,
                    true,
                    false
                ),
                emailField(
                    email,
                    (value) => changeValue('branch', 'email', value),
                    1,
                    true,
                    false
                ),
            ],
            [
                streetField(
                    street,
                    (value) => changeValue('branch', 'street', value),
                    1,
                    true,
                    false
                ),
                housenumberField(
                    housenumber,
                    (value) => changeValue('branch', 'housenumber', value),
                    0.5,
                    true,
                    false
                ),
                addressAdditionalField(
                    addressAdditional,
                    (value) =>
                        changeValue('branch', 'addressAdditional', value),
                    1,
                    false,
                    false
                ),
            ],
            [
                zipcodeField(
                    zipcode,
                    (value) => changeValue('branch', 'zipcode', value),
                    0.5,
                    true,
                    false
                ),
                cityField(
                    city,
                    (value) => changeValue('branch', 'city', value),
                    1,
                    true,
                    false
                ),
            ],
            [
                stateField(
                    state,
                    (value) => changeValue('branch', 'state', value),
                    1,
                    true,
                    false
                ),
                countryField(
                    country,
                    (value) => changeValue('branch', 'country', value),
                    1,
                    true,
                    false,
                    countries
                ),
            ],
            [
                phoneFieldNew(
                    phone,
                    (value) => changeValue('branch', 'phone', value),
                ),
                websiteField(
                    website,
                    (value) => changeValue('branch', 'website', value),
                    1,
                    false,
                    false
                ),
            ],
        ]

        return (
            <>
                <Form
                    elements={
                        isBranch === 'yes' ? reducedFormElements : formElements
                    }
                    submit={{ label: '', onSubmit: this.onSubmit }}
                >
                    {isBranch === 'yes' && (
                        <div style={{ marginTop: 35 }}>
                            <p style={{ marginBottom: 15 }}>
                                {t('setup.chef.isBranchInfo')}
                            </p>

                            <StyledLabel>{t('forms.address')}</StyledLabel>
                            <p style={{ fontWeight: 'bold' }}>
                                {company.street} {company.housenumber},{' '}
                                {company.addressAdditional &&
                                    `${company.addressAdditional}, `}{' '}
                                {company.zipcode} {company.city},{' '}
                                {company.state && `${company.state}, `}
                                {company.country}
                            </p>

                            <FlexDiv style={{ marginTop: 15 }}>
                                <div>
                                    <StyledLabel>
                                        {t('forms.phone')}
                                    </StyledLabel>
                                    <p style={{ fontWeight: 'bold' }}>
                                        {'+'+company.phone}
                                    </p>
                                </div>

                                {company.website && (
                                    <div>
                                        <StyledLabel>
                                            {t('forms.website')}
                                        </StyledLabel>
                                        <p style={{ fontWeight: 'bold' }}>
                                            {company.website}
                                        </p>
                                    </div>
                                )}
                            </FlexDiv>
                        </div>
                    )}
                    <ButtonWrapper>
                        <SetupSubmit
                            primary
                            type={'submit'}
                            value={t('setup.finish')}
                        />
                        {/* <SetupButton tertiary
                        onClick={() => {
                            const { logout } = this.props;

                            logout();
                        }}>
                        {t('setup.abort')}
                    </SetupButton> */}
                    </ButtonWrapper>
                </Form>
            </>
        )
    }
}

export default withTranslation()(BranchDataForm)
