import React, { Component } from 'react';
import { StyledButton, StyledWrapper, SubTitle } from "../styled-components";
import { formatAMPM, formatMoney, formatTimeString } from "../../utils/utility-functions";
import styled from "styled-components";
import { ReactComponent as StarIcon } from '../../assets/images/star.svg';
import JobbelIcon from '../../assets/images/jobbelganger-icon2.png';
import dateFormat from "dateformat";
import { withTranslation } from "react-i18next";
import { DEVICE } from "../../assets/constants";
import SearchService from '../../../services/search-service';
import i18n, { locales } from '../../../i18n/config';
import { format } from 'date-fns';

const SearchDetailsWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    
    > div {
        flex: 1;
        padding-right: 2em;
        
        > h3 {
            margin-top: 10px;
            margin-bottom: 0;
        }
        
        > h6 {
            margin-top: 0;
        }
    }
    
    @media ${DEVICE.phone} {
        flex-direction: column;
        align-items: flex-start;
    }
    
    @media ${DEVICE.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
    
`;

const ProposalWrapper = styled(StyledWrapper)`
    margin: 0 0 15px 0;
    
    @media ${DEVICE.phone} {
        margin: 15px 0 15px 0;

    }
    
`;

const FreelancerBanner = styled.div`
    background: #c3b6c7;
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
`;

const RoundImage = styled.img`
    border-radius: 100% !important;
    height: 130px !important;
    width: 130px;
    position: absolute;
    right: 40px;
    border: 5px solid #fff !important;
    object-fit: contain;
    background: black;
    padding: 20px;
`;

const FreelancerDetailWrapper = styled.div`
    display: flex;
    padding-top: 130px;
    width: 100%;
    margin: auto;
    align-items: center;
    
    > div {
        flex: 1;
    }
    
    > .vertical-line {
        flex: 0 0 1px;
        height: 35px;
        width: 1px; 
        background-color: #D5D5D5;
    }
    
    @media ${DEVICE.phone} {
        width: 100%;
    }
`;

const FreelancerDetailWrapper2 = styled.div`
    display: flex;
    padding-top: 20px;
    width: 100%;
    margin: auto;
    align-items: center;
    
    > div {
        flex: 1;
    }
    
    > .vertical-line {
        flex: 0 0 1px;
        height: 35px;
        width: 1px; 
        background-color: #D5D5D5;
    }
    
    @media ${DEVICE.phone} {
        width: 100%;
    }
`;

const BookButton = styled(StyledButton)`
    margin-right: 10px; 
    width: 225px;
    
    @media ${DEVICE.phone} {
        width: 100%;
        margin: 0;
    }
    
     @media ${DEVICE.tablet} {
        width: 100%;
        margin: 0;
    }
`;

const DeclineButton = styled(StyledButton)`
    margin-left: 10px; 
    width: 150px;
    
    @media ${DEVICE.phone} {
        width: 100%;
        margin: 0;
        margin-top: 15px;
    }
    
    @media ${DEVICE.tablet} {
        width: 100%;
        margin: 0;
        margin-top: 15px;
    }
`;

class FreelancerCard extends Component {

    constructor(props) {
        super(props);

        this.searchService = new SearchService();
    }

    bookFreelancer = (requestId) => {
        const { setSearchResult, setIsLoading, setLoadingMessage, selectedBranch, t } = this.props;

        setIsLoading(true);
        setLoadingMessage(t('search.booking'));
        this.searchService.bookFreelancer(
            selectedBranch.branchId,
            requestId
        ).then(res => {
            setIsLoading(false);
            setLoadingMessage(undefined);
            res.booked = true;
            setSearchResult(res);
        });
    }

    declineFreelancer = (requestId, second) => {
        const { setSearchResult, setIsLoading, setLoadingMessage, selectedBranch, t } = this.props;

        setIsLoading(true);
        setLoadingMessage(second ? t('search.nextTrainer') : t('search.declining'));
        this.searchService.declineFreelancer(
            selectedBranch.branchId,
            requestId
        ).then(res => {
            setIsLoading(false);
            setLoadingMessage(undefined);
            res.declined = !second;
            setSearchResult(res);
        });
    }

    render() {
        const { id, course, currency, br_name, date, starttime, endtime, fl_firstname, fl_lastname, fl_total_avg, costs, surcharge, duration, second } = this.props.searchResult;
        const { t } = this.props;

        let dateformat = 'd. mmmm yyyy';
        let convertedStarttime = formatTimeString(starttime);
        let convertedEndtime = formatTimeString(endtime);

        if (i18n.language === 'en') {
            dateformat = 'mm/dd/yyyy';
            convertedStarttime = formatAMPM(convertedStarttime, true);
            convertedEndtime = formatAMPM(convertedEndtime, true);
        }

        return <div style={{ position: 'relative'}}>
            <StyledWrapper style={{ textAlign: 'start', overflow: 'hidden'}}>
                <FreelancerBanner>
                    <div style={{width: '50%', paddingLeft: '30px', height: '100px', display: 'flex', alignItems: 'center'}}>
                        <h2 style={{ color: 'white' }}>
                            {t('search.proposals')}
                        </h2>
                    </div>

                </FreelancerBanner>
                <RoundImage src={JobbelIcon} />
                <FreelancerDetailWrapper style={{ marginTop: 15 }}>
                    <div>
                        <h3>{fl_firstname} {fl_lastname}</h3>
                        <span>{fl_total_avg ? <>{fl_total_avg.toFixed(2)}<StarIcon
                            style={{ marginBottom: -1 }} /></> : t('search.noRating')} </span>
                    </div>
                </FreelancerDetailWrapper>
                <FreelancerDetailWrapper2>
                    <div>
                        <h3>{formatMoney(costs + surcharge, 2, ',', '.')} {currency}</h3>
                        <p>{t('search.for')} {duration} {t('search.hour')}</p>
                    </div>
                </FreelancerDetailWrapper2>
                <FreelancerDetailWrapper2>
                    <div>
                        <h3>{course}</h3>
                        <p>{format(new Date(date), 'P', { locale: locales[i18n.language] })}, {convertedStarttime} - {convertedEndtime} {t('popup.clock')}</p>
                    </div>
                </FreelancerDetailWrapper2>
                <p style={{textAlign: 'start', paddingTop: '16px'}}>{t('search.proposalDescription')}</p>
                <div className="button-freelancer-card">
                    <BookButton primary
                                onClick={() => this.bookFreelancer(id)}>{t('search.book')}</BookButton>
                    <DeclineButton onClick={() => this.declineFreelancer(id, second)}>{second ? t('search.second') : t('search.decline')}</DeclineButton>
                </div>

            </StyledWrapper>
        </div>;
    }
}

export default withTranslation()(FreelancerCard);