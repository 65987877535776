import React, { Component } from 'react'
import BranchDropdown from '../branch-dropdown'
import { sortByDateProperty } from '../../utils/utility-functions'
import List from '../list'
import dateFormat from 'dateformat'
import Loader from '../loader'
import {
    InputSection,
    StyledButton,
    StyledLabel,
    SubHeading,
} from '../styled-components'
import PopupMessage from '../popup-message'
import { MdBlock, MdClear } from 'react-icons/md'
import { withTranslation } from 'react-i18next'
import BlacklistService from '../../../services/blacklist-service'
import BookingsService from '../../../services/bookings-service'
import Popup from '../popup'
import { TextArea } from '../../../common/ui-elements'
import { SPACING } from '../../../assets/constants'
import i18n from '../../../i18n/config'

class BlacklistWidget extends Component {
    constructor(props) {
        super(props)

        this.blacklistService = new BlacklistService()
        this.bookingsService = new BookingsService()

        this.state = {
            selectedBranchId: undefined,
            blacklist: undefined,
            pastBookings: undefined,
            upcomingBookings: undefined,
            isLoading: false,
            showMessage: false,
            message: undefined,
            showPopup: false,
            cancelReason: undefined,
            cancelId: undefined,
            dateformat: i18n.language === 'en' ? 'MM/dd/yyyy' : 'd. mmm yyyy',
        }
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading })
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show })
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message })
    }

    setCancelReason = (text) => {
        this.setState({ ...this.state, cancelReason: text })
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show })
    }

    setCancelId = (id) => {
        this.setState({ ...this.state, cancelId: id })
    }

    updateItems = (branchId) => {
        this.updateBlacklist(branchId)
        this.updatePastBookings(branchId)
        this.updateUpcomingBookings(branchId)
    }

    updateBlacklist = (branchId) => {
        this.blacklistService.getBranchBlacklist(branchId).then((res) => {
            console.log(res)
            if (res && res.data) {
                this.setState({ ...this.state, blacklist: res.data })
            }
        })
    }

    updatePastBookings = (branchId) => {
        this.bookingsService.getBlacklistableBookings(branchId).then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    pastBookings: sortByDateProperty(res.data, false),
                })
            }
        })
    }

    updateUpcomingBookings = (branchId) => {
        this.bookingsService.getUpcomingBookings(branchId).then((res) => {
            if (res && res.data) {
                this.setState({
                    ...this.state,
                    upcomingBookings: sortByDateProperty(res.data, true),
                })
            }
        })
    }

    block = (branchId, freelancerId) => {
        const { t } = this.props

        this.setIsLoading(true)
        this.blacklistService
            .blockFreelancer(branchId, freelancerId)
            .then((res) => {
                this.setIsLoading(false)
                this.setShowMessage(true)
                this.setMessage(`<div>${t('blacklist.trainerBlocked')}</div>`)
                this.updateItems(branchId)
            })
    }

    unblock = (branchId, freelancerId) => {
        const { t } = this.props
        this.setIsLoading(true)
        this.blacklistService
            .unblockFreelancer(branchId, freelancerId)
            .then((res) => {
                this.setIsLoading(false)
                this.setShowMessage(true)
                this.setMessage(`<div>${t('blacklist.trainerUnblocked')}</div>`)
                this.updateItems(branchId)
            })
    }

    setSelectedBranch = (branchId) => {
        this.setState({ ...this.state, selectedBranchId: branchId })
    }

    cancelBooking = () => {
        const { selectedBranchId, cancelReason, cancelId } = this.state

        this.setState({
            ...this.state,
            isLoading: true,
            showPopup: false,
            cancelId: undefined,
            cancelReason: undefined,
        })

        this.bookingsService
            .cancelBookingAsBranch(selectedBranchId, cancelId, cancelReason)
            .then((res) => {
                this.setIsLoading(false)

                if (res) {
                    this.setState({
                        ...this.state,
                        showMessage: true,
                    })

                    this.setMessage(`<div>${res.message}</div>`)
                }

                this.updateItems(selectedBranchId)
            })
    }

    getBlacklistListStructure = (item) => {
        const {
            id,
            branch_id,
            blocked_since,
            freelancer_firstname,
            freelancer_lastname,
        } = item

        let dateformat = 'd. mmm yyyy'
        if (i18n.language === 'en') {
            dateformat = 'mm/dd/yyyy'
        }

        const trainerMarkdown = `<b>${freelancer_firstname} ${freelancer_lastname}</b>`
        const blockedSinceMarkdown = `${dateFormat(
            blocked_since.slice(0, 10),
            dateformat
        )}`

        return [
            { markdown: trainerMarkdown, isHTML: true },
            { markdown: blockedSinceMarkdown, isHTML: true },
            {
                markdown: (
                    <MdClear
                        style={{
                            cursor: 'pointer',
                            color: '#db64ff',
                            transform: 'scale(1.2)',
                        }}
                        onClick={() => this.unblock(branch_id, id)}
                    />
                ),
                isHTML: false,
            },
        ]
    }

    getBookingListStructure = (item, mode = 'block') => {
        const {
            id,
            br_id,
            fl_id,
            course,
            fl_firstname,
            fl_lastname,
            date,
            blacklisted,
        } = item

        let dateformat = 'd. mmm yyyy'
        if (i18n.language === 'en') {
            dateformat = 'mm/dd/yyyy'
        }

        const courseMarkdown = `<b>${course}</b>`
        const trainerMarkdown = `<b>${fl_firstname} ${fl_lastname}</b>`

        return [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: trainerMarkdown, isHTML: true },
            { markdown: dateFormat(date, dateformat), isHTML: false },
            {
                markdown: (
                    <MdBlock
                        style={{
                            cursor: 'pointer',
                            color: blacklisted ? '#dedede' : '#db64ff',
                            transform: 'scale(1.2)',
                            pointerEvents: blacklisted ? 'none' : 'initial',
                        }}
                        onClick={() => {
                            if (mode === 'block') {
                                this.block(br_id, fl_id)
                            }
                            if (mode === 'cancel') {
                                this.setState({
                                    ...this.state,
                                    showPopup: true,
                                    cancelId: id,
                                })
                            }
                        }}
                    />
                ),
                isHTML: false,
            },
        ]
    }

    render() {
        const {
            blacklist,
            pastBookings,
            upcomingBookings,
            isLoading,
            showMessage,
            message,
            showPopup,
            cancelReason,
            cancelId,
        } = this.state
        const { t } = this.props

        return (
            <>
                <BranchDropdown
                    updateItems={this.updateItems}
                    setSelectedBranch={this.setSelectedBranch}
                />

                <div style={{ marginTop: 50 }}>
                    <SubHeading>{t('blacklist.blockedTrainers')}</SubHeading>
                    <p>{t('blacklist.blockedTrainersDescription')}</p>
                </div>

                <List
                    items={blacklist}
                    noItemsMessage={t('blacklist.noBlockedTrainers')}
                    headings={[t('list.trainer'), t('list.blockedSince'), '']}
                    getListItemStructure={this.getBlacklistListStructure}
                    style={{ marginTop: '30px' }}
                />

                <div style={{ marginTop: 50 }}>
                    <SubHeading>{t('booking.pastAltBlacklist')}</SubHeading>
                    <p>{t('blacklist.pastDescriptionAlt')}</p>
                </div>

                <List
                    items={pastBookings}
                    noItemsMessage={t('booking.noPast')}
                    headings={[
                        t('list.job'),
                        t('list.trainer'),
                        t('list.date'),
                        '',
                    ]}
                    getListItemStructure={(item) =>
                        this.getBookingListStructure(item, 'block')
                    }
                    style={{ marginTop: '30px' }}
                />

                <div style={{ marginTop: 50 }}>
                    <SubHeading>{t('booking.cancelBookingTitle')}</SubHeading>
                    <p>{t('booking.cancelBookingDesc')}</p>
                </div>

                <List
                    items={upcomingBookings}
                    noItemsMessage={t('booking.noUpcoming')}
                    headings={[
                        t('list.job'),
                        t('list.trainer'),
                        t('list.date'),
                        '',
                    ]}
                    getListItemStructure={(item) =>
                        this.getBookingListStructure(item, 'cancel')
                    }
                    style={{ marginTop: '30px' }}
                />

                {isLoading && <Loader />}
                {showMessage && message && (
                    <PopupMessage
                        message={message}
                        setShowMessage={this.setShowMessage}
                    />
                )}
                {showPopup && (
                    <Popup
                        title={t('booking.cancelBookingTitle')}
                        hidePopup={() =>
                            this.setState({
                                ...this.state,
                                showPopup: false,
                                cancelId: undefined,
                                cancelReason: undefined,
                            })
                        }
                        size={'medium'}
                    >
                        <p>{t('booking.cancelBookingConfirm')}</p>

                        <InputSection style={{ marginTop: 25 }}>
                            <StyledLabel>
                                {t('booking.cancelBookingReason')}
                            </StyledLabel>
                            <TextArea
                                value={cancelReason}
                                onChange={(e) =>
                                    this.setCancelReason(e.target.value)
                                }
                                rows="5"
                            />
                        </InputSection>

                        <StyledButton
                            primary
                            style={{
                                width: '100%',
                                marginTop: SPACING.xlarge,
                                marginBottom: 15,
                                backgroundColor: '#d01a1a',
                            }}
                            disabled={!!!cancelId || !!!cancelReason}
                            onClick={() => this.cancelBooking()}
                        >
                            {t('booking.cancelBookingButton')}
                        </StyledButton>

                        <StyledButton
                            tertiary
                            style={{ width: '100%' }}
                            onClick={() =>
                                this.setState({
                                    ...this.state,
                                    showPopup: false,
                                    cancelId: undefined,
                                    cancelReason: undefined,
                                })
                            }
                        >
                            {t('popup.abort')}
                        </StyledButton>
                    </Popup>
                )}
            </>
        )
    }
}

export default withTranslation()(BlacklistWidget)
