import { useTranslation, getI18n } from "react-i18next";
import { Calendar } from '@natscale/react-calendar';
import React, {useState, useCallback, useEffect} from 'react';
import '@natscale/react-calendar/dist/main.css';
import AvailabilityService from "../../services/availability-service";
import styled from "styled-components";
import {MdClose} from "react-icons/md";
import Loader from "../../legacy/components/loader";
import PopupMessage from "../../legacy/components/popup-message";
import AvailabilityPopup from "../../legacy/components/edit-availability-popup-new";
import BookingsService from "../../services/bookings-service";
import FreelancerBookingsPopup from "../../legacy/components/freelancer-bookings-popup";
import SearchService from "../../services/search-service";
import FreelancerRequestPopupNew from "../../legacy/components/freelancer-request-popup-new";
import NewAvailabilityPopupNew from "../../legacy/components/new-availability-popup-new";
import {InfoMessage, StyledLabel} from "../../legacy/components/styled-components";
import {DEVICE, SPACING} from "../../assets/constants";
import {AVAILABILITY_COLORS} from "../../legacy/assets/constants";

const NoItemMessageWrapper = styled.div`
  font-family: Montserrat, sans-serif;
  padding: 30px;

  i {
    font-size: 0.7em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-style: normal;
    color: #bdbdbd;
    flex: 1 !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
`;

const Wrapper = styled.div`

    @media ${DEVICE.phone} {
        flex-direction: column;
    }
`;

const CloseButton = styled(MdClose)`
  position: absolute;
  top: 20px;
  left: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const NewCalendar = () => {

    const { t } = useTranslation();
    const language = getI18n().language

    const monthsLabel = {
        0: t('monthsLabel.0'),
        1: t('monthsLabel.1'),
        2: t('monthsLabel.2'),
        3: t('monthsLabel.3'),
        4: t('monthsLabel.4'),
        5: t('monthsLabel.5'),
        6: t('monthsLabel.6'),
        7: t('monthsLabel.7'),
        8: t('monthsLabel.8'),
        9: t('monthsLabel.9'),
        10: t('monthsLabel.10'),
        11: t('monthsLabel.11'),
    };

    const weekDaysLabel = {
        0: t('weekDaysLabel.0'),
        1: t('weekDaysLabel.1'),
        2: t('weekDaysLabel.2'),
        3: t('weekDaysLabel.3'),
        4: t('weekDaysLabel.4'),
        5: t('weekDaysLabel.5'),
        6: t('weekDaysLabel.6'),
    };

    const getMaxDate = () => {
        const current = new Date();
        const month = current.getMonth() + 6
        const timeInt = new Date().setMonth(month)

        return new Date(timeInt)
    }

    const minDate = new Date().getTime();
    const maxDate = getMaxDate().getTime();

    const [date, setDate] = useState(null);
    const [title, setTitle] = useState('');
    const [entries, setEntries] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [requests, setRequests] = useState([]);
    const [activeEntries, setActiveEntries] = useState([]);
    const [activeBookings, setActiveBookings] = useState([]);
    const [activeRequests, setActiveRequests] = useState([]);
    const [currentAvailability, setCurrentAvailability] = useState(null);
    const [currentBooking, setCurrentBooking] = useState(null);
    const [currentRequest, setCurrentRequest] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [showPopupAvailabilities, setShowPopupAvailabilities] = useState(false);
    const [showPopupNew, setShowPopupNew] = useState(false);
    const [showPopupBookings, setShowPopupBookings] = useState(false);
    const [showPopupRequests, setShowPopupRequests] = useState(false);
    const [message, setMessage] = useState('');
    const [reloadAvailabilities, setReloadAvailabilities] = useState(0);
    const [reloadBookings, setReloadBookings] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const availabilityService = new AvailabilityService();
    const bookingsService = new BookingsService();
    const requestService = new SearchService();


    useEffect(() => {
        getAvailabilities()
    }, [reloadAvailabilities])

    useEffect(() => {
        getBookings()
        getRequests()
    }, [reloadBookings])

    useEffect(() => {
        if (entries && entries.length > 0) {
            setActiveEntries(entries.filter(element => {
                return element.startdate === date || element.enddate === date
            }))
        }
        if (bookings && bookings.length > 0) {
            setActiveBookings(bookings.filter(element => {
                return element.date === date || element.enddate === date
            }).sort((a, b) => {
                return new Date(a.date + 'T' + a.starttime).getTime() - new Date(b.date + 'T' + b.starttime).getTime();
            }))
        }
        if (requests && requests.length > 0) {
            setActiveRequests(requests.filter(element => {
                return element.date === date || element.enddate === date
            }).sort((a, b) => {
                return new Date(a.date + 'T' + a.starttime).getTime() - new Date(b.date + 'T' + b.starttime).getTime();
            }))
        }
        if (date) {
            setTitle(dateFormat(date))
        }
    }, [entries, bookings, requests, date, language])

    const getAvailabilities = () => {
        setLoading(true)
        availabilityService.getAvailabilities().then((availabilities: any) => {
            setEntries(availabilities)
            setLoading(false)
        });
    }

    const getBookings = () => {
        setLoading(true)
        bookingsService.getFreelancerAllBookings().then((bookingEntries: any) => {
            setBookings(bookingEntries)
            setLoading(false)
        });
    }

    const getRequests = () => {
        setLoading(true)
        requestService.getFreelancerRequests().then((requestEntries: any) => {
            setRequests(requestEntries.data)
            setLoading(false)
        });
    }

    const isHighlight = useCallback((d) => {
        const date =  d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +("0" + d.getDate()).slice(-2);

        if (entries && entries.length > 0) {
            if (entries.filter(element => {
                return element.startdate === date || element.enddate === date
            }).length > 0) { return true }
        }

        if (bookings && bookings.length > 0) {
            if (bookings.filter(element => {
                return element.date === date || element.enddate === date
            }).length > 0) { return true }
        }

        if (requests && requests.length > 0) {
            if (requests.filter(element => {
                return element.date === date || element.enddate === date
            }).length > 0) { return true }
        }
        return false

    }, [requests, entries, bookings]);

    const changeDate = useCallback(
        (d) => {
            setDate(d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2));
        },
        [setDate],
    );

    const dateFormat = (date: string) => {
        const d = new Date(date)
        if (language === 'de') {
            return  ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear()
        } else {
            return  ("0"+(d.getMonth()+1)).slice(-2) + "/" +("0" + d.getDate()).slice(-2) + "/" + d.getFullYear()
        }
    }

    const timeFormat = (time: string) => {
        if (language === 'de') {
            return time.slice(0, -3)
        } else {
            let hours = parseInt(time.substr(0, 2))
            let minutes = time.substr(3, 2)
            let ampm = hours >= 12 ? 'pm' : 'am'

            hours = hours % 12
            hours = hours ? hours : 12
            return hours + ':' + minutes + ' ' + ampm
        }
    }

    const hideDetail = () => {
        setDate(null)
    }

    return <>

        <h1>
            {t('pages.title.calendar')}
        </h1>

        <p>
            {t('pages.description.calendar')}
        </p>
        <div className="flip-card">
            <div className={`flip-card-inner ${date !== null ? "flipped" : ""}`}>
                <Calendar className="flip-card-front" fontSize={16} weekends={[]} isHighlight={isHighlight} hideAdjacentDates
                          weekDaysLabel={weekDaysLabel} monthsLabel={monthsLabel} onChange={changeDate}/>
                <div className="flip-card-back">
                    <div className="details-title">
                        <CloseButton onClick={() => hideDetail()}/>
                        <span>{title}</span>
                        {
                            (date && new Date(date).getTime() >= minDate && new Date(date).getTime() <= maxDate) &&
                            <div className="details-plus" onClick={() => {
                                setShowPopupNew(true)
                            }}>
                                <span>+</span>
                            </div>
                        }
                    </div>
                    <div className="details-day" style={{WebkitOverflowScrolling: 'touch'}}>
                        <p style={{marginBottom: '10px'}}>{t('newCalendar.availabilities')}</p>
                        {activeEntries.map((entry, i:number) => {
                            return (
                                <div
                                    className="details-element"
                                    onClick={() => {
                                        setCurrentAvailability(entry)
                                        setShowPopupAvailabilities(true)
                                    }}
                                    key={'entry_' + i}
                                >
                                    <div className={"details-element-time"}>
                                        {timeFormat(entry.starttime)}
                                        {entry.startdate !== date &&
                                            <sup className="day-plus"> {t('newCalendar.dayMinus')} </sup>
                                        }
                                        <span> - </span>
                                        {timeFormat(entry.endtime)}
                                        {entry.enddate !== date &&
                                            <sup className="day-plus"> {t('newCalendar.dayPlus')} </sup>
                                        }
                                    </div>
                                    <div className={"details-element-level details-element-"+entry.availability}>{t('forms.'+entry.availability)}</div>
                                    <svg width="7px" height="27px" viewBox="0 0 7 27" fill="#DB64FF" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4" cy="4" r="3.2894817" transform="translate(0.000000, 19.000000)"/>
                                        <circle cx="4" cy="4" r="3.2894817" transform="translate(0.000000, 9.500000)"/>
                                        <circle cx="4" cy="4" r="3.2894817"/>
                                    </svg>
                                </div>
                            )
                        })}
                        {
                            activeEntries.length === 0 &&
                            <NoItemMessageWrapper>
                                <i>{t('list.noCalendarEntries')}</i>
                            </NoItemMessageWrapper>
                        }
                        <p style={{marginBottom: '10px', marginTop: '30px'}}>{t('newCalendar.bookings')}</p>
                        {activeBookings.map((booking, i:number) => {
                            return (
                                <div
                                    className="details-element"
                                    onClick={() => {
                                        setCurrentBooking(booking)
                                        setShowPopupBookings(true)
                                    }}
                                    key={'booking_' + i}
                                >
                                    <div className={"details-element-time"}>
                                        {timeFormat(booking.starttime)}
                                        {booking.date !== date &&
                                            <sup className="day-plus"> {t('newCalendar.dayMinus')} </sup>
                                        }
                                        <span> - </span>
                                        {timeFormat(booking.endtime)}
                                        {booking.enddate !== date &&
                                            <sup className="day-plus"> {t('newCalendar.dayPlus')} </sup>
                                        }
                                    </div>
                                    <div className={"details-element-level details-element-booking"}>{booking.course} - {booking.br_name}</div>
                                    <svg width="7px" height="27px" viewBox="0 0 7 27" fill="#DB64FF" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4" cy="4" r="3.2894817" transform="translate(0.000000, 19.000000)"/>
                                        <circle cx="4" cy="4" r="3.2894817" transform="translate(0.000000, 9.500000)"/>
                                        <circle cx="4" cy="4" r="3.2894817"/>
                                    </svg>
                                </div>
                            )
                        })}
                        {
                            activeBookings.length === 0 &&
                            <NoItemMessageWrapper>
                                <i>{t('list.noCalendarEntries')}</i>
                            </NoItemMessageWrapper>
                        }
                        <p style={{marginBottom: '10px', marginTop: '30px'}}>{t('newCalendar.requests')}</p>
                        {activeRequests.map((request, i:number) => {
                            return (
                                <div
                                    className="details-element"
                                    onClick={() => {
                                        setCurrentRequest(request)
                                        setShowPopupRequests(true)
                                    }}
                                    key={'request_' + i}
                                >
                                    <div className={"details-element-time"}>
                                        {timeFormat(request.starttime)}
                                        {request.date !== date &&
                                            <sup className="day-plus"> {t('newCalendar.dayMinus')} </sup>
                                        }
                                        <span> - </span>
                                        {timeFormat(request.endtime)}
                                        {request.enddate !== date &&
                                            <sup className="day-plus"> {t('newCalendar.dayPlus')} </sup>
                                        }
                                    </div>
                                    <div className={"details-element-level details-element-request"}>{request.course} - {request.br_name}</div>
                                    <svg width="7px" height="27px" viewBox="0 0 7 27" fill="#DB64FF" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4" cy="4" r="3.2894817" transform="translate(0.000000, 19.000000)"/>
                                        <circle cx="4" cy="4" r="3.2894817" transform="translate(0.000000, 9.500000)"/>
                                        <circle cx="4" cy="4" r="3.2894817"/>
                                    </svg>
                                </div>
                            )
                        })}
                        {
                            activeRequests.length === 0 &&
                            <NoItemMessageWrapper>
                                <i>{t('list.noCalendarEntries')}</i>
                            </NoItemMessageWrapper>
                        }
                    </div>
                </div>

            </div>
        </div>

        <div style={{ marginTop: 50 }}>
            <StyledLabel>{t('booking.legend')}</StyledLabel>
            <Wrapper style={{ display: 'flex', fontSize: '0.8rem' }}>
                <InfoMessage
                    style={{
                        marginTop: SPACING.medium,
                        flex: 1,
                        paddingRight: SPACING.large,
                    }}
                >
                    <strong
                        style={{
                            backgroundColor: AVAILABILITY_COLORS.high,
                            borderRadius: 5,
                            width: 100,
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: SPACING.small,
                        }}
                    >
                        {t('booking.availabilityHighTitle')}
                    </strong>
                    <span style={{fontSize: '1rem'}}>{t('booking.availabilityHighDesc')}</span>
                </InfoMessage>
                <InfoMessage
                    style={{
                        marginTop: SPACING.medium,
                        flex: 1,
                        paddingRight: SPACING.large,
                    }}
                >
                    <strong
                        style={{
                            backgroundColor: AVAILABILITY_COLORS.medium,
                            borderRadius: 5,
                            width: 100,
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: SPACING.small,
                        }}
                    >
                        {t('booking.availabilityMediumTitle')}
                    </strong>
                    <span style={{fontSize: '1rem'}}>{t('booking.availabilityMediumDesc')}</span>
                </InfoMessage>
                <InfoMessage
                    style={{
                        marginTop: SPACING.medium,
                        flex: 1,
                        paddingRight: SPACING.large,
                    }}
                >
                    <strong
                        style={{
                            backgroundColor: AVAILABILITY_COLORS.onrequest,
                            borderRadius: 5,
                            width: 100,
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: SPACING.small,
                        }}
                    >
                        {t('booking.availabilityRequestTitle')}
                    </strong>
                    <span style={{fontSize: '1rem'}}>{t('booking.availabilityRequestDesc')}</span>
                </InfoMessage>
            </Wrapper>
        </div>
        {
            showPopupAvailabilities &&
            <AvailabilityPopup
                entry={currentAvailability}
                hidePopup={() => setShowPopupAvailabilities(false)}
                addMessage={(message:string) => setMessage(message)}
                showMessage={() => setShowMessage(true)}
                reloadData={() => setReloadAvailabilities(reloadAvailabilities + 1)}
            />
        }
        {
            showPopupNew &&
            <NewAvailabilityPopupNew
                datetime={date}
                hidePopup={() => setShowPopupNew(false)}
                addMessage={(message:string) => setMessage(message)}
                showMessage={() => setShowMessage(true)}
                reloadData={() => setReloadAvailabilities(reloadAvailabilities + 1)}
            />
        }

        {
            showPopupBookings &&
            <FreelancerBookingsPopup
                booking={currentBooking}
                hidePopup={() => setShowPopupBookings(false)}
                currentLanguage={language} />
        }
        {
            showPopupRequests &&
            <FreelancerRequestPopupNew
                request={currentRequest}
                hidePopup={() => setShowPopupRequests(false)}
                addMessage={(message:string) => setMessage(message)}
                showMessage={() => setShowMessage(true)}
                reloadData={() => setReloadBookings(reloadBookings + 1)}
            />
        }
        {
            isLoading &&
            <Loader />
        }

        {
            showMessage && message &&
            <PopupMessage message={message} setShowMessage={setShowMessage} />
        }
    </>;

}

export default NewCalendar;
