import React, { Component } from 'react';
import BranchDropdown from "../branch-dropdown";
import List from "../list";
import dateFormat from "dateformat";
import Loader from "../loader";
import PopupMessage from "../popup-message";
import { ReactComponent as StarIcon } from '../../assets/images/star.svg';
import { ReactComponent as MoreButton } from "../../assets/images/more.svg";
import RatingsPopup from "../ratings-popup";
import { sortByDateProperty } from "../../utils/utility-functions";
import { withTranslation } from "react-i18next";
import RatingsService from '../../../services/ratings-service';
import i18n from '../../../i18n/config';

class FreelancerGivenRatingsWidget extends Component {

    constructor(props) {
        super(props);

        this.ratingsService = new RatingsService();

        this.state = {
            selectedBooking: undefined,
            ratings: undefined,
            isLoading: false,
            showMessage: false,
            message: undefined,
            showPopup: false,
            dateformat: i18n.language === 'en' ? 'mm/dd/yyyy' : 'd. mmm yyyy',
        }
    }

    componentDidMount() {
        this.updateRatings();
    }

    setShowPopup = (show) => {
        this.setState({ ...this.state, showPopup: show });
    }

    setIsLoading = (isLoading) => {
        this.setState({ ...this.state, isLoading: isLoading });
    }

    setShowMessage = (show) => {
        this.setState({ ...this.state, showMessage: show });
    }

    setMessage = (message) => {
        this.setState({ ...this.state, message: message });
    }

    updateRatings = () => {
        this.ratingsService.getFreelancerGivenRatings().then(res => {
            if (res && res.data) {
                this.setState({ ...this.state, ratings: sortByDateProperty(res.data, false) });
            }
        });
    }

    openRatingsPopup = (item) => {
        this.setState(
            { ...this.state, selectedBooking: item },
            () => this.setShowPopup(true)
        );
    }

    getRatingsListStructure = (item) => {
        const { course, br_name, date, total } = item;
        const { dateformat } = this.state;

        const courseMarkdown = `<b>${course}</b>`;
        const branchMarkdown = `<b>${br_name}</b>`;
        const dateMarkdown = `<span>${dateFormat(date, dateformat)}</span>`;
        const ratingMarkdown = total ?
            (<>{total.toFixed(2)} <StarIcon
                style={{ marginBottom: 3, marginLeft: 3 }} /></>) :
            <i>Noch keine Bewertungen</i>;

        return [
            { markdown: courseMarkdown, isHTML: true },
            { markdown: branchMarkdown, isHTML: true },
            { markdown: dateMarkdown, isHTML: true },
            { markdown: ratingMarkdown, isHTML: false },
            {
                markdown: <MoreButton style={{ cursor: 'pointer' }} onClick={() => this.openRatingsPopup(item)} />,
                isHTML: false
            },
        ];
    }

    render() {
        const { ratings, isLoading, showMessage, message, showPopup, selectedBooking } = this.state;
        const { t } = this.props;

        return <>
            <List items={ratings}
                noItemsMessage={t('list.noGivenRatings')}
                headings={[t('list.job'), t('list.company'), t('list.date'), t('list.rating'), '']}
                getListItemStructure={this.getRatingsListStructure}
                style={{ marginTop: '30px' }} />

            {
                showPopup &&
                <RatingsPopup booking={selectedBooking}
                    role={'freelancer'}
                    hidePopup={() => this.setShowPopup(false)} />
            }
            {
                isLoading &&
                <Loader />
            }
            {
                showMessage && message &&
                <PopupMessage message={message} setShowMessage={this.setShowMessage} />
            }
        </>;
    }
}

export default withTranslation()(FreelancerGivenRatingsWidget);