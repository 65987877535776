export const HOME_PAGE = 'https://jobbelganger.com'
export const PRIVACY_PAGE = 'https://jobbelganger.com/datenschutz/'
export const COOKIES_PAGE = 'https://jobbelganger.com/cookie-richtlinie/'
export const TERMS_PAGE = 'https://jobbelganger.com/nutzungsbedingungen/'
export const IMPRINT_PAGE = 'https://jobbelganger.com/rechtliche-informationen/'
export const FAQ_PAGE = 'https://jobbelganger.com/faq/'

export const HOME_PAGE_EN = 'https://jobbelganger.com/en'
export const PRIVACY_PAGE_EN = 'https://jobbelganger.com/en/data-protection/'
export const COOKIES_PAGE_EN = 'https://jobbelganger.com/en/cookie-policy/'
export const TERMS_PAGE_EN = 'https://jobbelganger.com/en/terms-of-use/'
export const IMPRINT_PAGE_EN = 'https://jobbelganger.com/en/legal-notice/'
export const FAQ_PAGE_EN = 'https://jobbelganger.com/en/faqs/'
