import React, { Component } from "react";
import SearchForm from "./search-form";
import FreelancerCard from "./freelancer-card";
import { StyledButton, StyledWrapper } from "../styled-components";
import { DEVICE } from "../../assets/constants";
import styled from "styled-components";
import LoadingSpinner from "../../assets/images/loading.gif";
import ReactHtmlParser from "react-html-parser";
import { withTranslation } from "react-i18next";
import SearchService from "../../../services/search-service";
import BranchService from "../../../services/branch-service";
import { SPACING } from "../../../assets/constants";

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${DEVICE.phone} {
    flex-direction: column;
  }
`;

const SearchFormWrapper = styled(StyledWrapper)`
  margin: 0 15px 0 0;
  flex: 0 0 30em;
  opacity: ${(props) => (props.active ? "1" : "0.3")};
  transform: ${(props) => (props.active ? "scale(1)" : "scale(0.97)")};
  transition: 0.2s;
  min-height: 640px;

  &:hover {
    opacity: 1;
    transform: scale(1);
  }

  @media ${DEVICE.phone} {
    flex: 0 0 100%;
    margin: 0;
    padding: 25px;
    min-height: 600px;
  }
`;

const MessageBox = styled(StyledWrapper)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media ${DEVICE.phone} {
    text-align: center;
    position: initial;
    width: 100%;
    transform: initial;
    margin-top: 15px;
  }
`;

const RequestButton = styled(StyledButton)`
  width: 100%;
  margin: 0;

  @media ${DEVICE.phone} {
    width: 100%;
    margin: 0;
  }
`;

const ExtendButton = styled(StyledButton)`
  width: 100%;
  margin: 0;
  margin-top: 15px;

  @media ${DEVICE.phone} {
    width: 100%;
    margin: 0;
    margin-top: 15px;
  }
`;

class SearchWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResult: undefined,
      isLoading: false,
      loadingMessage: undefined,
      branches: [],
      selectedBranch: undefined,
    };

    this.searchService = new SearchService();
    this.branchService = new BranchService();
  }

  componentDidMount() {
    this.branchService.getAllBranches().then((branches) => {
      this.setState({
        ...this.state,
        branches: branches.length
          ? branches.map((branch) => {
              return {
                label: `${branch.name} (${branch.street} ${branch.housenumber}, ${branch.city})`,
                branchId: branch.id,
              };
            })
          : [
              {
                label: `${branches.name} (${branches.street} ${branches.housenumber}, ${branches.city})`,
                branchId: branches.id,
              },
            ],
        selectedBranch: branches.length
          ? {
              label: `${branches[0].name} (${branches[0].street} ${branches[0].housenumber}, ${branches[0].city})`,
              branchId: branches[0].id,
            }
          : {
              label: `${branches.name} (${branches.street} ${branches.housenumber}, ${branches.city})`,
              branchId: branches.id,
            },
      });
    });
  }

  handleBranchSelect = (branch) => {
    this.setState({
      selectedBranch: branch,
    });
  };

  setSearchResult = (result) => {
    this.setState({ searchResult: result });
  };

  setIsLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  setLoadingMessage = (message) => {
    this.setState({ loadingMessage: message });
  };

  generateMessageFromResult = (searchResult) => {
    const { t } = this.props;

    if (!searchResult) return;

    if (searchResult.message) return searchResult.message;

    if (searchResult.booked) {
      const { course, fl_firstname, fl_lastname, date, starttime, endtime } =
        searchResult.data;
      //return `<b>${fl_firstname} ${fl_lastname}</b> wurde verbindlich für <b>${course}</b> am <b>${dateFormat(date, 'd. mmmm yyyy')}</b> von <b>${formatTimeString(starttime)} bis ${formatTimeString(endtime)} Uhr</b> gebucht.`;
      return t("search.booked");
    }

    if (searchResult.declined) return t("search.declined");

    if (searchResult.data && searchResult.data.requestables === 0)
      return t("search.noFreelancers");

    if (
      searchResult.data &&
      searchResult.data.requestables > 0 &&
      searchResult.data.requested === 0
    ) {
      return t("search.requestOrExtend");
    }

    if (searchResult.data && searchResult.data.requested > 0) {
      return t("search.alreadySearchedFor");
    }

    return;
  };

  requestTrainer = (requestId) => {
    const { t } = this.props;

    this.setIsLoading(true);
    this.setLoadingMessage(t("search.requesting"));
    this.searchService
      .requestFreelancer(this.state.selectedBranch.branchId, requestId)
      .then((res) => {
        this.setIsLoading(false);
        this.setLoadingMessage(undefined);
        this.setState({ searchResult: res });
      });
  };

  extendSearch = (requestId) => {
    const { t } = this.props;

    this.setIsLoading(true);
    this.setLoadingMessage(t("search.extending"));

    this.searchService
      .extendSearch(this.state.selectedBranch.branchId, requestId)
      .then((res) => {
        this.setIsLoading(false);
        this.setLoadingMessage(undefined);
        this.setState({ searchResult: res });
      });
  };

  render() {
    const {
      searchResult,
      isLoading,
      loadingMessage,
      branches,
      selectedBranch,
    } = this.state;

    const { t } = this.props;
    const { data, extendable, requestable, id } = searchResult || {};

    const freelancerFound = searchResult && data && data.suggestion > 0;
    const searchMessage = this.generateMessageFromResult(searchResult);

    return (
      <SearchWrapper>
        <SearchFormWrapper active={!freelancerFound}>
          <SearchForm
            setSearchResult={this.setSearchResult}
            setIsLoading={this.setIsLoading}
            setLoadingMessage={this.setLoadingMessage}
            branches={branches}
            selectedBranch={selectedBranch}
            handleBranchSelect={this.handleBranchSelect}
          />
        </SearchFormWrapper>
        {freelancerFound && !isLoading && (
          <FreelancerCard
            searchResult={data}
            selectedBranch={selectedBranch}
            setSearchResult={this.setSearchResult}
            setIsLoading={this.setIsLoading}
            setLoadingMessage={this.setLoadingMessage}
          />
        )}
        {searchMessage &&
          !isLoading &&
          !freelancerFound &&
          (!data || (data && !data.old)) && (
            <MessageBox>
              <p>{ReactHtmlParser(searchMessage)}</p>
              {searchResult && (
                <div style={{ width: "100%", marginTop: SPACING.large }}>
                  {(requestable ||
                    (data &&
                      data.requestables > 0 &&
                      data.requested === 0)) && (
                    <RequestButton
                      primary
                      onClick={() => this.requestTrainer(data ? data.id : id)}
                    >
                      {t("search.request")}
                    </RequestButton>
                  )}
                  {(extendable ||
                    (data &&
                      data.requestables > 0 &&
                      data.requested === 0)) && (
                    <ExtendButton
                      primary
                      onClick={() => this.extendSearch(data ? data.id : id)}
                    >
                      {t("search.extend")}
                    </ExtendButton>
                  )}
                </div>
              )}
            </MessageBox>
          )}
        {data && data.old && !isLoading && !freelancerFound && (
          <MessageBox>
            <p>{t("search.existingRequest")}</p>
          </MessageBox>
        )}
        {isLoading && (
          <MessageBox>
            <p>{loadingMessage}</p>
            <img src={LoadingSpinner} width={55} height={45} />
          </MessageBox>
        )}
      </SearchWrapper>
    );
  }
}

export default withTranslation()(SearchWidget);
