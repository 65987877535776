import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DEVICE, SPACING } from "../../assets/constants";
import { AVAILABILITY_COLORS } from "../../legacy/assets/constants";
import { InfoMessage, StyledLabel } from "../../legacy/components/styled-components";
import CalendarWidget from "../../legacy/components/widgets/calendar-widget";

const Wrapper = styled.div`

    @media ${DEVICE.phone} {
        flex-direction: column;
    }
`;

const Calendar = () => {

    const { t } = useTranslation();

    return <>

        <h1>
            {t('pages.title.calendar')}
        </h1>

        <p>
            {t('pages.description.calendar')}
        </p>

        <CalendarWidget />

        <div style={{ marginTop: 50 }}>
            <StyledLabel>{t('booking.legend')}</StyledLabel>
            <Wrapper style={{ display: 'flex', fontSize: '0.8rem' }}>
                <InfoMessage
                    style={{
                        marginTop: SPACING.medium,
                        flex: 1,
                        paddingRight: SPACING.large,
                    }}
                >
                    <strong
                        style={{
                            backgroundColor: AVAILABILITY_COLORS.high,
                            borderRadius: 5,
                            width: 100,
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: SPACING.small,
                        }}
                    >
                        {t('booking.availabilityHighTitle')}
                    </strong>
                    <span style={{fontSize: '1rem'}}>{t('booking.availabilityHighDesc')}</span>
                </InfoMessage>
                <InfoMessage
                    style={{
                        marginTop: SPACING.medium,
                        flex: 1,
                        paddingRight: SPACING.large,
                    }}
                >
                    <strong
                        style={{
                            backgroundColor: AVAILABILITY_COLORS.medium,
                            borderRadius: 5,
                            width: 100,
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: SPACING.small,
                        }}
                    >
                        {t('booking.availabilityMediumTitle')}
                    </strong>
                    <span style={{fontSize: '1rem'}}>{t('booking.availabilityMediumDesc')}</span>
                </InfoMessage>
                <InfoMessage
                    style={{
                        marginTop: SPACING.medium,
                        flex: 1,
                        paddingRight: SPACING.large,
                    }}
                >
                    <strong
                        style={{
                            backgroundColor: AVAILABILITY_COLORS.onrequest,
                            borderRadius: 5,
                            width: 100,
                            display: 'block',
                            textAlign: 'center',
                            marginBottom: SPACING.small,
                        }}
                    >
                        {t('booking.availabilityRequestTitle')}
                    </strong>
                    <span style={{fontSize: '1rem'}}>{t('booking.availabilityRequestDesc')}</span>
                </InfoMessage>
            </Wrapper>
        </div>

    </>;

}

export default Calendar;