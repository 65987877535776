import React from "react";

export const Heading = ({ children }: any) => {

    return <h3 style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
        letterSpacing: 1,
    }}>{children}</h3>
}