import React, { Component } from 'react'
import {
    InputSection,
    StyledDatePicker,
    StyledLabel,
    StyledSelect,
} from '../styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import dateFormat from 'dateformat'
import { withTranslation } from 'react-i18next'
import PhoneInput from "react-phone-input-2";

class FreelancerPersonalDataForm extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            phone,
            birth,
            changeValue,
            t,
            currentLang,
        } = this.props
        const maxDate = new Date().setFullYear(new Date().getFullYear() - 18) // At least 18 y/o

        return (
            <>
                <div style={{ display: 'flex', margin: '0 -5px' }}>
                    <InputSection style={{ flex: '1 1 0', margin: '0 5px'}}>
                        <StyledLabel>{t('forms.phone')}</StyledLabel>
                        <PhoneInput
                            country={'us'}
                            preferredCountries={['us', 'ch', 'at', 'gb', 'de', 'au']}
                            countryCodeEditable={false}
                            enableSearch={true}
                            disableSearchIcon={true}
                            value={phone}
                            onChange={phone =>
                                changeValue('phone', phone)
                            }
                            inputClass={'phoneInput'}
                        />

                    </InputSection>
                </div>
                <InputSection style={{ marginTop: 20 }}>
                    <StyledLabel>{t('forms.birth')}</StyledLabel>
                    <StyledDatePicker
                        style={{ width: '100%' }}
                        selected={birth ? new Date(birth) : maxDate}
                        onChange={(date) => {
                            changeValue(
                                'birth',
                                dateFormat(date ? date : maxDate, 'yyyy-mm-dd')
                            )
                        }}
                        maxDate={maxDate}
                        showYearDropdown
                        locale={currentLang}
                        dateFormat={
                            currentLang === 'de' ? 'd. MMMM yyyy' : 'MM/dd/yyyy'
                        }
                    />
                </InputSection>
            </>
        )
    }
}

export default withTranslation()(FreelancerPersonalDataForm)
