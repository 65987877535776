import React, { Component } from 'react'
import styled from 'styled-components'
import { Backdrop, StyledWrapper } from './styled-components'
import { MdClose } from 'react-icons/md'
import { DEVICE } from '../assets/constants'
import { NO_SCROLL_BARS } from '../../assets/constants'

const PopupWrapper = styled(StyledWrapper)`
    padding: 0;
    width: ${(props) => (props.size === 'large' ? '500px' : '370px')};
    max-width: 90%;
    max-height: 90vh;
    overflow: scroll;
    ${NO_SCROLL_BARS}

    h3 {
        font-size: 1.2em;
        margin-bottom: 0;
    }

    h6 {
        margin-top: 5px;
    }

    @media ${DEVICE.phone} {
        max-height: 70vh;
        overflow: auto;
    }
`

const PopupHeader = styled.p`
    font-size: 1.1em;
    font-weight: bold;
    position: relative;
    text-align: center;
    padding: 20px 80px 17px;
    border-bottom: 1px solid #e1e1e1;
    margin: 0;
`

const PopupBody = styled.div`
    padding: 20px 30px 15px;
`

const CloseButton = styled(MdClose)`
    position: absolute;
    top: 53%;
    transform: translateY(-50%);
    left: 23px;
    height: 20px;
    width: 20px;
    cursor: pointer;
`

class Popup extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        // TODO find a better way so content do not shifts
        document.body.style.overflow = 'hidden'
    }

    componentWillUnmount() {
        // TODO find a better way so content do not shifts
        document.body.style.overflow = 'initial'
    }

    render() {
        const { title, hidePopup, children, size } = this.props

        return (
            <Backdrop>
                <PopupWrapper size={size}>
                    <PopupHeader>
                        {' '}
                        <CloseButton onClick={hidePopup} /> {title}
                    </PopupHeader>
                    <PopupBody>{children}</PopupBody>
                </PopupWrapper>
            </Backdrop>
        )
    }
}

export default Popup
