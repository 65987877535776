import axios from 'axios'

class SearchService {
    constructor(options = {}) {
        this.apiBase = process.env.REACT_APP_API_BASE
        this.config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jg-token')}`,
            },
        }
    }

    async searchTrainer(branch, course, date, endDate, from, to) {
        try {
            const response = await axios.post(
                `${this.apiBase}/branch/${branch}/requests`,
                {
                    course_id: course,
                    startdate: date,
                    enddate: endDate,
                    starttime: from,
                    endtime: to,
                },
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async bookFreelancer(branchId, requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/requests/${requestId}/confirm`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async declineFreelancer(branchId, requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/requests/${requestId}/decline`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async requestFreelancer(branchId, requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/requests/${requestId}/requesting`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async extendSearch(branchId, requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/requests/${requestId}/extending`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getBranchRequests(branchId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/requests`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getRequest(branchId, requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/branch/${branchId}/requests/${requestId}`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async deleteRequest(branchId, requestId) {
        try {
            const response = await axios.delete(
                `${this.apiBase}/branch/${branchId}/requests/${requestId}`,
                this.config
            )

            return response.data
        } catch (error) {
            throw error.response
        }
    }

    async getFreelancerRequests() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/requests`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getFreelancerSuggestions() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/suggestions`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async getOtherFreelancerRequests() {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/open-requests`,
                this.config
            )

            return response
        } catch (error) {
            return error
        }
    }

    async confirmBranch(requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/requests/${requestId}/confirm`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async confirmOtherBranch(requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/open-requests/${requestId}/confirm`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }

    async declineBranch(requestId) {
        try {
            const response = await axios.get(
                `${this.apiBase}/me/requests/${requestId}/decline`,
                this.config
            )

            return response.data
        } catch (error) {
            return error
        }
    }
}

export default SearchService
