import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import deJson from './de/translation.json'
import enJson from './en/translation.json'
import dateFormat from 'dateformat'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import en from 'date-fns/locale/en-US'

export const resources = {
    de: {
        translation: deJson,
    },
    en: {
        translation: enJson,
    },
} as const

export const locales = {
    de: de,
    en: en,
}

const currentLanguage: any = localStorage.getItem('jg-lang') || 'de'

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: currentLanguage,
        resources,
        react: {
            // Turn off the use of React Suspense
            useSuspense: false,
        },
    })

i18n.on('languageChanged', (lang) => {
    switch (lang) {
        case 'en':
            registerLocale('en', en)

            dateFormat.i18n = {
                dayNames: [
                    'Sun',
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat',
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                ],
                monthNames: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
            }
            break
        default:
            registerLocale('de', de)
            setDefaultLocale('de')

            dateFormat.i18n = {
                dayNames: [
                    'So',
                    'Mo',
                    'Di',
                    'Mi',
                    'Do',
                    'Fr',
                    'Sa',
                    'Sonntag',
                    'Montag',
                    'Dienstag',
                    'Mittwoch',
                    'Donnerstag',
                    'Freitag',
                    'Samstag',
                ],
                monthNames: [
                    'Jan',
                    'Feb',
                    'Mär',
                    'Apr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Dez',
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember',
                ],
                timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
            }
            break
    }
})

export default i18n
