import background from '../assets/images/background.png'
import routes from './routes'
import styled from 'styled-components'
import {COLORS, DEVICE, SPACING} from '../assets/constants'
import {ReactComponent as Shape1} from '../assets/images/shape-1.svg'
import {ReactComponent as Shape2} from '../assets/images/shape-2.svg'
import {useAuth} from '../context/AuthContext'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'

const Wrapper = styled.div`
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    height: 100%;
`

const WrapperWithMenu = styled.div`
    height: 100%;
    padding: ${SPACING.xlarge}px;
    padding-top: ${SPACING.xlarge * 2}px;

    @media ${DEVICE.tablet} {
        padding-left: ${SPACING.xlarge}px;
    }
`

const Inner = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 950px;

    & > h1 {
        margin-bottom: ${SPACING.medium}px;
    }

    & > p {
        margin-bottom: ${SPACING.xlarge}px;
    }
`

const StyledShape1 = styled(Shape1)`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${COLORS.lightgrey};
  height: 48%;
  max-height: 800px;
  width: 300%;
`;

const StyledShape2 = styled(Shape2)`
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${COLORS.lightgrey};
    height: 48%;
    max-height: 600px;
    width: 300%;
    transform: rotate(180deg);
`

const Pages = () => {
    const {token, user} = useAuth()
    const {t} = useTranslation()
    const routesWithoutMenu = [
        '/' + t('routes.login'),
        '/' + t('routes.register'),
        '/' + t('routes.forgotPassword'),
        '/' + t('routes.resetPassword'),
        '/' + t('routes.setup'),
        '*',
    ]

    const setupFinished =
        user &&
        (['employee', 'manager'].includes(user.role) ||
            user.freelancer_id ||
            user.company)

    const location = useLocation()
    const locale = new URLSearchParams(location.search).get('locale')

    return (
        <Switch>
            {routes.map((route, index) => (
                <Route
                    exact={route.exact}
                    path={route.path}
                    key={route.key || index}
                >
                    {(!token || !user) && route.auth ? (
                        <Redirect
                            to={`/${t('routes.login')}?locale=${locale}`}
                        />
                    ) : token &&
                    user &&
                    !setupFinished &&
                    route.path !== `/${t('routes.setup')}` ? (
                        <Redirect to={`/${t('routes.setup')}`}/>
                    ) : token &&
                    user &&
                    setupFinished &&
                    (route.path === `/${t('routes.setup')}` ||
                        route.path === `/${t('routes.login')}`) ? (
                        <Redirect to="/"/>
                    ) : routesWithoutMenu.includes(route.path) ? (
                        <Wrapper>{route.component}</Wrapper>
                    ) : (
                        <WrapperWithMenu>
                            <StyledShape1/>
                            <StyledShape2/>
                            <Inner>{route.component}</Inner>
                        </WrapperWithMenu>
                    )}
                </Route>
            ))}
        </Switch>
    )
}

export default Pages
