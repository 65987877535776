import React, { Component } from 'react';
import Select, { components } from 'react-select'
import { InputSection, StyledLabel } from "../styled-components";
import { withTranslation } from 'react-i18next';
import { PRIMARY_COLOR } from '../../assets/constants';
import { AiFillCaretDown } from "react-icons/ai";
import styled from "styled-components";
import { SPACING } from '../../../assets/constants';

const FilterContainer = styled.div`
`;

const FilterSelection = styled.p`
    margin: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const FilterCheckbox = styled.input`
    margin-left: 1px;
`;

const FilterLabel = styled.span`
    margin: 0 5px !important;
`;

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <AiFillCaretDown size={14} style={{ marginRight: 3 }} />
        </components.DropdownIndicator>
    );
};

const Placeholder = props => {
    return <components.Placeholder {...props} />;
};

class FreelancerCourseForm extends Component {

    constructor(props) {
        super(props);

        this.colourStyles = {
            multiValue: (styles, { data }) => {
                return {
                    ...styles,
                    backgroundColor: 'rgba(155, 199, 76, 0.15)',
                    borderRadius: '3px',
                };
            },
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: '#71ac08',
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: '#71ac08',
                ':hover': {
                    backgroundColor: '#71ac08',
                    color: 'white',
                },
            }),
        };
    }

    render() {
        const {
            courses, selectedCourses, changeValue, t,
            fitnessFilter, gastronomyFilter,
            setFitnessFilter, setGastronomyFilter
        } = this.props;

        return <>

            <div style={{ marginBottom: SPACING.medium }}>
                <StyledLabel>{t('courses.filter')}</StyledLabel>
                <FilterContainer>
                    <FilterSelection>
                        <FilterCheckbox type={'checkbox'} id={'fitnessFilter'}
                            checked={fitnessFilter}
                            onChange={(event) => setFitnessFilter(event.target.checked)} />
                        <FilterLabel htmlFor={'fitnessFilter'}>{t('courses.fitness')}</FilterLabel>
                    </FilterSelection>
                    <FilterSelection>
                        <FilterCheckbox type={'checkbox'} id={'gastroFilter'}
                            checked={gastronomyFilter}
                            onChange={(event) => setGastronomyFilter(event.target.checked)} />
                        <FilterLabel htmlFor={'gastroFilter'}>{t('courses.gastronomy')}</FilterLabel>
                    </FilterSelection>
                </FilterContainer>
            </div>

            <InputSection>
                <StyledLabel>{t('forms.jobCatalogue')}</StyledLabel>
                <Select options={courses}
                    styles={this.colourStyles}
                    defaultValue={selectedCourses}
                    components={{ Placeholder, DropdownIndicator }}
                    styles={{
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            color: PRIMARY_COLOR,
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused || state.isSelected ? PRIMARY_COLOR : 'rgba(0,0,0,0.10)',
                            boxShadow: state.isFocused || state.isSelected ? `0 0 0 1px ${PRIMARY_COLOR}` : `none`,

                            '&:hover': {
                                borderColor: 'rgba(0,0,0,0.10)',
                            },
                        }),
                        valueContainer: (styles, { data }) => ({
                            ...styles,
                            maxHeight: 125,
                            overflow: 'scroll',
                        })
                    }}
                    onChange={selectedCourses => changeValue('selectedCourses', selectedCourses)}
                    closeMenuOnSelect={false}
                    isMulti
                    placeholder={t('forms.selectPlaceholder')}
                    isSearchable />
            </InputSection>

        </>;
    }
}

export default withTranslation()(FreelancerCourseForm);